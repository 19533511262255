import { Button, Card, Col, Form, List, Row, Spin, notification } from 'antd';
import type { RadioChangeEvent } from 'antd/es/radio/interface';
import clsx from 'clsx';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionItem } from '../../../components';
import { AnswerType, literals } from '../../../constants';
import { mapQuestionsToAnswers } from '../../../mapper/mapper-questions-answers';
import {
    getQuestionsRequest,
    setCoterieQuestionnaireData,
    setCoterieQuestionnaireState,
    setQuestionsState,
    updateQuestionnaireRequest
} from '../../../redux/actions';
import type { IFormPropTypes } from '../../../types';
import type * as RT from '../../../types/reduxTypes';
import { useQuestionnaireStyles } from '../questionnaireStyles';
import Item = List.Item;

export const QuestionsForm: FC<IFormPropTypes> = ({ prevTab, nextTab, setAreFormsUpdated }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [conditionalQuestions, setConditionalQuestions] = useState<Set<string | undefined>>(
        new Set()
    );
    const classes = useQuestionnaireStyles();
    const [forceSubmit, setForceSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const {
        questions = [],
        status,
        id,
        classCode,
        questionStatus,
        answers
    } = useSelector(
        ({
            coterieQuestionnaire: {
                data: { answers, id, classCode },
                status
            },
            questions: {
                data: { questions },
                status: questionStatus
            }
        }: RT.TRootState) => ({ answers, classCode, id, questionStatus, questions, status })
    );

    const initialValues: Record<string, boolean | string> = {};

    if (answers) {
        for (const question of answers) {
            if (question?.questionId) {
                initialValues[question.questionId] = question.answer ?? '';
            }
        }
    }

    useEffect(() => {
        if (!classCode) {
            setIsLoading(false);
            notification.error({
                description: literals.CLASS_CODE_ERROR,
                message: 'Failed'
            });
        } else if (!questions.length) {
            dispatch(getQuestionsRequest({ classCode }));
        }
    }, []);

    useEffect(() => {
        setConditionalQuestions(
            new Set<string | undefined>(
                questions
                    .map((question) =>
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        question.options?.map(
                            (option): string | undefined => option?.conditionQuestionId
                        )
                    )
                    .flat()
                    .filter((id) => id)
            )
        );
        setIsLoading(!questions.length);
    }, [questions]);

    useEffect(() => {
        if (status === 'created' || status === 'updated') {
            if (forceSubmit) {
                setForceSubmit(false);
            }
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
            nextTab();
        } else if (status === 'failed') {
            notification.error({
                description: literals.SUBMISSION_ERROR,
                message: 'Failed'
            });
            setForceSubmit(true);
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
        }
    }, [status]);

    useEffect(() => {
        if (questionStatus === 'failed') {
            notification.error({
                description: literals.QUESTION_ERRORS,
                message: 'Failed'
            });
            dispatch(setQuestionsState({ status: 'idle' }));
            setIsLoading(false);
        }
        if (questionStatus === 'loaded' && !questions.length) {
            nextTab();
            dispatch(setQuestionsState({ status: 'idle' }));
        }
    }, [questionStatus]);

    const handleSubmit = (values: Record<string, boolean | string>) => {
        if (id) {
            const answers = mapQuestionsToAnswers(values);
            const payload: Partial<RT.ICoterieQuestionnaireData> = {};
            // if (!compareAnswers(value.answers, answers)) {
            payload.answers = answers;
            // }

            // eslint-disable-next-line array-callback-return
            questions.forEach((question) => {
                if (!values[question.questionId]) {
                    // eslint-disable-next-line no-param-reassign
                    question.action.message = 'This question is required.';
                } else {
                    // eslint-disable-next-line no-param-reassign
                    question.action.message = '';
                }
            });

            if (Object.keys(payload).length !== 0) {
                dispatch(setCoterieQuestionnaireData(payload));
                dispatch(updateQuestionnaireRequest(payload));
                setAreFormsUpdated(true);
            } else {
                nextTab();
            }
        } else {
            notification.error({ description: literals.SUBMISSION_ERROR, message: 'Failed' });
        }
    };

    const handleChange = (value: RadioChangeEvent, question: RT.IQuestion) => {
        if (question.answerType === AnswerType.SELECT) {
            const conditionalOption = question.options?.find(
                (option) => option.conditionQuestionId
            );
            if (conditionalOption) {
                if (conditionalOption?.optionId === value.target.value) {
                    conditionalQuestions.delete(conditionalOption?.conditionQuestionId);
                } else {
                    conditionalQuestions.add(conditionalOption.conditionQuestionId);
                    if (conditionalOption.conditionQuestionId) {
                        const values = form.getFieldsValue();
                        values[conditionalOption.conditionQuestionId] = null;
                        form.setFieldsValue(values);
                        const innerQuestion = questions.find(
                            (question) =>
                                question.questionId === conditionalOption?.conditionQuestionId
                        );
                        const innerCondition = innerQuestion?.options?.find(
                            (option) => option.conditionQuestionId
                        );
                        // eslint-disable-next-line max-depth
                        if (innerCondition) {
                            conditionalQuestions.add(innerCondition?.conditionQuestionId);
                        }
                    }
                }
                setConditionalQuestions(() => new Set(conditionalQuestions));
            }
        }
    };

    return (
        <Card className={classes.form}>
            {isLoading ? (
                <Row justify='center'>
                    <Spin />
                </Row>
            ) : (
                <Row justify='center'>
                    <Col className='question-container' span={10}>
                        <Form form={form} initialValues={initialValues} onFinish={handleSubmit}>
                            {questions.map(
                                (question) =>
                                    !conditionalQuestions.has(question.questionId) && (
                                        <QuestionItem
                                            key={question.questionId}
                                            handleChange={handleChange}
                                            question={question}
                                        />
                                    )
                            )}

                            <Row justify='center' style={{ gap: '20px' }}>
                                <Item>
                                    <Button
                                        className={classes.backBtn}
                                        htmlType='submit'
                                        onClick={prevTab}
                                        shape='round'
                                        type='default'>
                                        Back
                                    </Button>
                                </Item>
                                <Item>
                                    <Button
                                        className={clsx(
                                            classes.continueBtn,
                                            !questions.length && 'disabled'
                                        )}
                                        disabled={!questions.length}
                                        htmlType='submit'
                                        loading={status === 'pending'}
                                        shape='round'
                                        type='default'>
                                        Continue
                                    </Button>
                                </Item>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            )}
        </Card>
    );
};

export default QuestionsForm;
