import { Form, Radio } from 'antd';
import type { FC } from 'react';
import { literals, validateMessages } from '../../constants';
import type { IFormRadioProps } from '../../types';
import { useFormElementsStyles } from './formElementsStyles';

const { Item } = Form;
export const FormRadio: FC<IFormRadioProps> = ({ label, name, showLabel = false }) => {
    const classes = useFormElementsStyles();
    return (
        <Item
            className={classes.materialFormItem}
            label={
                showLabel ? (
                    <p>
                        {label}

                        <strong>*</strong>
                    </p>
                ) : null
            }
            name={name}
            rules={[{ message: validateMessages.required, required: true }]}
            style={{
                position: 'relative'
            }}>
            <Radio.Group>
                <Radio value>{literals.YES}</Radio>

                <Radio value={false}>{literals.NO}</Radio>
            </Radio.Group>
        </Item>
    );
};
