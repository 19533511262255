import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import type { AxiosResponse } from 'axios';
import type * as RT from '../../types/reduxTypes/compliance';
import { axiosRequest } from '../../utils';
import {
    getAvailableCarriersSuccess,
    getComplianceMarketFailure,
    getComplianceMarketSuccess
} from '../actions/compliance';
import * as actTypes from '../actionTypes/compliance';

function* getComplianceMarkets({ payload: { producerCode } }: RT.IGetComplianceMarketRequest) {
    const { response, error }: { response: AxiosResponse; error: any } = yield call(
        axiosRequest,
        'GET',
        `compliance/${producerCode}`
    );
    if (response) {
        yield put(getComplianceMarketSuccess(response.data.markets));
    } else {
        yield put(getComplianceMarketFailure(error?.response?.data ?? error?.message ?? error));
    }
}

function* getAvailableCarriers({ payload }: RT.IGetAvailableCarriersRequest) {
    const { response, error } = yield call(axiosRequest, 'GET', `compliance/carriers/${payload}`);
    if (response) {
        yield put(getAvailableCarriersSuccess(response.data));
    } else {
        yield put(getComplianceMarketFailure(error?.response?.data ?? error?.message ?? error));
    }
}

export function* complianceSaga() {
    yield all([
        takeLatest(actTypes.GET_COMPLIANCE_MARKET_REQUEST, getComplianceMarkets),
        takeLatest(actTypes.GET_AVAILABLE_CARRIERS_REQUEST, getAvailableCarriers)
    ]);
}
