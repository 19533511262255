export const INDUSTRY_CLASSIFICATION = {
    'Ask Kodiak Description': {
        0: 'Porch construction, residential-type',
        1: 'Decorative steel and wrought iron work installation',
        10: 'Exhaust system (e.g., kitchens, industrial work areas) installation',
        100: 'Conveyor system installation',
        1000: 'Granges',
        1001: 'NOAA (National Oceanic and Atmospheric Administration)',
        1002: 'Membership associations, civic or social',
        1003: "Women's auxiliaries",
        1004: 'Golden age clubs',
        1005: "Senior citizens' associations, social",
        1006: 'Alumni associations',
        1007: "Parent-teachers' associations",
        1008: 'Alumni clubs',
        1009: 'Farm granges',
        101: 'Vending machine installation',
        1010: 'Booster clubs',
        1011: 'Historical clubs',
        1012: 'Public speaking improvement clubs',
        1013: 'Writing clubs',
        1014: 'Computer enthusiasts clubs',
        1015: 'University clubs',
        1016: 'Retirement associations, social',
        1017: 'Scouting organizations',
        1018: "Women's clubs",
        1019: 'Social organizations, civic and fraternal',
        102: 'Automatic gate (e.g., garage, parking lot) installation',
        1020: "Students' associations",
        1021: 'Singing societies',
        1022: 'Book discussion clubs',
        1023: 'Civic associations',
        1024: "Veterans' membership organizations",
        1025: "Speakers' clubs",
        1026: 'Garden clubs',
        1027: 'Student clubs',
        1028: 'Poetry clubs',
        1029: 'Fraternal lodges',
        103: 'Commercial-type door installation',
        1030: 'Social clubs',
        1031: 'Check room services',
        1032: 'Pet sitting services',
        1033: 'Life coach services',
        1034: 'Personal chef services',
        1035: 'Yoga instruction, camps, or schools',
        1036: 'Personal fitness training services',
        1037: 'Personal organizer services',
        1038: 'Personal shopping services',
        1039: 'Shoeshine parlors',
        104: 'Mechanical equipment insulation',
        1040: 'Shoeshine services',
        1041: 'Shopping services, personal',
        1042: 'Pet grooming services',
        1043: 'Grooming services, animal',
        1044: 'Pet boarding services',
        1045: 'Animal grooming services',
        1046: 'Better business bureaus',
        1047: "Distributors' associations",
        1048: "Bankers' associations",
        1049: 'Industrial associations',
        105: 'Pneumatic tube conveyor system installation',
        1050: 'Business associations',
        1051: "Contractors' associations",
        1052: "Shipping companies' associations",
        1053: 'Trade associations',
        1054: 'Junior chambers of commerce',
        1055: 'Construction associations',
        1056: 'Mining associations',
        1057: "Manufacturers' associations",
        1058: 'Warehousing associations',
        1059: "Farmers' unions",
        106: 'Satellite dish, household-type, installation',
        1060: "Farmers' associations",
        1061: 'Chambers of commerce',
        1062: "Insurers' associations",
        1063: "Merchants' associations",
        1064: 'Public utility associations',
        1065: 'Boards of trade',
        1066: 'Real estate boards',
        1067: "Growers' associations",
        1068: "Producers' associations",
        1069: 'Service industries associations',
        107: 'Rubber door installation',
        1070: "Animal breeders' associations",
        1071: "Wholesalers' associations",
        1072: 'Restaurant associations',
        1073: 'Agricultural organizations (except youth farming organizations, farm granges)',
        1074: "Retailers' associations",
        1075: 'Household-type laundry equipment (e.g., dryers, washers) merchant wholesalers',
        1076: 'Photofinishing equipment merchant wholesalers',
        1077: 'Computer printers merchant wholesalers',
        1078: 'Vacuum cleaner stores, household-type',
        1079: 'Art auctions',
        108: 'Power generating equipment installation',
        1080: 'Mirrors, automotive, merchant wholesalers',
        1081: 'Baked goods stores, retailing only (except immediate consumption)',
        1082: 'Cookie shops, on premise baking and carryout service',
        1083: "Hair stylist shops, men's",
        1084: 'Esthetician (i.e., skin care) services',
        1085: 'Weight loss centers, non-medical',
        1086: 'Book stores',
        1087: 'Cement merchant wholesalers',
        1088: 'Nut (i.e., packaged) stores',
        1089: 'Cleaning rugs',
        109: 'Lightning protection equipment (e.g., lightning rod) installation',
        1090: 'Diapers (except paper) merchant wholesalers',
        1091: "Work clothing, men's and boys', merchant wholesalers",
        1092: 'Shoes merchant wholesalers',
        1093: 'Sports apparel stores (except uniforms)',
        1094: "Clothing stores, men's and boys'",
        1095: "Apparel stores, women's and girls' clothing",
        1096: "Clothing stores, children's and infants'",
        1097: 'Unisex clothing stores',
        1098: 'Apparel accessory stores',
        1099: 'Leather goods stores',
        11: 'Household oil storage tank installation',
        110: 'Millwrights',
        1100: 'Copy centers (except combined with printing services)',
        1101: 'Beauty supply stores',
        1102: 'Department stores',
        1103: 'Pretzels (except frozen) merchant wholesalers',
        1104: 'Food, general-line, merchant wholesalers',
        1105: 'Toppings, fountain, merchant wholesalers',
        1106: 'Optical goods (except cameras) merchant wholesalers',
        1107: 'Brochures merchant wholesalers',
        1108: 'Durable goods business to business electronic markets, wholesale trade',
        1109: 'Apothecaries',
        111: 'Garage door, commercial- or industrial-type, installation',
        1110: 'Lugs and connectors, electrical, merchant wholesalers',
        1111: 'Video game software stores',
        1112: 'Security safes merchant wholesalers',
        1113: 'Mannequins merchant wholesalers',
        1114: 'Blackboards merchant wholesalers',
        1115: 'Upholstery fabrics merchant wholesalers',
        1116: 'Upholstery materials stores',
        1117: 'Soybeans merchant wholesalers',
        1118: 'Napkins (except paper) merchant wholesalers',
        1119: 'Rug stores',
        112: 'Boiler covering installation',
        1120: "Florist's supplies merchant wholesalers",
        1121: 'Flower shops, fresh',
        1122: 'Pretzels, frozen, merchant wholesalers',
        1123: 'Fruit stands, permanent',
        1124: 'Produce, fresh, merchant wholesalers',
        1125: 'Religious furniture merchant wholesalers',
        1126: 'Mattress stores (including waterbeds)',
        1127: 'Novelty shops',
        1128: 'Convenience food stores',
        1129: 'Staples merchant wholesalers',
        113: 'Duct insulation installation',
        1130: 'Hardware stores',
        1131: 'Nutrition (i.e., food supplement) stores',
        1132: 'Warm air heating equipment merchant wholesalers',
        1133: 'Pottery (unfinished pottery to be painted by customer on premises) stores',
        1134: 'Home improvement centers',
        1135: 'Jewelry stores, precious',
        1136: 'Laundries, coin-operated or similar self-service',
        1137: 'Power laundries, family',
        1138: 'Bed linen supply services',
        1139: 'Lock rekeying services',
        114: 'Hoisting and placement of large-scale apparatus',
        1140: 'Lawn power equipment stores',
        1141: 'Mailbox rental services combined with one or more other office support services, private',
        1142: 'Textile folding and packaging services',
        1143: 'Frozen poultry (except packaged) merchant wholesalers',
        1144: 'Frozen meats (except packaged) merchant wholesalers',
        1145: 'Frozen seafood (except packaged) merchant wholesalers',
        1146: 'Frozen meat stores',
        1147: 'Seafood markets',
        1148: 'Hospital furniture merchant wholesalers',
        1149: 'Medical equipment and supplies stores',
        115: 'Vacuum cleaning system, built-in, installation',
        1150: 'Music stores (i.e., instrument)',
        1151: 'Manicurist services',
        1152: 'News dealers',
        1153: 'Nurseries, retail, stock primarily grown off premises',
        1154: 'Sunglass stores',
        1155: 'Wallpaper and wall coverings stores',
        1156: 'Home furnishings stores',
        1157: 'Printing paper, bulk, merchant wholesalers',
        1158: 'Personal sanitary paper products merchant wholesalers',
        1159: 'Social stationery merchant wholesalers',
        116: 'Revolving door installation',
        1160: 'Sample direct distribution services',
        1161: 'Feed stores, pet',
        1162: 'Video taping services, special events (e.g., birthdays, weddings)',
        1163: 'Commercial photography services',
        1164: 'Fountains, drinking (except refrigerated), merchant wholesalers',
        1165: 'Soda fountain fixtures, refrigerated, merchant wholesalers',
        1166: 'Diners, full service',
        1167: 'Fast casual restaurants',
        1168: 'Leather goods repair shops without retailing new leather goods',
        1169: 'Shoe stores, orthopedic',
        117: 'Incinerators, building equipment type, installation',
        1170: 'Coffee and tea (i.e., packaged) stores',
        1171: 'Curtain and drapery stores, packaged',
        1172: 'Office supply stores',
        1173: 'Limited price variety stores',
        1174: 'Video tape rental stores',
        1175: "Architects' associations",
        1176: 'Computer software analysis and design services, custom',
        1177: 'CAM (computer-aided manufacturing) systems integration design services',
        1178: 'Stock photo agencies',
        1179: 'Private equity fund managing',
        118: 'Dismantling large-scale machinery and equipment',
        1180: 'Investment advice consulting services, customized, fees paid by client',
        1181: "Public accountants' (CPAs) offices, certified",
        1182: 'Income tax return preparation services',
        1183: "Accountants' (except CPAs) offices",
        1184: 'Silk screen design services',
        1185: 'Float design services',
        1186: 'Site location consulting services',
        1187: 'Marketing consulting services',
        1188: 'Tariff rate consulting services',
        1189: 'Utilities management consulting services',
        119: 'Vault, safe and banking machine installation',
        1190: 'Radio transcription services',
        1191: 'Computer input preparation services',
        1192: "Data processing facilities (i.e., clients' facilities) management and operation services",
        1193: 'Undertaker services',
        1194: 'Pain therapy centers and clinics, outpatient',
        1195: 'Insurance brokerages',
        1196: 'Flame resistant clothing supply services',
        1197: 'Catalog of collections publishers (except exclusive Internet publishing)',
        1198: 'Advertising material preparation services for mailing or other direct distribution',
        1199: 'Pathology laboratories, medical',
        12: 'Heating boiler installation',
        120: 'Commercial kitchen food preparation equipment (e.g., mixers, ovens, stoves) installation',
        1200: 'Radiological laboratory services, medical',
        1201: 'Real estate agencies',
        1202: 'Real estate asset management services (except property management)',
        1203: 'Title abstract companies, real estate',
        1204: 'Managing residential condominiums',
        1205: "Managers' offices, commercial condominium",
        1206: 'Audio recording postproduction services',
        1207: 'Music program distribution, prerecorded',
        1208: 'Managing offices of dentists',
        1209: 'Offices of notaries',
        121: 'Antenna, household-type, installation',
        1210: 'Advertising agencies',
        1211: 'Advertising media representatives (i.e., independent of media owners)',
        1212: 'Political opinion polling services',
        1213: 'Sign language services',
        1214: 'Telephone call centers',
        1215: 'Closed captioning services, real-time (i.e., simultaneous)',
        1216: 'Fundraising campaign organization services on a contract or fee basis',
        1217: 'Travel management services',
        1218: 'Study abroad programs',
        1219: 'Media buying agencies',
        122: 'Rigging large-scale equipment',
        1220: 'Automatic merchandising machine operators',
        1221: 'Electronic communications equipment merchant wholesalers',
        1222: 'Games, computer software, publishing',
        1223: 'Butter merchant wholesalers',
        1224: 'Printing, screen (except books, manifold business forms, grey goods)',
        1225: 'Pamphlets printing without publishing',
        1226: 'Postpress services (e.g., beveling, bronzing, folding, gluing, edging, foil stamping, gilding) on printed materials',
        1227: 'Publishers, newspaper, combined with printing',
        1228: 'School book publishers and printing combined',
        1229: 'Greeting card publishers and printing combined',
        123: 'Parking lot paving and sealing',
        1230: 'Religious magazine and periodical publishers and printing combined',
        1231: 'Poster publishers (except exclusive Internet publishing)',
        1232: 'Publishers, music',
        1233: 'Integrated record production and distribution',
        1234: 'Family law offices',
        1235: "Notary publics' private practices",
        1236: "Surgeons' (except dental) offices (e.g., centers, clinics)",
        1237: "Pediatricians', mental health, offices (e.g., centers, clinics)",
        1238: "Oral and maxillofacial surgeons' offices (e.g., centers, clinics)",
        1239: "DCs' (doctors of chiropractic) offices (e.g., centers, clinics)",
        124: 'Concrete paving, residential and commercial driveway and parking area',
        1240: 'Doctors of optometry (ODs) offices (e.g., centers, clinics)',
        1241: "Psychoanalysts' (except MDs or DOs) offices (e.g., centers, clinics)",
        1242: "Physiotherapists' offices (e.g., centers, clinics)",
        1243: 'Doctors of podiatry (DPs) offices (e.g., centers, clinics)',
        1244: "Registered nurses' (RNs) offices (e.g., centers, clinics)",
        1245: 'Renal dialysis centers and clinics',
        1246: 'Medical care management services',
        1247: 'Indoor display advertising services',
        1248: 'Water heater repair and maintenance services without retailing new water heaters',
        1249: 'Finish carpentry',
        125: 'Brick paver (e.g., driveways, patios, sidewalks) installation',
        1250: 'Computer disaster recovery services',
        1251: 'Computer peripheral equipment repair and maintenance, without retailing new computer peripheral equipment',
        1252: "Draftsmen's offices",
        1253: 'Electrical equipment and appliance installation',
        1254: 'Public address system repair and maintenance services',
        1255: 'Architectural (except landscape) design services',
        1256: "Urban planners' offices",
        1257: 'Heating engineering consulting services',
        1258: 'Tool industrial design services',
        1259: 'Environmental consulting services',
        126: 'Blacktop work, residential and commercial driveway and parking area',
        1260: 'Radioactive geophysical surveying services',
        1261: 'Insurance claims investigation services',
        1262: 'Appraisal services, real estate',
        1263: 'Home inspection services',
        1264: 'Appraisal (except real estate) services',
        1265: 'Attic space insulating',
        1266: 'Interior design services',
        1267: 'Aircraft janitorial services',
        1268: 'Dental research and development laboratories or services',
        1269: 'Language research and development services',
        127: 'Sidewalk construction, residential and commercial',
        1270: 'Radar and sonar equipment repair and maintenance services',
        1271: 'Small engine repair and maintenance shops',
        1272: 'Foundation (e.g., brick, block, stone), building, contractors',
        1273: 'Motion picture film laboratories',
        1274: 'Tape distribution for television',
        1275: 'Motion picture production',
        1276: 'House painting',
        1277: 'Vibration isolation contractors',
        1278: 'Sign lettering and painting services',
        1279: 'Geographic information system (GIS) base mapping services',
        128: 'Flagpole installation',
        1280: 'Telephone call forwarding services',
        1281: 'Motion picture or video editing services',
        1282: 'Radio repair, automotive, without retailing new',
        1283: 'Dance theaters',
        1284: 'Parquet flooring installation',
        1285: 'Slate, interior, installation',
        1286: 'Livestock veterinary services',
        1287: 'Commercial printing (except screen, books)',
        1288: 'Costume jewelry merchant wholesalers',
        1289: 'Human resource consulting services',
        129: 'Driveway paving or sealing',
        1290: 'Bed and breakfast inns',
        1291: 'Food carts, mobile',
        13: 'Plumbing fixture installation',
        130: 'Paving, residential and commercial driveway and parking lot',
        131: 'Chain link fence installation',
        132: 'Fence installation (except electronic containment fencing for pets)',
        133: 'Culvert, concrete, residential and commercial paved area',
        134: 'Concrete patio construction',
        135: 'Fencing contractors (except electronic containment fencing for pets)',
        136: 'Brick driveway contractors',
        137: 'Street, interlocking brick (i.e., not mortared), installation',
        138: 'Asphalt coating and sealing, residential and commercial parking lot and driveway',
        139: 'Curb and gutter construction, residential and commercial driveway and parking area, concrete',
        14: 'Snow melting system (e.g., hot water, glycol) installation',
        140: 'Pole (e.g., telephone) removal',
        141: 'Mail box units, outdoor, multiple box-type, erection',
        142: 'Asphalting, residential and commercial driveway and parking area',
        143: 'Interlocking brick and block installation',
        144: 'Posthole digging',
        145: 'Concrete sawing and drilling (except demolition)',
        146: 'Patio construction',
        147: 'Sandblasting, building exterior',
        148: 'Paver, brick (e.g., driveway, patio, sidewalk), installation',
        149: 'Artificial turf installation',
        15: 'Dry heating equipment installation',
        150: 'Cable splicing (except electrical or fiber optic)',
        151: 'Cleaning building interiors during and immediately after construction',
        152: 'Cleaning new building interiors immediately after construction',
        153: 'Playground equipment installation',
        154: 'Safety net system, erecting and dismantling at construction site',
        155: 'Turf, artificial, installation',
        156: 'Frozen food and freezer meal plan providers, direct selling',
        157: 'Cafeterias',
        158: 'Kitchen cabinets, built-in, merchant wholesalers',
        159: 'Lumber (e.g., dressed, finished, rough) merchant wholesalers',
        16: 'Water heater installation',
        160: 'Molding (e.g., sheet metal, wood) merchant wholesalers',
        161: 'Screens, window and door, merchant wholesalers',
        162: 'Plywood merchant wholesalers',
        163: 'Wood flooring merchant wholesalers',
        164: 'Fiberboard merchant wholesalers',
        165: 'Wood shingles merchant wholesalers',
        166: 'Veneer, wood, merchant wholesalers',
        167: 'Drywall board merchant wholesalers',
        168: 'Doors and door frames merchant wholesalers',
        169: 'Railings, wood, merchant wholesalers',
        17: 'Ventilating contractors',
        170: 'Fencing, wood, merchant wholesalers',
        171: 'Paneling merchant wholesalers',
        172: 'Wallboard merchant wholesalers',
        173: 'Stairs, wood, merchant wholesalers',
        174: 'Building board (e.g., fiber, flake, particle) merchant wholesalers',
        175: 'Particleboard merchant wholesalers',
        176: 'Millwork merchant wholesalers',
        177: 'Laminates, wood, merchant wholesalers',
        178: 'Countertops (except granite) merchant wholesalers',
        179: 'Wood siding merchant wholesalers',
        18: 'Dust collecting and bag house equipment installation',
        180: 'Cabinets, kitchen, built-in, merchant wholesalers',
        181: 'Wood fencing merchant wholesalers',
        182: 'Flooring, wood, merchant wholesalers',
        183: 'Buildings, prefabricated wood, merchant wholesalers',
        184: 'Siding, wood, merchant wholesalers',
        185: 'Siding (except wood) merchant wholesalers',
        186: 'Ceramic and clay roofing materials merchant wholesalers',
        187: 'Weather stripping merchant wholesalers',
        188: 'Eavestroughing merchant wholesalers',
        189: 'House wrapping insulation materials merchant wholesalers',
        19: 'HVAC (heating, ventilation and air-conditioning) contractors',
        190: 'Sheet metal roofing materials merchant wholesalers',
        191: 'Shingles (except wood) merchant wholesalers',
        192: 'Gutters and down spouts (except wood) merchant wholesalers',
        193: 'Asphalt roofing shingles merchant wholesalers',
        194: 'Thermal wrap, house, merchant wholesalers',
        195: 'Vinyl siding merchant wholesalers',
        196: 'Tarred felts merchant wholesalers',
        197: 'Sound insulation merchant wholesalers',
        198: 'Soffit, building (except wood), merchant wholesalers',
        199: 'Asphalt felts and coatings merchant wholesalers',
        2: 'Metal furring contractors',
        20: 'Sprinkler system, building, installation',
        200: 'Insulation materials (except wood) merchant wholesalers',
        201: 'Bailey bridges merchant wholesalers',
        202: 'Fencing and fencing accessories, wire, merchant wholesalers',
        203: 'Ornamental ironwork merchant wholesalers',
        204: 'Wire fencing and fencing accessories merchant wholesalers',
        205: 'Silt fence and other fabrics (e.g., for erosion control) merchant wholesalers',
        206: 'Septic tanks (except concrete) merchant wholesalers',
        207: 'Glass, plate, merchant wholesalers',
        208: 'Paper, building, merchant wholesalers',
        209: 'Gypsum building products merchant wholesalers',
        21: 'Industrial process piping installation',
        210: 'Flat glass merchant wholesalers',
        211: 'Mastics, construction, merchant wholesalers',
        212: 'Fiberglass building materials (except insulation, roofing, siding) merchant wholesalers',
        213: 'Iron and steel architectural shapes merchant wholesalers',
        214: 'Gate and fence hardware merchant wholesalers',
        215: 'Architectural metalwork merchant wholesalers',
        216: 'Storage bins merchant wholesalers',
        217: 'Bins, storage, merchant wholesalers',
        218: 'Glass, block and brick, merchant wholesalers',
        219: 'Building paper merchant wholesalers',
        22: 'Pumping system, water, installation',
        220: 'Awnings (except canvas) merchant wholesalers',
        221: 'Plate glass merchant wholesalers',
        222: 'Fencing (except wood) merchant wholesalers',
        223: 'Ceiling tile merchant wholesalers',
        224: 'Poles, metal, merchant wholesalers',
        225: 'Copper and copper alloy primary forms merchant wholesalers',
        226: 'Nails merchant wholesalers',
        227: 'Metals, ferrous and nonferrous, merchant wholesalers',
        228: 'Posts, metal, merchant wholesalers',
        229: 'Aluminum foil, plate, sheet, coil, and roll products merchant wholesalers',
        23: 'Fuel oil burner installation',
        230: 'Black plate merchant wholesalers',
        231: 'Ferrous metals merchant wholesalers',
        232: 'Grinding balls merchant wholesalers',
        233: 'Billets, metal, merchant wholesalers',
        234: 'Steel wire cloth (screening) merchant wholesalers',
        235: 'Wire screening merchant wholesalers',
        236: 'Tin plate merchant wholesalers',
        237: 'Strips, metal (except precious), merchant wholesalers',
        238: 'Rails and accessories, metal, merchant wholesalers',
        239: 'Galvanized iron and steel products merchant wholesalers',
        24: 'Heat pump installation',
        240: 'Metals sales offices',
        241: 'Blooms, metal, merchant wholesalers',
        242: 'Storage tanks, metal, merchant wholesalers',
        243: 'Wire and cable (except electrical) merchant wholesalers',
        244: 'Steel merchant wholesalers',
        245: 'Metal pipe merchant wholesalers',
        246: 'Metal products (e.g., bars, ingots, plates, rods, shapes, sheets) merchant wholesalers',
        247: 'Pipe, metal, merchant wholesalers',
        248: 'Ropes, wire (except insulated), merchant wholesalers',
        249: 'Bars, metal (except precious), merchant wholesalers',
        25: 'Sanitary ware installation',
        250: 'Tanks, storage metal, merchant wholesalers',
        251: 'Castings, metal, merchant wholesalers',
        252: 'Carbon and alloy steel primary forms merchant wholesalers',
        253: 'Aluminum and aluminum alloy primary forms merchant wholesalers',
        254: 'Pilings, metal, merchant wholesalers',
        255: 'Noninsulated wire merchant wholesalers',
        256: 'Terneplate merchant wholesalers',
        257: 'Wire (except insulated) merchant wholesalers',
        258: 'Semi-finished metal products merchant wholesalers',
        259: 'Cable, wire (except insulated), merchant wholesalers',
        26: 'Warehouse refrigeration system installation',
        260: 'Tin and tin alloy primary forms merchant wholesalers',
        261: 'Spikes, metal, merchant wholesalers',
        262: 'Steel wool merchant wholesalers',
        263: 'Cast iron pipe merchant wholesalers',
        264: 'Bearing piles, metal, merchant wholesalers',
        265: 'Stainless steel merchant wholesalers',
        266: 'Rods, metal (except precious), merchant wholesalers',
        267: 'Tubing, metal, merchant wholesalers',
        268: 'Ferroalloys merchant wholesalers',
        269: 'Reinforcement mesh and wire merchant wholesalers',
        27: 'Radiant floor heating equipment installation',
        270: 'Concrete reinforcing bars merchant wholesalers',
        271: 'Mops, industrial, merchant wholesalers',
        272: 'Funeral home supplies merchant wholesalers',
        273: 'Beauty parlor equipment and supplies merchant wholesalers',
        274: 'Hair preparations, professional, merchant wholesalers',
        275: 'Taxidermy supplies merchant wholesalers',
        276: 'Boot and shoe cut stock and findings merchant wholesalers',
        277: 'Burial caskets merchant wholesalers',
        278: 'Service establishment equipment and supplies merchant wholesalers',
        279: 'Manicurists supplies merchant wholesalers',
        28: 'Heating equipment installation',
        280: 'Barber shop equipment and supplies merchant wholesalers',
        281: 'Coffins merchant wholesalers',
        282: 'Fare boxes, public transit vehicle, merchant wholesalers',
        283: "Upholsterers' equipment and supplies (except fabrics) merchant wholesalers",
        284: 'Floor maintenance equipment merchant wholesalers',
        285: 'Carpet cleaning equipment and supplies merchant wholesalers',
        286: 'Janitorial equipment and supplies merchant wholesalers',
        287: 'Chairs, hydraulic, beauty and barber shop, merchant wholesalers',
        288: "Tailors' supplies merchant wholesalers",
        289: 'Clothes dryers (except household-type) merchant wholesalers',
        29: 'Sewer hookup and connection, building',
        290: "Undertakers' equipment and supplies merchant wholesalers",
        291: 'Laundry machinery, equipment, and supplies, commercial, merchant wholesalers',
        292: 'Meters, parking, merchant wholesalers',
        293: 'Car wash equipment and supplies merchant wholesalers',
        294: 'Firefighting equipment and supplies merchant wholesalers',
        295: 'Locksmith equipment and supplies merchant wholesalers',
        296: 'Patterns, shoe, merchant wholesalers',
        297: 'Water treatment equipment, municipal, merchant wholesalers',
        298: 'Caskets, burial, merchant wholesalers',
        299: 'Shoe repair materials merchant wholesalers',
        3: 'Concrete form contractors',
        30: 'Sheet metal duct work installation',
        300: 'Brooms (except household-type) merchant wholesalers',
        301: 'Law enforcement equipment (except safety) merchant wholesalers',
        302: 'Sprinkler systems, fire, merchant wholesalers',
        303: 'Furriers equipment and supplies merchant wholesalers',
        304: 'Vacuum cleaning systems, commercial, merchant wholesalers',
        305: 'Padding, upholstery filling, merchant wholesalers',
        306: 'Drycleaning equipment and supplies merchant wholesalers',
        307: 'Tennis equipment and supplies merchant wholesalers',
        308: 'Marine supplies, pleasure, merchant wholesalers',
        309: 'Hot tubs merchant wholesalers',
        31: 'Pipe fitting contractors',
        310: 'Pool (billiards) tables and supplies merchant wholesalers',
        311: 'Casino supplies merchant wholesalers',
        312: 'Baseball equipment and supplies merchant wholesalers',
        313: 'Fishing equipment and supplies (except commercial) merchant wholesalers',
        314: 'Bait, artificial, merchant wholesalers',
        315: 'Bowling equipment and supplies merchant wholesalers',
        316: 'Fitness equipment and supplies merchant wholesalers',
        317: 'Gymnasium equipment merchant wholesalers',
        318: 'Billiard equipment and supplies merchant wholesalers',
        319: 'Golf equipment and supplies merchant wholesalers',
        32: 'Central cooling equipment and piping installation',
        320: 'Athletic uniforms merchant wholesalers',
        321: 'Hockey equipment and supplies merchant wholesalers',
        322: 'Camping equipment and supplies merchant wholesalers',
        323: 'Cameramen, independent (freelance)',
        324: 'Lecturers, independent',
        325: 'Briefcases merchant wholesalers',
        326: 'Musical recordings (e.g., compact discs, records, tapes) merchant wholesalers',
        327: 'Safety devices (e.g., eye shields, face shields) merchant wholesalers',
        328: 'Fire extinguisher sales combined with rental and/or service, merchant wholesalers',
        329: 'Musical instruments merchant wholesalers',
        33: 'Boiler, heating, installation',
        330: 'Signs (except electrical) merchant wholesalers',
        331: 'Games, coin-operated, merchant wholesalers',
        332: 'Logs merchant wholesalers',
        333: 'Safety deposit boxes merchant wholesalers',
        334: 'Wood products (e.g., chips, posts, shavings, ties) merchant wholesalers',
        335: 'Luggage merchant wholesalers',
        336: 'Billboards merchant wholesalers',
        337: 'Extinguishers, fire, merchant wholesalers',
        338: 'Fire extinguishers merchant wholesalers',
        339: 'Forest products (except lumber) merchant wholesalers',
        34: 'Water softener installation',
        340: 'Tombstones merchant wholesalers',
        341: 'Musical instrument accessories and supplies merchant wholesalers',
        342: 'Gas lighting fixtures merchant wholesalers',
        343: 'Audio and video tapes and disks, prerecorded, merchant wholesalers',
        344: 'Digital video discs (DVDs), prerecorded, merchant wholesalers',
        345: 'Timber and timber products (except lumber) merchant wholesalers',
        346: 'Mailboxes merchant wholesalers',
        347: 'Coin-operated game machines merchant wholesalers',
        348: 'Ordnance and accessories merchant wholesalers',
        349: 'Compact discs (CDs), prerecorded, merchant wholesalers',
        35: 'Refrigeration system (e.g., commercial, industrial, scientific) installation',
        350: 'General merchandise, durable goods, merchant wholesalers',
        351: 'Cassettes, prerecorded audio and video, merchant wholesalers',
        352: 'Video tapes, prerecorded, merchant wholesalers',
        353: 'Monuments and grave markers merchant wholesalers',
        354: 'Phonograph records merchant wholesalers',
        355: 'Instruments, musical, merchant wholesalers',
        356: 'Prerecorded audio and video tapes and discs merchant wholesalers',
        357: 'Suitcases merchant wholesalers',
        358: 'Lighting equipment, gas, merchant wholesalers',
        359: 'Roundwood merchant wholesalers',
        36: 'Furnace installation',
        360: 'Tapes, prerecorded, audio or video, merchant wholesalers',
        361: 'Perfumes merchant wholesalers',
        362: 'Beauty supplies merchant wholesalers',
        363: 'Medical sundries, rubber, merchant wholesalers',
        364: 'First-aid kits (except industrial) merchant wholesalers',
        365: 'Razor blades merchant wholesalers',
        366: 'Rubber goods, medical, merchant wholesalers',
        367: 'Toothbrushes (except electric) merchant wholesalers',
        368: 'Dental care preparations merchant wholesalers',
        369: 'Skin care preparations merchant wholesalers',
        37: 'Steam fitting contractors',
        370: 'Razors (except electric) merchant wholesalers',
        371: 'Deodorants, personal, merchant wholesalers',
        372: 'Colognes merchant wholesalers',
        373: 'Beauty preparations merchant wholesalers',
        374: 'Antiseptics merchant wholesalers',
        375: 'Toothpastes merchant wholesalers',
        376: 'Hair preparations (except professional) merchant wholesalers',
        377: 'First-aid supplies merchant wholesalers',
        378: 'Dentifrices merchant wholesalers',
        379: 'Gauze merchant wholesalers',
        38: 'Duct work (e.g., cooling, dust collection, exhaust, heating, ventilation) installation',
        380: 'Salts, bath, merchant wholesalers',
        381: 'Blades, razor, merchant wholesalers',
        382: 'Cosmetics merchant wholesalers',
        383: 'Shaving preparations merchant wholesalers',
        384: 'Toiletries merchant wholesalers',
        385: 'Toilet soaps merchant wholesalers',
        386: "Druggists' sundries merchant wholesalers",
        387: 'Hygiene products, oral, merchant wholesalers',
        388: 'Toilet preparations merchant wholesalers',
        389: 'Biologicals and allied products merchant wholesalers',
        39: 'Process piping installation',
        390: 'Hair care products merchant wholesalers',
        391: 'Bandages (except surgical) merchant wholesalers',
        392: 'Flower bulbs merchant wholesalers',
        393: 'Harness equipment merchant wholesalers',
        394: 'Agricultural limestone merchant wholesalers',
        395: 'Hay merchant wholesalers',
        396: 'Phosphate rock, ground, merchant wholesalers',
        397: 'Alfalfa merchant wholesalers',
        398: 'Lime, agricultural, merchant wholesalers',
        399: 'Saddlery merchant wholesalers',
        4: 'Shutter installation',
        40: 'Water system balancing and testing contractors',
        400: 'Horticultural products merchant wholesalers',
        401: 'Plant bulbs merchant wholesalers',
        402: 'Produce containers merchant wholesalers',
        403: 'Tack (e.g., harnesses, saddlery) merchant wholesalers',
        404: 'Farm supplies merchant wholesalers',
        405: 'Straw merchant wholesalers',
        406: 'Garden supplies (e.g., fertilizers, pesticides) merchant wholesalers',
        407: 'Beekeeping supplies merchant wholesalers',
        408: 'Paint rollers merchant wholesalers',
        409: 'Wallpaper merchant wholesalers',
        41: 'Fire extinguisher installation and repair',
        410: 'Paintbrushes merchant wholesalers',
        411: 'Wigs merchant wholesalers',
        412: 'Toupees merchant wholesalers',
        413: 'Jewelry boxes merchant wholesalers',
        414: 'Rennets merchant wholesalers',
        415: 'Piece goods, burlap and felt, merchant wholesalers',
        416: 'Bags, textile, merchant wholesalers',
        417: 'Hairbrushes merchant wholesalers',
        418: 'Skins, dressed, merchant wholesalers',
        419: 'General merchandise, nondurable goods, merchant wholesalers',
        42: 'Water meter installation',
        420: 'Wood carvings merchant wholesalers',
        421: 'Ice (except dry ice) merchant wholesalers',
        422: 'Hairpieces (e.g., toupees, wigs, wiglets) merchant wholesalers',
        423: 'Yarns, industrial, merchant wholesalers',
        424: 'Canvas products merchant wholesalers',
        425: 'Statuary (except religious) merchant wholesalers',
        426: 'Candles merchant wholesalers',
        427: 'Dressed furs and skins merchant wholesalers',
        428: 'Tents (except camping) merchant wholesalers',
        429: 'Sponges merchant wholesalers',
        43: 'Lawn sprinkler system installation',
        430: 'Leather goods (except belting, footwear, handbags, gloves, luggage) merchant wholesalers',
        431: 'Art goods merchant wholesalers',
        432: 'Wood pulp merchant wholesalers',
        433: 'Curios merchant wholesalers',
        434: 'Sawdust merchant wholesalers',
        435: 'Ship chandler merchant wholesalers',
        436: 'Handles (e.g., broom, mop, paint) merchant wholesalers',
        437: "Artists' supplies merchant wholesalers",
        438: 'Vegetable cake and meal merchant wholesalers',
        439: 'Textile bags merchant wholesalers',
        44: 'Oil burner installation',
        440: 'Clothes hangers merchant wholesalers',
        441: 'Artificial Christmas trees merchant wholesalers',
        442: 'Sheet music merchant wholesalers',
        443: 'Awnings, canvas, merchant wholesalers',
        444: 'Leather cut stock (except boot, shoe) merchant wholesalers',
        445: 'Novelties merchant wholesalers',
        446: 'Felt merchant wholesalers',
        447: 'Burlap merchant wholesalers',
        448: "Paints, artist's, merchant wholesalers",
        449: 'Christmas ornaments merchant wholesalers',
        45: 'Hot water tank installation',
        450: 'Music, sheet, merchant wholesalers',
        451: 'Backpacks, textile, merchant wholesalers',
        452: 'Furs, dressed, merchant wholesalers',
        453: 'Baskets merchant wholesalers',
        454: 'Cotton (except raw) merchant wholesalers',
        455: 'Plant food merchant wholesalers',
        456: "Brushes, artists', merchant wholesalers",
        457: 'Pre-paid calling card distribution, merchant wholesalers',
        458: 'Chamois, leather, merchant wholesalers',
        459: 'Pottery, novelty, merchant wholesalers',
        46: 'Fire sprinkler system installation',
        460: 'Auto supply stores',
        461: 'Stereo stores, automotive',
        462: 'Truck cap stores',
        463: 'Automobile parts dealers',
        464: 'Parts and accessories dealers, automotive',
        465: 'Automotive parts and supply stores',
        466: 'Batteries, automotive, dealers',
        467: 'Automotive audio equipment stores',
        468: 'Lumber retailing yards',
        469: 'Door stores',
        47: 'Fire extinguisher installation',
        470: 'Lighting fixture stores',
        471: 'Glass stores',
        472: 'Tile stores, ceramic',
        473: 'Hardwood flooring dealers',
        474: 'Building materials supply dealers',
        475: 'Electrical supply stores',
        476: 'Plumbing supply stores',
        477: 'Masonry (e.g., block, brick, stone) dealers',
        478: 'Cabinet stores, kitchen (except custom), to be installed',
        479: 'Fencing dealers',
        48: 'Hydronic heating system installation',
        480: 'Floor covering stores, wood or ceramic tile only',
        481: 'Siding dealers',
        482: 'Kitchen cabinet (except custom) stores',
        483: 'Ceramic tile stores',
        484: 'Ceiling fan stores',
        485: 'Garage door dealers',
        486: 'Exercise equipment stores',
        487: 'Sports gear stores (e.g., outdoors, scuba, skiing)',
        488: 'Tackle shops (i.e., fishing)',
        489: 'Athletic equipment and supply stores (including uniforms)',
        49: 'Air vent installation',
        490: 'Uniform stores, athletic',
        491: 'Footwear (e.g., bowling, golf, spiked), specialty sports, stores',
        492: 'Pro shops (e.g., golf, skiing, tennis)',
        493: 'Sporting goods stores',
        494: 'Bicycle (except motorized) shops',
        495: 'Fishing supply stores (e.g., bait)',
        496: 'Golf pro shops',
        497: 'Outdoor sporting equipment stores',
        498: 'Diving equipment stores',
        499: 'Shoe stores, specialty sports footwear (e.g., bowling, golf, spiked)',
        5: 'Storefront, metal or metal frame, installation',
        50: 'Plumbing and heating contractors',
        500: 'Saddlery stores',
        501: 'Tack (e.g., harnesses, saddlery) shops',
        502: 'Bowling equipment and supply stores',
        503: 'Supercenters',
        504: "Used rare collectors' items (e.g., autograph, coin, card, stamps) shops",
        505: 'Consignment shops, used merchandise',
        506: 'Bicycle (except motorized) shops, used',
        507: 'Antique dealers (except motor vehicles)',
        508: 'Book stores, used',
        509: 'Apparel stores, used clothing',
        51: 'Heating and cooling duct work installation',
        510: 'Appliance stores, household-type, used',
        511: 'Record stores, used',
        512: 'Clothing stores, used',
        513: 'Architectural salvage dealers',
        514: 'Music stores (e.g., cassette, instrument, record, tape), used',
        515: 'Secondhand merchandise stores',
        516: 'Rare manuscript stores',
        517: 'Antique shops',
        518: 'Sporting goods stores, used',
        519: 'Furniture stores, used',
        52: 'Hot water heating system installation',
        520: 'Trophy (including awards and plaques) shops',
        521: 'Architectural supply stores',
        522: "Collectors' items (e.g., autograph, card, coin, stamp) shops (except used rare items)",
        523: 'Flag and banner shops',
        524: 'Cemetery memorial dealers (e.g., headstones, markers, vaults)',
        525: 'Swimming pool supply stores',
        526: 'Monument (i.e., burial marker) dealers',
        527: 'Candle shops',
        528: 'Janitorial equipment and supplies stores',
        529: 'Emergency preparedness supply stores',
        53: 'Blower or fan, cooling and dry heating, installation',
        530: 'Cake decorating supply stores',
        531: 'Closet organizer stores',
        532: 'Batteries, except automotive, dealers',
        533: 'Religious goods (except books) stores',
        534: 'Art supply stores',
        535: 'Flower shops, artificial or dried',
        536: 'Calendar shops',
        537: 'Binocular stores',
        538: 'Auction houses (general merchandise)',
        539: 'Bazaars (i.e., temporary stands)',
        54: 'Central air-conditioning equipment installation',
        540: 'In-home sales of merchandise, direct selling',
        541: 'Locker meat provisioners, direct selling',
        542: 'Bottled water providers, direct selling',
        543: 'Party plan merchandisers, direct selling',
        544: 'Fruit stands, temporary',
        545: 'Produce stands, temporary',
        546: 'Water softener service providers, direct selling',
        547: 'Christmas trees, cut, direct selling',
        548: 'Street vendors (except food)',
        549: 'Coffee-break supplies providers, direct selling',
        55: 'Furnace, forced air, installation',
        550: 'Door-to-door retailing of merchandise, direct selling',
        551: 'Claims processing services, insurance, third party',
        552: 'Recording technicians, independent',
        553: 'Hobby craft kits merchant wholesalers',
        554: 'Insurance actuarial services',
        555: 'Film actors, independent',
        556: 'Trading cards merchant wholesalers',
        557: 'Hydrology consulting services',
        558: 'Journalists, independent (freelance)',
        559: 'Dancers, independent',
        56: 'Chimney liner installation',
        560: 'Radio commentators, independent',
        561: 'Fingerprint services',
        562: 'Ballet dancers, independent',
        563: 'Television producers, independent',
        564: 'Mental health centers and clinics (except hospitals), outpatient',
        565: 'Political cartoonists, independent',
        566: 'Card games merchant wholesalers',
        567: 'Cinematographers, independent',
        568: 'Outpatient mental health centers and clinics (except hospitals)',
        569: 'Comedians, independent',
        57: 'Mechanical contractors',
        570: 'Choreographers, independent',
        571: 'Screenplay writers, independent',
        572: 'Artists (except commercial, musical), independent',
        573: 'Disc jockeys, independent',
        574: 'Art restorers, independent',
        575: 'Safety consulting services',
        576: "Hobbyists' supplies merchant wholesalers",
        577: 'Video games merchant wholesalers',
        578: 'Special effect technicians, independent',
        579: 'Insurance claims processing services, third party',
        58: 'Heating and ventilation system component (e.g., air registers, diffusers, filters, grilles, sound attenuators) installation',
        580: 'Performing artists, independent',
        581: 'Electronic games merchant wholesalers',
        582: 'Urgent medical care centers and clinics (except hospitals), freestanding',
        583: 'Scenery designers, independent theatrical',
        584: 'Livestock breeding consulting services',
        585: 'Agrology consulting services',
        586: 'Actors, independent',
        587: 'Independent technical writers',
        588: 'Motivational speakers, independent',
        589: 'Producers, independent',
        59: 'Furnace humidifier installation',
        590: 'Cartoonists, independent',
        591: 'Music archives',
        592: 'Medical cost evaluation services',
        593: 'Directors, independent motion picture',
        594: 'Storytellers, independent',
        595: 'Sculptors, independent',
        596: 'Video tape player rental',
        597: 'Orchestra conductors, independent',
        598: 'Performers (i.e., entertainers), independent',
        599: 'Childbirth preparation classes',
        6: 'Awning installation',
        60: 'Plumbing contractors',
        600: 'Toys (including electronic) merchant wholesalers',
        601: 'Toy furniture merchant wholesalers',
        602: 'Music arrangers, independent',
        603: 'Puzzles merchant wholesalers',
        604: 'Entomology consulting services',
        605: 'Playwrights, independent',
        606: 'Insurance underwriters laboratories and standards services',
        607: 'Craft kits merchant wholesalers',
        608: 'Playing cards merchant wholesalers',
        609: 'Actuarial services, insurance',
        61: 'Boiler chipping, cleaning and scaling',
        610: 'Vibration testing laboratories or services',
        611: 'Geotechnical testing laboratories or services',
        612: 'Soil testing laboratories or services',
        613: 'Mechanical testing laboratories or services',
        614: 'Acoustics testing laboratories or services',
        615: 'Industrial testing laboratories or services',
        616: "Fire insurance underwriters' laboratories",
        617: 'Product testing laboratories or services',
        618: 'Electrical testing laboratories or services',
        619: 'Radiographic testing laboratories or services',
        62: 'Heating contractors',
        620: 'Nondestructive testing laboratories or services',
        621: 'Thermal testing laboratories or services',
        622: 'Biological (except medical, veterinary) testing laboratories or services',
        623: 'Seed testing laboratories or services',
        624: 'Electronic testing laboratories or services',
        625: 'Forensic (except medical) laboratories or services',
        626: 'Laboratory testing (except medical, veterinary) services',
        627: 'Environmental testing laboratories or services',
        628: 'Metallurgical testing laboratories or services',
        629: 'Pollution testing (except automotive emissions testing) services',
        63: 'Commercial freezer installation',
        630: 'X-ray inspection services',
        631: 'Radiation testing laboratories or services',
        632: 'Food testing laboratories or services',
        633: 'Testing laboratories (except medical, veterinary)',
        634: 'Radiographing welded joints on pipes and fittings',
        635: 'Assaying services',
        636: 'Film badge testing (i.e., radiation testing) laboratories or services',
        637: 'Hydrostatic testing laboratories or services',
        638: 'Radiography inspection services',
        639: 'Calibration and certification testing laboratories or services',
        64: 'Commercial refrigeration system installation',
        640: 'Radiation dosimetry (i.e., radiation testing) laboratories or services',
        641: 'Motion picture producers, independent',
        642: 'Artists (i.e., painters), independent',
        643: 'Geochemical consulting services',
        644: 'Entertainers, independent',
        645: 'Magicians, independent',
        646: 'Agronomy consulting services',
        647: 'Ratemaking services, insurance',
        648: 'Counseling services, family planning',
        649: 'Jazz dancers, independent',
        65: 'Kitchen sink and hardware installation',
        650: 'Photojournalists, independent (freelance)',
        651: "Vehicles, children's (except bicycles), merchant wholesalers",
        652: 'Model kits merchant wholesalers',
        653: 'Record producers, independent',
        654: 'Photographers, independent artistic',
        655: 'Games (except coin-operated) merchant wholesalers',
        656: 'Commercial refrigeration equipment repair and maintenance services',
        657: 'Building envelope consulting services',
        658: 'Set designers, independent theatrical',
        659: 'Music directors, independent',
        66: 'Fireplace, natural gas, installation',
        660: 'Sports announcers, independent',
        661: 'Ghost writers, independent',
        662: 'Folk dancers, independent',
        663: 'Directors (i.e., film, motion picture, music, theatrical), independent',
        664: 'Painters (i.e., artists), independent',
        665: 'Newspaper columnists, independent (freelance)',
        666: 'Laser surgery centers, freestanding',
        667: 'Motion picture film libraries, archives',
        668: 'Chemical consulting services',
        669: 'Directors, independent music',
        67: 'Drinking fountain installation',
        670: 'Speakers, independent',
        671: 'Public relations consulting services',
        672: 'Insurance advisory services',
        673: 'Costume designers, independent theatrical',
        674: 'Fertilizing lawns',
        675: 'Spraying lawns',
        676: 'Maintenance of plants and shrubs in buildings',
        677: 'Landscape installation services',
        678: 'Lawn spraying services',
        679: 'Turf (except artificial) installation services',
        68: 'Furnace conversion (i.e., from one fuel to another)',
        680: 'Lawn seeding services',
        681: 'Hydroseeding services (e.g., decorative, erosion control purposes)',
        682: 'Line slash (i.e., rights of way) maintenance services',
        683: 'Pruning services, ornamental tree and shrub',
        684: 'Tree pruning services',
        685: 'Seasonal property maintenance services (i.e., snow plowing in winter, landscaping during other seasons)',
        686: 'Landscaping services (except planning)',
        687: 'Tree surgery services',
        688: 'Interior landscaping services',
        689: 'Lawn mulching services',
        69: 'Heating, ventilation and air-conditioning (HVAC) contractors',
        690: 'Plant maintenance services',
        691: 'Landscape care and maintenance services',
        692: 'Tree and brush trimming, overhead utility line',
        693: 'Landscape contractors (except construction)',
        694: 'Mowing services (e.g., highway, lawn, road strip)',
        695: 'Lawn maintenance services',
        696: 'Sod laying services',
        697: 'Lawn care services (e.g., fertilizing, mowing, seeding, spraying)',
        698: 'Shrub services (e.g., bracing, planting, pruning, removal, spraying, surgery, trimming)',
        699: 'Tropical plant maintenance services',
        7: 'Epoxy application contractors',
        70: 'Chilled water system installation',
        700: 'Weed control and fertilizing services (except crop)',
        701: 'Cemetery plot care services',
        702: 'Lawn fertilizing services',
        703: 'Seeding lawns',
        704: 'Lawn mowing services',
        705: 'Arborist services',
        706: 'Plant and shrub maintenance in buildings',
        707: 'Garden maintenance services',
        708: 'Snow plowing services combined with landscaping services (i.e., seasonal property maintenance services)',
        709: 'Ornamental tree and shrub services',
        71: 'Central heating equipment and piping installation',
        710: 'Ventilation duct cleaning services',
        711: 'Steam cleaning building exteriors',
        712: 'Lighting maintenance services (e.g., bulb and fuse replacement and cleaning)',
        713: 'Cleaning (e.g., power sweeping, washing) driveways and parking lots',
        714: 'Duct cleaning services',
        715: 'Swimming pool cleaning and maintenance services',
        716: 'Parking lot cleaning (e.g., power sweeping, washing) services',
        717: 'Cleaning building exteriors (except sandblasting, window cleaning)',
        718: 'Chimney sweep (i.e., cleaning) services',
        719: 'Power washing building exteriors',
        72: 'Drain, waste and vent system installation',
        720: 'Gutter cleaning services',
        721: 'Pressure washing (e.g., buildings, decks, fences)',
        722: 'Drain cleaning services',
        723: 'Driveway cleaning (e.g., power sweeping, washing) services',
        724: 'Cleaning swimming pools',
        725: 'Building exterior cleaning services (except sandblasting, window cleaning)',
        726: 'Interpretive dancers, independent',
        727: 'Celebrity spokespersons, independent',
        728: 'Insurance reporting services',
        729: 'Bartering services',
        73: 'Bathroom plumbing fixture and sanitary ware installation',
        730: 'Inventory computing services',
        731: 'Tape slitting (e.g., cutting plastic or leather into widths) for the trade',
        732: 'Auctioneers, independent',
        733: 'Lumber grading services',
        734: 'Document shredding services',
        735: 'Inventory taking services',
        736: 'Textile cutting services',
        737: 'Cloth cutting, bolting, or winding for the trade',
        738: 'Meter reading services, contract',
        739: 'Museum cataloging services',
        74: 'Sump pump installation',
        740: 'Gas meter reading services, contract',
        741: 'Coupon processing services',
        742: 'Trading stamp redemption services',
        743: 'Flagging (i.e., traffic control) services',
        744: 'License issuing services (except government), motor vehicle',
        745: 'Locating underground utility lines prior to digging',
        746: 'Coupon redemption services (i.e., clearinghouse)',
        747: 'Trading stamp promotion and sale to stores',
        748: 'Motor vehicle license issuing services, private franchise',
        749: 'Water softening and conditioning services',
        75: 'Air-conditioning system (except window) installation',
        750: 'Float decorating services',
        751: 'Coin pick-up services, parking meter',
        752: 'Bottle exchanges',
        753: 'Electrical meter reading services, contract',
        754: 'Printing brokers',
        755: 'Portable toilet pumping (i.e., cleaning) services',
        756: 'Tank cleaning services, septic',
        757: 'Septic tank pumping (i.e., cleaning) services',
        758: 'Pumping (i.e., cleaning) cesspools and septic tanks',
        759: 'Aerobic dance and exercise instruction',
        76: 'Plumbers',
        760: 'Standup comedians, independent',
        761: 'Conductors, independent',
        762: 'Stage set (e.g., concert, motion picture, television) erecting and dismantling, independent',
        763: 'Technical writers, independent',
        764: 'Investigation services (except credit), private',
        765: 'Film archives',
        766: 'Insurance rate making services',
        767: 'Agricultural consulting services',
        768: 'Economic consulting services',
        769: 'Roof consulting services',
        77: 'Waterless fire suppression system installation',
        770: 'Physics consulting services',
        771: 'Script writers, independent',
        772: 'Waterproof consulting services',
        773: 'Board games merchant wholesalers',
        774: 'Public relations services',
        775: 'Biological consulting services',
        776: 'Horticultural consulting services',
        777: 'Authors, independent',
        778: 'Wildlife artists, independent',
        779: 'Science kits and sets merchant wholesalers',
        78: 'Cooling tower installation',
        780: 'News correspondents, independent (freelance)',
        781: 'Models, independent',
        782: 'Insurance loss prevention services',
        783: 'Dairy herd consulting services',
        784: 'Lighting technicians, theatrical, independent',
        785: 'Dolls merchant wholesalers',
        786: 'Coloring books merchant wholesalers',
        787: 'Film producers, independent',
        788: 'Motion picture directors, independent',
        789: 'Ethnic dancers, independent',
        79: 'Scrubber, air purification, installation',
        790: 'Painting restorers, independent',
        791: 'Poets, independent',
        792: 'Writers of advertising copy, independent',
        793: 'Insurance investigation services (except claims investigation)',
        794: 'Stamps, philatelist, merchant wholesalers',
        795: 'Writers, independent (freelance)',
        796: 'Song writers, independent',
        797: 'Sketch artists, independent',
        798: 'Motion picture consulting services',
        799: 'Investigators, private',
        8: 'Balcony, metal, installation',
        80: 'Solar heating equipment installation',
        800: 'Conservators (i.e., art, artifact restorers), independent',
        801: 'Freestanding emergency medical centers and clinics',
        802: 'Exercise centers',
        803: 'Body building studios, physical fitness',
        804: 'Dance centers, aerobic',
        805: 'Squash club facilities',
        806: 'Gymnasiums',
        807: 'Fitness spas without accommodations',
        808: 'Racquetball club facilities',
        809: 'Health club facilities, physical fitness',
        81: 'Warm air heating system installation',
        810: 'Strength development centers',
        811: 'Aerobic dance and exercise centers',
        812: 'Weight training centers',
        813: 'Fitness centers',
        814: 'Fitness salons',
        815: 'Athletic club facilities, physical fitness',
        816: 'Health spas without accommodations, physical fitness',
        817: 'Gyms, physical fitness',
        818: 'Health studios, physical fitness',
        819: 'Physical fitness studios',
        82: 'Air system balancing and testing',
        820: 'Physical fitness facilities',
        821: 'Recreational sports club facilities',
        822: 'Handball club facilities',
        823: 'Sports club facilities, physical fitness',
        824: 'Physical fitness centers',
        825: 'Tennis courts',
        826: 'Gaming consoles merchant wholesalers',
        827: 'Commercial gaming machine repair and maintenance services',
        828: 'Welding repair services (e.g., automotive, general)',
        829: 'Electric motor repair and maintenance services, commercial or industrial',
        83: 'Boiler and pipe insulation installation',
        830: 'Food machinery repair and maintenance services',
        831: 'Armature rewinding services (except on an assembly line or factory basis)',
        832: 'Machine tools repair and maintenance services',
        833: 'Service machinery and equipment repair and maintenance services',
        834: 'Truck refrigeration repair and maintenance services',
        835: 'Material handling equipment repair and maintenance services',
        836: 'Fire extinguisher repair and maintenance, without installation',
        837: 'Freezer, commercial, repair and maintenance services',
        838: 'Paper making machinery repair and maintenance services',
        839: 'Reconditioning shipping barrels and drums',
        84: 'Bowling alley equipment installation',
        840: 'Coil rewinding (except on an assembly line or factory basis)',
        841: 'Foundry machinery and equipment repair and maintenance services',
        842: 'Cargo container repair and maintenance services',
        843: 'Waterless fire suppression system repair and maintenance, without installation',
        844: 'Engine repair (except automotive, small engine)',
        845: 'Refrigeration equipment repair and maintenance services, industrial and commercial-type',
        846: 'Rewinding armatures (except on an assembly line or factory basis)',
        847: 'Printing trade machinery repair and maintenance services',
        848: 'Blade sharpening, commercial and industrial machinery and equipment',
        849: 'Textile machinery repair and maintenance services',
        85: 'Power boiler, installation only',
        850: 'Commercial and industrial machinery repair and maintenance services',
        851: 'Electrical generating and transmission equipment repair and maintenance services',
        852: 'Restoration and repair of antique furniture',
        853: 'Antique furniture repair and restoration shops',
        854: 'Upholstery (except motor vehicle) repair services',
        855: 'Furniture refinishing shops',
        856: 'Reupholstery shops, furniture',
        857: 'Furniture repair shops',
        858: 'Furniture reupholstering shops',
        859: 'Clock repair shops without retailing new clocks',
        86: 'Pipe covering',
        860: 'Fur garment repair shops without retailing new fur garments',
        861: 'Venetian blind repair and maintenance shops without retailing new venetian blinds',
        862: 'Garment alteration and/or repair shops without retailing new garments',
        863: 'Tuning and repair of musical instruments',
        864: 'Tailor shops, alterations only',
        865: 'Musical instrument repair shops without retailing new musical instruments',
        866: 'Watch repair shops without retailing new watches',
        867: 'Key duplicating shops',
        868: 'Exercise and athletic equipment repair and maintenance services without retailing new exercise and athletic equipment',
        869: 'Window shade repair and maintenance shops',
        87: 'Insulation, boiler, duct and pipe, installation',
        870: 'Clothing repair shops, alterations only',
        871: 'Sporting equipment repair and maintenance without retailing new sports equipment',
        872: 'Jewelry repair shops without retailing new jewelry',
        873: 'Cutlery (e.g., knives, scissors) sharpening, household-type',
        874: 'China repair services',
        875: 'Bicycle repair and maintenance shops without retailing new bicycles',
        876: 'Radio consulting services',
        877: 'Baths, steam or turkish',
        878: 'Hair removal (i.e., depilatory, electrolysis, laser, waxing) services',
        879: 'Depilatory (i.e., hair removal) salons',
        88: 'Pipe, duct and boiler insulation',
        880: 'Makeup salons, permanent',
        881: 'Ear piercing services',
        882: 'Steam baths',
        883: 'Turkish baths',
        884: 'Electrolysis (i.e., hair removal) salons',
        885: 'Massage parlors',
        886: 'Sun tanning salons',
        887: 'Day spas',
        888: 'Turkish bathhouses',
        889: 'Hair replacement services (except by offices of physicians)',
        89: 'Material handling equipment installation',
        890: 'Scalp treating services',
        891: 'Saunas',
        892: 'Tanning salons',
        893: 'Color consulting services (i.e., personal care services)',
        894: 'Hair weaving services',
        895: 'Reporters, independent (freelance)',
        896: 'Insurance coverage consulting services',
        897: 'Swimming instruction',
        898: 'Public relations agencies',
        899: 'Energy consulting services',
        9: 'Waterless fire suppression system installation and repair',
        90: 'Moving sidewalk installation',
        900: 'Obedience training services, pet',
        901: 'Sitting services, pet',
        902: 'Pet training services',
        903: 'Boarding services, pet',
        904: 'Bootblack parlors',
        905: 'Composers, independent',
        906: 'Handyman construction service, residential building',
        907: 'Postcard publishers, exclusively on Internet',
        908: 'Publishers, Internet map',
        909: 'Art publishers, exclusively on Internet',
        91: 'Overhead door, commercial- or industrial-type, installation',
        910: 'Encyclopedia publishers, exclusively on Internet',
        911: 'Juvenile magazine and periodical publishers, exclusively on Internet',
        912: 'Web search portals',
        913: 'Trade journal publishers, exclusively on Internet',
        914: 'Religious book publishers, exclusively on Internet',
        915: 'Electronic directory publishers, exclusively on Internet',
        916: 'Social networking sites, Internet',
        917: 'Radio guide publishers, exclusively on Internet',
        918: 'Agricultural magazine and periodical publishers, exclusively on Internet',
        919: 'Periodical publishers, exclusively on Internet',
        92: 'Lightning rod and conductor installation',
        920: 'Religious magazine and periodical publishers, exclusively on Internet',
        921: 'Publishers, Internet greeting card',
        922: 'Diary and time scheduler publishers, exclusively on Internet',
        923: 'Street map and guide publishers, exclusively on Internet',
        924: 'Technical manual publishers, exclusively on Internet',
        925: 'Search portals, Internet',
        926: 'Internet magazine publishing',
        927: 'University press publishers, exclusively on Internet',
        928: "Children's coloring book publishers, exclusively on Internet",
        929: 'Internet comic book publishing',
        93: 'Door, commercial- or industrial-type, installation',
        930: 'Travel guide book publishers, exclusively on Internet',
        931: 'Book publishers, exclusively on Internet',
        932: 'Internet book publishers',
        933: 'Comic book publishers, exclusively on Internet',
        934: 'Greeting card publishers, exclusively on Internet',
        935: 'Atlas publishers, exclusively on Internet',
        936: 'Nonfiction book publishers, exclusively on Internet',
        937: 'Internet newspaper publishing',
        938: 'Dictionary publishers, exclusively on Internet',
        939: 'Advertising periodical publishers, exclusively on Internet',
        94: 'Water pipe insulating',
        940: 'Publishers, Internet racing form',
        941: 'Trade magazine and periodical publishers, exclusively on Internet',
        942: 'Magazine publishers, exclusively on Internet',
        943: 'Business directory publishers, exclusively on Internet',
        944: 'Internet broadcasting',
        945: 'Web communities',
        946: 'Special interest portals (e.g., parents sharing information about child rearing, etc.), Internet',
        947: 'Broadcasting exclusively on Internet, video',
        948: 'Directory publishers, exclusively on Internet',
        949: 'Television guide publishers, exclusively on Internet',
        95: 'Church bell and tower clock installation',
        950: 'Newspaper publishing, exclusively on Internet',
        951: 'Entertainment sites, Internet',
        952: 'Scholastic magazine and periodical publishers, exclusively on Internet',
        953: 'Medical journal and periodical publishers, exclusively on Internet',
        954: 'Internet radio stations',
        955: 'Racing form publishers, exclusively on Internet',
        956: 'Racetrack program publishers, exclusively on Internet',
        957: 'Internet periodical publishers',
        958: 'Map publishers, exclusively on Internet',
        959: 'Calendar publishers, exclusively on Internet',
        96: 'Deodorization (i.e., air filtration) system installation',
        960: 'Web broadcasting',
        961: 'Professional magazine and periodical publishers, exclusively on Internet',
        962: 'Video broadcasting, exclusively on Internet',
        963: 'Portals, web search',
        964: 'Yearbook (e.g., high school, college, university) publishers, exclusively on Internet',
        965: 'Pamphlet publishers, exclusively on Internet',
        966: 'Almanac publishers, exclusively on Internet',
        967: 'Discount coupon book publishers, exclusively on Internet',
        968: 'Broadcasting exclusively on Internet, audio',
        969: 'Radio schedule publishers, exclusively on Internet',
        97: 'ATMs (automatic teller machines) installation',
        970: 'Internet video broadcast sites',
        971: 'Professional book publishers, exclusively on Internet',
        972: 'Technical magazine and periodical publishers, exclusively on Internet',
        973: 'Internet news publishers',
        974: 'Fiction book publishers, exclusively on Internet',
        975: 'School textbook publishers, exclusively on Internet',
        976: 'Pattern and plan (e.g., clothing patterns) publishers, exclusively on Internet',
        977: 'Scholarly journal publishers, exclusively on Internet',
        978: 'Telephone directory publishers, exclusively on Internet',
        979: 'Financial magazine and periodical publishers, exclusively on Internet',
        98: 'Automated and revolving door installation',
        980: 'Internet newsletter publishing',
        981: 'Guide, street map, publishers, exclusively on Internet',
        982: 'Scientific journal and periodical publishers, exclusively on Internet',
        983: 'Newsletter publishers, exclusively on Internet',
        984: 'Catalog of collections publishers, exclusively on Internet',
        985: 'Poster publishers, exclusively on Internet',
        986: 'School book publishers, exclusively on Internet',
        987: 'Tennis instruction, camps, or schools',
        988: 'Food service contractors, industrial',
        989: 'Cafeteria food services contractors (e.g., government office cafeterias, hospital cafeterias, school cafeterias)',
        99: 'Dumbwaiter installation',
        990: 'Food service contractors, institutional',
        991: 'Food service contractors, cafeteria',
        992: 'Industrial caterers (i.e., providing food services on a contractual arrangement (except single-event basis))',
        993: 'Food service contractors, concession operators (e.g., convention facilities, entertainment facilities, sporting facilities)',
        994: 'Food concession contractors (e.g., convention facilities, entertainment facilities, sporting facilities)',
        995: 'Caterers',
        996: 'Catering services, social',
        997: 'Buffet eating places',
        998: 'Fan clubs',
        999: 'Ethnic associations'
    },
    'Ask Kodiak Hash': {
        0: '857c922976b2cabcc79f4f772ad0425d',
        1: '1c59e2688012c46b694302cf99bc12cc',
        10: '01f2c6983d5f8692c0431dba6d400bc7',
        100: '83fb94bfedebef916214bba08e31b3a7',
        1000: '242bf55a326a93e22ebeeddcc77d75e0',
        1001: '259152f7af03fa333d77ec7d26ff5ad8',
        1002: '304785445b0a8b47ca2f20a3123eae10',
        1003: '31dae57ef29b4593b64966c0fc8f499a',
        1004: '362c323a7cc0dfce305e1e5cfb630ffe',
        1005: '39a02233ec2987d4b389b7aed80cecc3',
        1006: '3d809d1b5e0f3dba43ab1f17080da69a',
        1007: '4b6517b6e45bac2e824852e311cb99bf',
        1008: '52723af58b54c0f1b5425c9f8523cffa',
        1009: '5459bb3ed92a7747e08dc6ae82b6e15b',
        101: '896f7abc2913df0e51f058d69daa03c3',
        1010: '59787de97eee3445af031ede8f1764c7',
        1011: '687283e776b253a3e2377067c5abab0c',
        1012: '786c638e442dc6a33d73c93c14147ba8',
        1013: '80359dbad2f3c5c6e35ee883a8cafb5a',
        1014: '845304e04de4a9a09e2cfb6dc032275f',
        1015: '905e251777c574f569ba6605e3e5233d',
        1016: '91072944a3df36015b9c83cbf7cb8383',
        1017: '9faca6ca0bad9c90355bcca774451084',
        1018: 'a3e8ed696577605039873b431e45a27c',
        1019: 'a8426f11b2c0c6ea71049602ed7db7c5',
        102: '8d2290917b1805c4426faae3553a458d',
        1020: 'b12d7a21b2572286e6ac8c03bc318b49',
        1021: 'b32feef9dee0dd20f2cdb2ddd89705e6',
        1022: 'c685b443cc6c6876b1448f22e6874868',
        1023: 'c6916385cab713032e78ece4210c9618',
        1024: 'cd7bc231db59f0d8176c1ba91be72a74',
        1025: 'd3e6fb1f5cf277ef31cb04b7d5ed7890',
        1026: 'd6f8f952a33bc414a813739ddb8fba4d',
        1027: 'd8c62c13d4c486a2d9d2361639d2bc33',
        1028: 'db81f4cbcf90a65723b39332f46094b0',
        1029: 'e67a5bb5346a2c891f2e01c44ae955ae',
        103: '90e96d89d5778e78e9380354cc9b05b3',
        1030: 'f8ed69d68236addf94ad291ea4cd9030',
        1031: '796f5d34695ffd1f6fb6965c8632e3c0',
        1032: '7ac0ae0270657fecf5ed809b28ef15a2',
        1033: '80434777455a71712fc817794697b999',
        1034: '903fd3b45129819f0bdc6a6d41436711',
        1035: 'dc8a2c208bfed26ce3cc102f929bf557',
        1036: '39c33b2f8fe71a4716f92728aba92278',
        1037: '6e305157d24b4150568fd0e157f8e9fb',
        1038: 'd320a595a901eb1a8b0468b697d1b0f9',
        1039: '678a26bc495ddd3583f699fd42b4c124',
        104: '92ea867adf1b708e08cac57d86245329',
        1040: '1c53c5cd08ff9c1a5df406d166184ec4',
        1041: '60cc8e3e0128b6647fa5cb7751af8d56',
        1042: '84f0c9c2999638e1533a06a41415f2be',
        1043: 'ae884448a16bcd0f098e019da79577fc',
        1044: 'e98d6821fd037793e40e8ad7530cc66c',
        1045: 'f739727af1ef8f46954c5cc995a0ec30',
        1046: '01060e19e7c9a0f20e044ee7e31deed4',
        1047: '021f4b01466a7887fe66a25f1afafe93',
        1048: '0b2353fa0ea6a82f7495f3b6e35b7875',
        1049: '138c1bcb3143d81bcf3226248823fc2c',
        105: 'a08edd8dfe29f2f8c9fcffef960fdac1',
        1050: '1a36be76440dbf07b4ce7dfeb55a9178',
        1051: '215e1fd48bf4991db2c5ddf7b5d01e39',
        1052: '224499f26178e03e9c9aab916928ce60',
        1053: '311e305d5dd099c39fe48b53e374db94',
        1054: '3378b3876b3cf8c458dbcd235083835c',
        1055: '374f788a3d958eb016a17330becb6745',
        1056: '3c386a3a7f3edb08fac0d288e20bf246',
        1057: '3e50f1d703efa1f7801b683582408b1b',
        1058: '480ad4234d1c748a7dd5ac80731074b5',
        1059: '5245b7f2a70f6d1a8330531bb28532f9',
        106: 'a4663b2a7502bb2f0a7d4578b970c7e1',
        1060: '53a23536d563fa0f1f526e66495aed3b',
        1061: '5c1548adf01c601780f8aade56820f42',
        1062: '74cf635ded4e85d1f9999a4d25cbb5ef',
        1063: '852181ceb2725e8284100311ca7f1ed9',
        1064: 'aff43bb430a7d8c64b3c22eed02cb869',
        1065: 'b0877e16c02ba1874c5976fa9dc15511',
        1066: 'b598e4ebc172d09aad74b3584be770e8',
        1067: 'bf4ea2b59e9a068af798d9d4daad4c1f',
        1068: 'c1d6c5f364dea821b810a1d5a2eef3c5',
        1069: 'd039eff66489484f693b396c8de7d58b',
        107: 'aa46f1f8bfaac274b7f91e78fe2f488f',
        1070: 'e46ac3f659a7b41ade7a5e9794cba2d7',
        1071: 'eb0302a49c449cf4fb5151b878633756',
        1072: 'ee217cd66e457024d0c311649f44a1fe',
        1073: 'f796bd7a6aa48cb33d24daf3e9f76759',
        1074: 'f81e6209e5f98a02e5be9374dcd99eb5',
        1075: '0347ea010813c15720cbe5b5f7ffbec8',
        1076: '07c30ecb7cacd014534769ff8d828883',
        1077: '035ce83c2ed5efb88ad7d0d60b5243d7',
        1078: '011fa691f49417afbafb77eec412dd61',
        1079: '487ba89c09bfaa0a743d4e2e11e07be7',
        108: 'b22f62067ef227cfe4d739d0e62cad09',
        1080: '0b0e9ba8d993ea657d4a1f6900b606e7',
        1081: 'f7a657e266ada4d80923ff8c442f0daf',
        1082: 'a9ac1c1f7f1e048590ef612b0127cbbc',
        1083: '6f4d837d2b6582da7db8185850281ea5',
        1084: '1c42c5afe9ed0e7d75a7dd041fe7dcb0',
        1085: '0e9fe0c30d6e81f7c0bb444fc8cd1478',
        1086: '5f34f77d36c1c9fb201969dfecc84117',
        1087: '1c00ada754efad51ab9bfdcad0f83636',
        1088: 'aecfed0bcb8ba1c5b63da4c225119b25',
        1089: '17c78f1adf7ec54acf3ad2febd40832c',
        109: 'b608eeff309b39e3a4a56d6912dcfb61',
        1090: '11e07a8357b0eefa919b91a541aa3b25',
        1091: '0893bef5d2d3e6cb08c3419fe98434cf',
        1092: '07481507f8a3e937e27e0161abced99e',
        1093: '1c408f4dfb93f581e8cd5589aa84e92f',
        1094: '2d61dd5445d773097f5e29ff5746fca8',
        1095: '06a682b67026b1b9e94ddfa74d9c1d4d',
        1096: '1720c1ea5802f31d600e6d22c8d4e48d',
        1097: '227385aab6467e9c376535fc3c888a97',
        1098: '1d25cb1bbef7372c8c67a5eb2d256311',
        1099: '1d4e64b5c888a08fe0b2f9b26887aa5e',
        11: '0dc7dc30a32276c9c639987c3e50d89b',
        110: 'b6c371b3ac81365ce1d8c5ab77b59c3b',
        1100: '33f65a2038bf325eddfd57bcd1c2a35d',
        1101: '2506947d13512aac17c0ae0164878f14',
        1102: 'c3fb1221af20f63fa368ee60e5d72568',
        1103: '06d99f1673cb7b5d548b85bc573a94cf',
        1104: 'a1700a5f1aa0ce307fcb0af5d44ae247',
        1105: '25e16b3cb1cf5cbff76d1390664ef5f5',
        1106: '09123442162825da4fc9e20a7e2be5b2',
        1107: '5dd4b3868972423fb9aa6b896fcdc3e9',
        1108: '086507ab4f7e829d4f3e66544824fa71',
        1109: '027a3a28428992f90de15a8548b38940',
        111: 'b80a5ab66aa8d2e0e319ed9e89717467',
        1110: '0180e6a834c74bb70f48dfd594840fbe',
        1111: '069d0b3f9817d6857f081e672aab2844',
        1112: '00264ad66e76a0b254c05bcde0df875e',
        1113: '0b1ab6fab617fcc1a6e705d16d19e44c',
        1114: '07e16f2e53d41c3b615bdef9679306e5',
        1115: '1024a4eab63c62dea498aae2d36ca688',
        1116: '60641af86ee45879d7ab1b76c55f39ca',
        1117: '03ef7a12447279cb52330c4b7aab7461',
        1118: '0191210071ab7b35ae25657a50f3fddd',
        1119: '05bf10bcb7c35721727b8cb462532675',
        112: 'bbc5829268c9edea134a245341657924',
        1120: '49a7b50a1d8a10d827fc4cd2b1894e9c',
        1121: 'dc48a4db1b9cae93124d82158e0f863b',
        1122: '11bc33815213d93b92c3de946d17d3e1',
        1123: '01f4e42e90e3da3fdbe7e72dafc36ee4',
        1124: '18cd33128d56826efebb5c54ce706480',
        1125: '05404fb96e28d4c1f657f76fe0d9bf5a',
        1126: '1add7f14b9d3567527e791bba5d204a5',
        1127: '0107b9f77c91dab3a0cac82327c804ea',
        1128: 'ba7269099932e1808df4f613037f6618',
        1129: '03db97b289e35589c7667eec2ad57cca',
        113: 'bd7da63846212ac9174d339e570271fc',
        1130: '242297c4716559e960c3aa5cf63988c1',
        1131: '2a72349d4025021ddc018aabaee9d5ae',
        1132: '07c2220f4fc29f0fc3bf5e2d9ee0b16a',
        1133: '8ae50ab022c08221b5fb6597a90bdadc',
        1134: 'a570b9ed71711304f47f40ad6ad23f3e',
        1135: '173c8cb41dd22aaf80f84cf9f4f31c2d',
        1136: '009513a07c304700496b561ebcf806cb',
        1137: '021ef576228c710bdcabc7585b6478d9',
        1138: '05e4b99ebec078b029afa6c08a674dfa',
        1139: '0d3e1f4a965f0842d673ac72edc41680',
        114: 'c059c0840cbce148d1e297ba14e7f609',
        1140: '13cdab5f2b922d5a8f46f2d627384f98',
        1141: '897dbe63037100fc32d1d667e23d4e9a',
        1142: '128e0c4ce32f732862b3128518a41c37',
        1143: '0380ec6a0e4597ed5be20a106b4005ab',
        1144: '3c9ad0d1636402b7261f7adae89745df',
        1145: '1641808047cf2dd7dee043bda5708c36',
        1146: 'bdf374f85fa10c2cb374532f64d8b322',
        1147: '4e70a46df431a58581747390058ae3f6',
        1148: '015514a18873228efeb459cfec895683',
        1149: '2e915b91c36975860718328659ade24d',
        115: 'd4869d471696e3abb439fbf4caba555b',
        1150: '15d7dece791e74a081abbb76d997daef',
        1151: '076d5f33b644a4b35e08e288171a65e6',
        1152: '059eabcefd28d4296faa4cd58c56eb45',
        1153: '231ee549aabedcd394ba3054724421ad',
        1154: '5654f7f3e635cd5c84c8909ba48d7812',
        1155: '4bb3ecacf82918078c5e14803a1893e0',
        1156: '201d8bbc7cb4964fefd7a8303111694d',
        1157: '757288b912969ec5a4bf21724753b6f4',
        1158: '0d8faee9eae7edb5d4684ef7b8051271',
        1159: '01028cc0a9ff10a73c2933a85dce83ea',
        116: 'd641343638041f9ff80359ae63d8cf7f',
        1160: '09d6f0dbaecda418f48a17a5321e8056',
        1161: '806e13383cc4ca1a29b109fd0e2dd12b',
        1162: '0961e78d863c205ffeea19e3d6e72828',
        1163: '36b1e76a83a9c05c8c06520cc7879695',
        1164: '00810332e23c2a339e782ce20802365c',
        1165: '0b11fd12571bb339ad5a6e70e9e66560',
        1166: 'a65b22c2cc2a5c130ee7db8a8bc579db',
        1167: 'aa0787fc6cd001c764c3597faaf061d9',
        1168: '0ff6b174fa585540cf87c2378b7447aa',
        1169: '037dd88d3c0719f571d54ae93ea1f520',
        117: 'e0d0d184b6aa599fb4820718b278f558',
        1170: '3ad82429a6bb9df1c142a0568f34e5c4',
        1171: 'b628f982bd6115182ec979d04738b1d7',
        1172: '9bd804476967a35c77da4a73051ed376',
        1173: '0b6bc698792eade49cd9974b755aa306',
        1174: '1dbbc57c6806471acf48e201527c8b20',
        1175: '02db470716433e877159d7de89cd4897',
        1176: '094a792ef1f1c09efe32f36d29bdac9f',
        1177: '19466700c849d0cddacac37cf246b93f',
        1178: '2d019e9454c1a4dcfd16dab23c89e150',
        1179: '1592d53e3912122f553d61fd47a7a845',
        118: 'e47de294f9e8b166d0de5ca355dba8e1',
        1180: '041c93b29b9edc9b5ecc3b694ae0ffe7',
        1181: '07335ea031dd44a5b2946f6dd773580f',
        1182: '226dfc0d3ada96040fb258dc48b562ee',
        1183: '13e89317f82d99300d627dae2727925f',
        1184: '1c586960e85cc0f24e84939549b67798',
        1185: '0409738cfa5d4af6bb4f240f850246d0',
        1186: '15c42bf57a96b0f1e18ba117055e878f',
        1187: '63a147f793a36c9601bfd350f7229444',
        1188: '1acfa82c45b2c41f6f55eb3ccf71d942',
        1189: '3f0a3b0d0e8d58047403c988ce895e78',
        119: 'e77e1687c9bb037b36697a81a62473b5',
        1190: '09a8b6c49f622962d316f0210a93a420',
        1191: '0982529d51511db05f5b5ac85d55a084',
        1192: '5ecc62b070014a429b1d0fb5b749a384',
        1193: '1e58113ef0bb81aab290a019b89b36b9',
        1194: '7b447138e0e09aa6fe7af9d354581428',
        1195: '74bb3cabbf5956cf0788681fb53ef065',
        1196: '0028606761e5519c25b0e34b26b4d33b',
        1197: '08382fcc54d754ebc98d1ce94bb79775',
        1198: '6ab09758c79c6fcb9c81857f09a0ffd6',
        1199: '0000dc045c872f122d694ef600c394df',
        12: '233afbfc677653d0b95ad805305ba2b3',
        120: 'e7cfe7193d15c77990ff566bf6875a42',
        1200: '06c0eb89c53a0c84af83c15258b0f81f',
        1201: '00e9b24a4f191d43ea3d66da3aa3a469',
        1202: '066d2b56ce2727c8ecbd94f8d6288101',
        1203: '152d815434275638fcd69d76ec283071',
        1204: '3adff9a5b929aa4bfa0c9d7bc7200959',
        1205: '0213c8f7a9996c2ba28a883cac74c43a',
        1206: '0b11b51241da58ad7d642f08295f55ac',
        1207: '331e3f1174d07b25c8b7b972ef8a2ce7',
        1208: '2e6553cc5b43da7c4d46323938b54d62',
        1209: '09cad4f953d41e7340590de9558fb7de',
        121: 'eeecfabd2394bf98b010b73169964f7f',
        1210: '2c52ecc34506ac2c68dc72962662b27f',
        1211: '2194581082c02ce6aba2c766dd7f710c',
        1212: '10ae89814707478b070334f0a4577e75',
        1213: '0099386b667c5d45bc90ff58ac3a01b1',
        1214: '1960bf9eb64fd501b0b0d5b5950c2740',
        1215: '145cfc5770a5b13d541db23d38f39788',
        1216: '04f13fdf097de57d9e05008a03ae28ab',
        1217: '23fc963de7bd8c59188aecc29c5d3cb0',
        1218: '1579343988f08966b65a12f52f65832f',
        1219: '105dd1a40c6ae65c1aad5ba5d0e22b02',
        122: 'f1db9af23e5122971975a8bf8043019e',
        1220: '5fd273b44509051ffcd41239ddde837d',
        1221: '023c68bb8e4f60f63d109df0dbcafda2',
        1222: '062ac79188e64a0da2ed8dcc713b563b',
        1223: '1c32448f16a2794606701d7b002409b3',
        1224: '0deb3d1c2be4aa173f9ab1b13f614672',
        1225: '1732e93f1c8d3987cb6e2d4a01e695dc',
        1226: '03830ad8cbd414af62f553c6e4ccd3b5',
        1227: '2296bfdd30e3fdbb456db8a1c3736a7c',
        1228: '03100d0afd80b348581b9d67438ff5c2',
        1229: '034f57397094b23ed2c10f909a750a6c',
        123: '0333a66fe00feabcbbfcebd519fd157d',
        1230: '164c8ab08a9d11cb23377dc61ea76b8b',
        1231: '0c93a2b74a0fe7837014a5138addd681',
        1232: '1d1dce16178ddb65fcebf5dcc7fc0a7a',
        1233: '0e8f5d6b27f3a9f420bbc2cc04fcc9d7',
        1234: '02b31d0cf2ff5c3cd3308c24cdfaf4c9',
        1235: '78fd08ff762be604af5912e0dfa98c50',
        1236: '00bad86ef01015eac4d177c614d7de9d',
        1237: '0b27b4e707a42d1aa2d877a658bb8f29',
        1238: '4793fcb8d87bbc220fe92b36124cb08b',
        1239: '8c3bc8cf8de8e76f1b2300b8eb1d1b84',
        124: '0d9f92516eaae756a2936295e6fd9792',
        1240: '104f849eb2cc4e3c9c20d0010436191d',
        1241: '0bc87dd22d88cef7f5a609a0320c65c9',
        1242: '021f63968c4b33e9abc42022e71c36c4',
        1243: '145551104f492517be37a6d65fd51807',
        1244: '09ef99fccb69d16bb8927d192231a882',
        1245: '4daa4db51819361e936fba566da9f286',
        1246: '00504aebe3b5c791d2860572d4092b81',
        1247: '0ba7ebd299dd1ffc82e08cfcba6b7d61',
        1248: '0e3325e2e6c2e72f4ca1d584e9ad6c4f',
        1249: '000a012549fc1716c0185c83f28fc666',
        125: '132d9856ee1179fad08260266de3ecf6',
        1250: '03ea7cd8e3153dd40c984f566f94e7d2',
        1251: '3537d7f920ca7e67b7abb7e991e395f4',
        1252: '6d5a4b7b30122163f055bb22c26cfa73',
        1253: '315f63ba427df8f3ef85a957341bb4de',
        1254: '174643f1f42878d79397cd0c3bf1ec9a',
        1255: '445c922147a3ccf019a9c5a7634aba3a',
        1256: '03e8db316d5704eece267d8bbd9da240',
        1257: '0ba6d50e9d31a924d007b34d578f7086',
        1258: '3c49084bd0686b9e38da38733fbebd29',
        1259: '3e73849435d9ac428a4c940df6682404',
        126: '16d4ae64e08fc36dc67b44de066d4920',
        1260: '1e1b7891b5b7b129246f129461a835dd',
        1261: '019e15f06616f1c2d796d167d09903ec',
        1262: '3f27ecafd78abe7b23f1c5f989bf9cd3',
        1263: '0de117273f3ee0e5ecd21a82827a4b61',
        1264: '3a5a0000dec4ecd04b39001e5face2c0',
        1265: '1612c51dd496f3f5e8e32fc31e439117',
        1266: '2ff77d3a4a58907d97ea3d45d63c720b',
        1267: '138f1e4c0544e23172f0ef64195916dd',
        1268: '0245d3bd905006491fba1d66749b6551',
        1269: '0e52e5e9c453469ffd43a99573e4e29d',
        127: '1efa6c58eb8924894cb1d511698c94df',
        1270: '0563791653956a7276ee4187d7f1ecff',
        1271: '07e38a77d063b5dfb4fa66476ec859a2',
        1272: '0fef2b746cdfcf98f78c482ac57d31e4',
        1273: '015a63ba3f399afacb77d66286e16750',
        1274: '041a55eb1bdfb7cea7ceddd9b5359a19',
        1275: '06c8c78c2eda6c6fd4d260e61819cb57',
        1276: '5cfa1bfa977b2c605a84031d9fe9670e',
        1277: '2418679ef022581db95f4925cdf3456a',
        1278: '5779acf4e2451590a40e7b6667a4342a',
        1279: '2f670fb2a5e68f24f4cf4dc10bfb3e0a',
        128: '22bf3692e49e9ffe8d9e2870830e9841',
        1280: '001852a993b8ca3c4ee43b7b514f544f',
        1281: '0100c28a4a3cd616e805620c2c44c626',
        1282: '72344ba185dbd66671c6d4e041a5e8bc',
        1283: '0d4d47bf932a655fb540422f1f4b915b',
        1284: '11c64d7e6de656cb30399e4b77e07f85',
        1285: '19b13a898159f583d6ac0df04c749877',
        1286: '0cec5f042064e9d57c6760aa25cbf077',
        1287: '43540fc5c9620922544327d61866b11f',
        1288: 'c92394552cd823d1141655974bfde19c',
        1289: 'ccbf3082a925dc1476512b3179ea166e',
        129: '25df6c2dfa3b2a13328e0a0ff219425e',
        1290: 'a22e85a05972e14209739e63d69b3733',
        1291: '30cfd5d06ab97ed3cf7a389589cf8c48',
        13: '234fb0b1d6c390c681779418e65fb627',
        130: '3aedf7979194960314c37e28b6fd4a77',
        131: '432f5f9159313261da0777bd357af5c6',
        132: '4bab74a4899ae4d85a5c535f7bf3965d',
        133: '56224563f6f4ac474398dbe6e72d5d7d',
        134: '5e11b5b9d9b9829307ca269e647de647',
        135: '62023ad2b5c0ca50bd94717a8ef0d9d9',
        136: '620661528e7fb04ebfae4567b7ff0fa7',
        137: '6a88b380ec2fc114552055c5702c74c6',
        138: '7a62c920948f759f07cfc552784bc003',
        139: '8377c2f79ed626c06b1a88d6ddd78c11',
        14: '26a90c82a142174ac7591381eb28da3d',
        140: '8b0aa44633e0b3d7baf34450c6c39e0a',
        141: '8fd51a5861872027fe7436566cdae69c',
        142: '9786e6059aedc2983a3e6c44c458b378',
        143: 'be6f844882eea68f5357df62bfddaebc',
        144: 'c9966355872592f11c4e3873346bd06f',
        145: 'd7ed66fffca17a8d3583734925fcce19',
        146: 'e47073b2673fc29697421fd86e764cef',
        147: 'e7e090bcf1da05da81afffc359594d62',
        148: 'e83bdb03b52699037ccf76d1c646b593',
        149: '3730d421db74813217ed5116d05fd940',
        15: '27b78d260f44f6244c0c95784acd4ba6',
        150: '4180f465c6a58c63bba9e65361143f11',
        151: '217da37e1e96d7d7423384c18f538af1',
        152: 'e9c97954120c5f1992197aa03164db1a',
        153: 'c32edd47da46d9e4e476e87e532167d9',
        154: '6edbeb5b1e2f70c6e81722adcf706ba5',
        155: '8c151865239bf9e856f78b613598e9b0',
        156: '53359e33e00f9c0774f0f0fef8d4b4a4',
        157: '44226a49ad5443ea3160a90d908d5254',
        158: '10227d65b444d5945fe67e14ffb2c10f',
        159: '19ee967a7e0bde2f145ded55c68a7c67',
        16: '2baecf28b014036cd3c8c3edc6e52b08',
        160: '21095f65a45cf3c1b2c5994ea50e258b',
        161: '217cb6d3742fbfb1f89ef309b2b7f99a',
        162: '21a9189a0b272716b1d16e9229435bb3',
        163: '36e4cab4b228499874ce313b4f553456',
        164: '3a260220dd1bead62fe02cf630c9c0fd',
        165: '40c237957f8db20b9c61577e895b9462',
        166: '50abc8a3f379c426d03e4460cbd4d415',
        167: '64516136bf6c1643f82d6ae83de3d92e',
        168: '68c09560173ecf61a663d5353151c758',
        169: '7e8706424eb33ffb7524fb9e62a5f088',
        17: '317903b746bc41fbc91c855b48ecdb17',
        170: '87298915ed1b897bf00cdbbea00898b1',
        171: '8780bb1a41b986486f13313e01fd289d',
        172: '87fcb5bd1c41c4cef59789daf8cb43c3',
        173: '8aa3c6b76d8944c9178ea8836a537fb1',
        174: '8d2bc513a5eed39b8a522b1d9fdd127c',
        175: '9d4da9be4a01f1ba32b3c8d802150fb4',
        176: 'a0cefdf273c497e4d9f97b936e7da0ff',
        177: 'a1fc9f2980dab03d9a2ded24c9d3c0b7',
        178: 'ac3a9b8f5791f65cb5a75f39ce766849',
        179: 'b0d464560a0fc9aefe8a2055ad3999ca',
        18: '35c7b8de2eb8278e49c00b70f5acfa09',
        180: 'ccada6195b60e091a63dc776764def54',
        181: 'db0e7886029eb17254cb20175e75974b',
        182: 'f0cda0df4bbba841abc3ec442f5e5f91',
        183: 'ff8f06fe1e5af7576f7756dc5dc96204',
        184: 'ffe80f83c6205c26b0d116ea5f571d9c',
        185: '062f8980a2024b88703eba2ac844dfc1',
        186: '227631f1aea5dbb727ea57c62c8636c7',
        187: '3579d83803bbeccf08e2a104d4a36cc2',
        188: '4776f9a9a30f084e986e18d113d9f513',
        189: '62ba523e58f1a5a53cf4dfcd1d8ac6b4',
        19: '3cdfc10ad6660692a3f77f6a4e3825b4',
        190: '6ed9f72a820fd21964eb1b6b7af23ce8',
        191: '8043c020cebb9211de081aa58d09c5ac',
        192: '958ad5fd199350e7980c2e037f6ab2c1',
        193: '961a70018d3cb4183f2392ee05f3632d',
        194: 'a8cf927f2766c084575c3c8190f3d8e1',
        195: 'ceabb57293cf4b4fb8f4a9d25bf59188',
        196: 'd6559b804b923ad8c226a58a3f4d2ff8',
        197: 'd6e2edca53c588305ce28f90c4e61e12',
        198: 'e872f0337c0b13febe84279847c13dcd',
        199: 'e904a220fcd9073620567d23f1bdf288',
        2: '41f617fd3ec8d7b08e5a2a2139dfedc3',
        20: '3d5224e2c6581645166665ff914eb807',
        200: 'ecf7ec7ac0e3223412e5cb28c908ff2e',
        201: '0430f5a1914b11d71291b174c6bb0c72',
        202: '093988d394f6643d29b3474b944ae686',
        203: '0c630a9716be6ab319a258c7c7619992',
        204: '137dd6108a2078371bd17504577bbf9b',
        205: '1d783ec3f41d1b0e065ff856d10843d4',
        206: '4244db03e8e5fb5efd0b6f4577f4ea95',
        207: '4386337e43f2a171af9257403de6803b',
        208: '44f84b628203c2c5423c18b9d349b1a8',
        209: '621738f1739cce57a116252910974fee',
        21: '428d3bdc9e8c69e348332db0f820d47d',
        210: '62241ff0aa3eb6bc83120cd734af2f56',
        211: '70fba10adb39283c971de5f8752b4a4a',
        212: '772d5968643e6847addff78f41c6f65e',
        213: '79b566cea6004c2ca0a05f59020774d7',
        214: '7fca082ba43c2b70ea9d183ccf202342',
        215: '9c5d7e5dcdbab3d4fbb5800bb6210c3c',
        216: 'a8cad6e4f32cd1f47ab7e64d71b88d5e',
        217: 'ad8f1b9e6abed864f6acd6be732558b2',
        218: 'cbd8b10c7923eecb025e4065866ebd11',
        219: 'cf60d9592160ccefc1dabf6168fcc1ec',
        22: '43d46a2328977dd28f5d215d9b9e045b',
        220: 'd6ed798d8df08f91876d9c7d311d7d18',
        221: 'd90db36e4aaa2d953f9a67cecca6c989',
        222: 'e4d66db1598b7bde5791f4ea6a7775cd',
        223: 'ee167ca5e8d54f0f4c28f26fafa9854d',
        224: '0bb66f9002b08f43f53e6355269f9455',
        225: '1a4237fd425692f4bd30503cc0f6488c',
        226: '387c0f789d92ee68636e8a2e9445dea8',
        227: '387ed3ec0aa1077eafaeeae8fe39d8e5',
        228: '415b67213b56a10b5f2563dc680b833a',
        229: '48536007b78a3033bad2824eb39830b6',
        23: '45fdf32fa68951bb374ff43c9bc1d1c6',
        230: '55b9f32d1813734662da5ad9887e44f5',
        231: '5c50c4b07f0ebb63ed9508e1dd2f07f6',
        232: '6329eb9a09c16ca8d153647356438c40',
        233: '67e7114ae4a41b75d3011aa41a2c2e2f',
        234: '6ab513114decb801975c3efa45e8716a',
        235: '73119010a38f27d8d1120fe43b859854',
        236: '74de3bbaa65099883b74f000c994905e',
        237: '758c95c45704d3d42bff134cbcffad0f',
        238: '75c46096fafb88b51e0a41f7876f0d38',
        239: '76e7cc36fcde8ef31e33896a159098dc',
        24: '481efa95c592655d32084cac1e1243da',
        240: '789adc592e7a84e7f197d930c6fd37f8',
        241: '7907817a55e6769d08ab15ae6cb1657c',
        242: '79e8db9485ff25acc1f4fce65dd1c699',
        243: '8345d5ee8c99d01b2c37311e6585918c',
        244: '87ff8536c915cde5dcb2f7cb1a3308c8',
        245: '89b6fd3639a59b23f58c8262f7286eb5',
        246: '8ac225c6dab788a9d2ebef7fcd9d17da',
        247: '8ea153740b40ab04ac22d1566d8ce039',
        248: '94cd365edaf1945ffd87ec4d2cd14beb',
        249: 'a2983a26dc9ef17fe6799c0bb71c727c',
        25: '51162da65398c11c55af4aa7352c776e',
        250: 'aaf8df0fe9664747aec9f216a7b0e563',
        251: 'ae518372aa1e8b111da585bba796d3b0',
        252: 'aef4b7d33706fa5690d6d9788c9a0afd',
        253: 'b7a4799334b850d484e182e719ca0d79',
        254: 'b996993238fd84001c4d67ee5fc8a43d',
        255: 'b9efab151acfbcb3244fbb3e297479f0',
        256: 'c394e8d98b55c1cf2e5aa12e642d7116',
        257: 'c60cd6e385f02a0021a333ee3157fbfb',
        258: 'c64fe532030a96202bb741fbb36f7982',
        259: 'c73676fd3ad6c752b3313e3b1829826b',
        26: '57a004df31ab05fdfa315b15edcbf032',
        260: 'cd1fb23cd114ab0e3ee2b4ccf2aa4569',
        261: 'cfafadf3d44c32bc53c020aded4b3070',
        262: 'd048ba0b12e42921922d37b96a4e3b1e',
        263: 'd221f057d011df03aaf68162664a77cd',
        264: 'd5d881ae932ead8403292150609c0c12',
        265: 'e362ac00b2b4b73e85e43768f670bbcf',
        266: 'ef030fdc986db7de3daa66dacb0e4a97',
        267: 'f44d74d129fcf58fb76747155185f227',
        268: 'fafa72f7a1d7f10c43ed305759ec34c7',
        269: 'fcf6c644c03de352762d041c48c86a74',
        27: '5a0ae32389c1e06ef260eb98e26e86ac',
        270: 'fdd8bb2a7ff4af5bdd15b50398433131',
        271: '003ff148f7058c8984af65c0e5c2311b',
        272: '254aae570e94d0e65e2f0a733dbbb2da',
        273: '3d6c8fcfe2ddc7bb6a74110d6dee529d',
        274: '434816c50a511f54921c1a77ea48fe46',
        275: '0f770570c179e99a879de42f862ac592',
        276: '11445836c883fcb3eee86ce82b2118dc',
        277: '616124c4341125b23b0b776e545a74f5',
        278: '1c186c0a5edf2090bc3bf7d4663eeb9d',
        279: '6d08a2eb83b113a3892a7b177a0ebc0f',
        28: '5d0387d862aee091d8eb1e9f78251216',
        280: 'ac82c519ffb21988c7e5dfa26eb54ccf',
        281: 'cf0593453685d9e4ac632a2320fb5dcf',
        282: '38aaf1dfe96eaa664f8c1c277b1558b9',
        283: '448959ef2c4b50d546183e285f353036',
        284: '46bc1137c02659279aca0f497aa61be0',
        285: '58a1c3223dc391b6e2bbd520c35b3cf0',
        286: '654e13a2ae4217fc6520715b23526b6f',
        287: '66fea09dc3ef5b9ff90c26dc7294ced6',
        288: '6b760514ae0fe887a937b538d3e05aac',
        289: '7e54369843e67a9d7d08532fec68b583',
        29: '5f5d7fa04e6cd5e789a2711adff895ff',
        290: '8570a9e56b33c5911b962fd2713d8586',
        291: '8700a68b148b9fc2d883aa660520d30a',
        292: '8e6d9461ca78c0b7e52a073ebdc63fdc',
        293: '8efe6eef70a357f866b69679a8fecc81',
        294: 'a69140d2f3c61c6286a7d8a42fa9f67a',
        295: 'a784d36bf54895db7fa2ce2d6bed6ab3',
        296: 'ae16b77c220fc14af82796e46e3796a7',
        297: 'b98c6097d22e2802cb755c6006e7abfd',
        298: 'bdf96c0eb7f6ab8855ba43d97613206e',
        299: 'c0ddce9bd9004a75d56a7d7bdf32a5ba',
        3: '73953af1db593b18f7c833d29eea9122',
        30: '65af1a0d6be5a9122b707617b9b141b7',
        300: 'cc1450e8ec8659e10300aaa5d8d0d877',
        301: 'cec4e61c38dba222993976eee1132e64',
        302: 'e1eb4da181d5b034a81e44bfa0edc464',
        303: 'e542f3663134cbc8ec8d8022f64573db',
        304: 'ee36b5a2c18bc230a768d9249c4284e1',
        305: 'f925cef5626d49c96a1767a6cb3a7d0e',
        306: 'f9946758c932f237680e9e86af0d1959',
        307: '0af8fe8b38608b9b7f76f14a45ff8b37',
        308: '10f712825b64460040a5be524a0dea91',
        309: '2372cd8294c83781aa0e69bf8edd5f9d',
        31: '66304c892ddaa6d1c42e5afafcf7d41f',
        310: '34f0c3a447429cbf89974b80a3b06183',
        311: '4d56559ca0861a76c01eb700314b5051',
        312: '4e9bc9db94745e58f6135a6638010cf7',
        313: '6506d15598b6090576cdcb185b71a1a6',
        314: '6c6bead1a9913b9473071a272077ed01',
        315: '818fed0620ccd71d15e4446ba4b689f1',
        316: '966670c5da9484a4f709b093e47b6bc3',
        317: 'cd43dea44fa07dcb3ac12e661681b47d',
        318: 'd46149851ee4cda8ec1a49d43627240b',
        319: 'd9464c1024ec7865706c535329646713',
        32: '67a10d85db085825ea64fbfabee10821',
        320: 'e73142186e62ef7ef1f30ea2af03b4c7',
        321: 'fb1f11bfbffa7f66d9865809a6dd6750',
        322: 'fb3af81e803e17bf5df13bee5b33cb2c',
        323: '004ee9655c9becad36cde4166f3ab652',
        324: '010aab407f2ef030528748f8b2d091d7',
        325: '11dadbf0c8181c9054fce5ceeb6e4bbe',
        326: '195f0d4dcb82beb6d66c8dced8ce5f1c',
        327: '24ee9eab72b053d2c284ab2bba2e2f20',
        328: '44832164712677f75f8554199fb67992',
        329: '47a6afe571c302c12ac53862fd4757a1',
        33: '67d0a7429a2675c6d49eb86e95c73332',
        330: '5a63ab648644c1bdbd5551a782699b75',
        331: '657e7592eb08747f291bc8360d7c9fff',
        332: '6aa6aa411a36be9c4d9e9e067066087c',
        333: '6ad0207d982b0f451b1b1100e44eac45',
        334: '6fb6c5c852e3f4330dd4b47d90ffdde6',
        335: '6fcc6a6742301ef3cae6eea3d6964386',
        336: '720775b928d36b60e3c7b689d06f1862',
        337: '78f33ce4b499f8dc2455f31e1d77e2f2',
        338: '82f177e393cfc7d2096cb10e55a334b4',
        339: '8326cb7436b944b56d6347fef7b43c91',
        34: '6cbad0f1819e671904a0073dd5577e5b',
        340: '965ccce2df8a3e548ed1bfb5f44daf06',
        341: '9ce4f475862d3a15107b24595a2550cb',
        342: 'a25bcf2662dcf173dbac9a720890c6a9',
        343: 'a7d023623d4634c7caa81bb748f28327',
        344: 'ae36ac60a5ee3957c490424b5f19ec33',
        345: 'b628847355e5ec1e74110cd0d9ae0ec5',
        346: 'b7de879b4873c9be49cf5a0b99a43040',
        347: 'b9cd642fb5714cc75f5a49a65cc75905',
        348: 'c6811c8481ad510a3c7d4ad4d414fbae',
        349: 'caee3de22e0700bd0836ec7848485b0f',
        35: '77f8f6d286f16251cccf1b77cb380b8f',
        350: 'd4042b003d30089be54b8c4bccd976a3',
        351: 'd563940fe8c1402231eb001267e1fbbb',
        352: 'd84d03852b9ec83ed8fbf1b2317866b2',
        353: 'daac63136824e3079b55614386aa8246',
        354: 'de4a52d172239b9f75594f993ebe7ed2',
        355: 'dfc41314f3fbd9b47a98ff2d65390fa6',
        356: 'e38662597a72d1933688ee5fff27ef73',
        357: 'eb67ff017396e2b104b4407aa630de5a',
        358: 'eed0836c14e72ba191d8fb269ec3f80b',
        359: 'fc1c35d23c37b0292ec7081487394ca8',
        36: '7bfdf44032351aa8e2b98909cf015d5d',
        360: 'fd1e784beb4f4306548bc3d2d9dbb75c',
        361: '09caf0de8f8f3e8a37c757b5913c8a26',
        362: '12468101b5dfb4e040b068e8eefe4d92',
        363: '1777dfcf1e772bdd05d1bf811241a430',
        364: '20390095ef1e070484124ee5f6110d52',
        365: '267d53513304f06a3703b36741bb6d3e',
        366: '27dcfb293ff81a8ae7f5347fa1fcfaae',
        367: '34bc8b2247df942628271c503deaaf71',
        368: '3722acae5801e0a2ae7853b791bf6792',
        369: '3937eff6b763f54dd851b7cd553869e0',
        37: '7c8e6bdeae8e1e6a22c94d92a30cd3e3',
        370: '3de48092e0767691a3ffd2780e35cc99',
        371: '49592c00a7ebd85be0a47357982a1137',
        372: '50a18f48e82f685ed539d9991c851caf',
        373: '5bfc4a1ffd4ebd967dabe8b2cb7a40da',
        374: '67d403e50540815095273a870689b017',
        375: '6bacaf3d2dd15cf22ff7bea767111428',
        376: '723db7862b3cb55d5eaa4b2ef609ac7f',
        377: '75c1ca6322767a2e5e2b09e674aff01c',
        378: '79fb5dd9212357f2a3dc2165958cc5e7',
        379: '82a8c572054ed03f2388b612f35cfcf6',
        38: '7eaa92614357c8a0385a205cc955ea5f',
        380: '8dfd857ff47e82aba57d0501018406c0',
        381: '8f7d6ffb7e6b4c95ed63789bc4cf5a37',
        382: 'a4cf13f53ce05dc1b44788f062e1f477',
        383: 'a999fb427978622dd27eb498a16f490f',
        384: 'c0192c87f65ad87445dd4198081a5a04',
        385: 'c03c7b46a82372858b0dfe312a95261f',
        386: 'c0965512212075b8d3272c353f6ba627',
        387: 'c22cc578c25742ef351c5c7137850178',
        388: 'c5cb465cb3def01a5ca69050b5ff3adf',
        389: 'da6f7d689145bb9edf4e2eb2335b017f',
        39: '7f2fa4ff6b784f7378cb8804ef41f0e9',
        390: 'e01978db8758b4ceaa1712bd8e1090b1',
        391: 'ff759aa710bd937694465844f9394e09',
        392: '285d511329954cb7c99e4184ce86fde8',
        393: '32f8fe3d467ca514991492ee7e66662e',
        394: '35e9345c9187975ef16b223e390afcf7',
        395: '45f0545bf3975e743949e901fe09c9c0',
        396: '5e49f89cfbbb677b83c5c6f146d57107',
        397: '5efc01929227dbfd42a0ed64661bead4',
        398: '60a8b3dff85d04276957b750c2256d68',
        399: '61a381b415b5d10a5299f27af07e106c',
        4: 'a58d2127e2c1be5ed2f2cfd2d53fa193',
        40: '7fdd550197e2ce995c9292928dcd4aa1',
        400: '6337479520ee10d93b41efe8166c4a73',
        401: '75e20cc451c870f529d4f1578d87be0b',
        402: '79a80b8f7efee653cfedea6dccc7ddd8',
        403: 'ade81cd6ec3d8eba356a32ee93ffee0c',
        404: 'ba44c6094ec03716547add723bc781bd',
        405: 'd12993df72744e4d7bd28e13c29d0b9d',
        406: 'de4de65b426e3bf88c6ee1ba7da35861',
        407: 'fec3c2b9697924048e88432e57010e9e',
        408: '4ccfe542a76506fc4d9112a0eb786a63',
        409: 'f8240f8b6e9127cf7b0c3bb41ad2a1b6',
        41: '81a0ecaf98fa09ddbad895246476ea5e',
        410: 'f914043d748163b4b7cc9e8c180e3892',
        411: '45e464ae246ff532b2ddd04226fa6ba2',
        412: '52cf5d918ddd05dc9b8421b74a627d89',
        413: '18426ddf19536b2409ff253c94651b1f',
        414: '1ee0a3d4acd44d9fb5db710f8be54db9',
        415: '254c41cb8f4620ea4d0e9f41d89b02ff',
        416: '2be0983ebc5e1a6a8ec18440e63a985b',
        417: 'df05c85f09fe8195b4e15542c034f67b',
        418: '2eb29ab344d40a19651d209e10d357c9',
        419: '2f9a061adb2022d5793bbbd1ded6ac43',
        42: '846758cc042456e8dc2c3d2ffef770fc',
        420: '2fa17d8a1d78b2fc3138e7b457d5e20f',
        421: '31ab6e3c8f060aa78e1a08f01386beaa',
        422: 'f183e65b1509f1b7d1bb3f2bf385fbf3',
        423: '3c48fc997b659e57ad1d582682df5f96',
        424: '3e2dd549e1a5e4bcd076edbe3459f071',
        425: '3e4f1db1f33d393bd7a71bf093e7dd1a',
        426: '44477d4cfabb140d4f2f945fd63f7537',
        427: '46d049e903c96c7c9b4da52d2be16f69',
        428: '516a9d0ec0aea9f9c90ad508ef98c566',
        429: '5747783104b13d90895c69a0652c086a',
        43: '85ee7bdcb3090ad365d59454bf32b81f',
        430: '5f3e4bbbcb7a3221ea093f967dc6c607',
        431: '62739f8369641923ab7baf881e6fb48a',
        432: '63aa029b3309980649f722c975899cd9',
        433: '6b5a065eca0efeaed6fd0aa4f960d2f7',
        434: '6bba425cffe34f1872cdf3ef9fccf0e5',
        435: '709a56d2715a30ffb33ed3ff02ab2f78',
        436: '7bbe58386eca8c59764a8791ea0ca8f2',
        437: '7dc5bba2790ce1de07ce59038d8ba80b',
        438: '8623b91942d99fcdd741be15caee41db',
        439: '940912c119141a77b253ecdd95e2de97',
        44: '8a446afcbc951cb3d9bf7af3ddb9066b',
        440: '9749322195c00458715bb7a0575f27a4',
        441: '9917aa583636ff27f93ad141593b587d',
        442: '9ac0a26db666b01f70a702d45c02f6e6',
        443: '9dca80b8fa092fe876172573f3f73df5',
        444: 'a89ac4ea3f24a782a091d0d8c523b898',
        445: 'bc33ba81070b6709addf6526c3941723',
        446: 'beb56a5c237c4e8ffca930d60cfef8bc',
        447: 'c499926d77798eac959776045356df71',
        448: 'd278a4123b28d09a18d08e884bdea7ac',
        449: 'd8af403e2b5d38cf9ee964e8e9f775e7',
        45: '8d13ad063841f890bdacfbdbc2b7afb4',
        450: 'd928f4b1ff20d038bf1565aeb9a8ce1f',
        451: 'd9a198c6ad758bbd4e54fb27e9788adc',
        452: 'ddcdc986aa8957e0f56d45943b3b4ea7',
        453: 'e0da718d073fb6a284b5eec96a68814f',
        454: 'ec1b5536f6c74bfe8fd74c0bac604aef',
        455: 'ef388ab344739a0e872bc75fe305010e',
        456: 'ef401420f0fe029f5de2a18debcc77d0',
        457: 'efba9d4266c19c531092eefd3fe298a6',
        458: 'f4cd8081c26bacbfac6d2c44f998a1f0',
        459: 'fb24fc2782975f616546825b0170b013',
        46: '91bad3cf9c436d59764fc0a94cedb29c',
        460: '067da709092ae04ab981b0981f811fb3',
        461: '34eeef4910d784a76800f056ffb098e6',
        462: '4a2effffc0086f01070e67b711d23025',
        463: '61a5633cf8fe333ea785ec696744e7f9',
        464: '7ed4d351aee8b18c3098ab80b563632c',
        465: 'c67062eeabdad77efb2ee00ab9a9f4c3',
        466: 'e3263b8557839af04724ae93fe18281c',
        467: 'ff1ac91f8b05ac697ad8bd7ccc43b5b3',
        468: '1488c342eeb79d7581ab89adb91d4d99',
        469: '373313cafcc5f7396cc1e9267d1624e5',
        47: '963b85f564dde551e62945248101c6e7',
        470: '3f0721bb9ea0605f2b4f13e55305c9f0',
        471: '4ab484749487600992c38c35a92d757a',
        472: '6cd497c6eab013c6baa2022c4dbe609c',
        473: '83318f0007acd58a23f6b665042683a0',
        474: '83c76a1a6215eefdf2858f3a3952a800',
        475: '86e67fec61f68589f3d929393a67384b',
        476: '929575307ed3f02d05350dab18dac805',
        477: '94a99c809b5b3ed391396604ff345784',
        478: 'abb7feb4d5674d45e202e443a7776a16',
        479: 'c323b917190bee46af1eb3749729759c',
        48: '9ecc1865b7ec33d67ee9360928b0efd1',
        480: 'cfae9b06df303f423ab01b64e089b1ee',
        481: 'd5ef557d972efca09fed5d91abbd6cb5',
        482: 'e2e0c59eb9c7b5d67b282a2f8a09e92b',
        483: 'e3bcb23c1ed516ba5459b0e18381e705',
        484: 'ef3c88e589f27075deaf2d5d2092aad3',
        485: 'fef3a12de9ece51c724413f9a5f237cf',
        486: '0671194714069c88e1b98c7f1ac072c7',
        487: '0f6c28cb12e13f2ad8716b3e02bd2a8d',
        488: '446fe0982b9ee19aa31750ca4267bea5',
        489: '4c2ad310d1d655cc6265500a93613ed9',
        49: 'a36aa41456297b994dc163c7aa71cef8',
        490: '53d442b563857e54eeea2cdb64eeffb1',
        491: '5dff2ecc8c11e69dadc43aa1faa8901b',
        492: '63081ea9545a57702ef822874ca06021',
        493: '67125ad3f6b323833a2f17c99fa04436',
        494: '70d1aea9e69400149d95da04d9d66347',
        495: '7ab15ccfbf5c51d70ec3e809c996be34',
        496: '7d539ee99800657e5890aa81b6d832d8',
        497: 'a3c813a253232fc92c00521fe5cb7b3b',
        498: 'a7881cb8a973ad5243290a6538e57d6f',
        499: 'bfae33b87289643050e71864563eb101',
        5: 'ca6d4e991e1723d0560bb1b598794ca8',
        50: 'a3a68ce1f350381eacf5b92ed82077cf',
        500: 'cb4cf89c99a8a50eacaedf843b8e9afa',
        501: 'e5553f2d9ab4d2b509aa13f81c846b42',
        502: 'f57026d3895e1798098d6f10fa8d881f',
        503: '5aa6eb31da2fa33b5fcaee9e219422f0',
        504: '1aed502cfac0cb4e1cb980733a824103',
        505: '2773bcf7111248d2fcb8205e1461af41',
        506: 'e9ba121d9a2a724393c63aa163f6c7cc',
        507: '3ad77774fc8faf3abbf45bbcab13ca02',
        508: '44fdb4e4e83feacc60d9f2bf7cbdd525',
        509: '6493777432631d9c12526b5a4924cac9',
        51: 'a3eba02aa24cff99ca471cf70213e38c',
        510: '7bfc1ef367745a4be0da3227a9e12bc9',
        511: '94f14b5f57244a055b660a5ae3eb581c',
        512: '9bc102b6b34988919a51f6f0516101da',
        513: 'a9866f1c5d79998048108080e19e6cd1',
        514: 'b085c96c36185afdfabc01f8e37d5ab8',
        515: 'b2475ccba7cc3e9d2b6b9bc3b43c3145',
        516: 'b35ed678e2fc29df638b63f9634b726a',
        517: 'c21befed89ac44844f831b40f1984d86',
        518: 'f0855aa776c15e0bb79ef90a440b6cbd',
        519: 'fec31491d016e95400f3c48373e9a633',
        52: 'a508c92fb37cef717af8846196c77964',
        520: '3bf963e69559c1b3a19fc989bb0609d2',
        521: '3e1de2c593d37815fc01232900c16a3c',
        522: '59c9293251ed0e40dc0184688d3c56d0',
        523: '5f969a18881f571b333bd4d0a1043906',
        524: '68bc1983f70b8f1e8b1e8824e9fbec96',
        525: '6b8683aaf1d4caf6bc7392eb5bd73c07',
        526: '84dd6f85bbadc7343dddcf4b27bd0a39',
        527: '889194ecbaf886855e1ea79e1319d81a',
        528: '9e4c95901fbb9b52fb7997e98dce3908',
        529: 'b9d63da3fe1868138ed18c91fc2240d1',
        53: 'af1edc1ba5caefaa76d7b3948f50afe9',
        530: 'cf4ed9754ef5021ca121c9c49887acce',
        531: 'cf89d33190f668871181fa0abc4f5bc0',
        532: 'd1cbf956526a8be48fba2343663703bb',
        533: 'd7ff1f685ac0a80993ccdc01c15196ed',
        534: 'e3f01db4c3ca586f8f11449a76d66bd9',
        535: 'e7367c38be3947ef946a6e94fe51d0f4',
        536: 'ea95ef98001de7ed984b957a0efb69a0',
        537: 'ef5ac1574d6dd1fede8ff745e32a36b8',
        538: 'fa77be4708cb4585326c5b16ecd78476',
        539: '02ba373ec9c43774c1f2f6361fc3a37a',
        54: 'b03c30416fbfda12b2a36d92b6c93c7c',
        540: '2058f6d8c09c221c68f9e0acc78b7764',
        541: '38245a1b514fe7ac019df5a9c071431f',
        542: '7522787580366d72fd7d50d8b45a1644',
        543: 'aeb609d2f48751c389602d88d8c15e8e',
        544: 'c070550e6d0bba5c953fc236ba85f25a',
        545: 'c49612a2f7f92a96d535511e6f6ba15f',
        546: 'e29b36d4e9d3460bce3e868fb085402f',
        547: 'e7faaf10897ab4dc6f00aa191fc5683f',
        548: 'e88bfa7afccdafaf082fb0812df05480',
        549: 'eeaf576e9ef35815bb1327248639b210',
        55: 'b2d79f97ce178f43ab3ceb5830c9bd9c',
        550: 'f1115fc0c6b6caef4d02636226d3e229',
        551: '031499826555532462d94408e9c53ea5',
        552: '03f72ce640921e3226ec2121a50dedfa',
        553: '041734f21f5a336fdbdddcefa4a8c890',
        554: '05124a2321e023fb4c4e7568729a5e58',
        555: '05221a0e9c899351bba6cb334a04b42e',
        556: '08ab7a4bd85c4e59f4d9215915149a13',
        557: '09ec78a38524bc1eef4e2374ee8c8a08',
        558: '0d198d9f40b29d5641fc267e44988b51',
        559: '0d5ab5834dc0842d50ebb5bef41f4549',
        56: 'b2f6412c7baca60a94ce3367fe676b53',
        560: '102e0fda13f766a7180a3704531da3a1',
        561: '1097374c0ba1d46060b751ad160104cc',
        562: '1097922c2225b426b936ce7d25fa32ee',
        563: '10b233fbf215a8e3965df79edf098724',
        564: '11f5773eb5e3fb0927b9f0520d9bc746',
        565: '125215256ded0bc63fcf2c2717884b94',
        566: '13b0dbc66b561caa5c8816dd58fa0baa',
        567: '14405f590697f342c0807454bff57751',
        568: '14cd6ec867bfff0f4f2337ab7622b428',
        569: '15adf7e07b73ee6137ad732f61f3373b',
        57: 'b3a601756c30f7759b3ce70e1aee5e23',
        570: '16bd7f3d8b71294023e943d2969182be',
        571: '188f06e4facbc30ad38e049f12132357',
        572: '18e4c46fac55a45990f59be3085105d7',
        573: '1b1b93353a3937e218202dc48c7b27da',
        574: '1bc8ab8cbde049d2ad31752610025899',
        575: '1c2a9c16f64c2fbba4c4f2476fa44f8b',
        576: '1c683aa9bb01f18d4dc2f8b0895e0263',
        577: '1d4e4a0bc6f2b81a8fe5340320eb25cf',
        578: '1ec38020a18347f7a8adf8a4ce9d1cbe',
        579: '231bc20c36a9f385df4b2f9c44e9a601',
        58: 'b74cf0cb3103b456b067a0fe38813f3d',
        580: '24492cf468bf168dab0209755c525de6',
        581: '24fcd88a394f6701698a5baf15241219',
        582: '2997c63c1d57130d2c523c5369564525',
        583: '2bddb2a70066ee814fd2f753de49ec0f',
        584: '2d14b659def5157c3e59d7289a6316eb',
        585: '3100b690b7cf00337268e0dabd2d289d',
        586: '318ac548c2515b7d2af215c857b02e9e',
        587: '371b53a560ddb1d66644b237c42ee324',
        588: '384508bb12b225a67df1691edd0da6b0',
        589: '3b2a74edfb5b658a0a307bd924e5b1e7',
        59: 'b8cd6dece0b741e0caeddd761ccd71f1',
        590: '3c86b6f928a40b4fa817206f9a9276e7',
        591: '3d70692fe2d49a384e7f86c6207c7d25',
        592: '3fa8ac4f6f6a607230a5699613d847c6',
        593: '404888654b0ae6b1043d2ff442f739d2',
        594: '40ec834dd0cb2f7e5b98ee1524b82005',
        595: '41184c32ba19e275674f0513045125f5',
        596: '77dead52f58ccab0c99b0f2dd3692bd5',
        597: '41bf2fa7f5505b9271f8c959c71e0a1e',
        598: '4432045e99439e6a8ce7121fda4558ad',
        599: '445d74d391c7da3747360073e74c911c',
        6: 'f74f4fb45fa7ff1d65ac0ac91534ad5e',
        60: 'b977fc92dc7b5436e7a79c5df4f7d9f9',
        600: '4494b522bae6b85e8bbe601806ed2737',
        601: '45d445f91795ff8c8b4de2bfbed11644',
        602: '4645b6ce3081f0091261fe765a06468a',
        603: '465f1d3f7458ddf55d80918f4a4316d7',
        604: '48cd070bb7408fe44f58785847262cf0',
        605: '5094304a452d4df3eb9f73c9a31589de',
        606: '539436e3c6598bac1f36c03c516b4502',
        607: '53b28c06d490611686782c0dabacc0d1',
        608: '5b655722b66067dff5c3ed828f14478f',
        609: '5f6dfb5f128bf40e5ef96124c3ec797a',
        61: 'b9d86fec16ab9fd52610b0531589f123',
        610: '096255dc49f9e7a21f5f71ac5aef3990',
        611: '0aa72d8fd9aa0f92e2425ec69187625c',
        612: '177ee4d572603edbb3e9c407c2c1aa2c',
        613: '1f1d0fb962f566114f032f2c961b32d2',
        614: '27164c174cffbe2fda870f5434dc1ccc',
        615: '2c947de04559c55c112e1f68a5c7850e',
        616: '3019e06af594b994731c726029164302',
        617: '38cf7215d1b80f818e1b45f99e097d5e',
        618: '43a4734e22bd2752b4d7d6f602077e89',
        619: '514198083060c948a2d4ae4ade7a96e6',
        62: 'b9f1d354fe6f7219bb18f385a82a232e',
        620: '5d51738f13e7a86f4bb85ad1a058cd63',
        621: '5dd27fb504282e5015b4867a6b201ca5',
        622: '66fc5ae4cf807463226b2c818da9210f',
        623: '6dcc198caf415687d6bf559dafcff8db',
        624: '73ecac33338ef11b11148e062a81c2f2',
        625: '77202742cc5984a689d2cc4ca45d111e',
        626: '8090fc2589d0c73ed54166408f93a039',
        627: '880e98591b1d90bb7d97fe3064fee494',
        628: '8a79b3ab46ebf8d31b175f69f057906f',
        629: '8bf7f13b65dfa30375fcac9e275451e6',
        63: 'bbbd5faaf51ddbb145b97b56f03f85ad',
        630: 'a07d23f06d8cd2311b742a351b083b56',
        631: 'a335a25e85b06ff8849d402909c700b3',
        632: 'afbd5ce7aa8686d8e8889c5660d530fe',
        633: 'b551f38542481e32322269c6fdcaafc9',
        634: 'c7854b572fd9f3e338e8cf7ee1b96fde',
        635: 'c8204a8bbb63187a72b13186d511a252',
        636: 'd4459dab3fa1669015df379e9ed56126',
        637: 'def47fdfe1d2a7664add1c359c979a48',
        638: 'ea48cefe899a5911b8120ab939c61854',
        639: 'eaf3a39e266f1779fcacaac219729e24',
        64: 'bd12945a3bd3610da133ab68d58ac703',
        640: 'ed413290e27c9a9fdd03aabdc7ee9b12',
        641: '63a8cb81db2a01f319033cb616fc1c69',
        642: '6468852093b4d4f30745b2a3f2d414c2',
        643: '6480b5671b1fdb00ecd282a4d4ff2997',
        644: '65c70310107186e0aa034a7cc91e9320',
        645: '669639e5ec6748c17074302773be0210',
        646: '6773e41caa6a081bc15aea3a6503d0db',
        647: '6a7b62c1a8b7fed032ebabc482f67747',
        648: '6afcf1b8f21c775a8b444eb401dc446f',
        649: '6e7c3f1a9659b16a2a762d187a19d174',
        65: 'c322faf94389b68476beb765b401a2c5',
        650: '707c6eaa731b478e95228aef2a7b8cbb',
        651: '7108851d2eafcd2f7025743c977a250f',
        652: '7355f36856c65fd60be257f7a180c5c9',
        653: '7403ce346c042bb1f0cfd2fd743c3165',
        654: '74f3191805a55f64547660f88187f0e0',
        655: '79f6aa17959db5f5151671fa323bcc66',
        656: '7c23ff1bdfa9089cd3e4159b58ecc07f',
        657: '7cfc81fb9a02547e0b5b9c2e93774482',
        658: '808d4abb318bb3c704c184bb1bb6de54',
        659: '84c3d855107b06d92092419852c010cd',
        66: 'c75cba95e17dad7e8f019ea653ae8b7c',
        660: '84cf51f2c49bc22c81108dffbc6bfcad',
        661: '87ff3f471596a5a574016ba3b6c6842a',
        662: '8ade11b98b6be27bd3db44327ff147dd',
        663: '8d5908d66b2e3ac2ec2076f15a4c4e15',
        664: '8e0ab7a7aae73f3db651cd910912115c',
        665: '8ec029a0d1c6c192beacac59ad4a4d83',
        666: '8ee0acde14fc0bcde855cfbe8a23d2a5',
        667: '90422d4e4d7b11972e57264ff2ea6999',
        668: '90d057d6d1fef0a3ee09c24259336fc5',
        669: '91979cab9f28f19e67858a9aaa702b06',
        67: 'c9ba64c1a65c2d1ea2b9656c7beedd53',
        670: '960df3f849e4757117d2214cadb35698',
        671: '985f0ded2698113679c1bacb42170361',
        672: '9ae2674518fb63fb591c223755d95da9',
        673: '9caa7f40e009b300fab2d4e95f9b6765',
        674: '11fc63554902f943de42ed35d7f87e65',
        675: '231249701b844e507044538bc682718b',
        676: '30895b0ef051b2163413397b67a6f454',
        677: '3623c0f6c8d8c67aa25ff2a121ea17af',
        678: '380e8b723a7e875534572c6ed41e151e',
        679: '3a1882158e37c19ac861fcc13bf00209',
        68: 'cea41f2d4ff423f37cd6b2e5ebbc214b',
        680: '3e90a82b4e7ef3dcb7aca25c07ad23db',
        681: '43e14f84d564e58e5c37bce510b8032d',
        682: '58cf8882dcfbbb57013170f8ad0419ce',
        683: '5b109168ee08b2fea1be4b773a4a23b8',
        684: '5efbb81cb06e2b0b76e0599d3ce3172e',
        685: '615d3222ae6930201a8ac0bf45cd320c',
        686: '6859f5fdb9cb4ed48c37e433ce06bcbb',
        687: '686df3ea06ad2fffe2570a98f79a5637',
        688: '6955bdf4954c773b8f119525be4790de',
        689: '6f2017bd03c5cc957f5bf827f655f684',
        69: 'd164c970babf4d0449503f018e3e3faf',
        690: '79a4e5cafd52b6fc6681ffdcfc0f1c24',
        691: '7a6bf299e8e74b3efd9f4c675d154db8',
        692: '852421d1371718841cbed9e52f6d4605',
        693: '94a0c0bdc8411c12c234ac72926e9bc7',
        694: '98061af63ba02e12cb9a1537e8d87d0d',
        695: 'a1c0fdb9fbe2d2786e9382652594c3de',
        696: 'a2e9f6433e9e14fcb7dc0fb71c36a583',
        697: 'a6247a2276867683229b7278f18fbb6c',
        698: 'aaf841b18e7bc349cb1cb8dabe9cc92b',
        699: 'b04a7f1d9cf2aa03522191a4cf12730d',
        7: 'fccb44bc98ee08416263e1e5b2896353',
        70: 'd723f23c3c752cfc31cbe426b3936277',
        700: 'b1a7846a7072b865eb8218311cefdbd6',
        701: 'b473034cb5540b7e435131bc9f28274e',
        702: 'baff63976442ac363576a43af16be7ab',
        703: 'c0d93f8d6ccf67bdf397c6bd855ab25d',
        704: 'cf2da137a8d53bd4900c242228e1dcb7',
        705: 'd065aa3f6e4c84472de3ebbaf578b292',
        706: 'd6400767f8d49fa416b867d4cfd3e230',
        707: 'f43c832f02c507250892308b01b881c0',
        708: 'f4be6a4f8b2a0da00f29e0407b0ab72b',
        709: 'f52658f82058fecae97d3daefbd7f8bd',
        71: 'd8cd9e8bfdd8de7529094220b8e779b9',
        710: '05c7d390c0ba71ada2a4a8c5a44dbbcd',
        711: '12f6cb358bc7005f86fdcf2f47c9cdb5',
        712: '1a9c31e4f917f270449a25151d972610',
        713: '1ac94bdc31b3ec641bca8a97fbe69a23',
        714: '2b1a10da52810e0b35285f1d48a1b23d',
        715: '2d403a4d4d128f6abf97b3fd2203b2a4',
        716: '30e5f0d3159872dacd485f70ef7161f0',
        717: '31904c1203fdbd1196dbe718688f059d',
        718: '56a35a1411e3e3edb750e08a34593eb8',
        719: '6308a2b6b6de2ed8af25815e27ac751d',
        72: 'de75f9ddce055b105a05dbb0b3841c62',
        720: '635219fdbbcbe67a3335f37e46db4db8',
        721: '6f14bcfd76de148147226d432bba8238',
        722: '7e3a090242bcc5cd328ca64329b00761',
        723: 'c34ce800714b56678ce43be8e9ccb37c',
        724: 'e6a5301fc64ca19d4999d11bf6bc9378',
        725: 'f73c0be35b01917e500fa3e7047e0ebd',
        726: 'a1d2e6e19add33b5481a5af2540876de',
        727: 'a4b43a5d5e659ee5cd6d0c0c3282702d',
        728: 'a601d709e2da94f012d3e885598f5ecb',
        729: '034a9c2aebca5150425b2b6ef109a1ef',
        73: 'e5781c8c26e709ccb4df4c17edfd74b8',
        730: '0b4331debbcc3fcfb5d74c37afb02a28',
        731: '10e2a42856985df2f5f7a8766fcbc377',
        732: '13f8b9bfb94b0effbafa51dc9e68a722',
        733: '15c1332beabeb382f1fd39b2845e321c',
        734: '25d9860bddab164f9a6e0b978260577f',
        735: '2eaa550767ba0a33714fe9854d0828fc',
        736: '33ffbe5a8c7eff0ae405cab008c4a3ca',
        737: '34b22ea078153a73dcc75348c29d2582',
        738: '3d6607cbaaed978e73b87fc6ae0f8936',
        739: '3f14b1b9e98e788e1d97e979d7f264ee',
        74: 'e753c1951a3e6beeca34027b463142bc',
        740: '4e69081e3e02f426ced971e011f28aaf',
        741: '5792ce4bc27f392fe04391c9691019c2',
        742: '75b9837ccc5703cb3311c2f6a8019f74',
        743: '798355a6f74f99951a67794e879e688b',
        744: '880a221e22adf07b9805c67d3d5d7346',
        745: '8e5aaa36f7b934064a807b98c5534fff',
        746: '9072fb4cd9dda1bb01cc498b46a1602a',
        747: '9f1e0bfb96336a22f4ffca36eb2e734e',
        748: 'a884755764b9da31417c14c6d9e97a36',
        749: 'ad844f1e94c3cc61f4d3496f783aae6d',
        75: 'ea760598820ae18be76b8e5b970217d4',
        750: 'bc81629ae7124e99c90eef8a6c692894',
        751: 'bf16adaf3695c68eca4c110788f53b74',
        752: 'c4f120c876f5998c233f9f7146aa24cb',
        753: 'c7b4e2d9717b75e2447aefed23ca31c2',
        754: '06377ae217388bbc0c1d13d0e226419b',
        755: '51178bae821bec39b3c44ff63fcf3258',
        756: '86fd3ea895a655685df5f1f6320137a3',
        757: 'f3320f1424598c41027e9e6dbd2139ec',
        758: 'f689206626acd9e4455113150710b699',
        759: '429319a2f4287d54021c84e1e5e6cf2b',
        76: 'ec166bae97d3a4cfcccc7749a1b426cb',
        760: 'a70df974a909f75719def7d9e7c5e161',
        761: 'a8da2349d2d04749712251bcbb46cfbd',
        762: 'aca7ff5dd160e85204e1d23c8bf9391c',
        763: 'ad0d37bdf41be6e5e2b8998b2d5769a1',
        764: 'b07906bcb3048297be28f8fc930be968',
        765: 'b24d34b134e32d42d7efe2ac5705efae',
        766: 'b381a0c9e3f7bd12220b2e8cc902df4d',
        767: 'b5461dde56172c29d84817268d566f78',
        768: 'b570b39cb2e4cd229295388debc176bb',
        769: 'b7a5b3b3f288be547c7ae729e7814e7c',
        77: 'ef17aa647a0aa8e98b9cd4d61e9ab83f',
        770: 'baa69e70c9d8414b62378ad27ef5caae',
        771: 'be766fcda9b26e8958b9321a0d7df8ed',
        772: 'c31901bc90db8b340c3b7ef22c53aa97',
        773: 'c4cd8983b9e979eaf8bef5587d81ed84',
        774: 'cdd25ed76c1fa769dd66576b5ee83f3e',
        775: 'cea0f365ca75ac7738aaf21819585601',
        776: 'd18a205dbc3279762f41859a1a37cd43',
        777: 'd3b067eaacfcab3d3f8e164528ec6a5d',
        778: 'd4b734636b3faf3ad89659874d845d5e',
        779: 'd58f43c7cd00200af3bfa5b966781346',
        78: 'f17db317cb4e60222942f5c8cb5e8ab7',
        780: 'd656c63a722bd32f9a35fcfa56948905',
        781: 'd6810ce5a9bd227aea40e1dbb1561d95',
        782: 'd99c871e465ed5835fd2ff06567d2478',
        783: 'dafad4f200deced6421fc0a0a426e654',
        784: 'dc17bf49a27b676c58ced60e2f5287c3',
        785: 'dc820d46e61031befb28d0b375fe9094',
        786: 'dcc5f0608681fa457f129ca2bf5982b3',
        787: 'de34385a1e53564bb4bfa9907beeec17',
        788: 'df7060ed014c51ed76fa4679888c0775',
        789: 'dfc06cdb040b62e8d02d22554e374a81',
        79: 'f3089255d4eb010cc15dee1c43b37b62',
        790: 'e0b0b08a1bd98012882f8cdc67fe5321',
        791: 'e0b8ca7561c90769d200d38f8c09ab7c',
        792: 'e0c46bd17fddfa69deec1c6fa7fc1846',
        793: 'e1f094e9b9b34f7b325484e100655f1a',
        794: 'e209bcee90917f7dbd73cea037b5f0c5',
        795: 'e27c3afd4902be2f977c7a410f59f286',
        796: 'e6380e05127b03e405fbb99eaead7a25',
        797: 'ea0222b08e0f5ed04ac35c6579f5c578',
        798: 'ea68510d47179279def5bb44ed7d151f',
        799: 'ec9a855de1263c8aca72a755eaf24a35',
        8: 'ff5329608b5464a1881291be87c2752a',
        80: 'faaeb9a12633650468360b504a13c7c5',
        800: 'ecf4bac30e66e2673cce0847eacb7ce2',
        801: 'eedc773472ea51a4c60a61370c75fd61',
        802: '1a6984eb75eba2743518709b8f6696ef',
        803: '2264c049293cbed05826b5656586e500',
        804: '2d960a67e7d648a30c9ab574d2f82941',
        805: '2fb63c390894537b339cd6b3fcd403c4',
        806: '38a9fdec6be4647a4efcf86e427b21d9',
        807: '3bf3ec1e5867f75967877112b82599a3',
        808: '40d4021b8e313e54b75f9597b3592965',
        809: '456b985493d2baa2131c0d63a774fdec',
        81: 'fc207bef399ddd85691cfd0a366fe582',
        810: '4b7366d40faaf0ceae14d7afeac93f43',
        811: '5bd61a2d3fe714db62f960702ec15f97',
        812: '62763ac3d0a8ea0e270b30a79742aeda',
        813: '645d9b04ba7f28c10bb46a802ceef7d7',
        814: '6563e6b87838f6b129a4849dbeb9f17f',
        815: '6ef535c489dd399b31494e5dee4fb530',
        816: '7275a356ad1492f461310e8d7ffcb961',
        817: '78225200b528cc946e6b3f5f02b04b68',
        818: '78543f0d63d748deede63e2c6c46bc79',
        819: '96811230e7feec657c12dc32b6910a60',
        82: 'fedd228b25a212d1c2487d78efa99a81',
        820: '9a1013ef23b1853e77cccd2e5bace528',
        821: 'aca2030623821d0aacf19589bd6fa6c4',
        822: 'd82d3a23861f8bfa5959f79fa28fe63b',
        823: 'dfc4111dbd8f0478b1f7e8ce0c3cabed',
        824: 'ed5521eacfb113eda6c5ee587f82ce5f',
        825: 'fbfac313065dc87046d33ea0b1d29e80',
        826: 'f176d43d300bcbebb4b09525848d6e83',
        827: '00dd01940e270471c45b6894b0f09772',
        828: '1595bea9756a653a9e6684ff4ac165ba',
        829: '26e5a39a79ca0c4a93c59127903c21fc',
        83: '02e4dc64965fc382d7e64fe13b68e5ed',
        830: '2eb23843f07b9f39015202b969f187ea',
        831: '35aa280291f3cca72780ca8ae5393ddf',
        832: '35c594fe71aa280c646726d57df5a851',
        833: '5104f5793155884628de851c1f2ccb73',
        834: '517cb52f8bb2c5eb7f951bc2fac421e6',
        835: '5d881e9d4735bf8a80a2026952132107',
        836: '6354484d0dc29f831c23c6ef073672cb',
        837: '6d98226d065bf5908c0c51160120e49c',
        838: '73d90c611684be1af67f3f2af3d2d5df',
        839: '79b325a9c1dc5a57614064b4193e918c',
        84: '0864481f8145698557cfef0c9949e873',
        840: '81778050c47255cc1fe4113b9196fbe5',
        841: 'ab0a565216cfe4e7c4f89c2a67696bf4',
        842: 'b418cef654c892e1de6615b0caa5ec38',
        843: 'c102c669062503c1f420d6969d37f2ed',
        844: 'c173ab3f171d32711eda6c6ee91d191b',
        845: 'c4f1ee7d7e5cbc712eb384aded2cf6de',
        846: 'd2e8a5ee8a90ce55cedbe441e0afd848',
        847: 'd59289b91226ff17fb83f46cbb8e83b7',
        848: 'dc38878a65b4fcc699ae2d9b0a8f1118',
        849: 'e8d32a8a6fe967a50612732be90ae21b',
        85: '18e00815c968706d8679c254fb3bb615',
        850: 'f396bb266f5904e0db517caf1f13ec37',
        851: 'f42dc335b758f4c940b8e78b14dce084',
        852: '1493e93fa84d4bba06369ae4f8206eb0',
        853: '1961c402f0be29a642513afdef32a640',
        854: '54f0eb82375ab66452c4c459cea74924',
        855: '57f52a19d2b7e99111409b61273d9922',
        856: 'a65b2a31a3327d54c7a89950bc6c3f62',
        857: 'bdae05c72f486daef6c8dbc4e2fb3e92',
        858: 'c8f38c8eeeb31c9716d63f5c630bd2cf',
        859: '02ed067da49bfc0c7847305c2a47534b',
        86: '215e9dfc1952f7a10401ab5fd2f75ee7',
        860: '0312998cf56ff0bd23704edcedb776a6',
        861: '078e5599e98dc71b6c454c683040ff90',
        862: '12747c7d984dd41f58ccc3d529f5d1fb',
        863: '17f624d3ae1ed6b084710de26930a775',
        864: '1a66431f6c4e0e2370ad194943f6d655',
        865: '3158e0f95f5669fade337cf22574c806',
        866: '689e2683637d2e8324e99f6eb8536fa4',
        867: '7825ede5965c6fc5714b08a7efcc2f59',
        868: 'a58c89838bf27d1ed65faa44d76dcfba',
        869: 'aa50ddd7f2eba3cac62543d6f178da56',
        87: '303bd507d7f6be74de8de9a7a9ae12c8',
        870: 'b05f4a00ba18ef5d70b876586c388884',
        871: 'b4579e096d51be3ccd66bc33e09bb72c',
        872: 'b5be75ded34fac729d10bfad165ce649',
        873: 'c411dadb0e920e9f2e64d49712d2a630',
        874: 'cacf9778cf2e0a716cbc171ab101dc1a',
        875: '0b5239554393d6746bebae81b43da9f1',
        876: 'f25a9d0c00f13ed0d56d89673ca57f00',
        877: '071f0f08acc7d0109b5fb1ad28a696e8',
        878: '327585a9ab481aa828c53688cbdcd5c6',
        879: '33df129bddfdaea823a2bd58a34414a1',
        88: '3125f499b6375645f9dcf541a6862e48',
        880: '4d99b91187585bfe4c990a7f58488528',
        881: '5c81b107cf812c1f03017ff7fadf5764',
        882: '6cabd273d446a0d7996bf7d06c77c340',
        883: '78f0d0e4b8ea869d29f5e694fad35cd5',
        884: '80ffa20bdd780fa3caa2f0f78ef399c8',
        885: '85aa3758b62d1ab1e67860ac34e3bd94',
        886: '8a5ed7e6e9a05f2c44f72269470a556d',
        887: '99560a62d3abb66193cef13b75464043',
        888: 'b26a5242958a56b7db892a7c5494c3a7',
        889: 'b9129e7d95fa80af345c20f514f2852f',
        89: '46ede21fe5258098fba13ce1b83c4c5a',
        890: 'df166bb73dfc17eea69ce7ece64cb286',
        891: 'e471f279733a300a8c51bffed8301cba',
        892: 'ebda2e8b37d7731a1d8b30669fe8a0a5',
        893: 'f075cb9220189e459502f044b5a48ed8',
        894: 'f2e3b711507ec6d8def24e8f0c11fec3',
        895: 'f65f310f3972469bdf9a679137941909',
        896: 'f88445ddbd324961e5e2f02fde7c1b81',
        897: 'ea43ebb5763806227e2a0cda3f9c581d',
        898: 'fade6cb379ee0a758ff2e7a4940e7315',
        899: 'faf8e4fd1de02568a0f3498246616ac0',
        9: '0091c4153a5df3f69b42e641dedd3330',
        90: '49b0a6846806889912ac55a4ae57fa0e',
        900: '1b3b922fc036c0d00cb9367931c0343b',
        901: '20c4b8d3342ea84304773d8f10978bd8',
        902: '53c824c87c3fb123edd1a0799e8d8254',
        903: '7095dc9e8586d3e926b4f8203b051e74',
        904: '05c9f8f7f05d4a7f02ed69c6f5765d0a',
        905: 'fc73bf40d6b7f5a16bf21a19f113faad',
        906: '8ea04835d08f45520a171945b5f4d31d',
        907: '0768730e63b21f938f9d65bd9bb666b0',
        908: '0b11b1e284c020b41fc04d958095b2f4',
        909: '0fde6cd08b762b0b73b4a0e84b0e0d9f',
        91: '533e38491ee5f6a4a721bed184fa56c9',
        910: '1289defd8e0d20db90e70757d8dd0af1',
        911: '1714b23dc4162cebf5b4478dcb106400',
        912: '1a19f38979fc7cc460513af61bf639da',
        913: '1b5918cd7f95d39909a603387a435a43',
        914: '1c4329b4bce6180425b24a9a65f95890',
        915: '1e6c95b44e1fdff8384f612a94998950',
        916: '24f450905bdc68823da0b2a08f217b0b',
        917: '264383178479838debb379c608c99b08',
        918: '27b9cb052c6b2751be7dc85eda6d9f21',
        919: '2e33aa1b0c36945e8bdbd3bde1c59c91',
        92: '5819f0804dc83f2838be455fa335376c',
        920: '2e99ef0ff4244d20c2042ff848662133',
        921: '31e75278bf3865444cb3ccfd6ca44807',
        922: '338635a18053d66e1a6b283304d5a7aa',
        923: '33c8233b7050067de7e743a8b63e80a5',
        924: '369049f49498b94c4f3c6e8b49787d13',
        925: '3b357787fe590c5c3f6ba1dc52a3ddb8',
        926: '3e9fe61480624e10601d2e3c39bbc724',
        927: '432a6df57b9075f6f6ff46d396c7f637',
        928: '4b58b444adc60136b3bc8fbf33d806d8',
        929: '4e72cea69ca278cbd09060f98b18337b',
        93: '5f0e0782bb376dc5e41fd0d6a71f7a05',
        930: '50dd0260ad78438aeaea93c35f9dd484',
        931: '53b09701af3e6ec53d34e97dc512e411',
        932: '58263e78b3367f6eb0dac93adf7916c9',
        933: '582aa85ebb06066809dbf842be0adb03',
        934: '58d57527369545ff82c34bb1e7ae60fc',
        935: '5982c064d3fcf9f4b9c15a6a1b31867c',
        936: '5c35c7baad2121677c57a942aa44a5a6',
        937: '5c8f8185e7b5380ed6936033e5d318b6',
        938: '5d2cd9524aa36f2b111cf7852055aa6e',
        939: '5e2aa5c03cd9c2be6b33888584b8dc56',
        94: '691f4bf5caaabfa81de80125df441cf0',
        940: '62883afb17eeef6e704a00588868695f',
        941: '68e6c2f1794d57e540ebd630295890ca',
        942: '6b40c0b205d20be07995c5fdea8ef6b1',
        943: '7526cf9b399887d2219c51154f1a098e',
        944: '755577da6aca07cf02ba958a137f8c03',
        945: '75c3dbc950f1ce16592b42421b1ed8c4',
        946: '7763adb5a246f7ea172a49b7178ca3b5',
        947: '77851c17dd20a00fc6ed65086edace9b',
        948: '7898e100a1ad004716c75cadd7e3b675',
        949: '7a0e880715638c7fede315fa49bc8b07',
        95: '6933d932a670fea9bb0afedce1cc010d',
        950: '7e3365f2ad2049470dd0688dd1cc1378',
        951: '81861295a3671cadedec1f939e28d681',
        952: '82fd552451909ce591d7f4ed52cb10da',
        953: '88aaf90d8d98af9e993cde128cdd847d',
        954: '88f2c71c68ba6f307eed356ab7ec1661',
        955: '90200e257f6724ce678c58b9a9fd8027',
        956: '9ba5b6973596a7470eeaedbbadd8f4e9',
        957: '9bda43502c3ca2f1e8335b7c94c2cf0f',
        958: 'a0d262ca80696a564393ef1ccb7c5250',
        959: 'a3010b9de92af8114badb05f665f038a',
        96: '69a05cb7c97e22fd585465ebb1d89d64',
        960: 'a8974c092390689ac8cf9bc53a697fa9',
        961: 'ab50212c752aa7165cb9b13a52809ecc',
        962: 'ad47a6b3ed573a540dc3c1f553e6673f',
        963: 'b15b75e1d72ca57099ebbad421c9ecfb',
        964: 'b160995f72ab18be198eb31bc4d76791',
        965: 'b2cdc413cb43d2f5f71f57088886a89f',
        966: 'be3dbbd9aa7a47da19d113efd7466c8f',
        967: 'c200b0dc26311e3459b3a6849d22c02c',
        968: 'c4ed4823565fb7e7eba3258ea5cdf406',
        969: 'ca03edf0a90036826bb8431e5d0be2b5',
        97: '6d1cfcc368a966e08597df5789e0d222',
        970: 'cb248b61302d3c5d39e757bc4ff5a92e',
        971: 'cf12eb12e5bee33c413ad97300a28b0c',
        972: 'd07aab5836184dad083784a613217b48',
        973: 'd19e365d26819a1004da20528e637fea',
        974: 'd2e8edb61a4af1571ca3dcf4d0c8850a',
        975: 'd578e98a821af253ef0f63306e0243ce',
        976: 'd823b0597bff218b1ba050c4ed61eacf',
        977: 'dd710c301a984e39dd842ca6deae0702',
        978: 'e0913b53a3033d62bd8830b24e4a452f',
        979: 'f0500a2469aa5a46fd376c0dd3303522',
        98: '6f73a56365d82a9d0139c4ddf0b8275d',
        980: 'f0fea8524814d3b1006edc5d917cbb86',
        981: 'f6b45d82aff6a5d0982b97553544fc82',
        982: 'f83dd39eeab7c8de9cd5961a95b00018',
        983: 'f9b5ca6f938adab4be863731b248d687',
        984: 'fcac99d798af44747db60d94e3ca24fb',
        985: 'fd85d619f5719557833efc95849d9a90',
        986: 'fdcbd63f9e5a47e2779ce0f776a0f3ad',
        987: '4457d237bf9c8bb102b1a2bf8fc632da',
        988: '071e2c478a40e03025d1bde1fe77d370',
        989: '3ec2a489983360030d7e5a3aaabcd2a6',
        99: '7e86aa3528c090d054733e9943f12255',
        990: '7f86ce989326bcd40156c13d0c1efdc1',
        991: '803d86c52779f244819b8c62f406a8b8',
        992: 'a3006673912960c8a75aada3c3815b25',
        993: '84dd1b67c065dcba1bca8b9fb01fdba8',
        994: '9302df9a947749dbbaaff1085a9632c8',
        995: '42d0a019dcf18e2ba02478febf36930c',
        996: '51a4892eac985fb8ca73730cab57b9b6',
        997: '86d2ab3ec168314467f92e50637d8112',
        998: '0685400bc8ca13c843820c9a7aff784b',
        999: '238e4de7de04ab85f2946212ba3f28a5'
    } as Record<string, string>,
    'Coterie ID': {
        0: 10109,
        1: 10461,
        10: 10024,
        100: 10039,
        1000: 10149,
        1001: 10149,
        1002: 10149,
        1003: 10149,
        1004: 10149,
        1005: 10149,
        1006: 10149,
        1007: 10149,
        1008: 10149,
        1009: 10149,
        101: 10039,
        1010: 10149,
        1011: 10149,
        1012: 10149,
        1013: 10149,
        1014: 10149,
        1015: 10149,
        1016: 10149,
        1017: 10149,
        1018: 10149,
        1019: 10149,
        102: 10039,
        1020: 10149,
        1021: 10149,
        1022: 10149,
        1023: 10149,
        1024: 10149,
        1025: 10149,
        1026: 10149,
        1027: 10149,
        1028: 10149,
        1029: 10149,
        103: 10039,
        1030: 10149,
        1031: 10515,
        1032: 10515,
        1033: 10108,
        1034: 10515,
        1035: 10234,
        1036: 10517,
        1037: 10515,
        1038: 10515,
        1039: 10639,
        104: 10024,
        1040: 10639,
        1041: 10515,
        1042: 10518,
        1043: 10518,
        1044: 10518,
        1045: 10518,
        1046: 10149,
        1047: 10149,
        1048: 10149,
        1049: 10149,
        105: 10024,
        1050: 10149,
        1051: 10149,
        1052: 10149,
        1053: 10149,
        1054: 10149,
        1055: 10149,
        1056: 10149,
        1057: 10149,
        1058: 10149,
        1059: 10149,
        106: 10041,
        1060: 10149,
        1061: 10149,
        1062: 10149,
        1063: 10149,
        1064: 10149,
        1065: 10149,
        1066: 10149,
        1067: 10149,
        1068: 10149,
        1069: 10149,
        107: 10214,
        1070: 10149,
        1071: 10149,
        1072: 10149,
        1073: 10149,
        1074: 10149,
        1075: 10034,
        1076: 10035,
        1077: 10252,
        1078: 10036,
        1079: 10048,
        108: 10039,
        1080: 10063,
        1081: 10066,
        1082: 10067,
        1083: 10069,
        1084: 10255,
        1085: 10204,
        1086: 10092,
        1087: 10320,
        1088: 10105,
        1089: 10113,
        109: 10039,
        1090: 10141,
        1091: 10317,
        1092: 10141,
        1093: 10659,
        1094: 10136,
        1095: 10132,
        1096: 10128,
        1097: 10129,
        1098: 10130,
        1099: 10135,
        11: 10041,
        110: 10039,
        1100: 10167,
        1101: 10169,
        1102: 10197,
        1103: 10313,
        1104: 10313,
        1105: 10313,
        1106: 10327,
        1107: 10085,
        1108: 10428,
        1109: 10224,
        111: 10039,
        1110: 10320,
        1111: 10239,
        1112: 10252,
        1113: 10253,
        1114: 10252,
        1115: 10253,
        1116: 10260,
        1117: 10313,
        1118: 10034,
        1119: 10284,
        112: 10024,
        1120: 10286,
        1121: 10287,
        1122: 10313,
        1123: 10290,
        1124: 10291,
        1125: 10034,
        1126: 10295,
        1127: 10303,
        1128: 10165,
        1129: 10320,
        113: 10024,
        1130: 10319,
        1131: 10326,
        1132: 10329,
        1133: 10332,
        1134: 10336,
        1135: 10396,
        1136: 10408,
        1137: 10409,
        1138: 10392,
        1139: 10420,
        114: 10039,
        1140: 10551,
        1141: 10167,
        1142: 10426,
        1143: 10550,
        1144: 10449,
        1145: 10280,
        1146: 10289,
        1147: 10288,
        1148: 10327,
        1149: 10328,
        115: 10039,
        1150: 10474,
        1151: 10475,
        1152: 10477,
        1153: 10301,
        1154: 10492,
        1155: 10499,
        1156: 10036,
        1157: 10661,
        1158: 10313,
        1159: 10661,
        116: 10214,
        1160: 10017,
        1161: 10518,
        1162: 10521,
        1163: 10521,
        1164: 10541,
        1165: 10253,
        1166: 10207,
        1167: 10318,
        1168: 10639,
        1169: 10638,
        117: 10039,
        1170: 10177,
        1171: 10036,
        1172: 10660,
        1173: 10727,
        1174: 10735,
        1175: 10149,
        1176: 10391,
        1177: 10160,
        1178: 10747,
        1179: 10271,
        118: 10039,
        1180: 10271,
        1181: 10006,
        1182: 10679,
        1183: 10010,
        1184: 10308,
        1185: 10561,
        1186: 10431,
        1187: 10560,
        1188: 10431,
        1189: 10431,
        119: 10039,
        1190: 10713,
        1191: 10183,
        1192: 10391,
        1193: 10293,
        1194: 10453,
        1195: 10377,
        1196: 10392,
        1197: 10430,
        1198: 10429,
        1199: 10586,
        12: 10024,
        120: 10039,
        1200: 10586,
        1201: 10580,
        1202: 10580,
        1203: 10699,
        1204: 10569,
        1205: 10569,
        1206: 10061,
        1207: 10061,
        1208: 10453,
        1209: 10482,
        121: 10041,
        1210: 10017,
        1211: 10017,
        1212: 10441,
        1213: 10714,
        1214: 10685,
        1215: 10061,
        1216: 10429,
        1217: 10718,
        1218: 10234,
        1219: 10017,
        122: 10039,
        1220: 10597,
        1221: 10320,
        1222: 10025,
        1223: 10449,
        1224: 10555,
        1225: 10555,
        1226: 10555,
        1227: 10430,
        1228: 10430,
        1229: 10430,
        123: 10220,
        1230: 10430,
        1231: 10430,
        1232: 10430,
        1233: 10061,
        1234: 10415,
        1235: 10508,
        1236: 10453,
        1237: 10453,
        1238: 10453,
        1239: 10122,
        124: 10220,
        1240: 10453,
        1241: 10458,
        1242: 10453,
        1243: 10453,
        1244: 10453,
        1245: 10453,
        1246: 10453,
        1247: 10017,
        1248: 10041,
        1249: 10112,
        125: 10220,
        1250: 10006,
        1251: 10041,
        1252: 10215,
        1253: 10238,
        1254: 10041,
        1255: 10045,
        1256: 10045,
        1257: 10235,
        1258: 10368,
        1259: 10251,
        126: 10220,
        1260: 10403,
        1261: 10127,
        1262: 10374,
        1263: 10095,
        1264: 10374,
        1265: 10041,
        1266: 10247,
        1267: 10587,
        1268: 10586,
        1269: 10586,
        127: 10157,
        1270: 10041,
        1271: 10041,
        1272: 10447,
        1273: 10734,
        1274: 10098,
        1275: 10734,
        1276: 10501,
        1277: 10039,
        1278: 10645,
        1279: 10403,
        128: 10406,
        1280: 10028,
        1281: 10734,
        1282: 10041,
        1283: 10740,
        1284: 10283,
        1285: 10696,
        1286: 10730,
        1287: 10555,
        1288: 10394,
        1289: 10271,
        129: 10220,
        1290: 10080,
        1291: 10676,
        13: 10537,
        130: 10220,
        131: 10268,
        132: 10268,
        133: 10157,
        134: 10110,
        135: 10268,
        136: 10220,
        137: 10220,
        138: 10220,
        139: 10220,
        14: 10039,
        140: 10406,
        141: 10406,
        142: 10220,
        143: 10110,
        144: 10110,
        145: 10157,
        146: 10110,
        147: 10110,
        148: 10220,
        149: 10406,
        15: 10024,
        150: 10039,
        151: 10588,
        152: 10588,
        153: 10406,
        154: 10039,
        155: 10406,
        156: 10597,
        157: 10103,
        158: 10320,
        159: 10320,
        16: 10537,
        160: 10320,
        161: 10320,
        162: 10320,
        163: 10320,
        164: 10320,
        165: 10320,
        166: 10320,
        167: 10320,
        168: 10320,
        169: 10320,
        17: 10024,
        170: 10320,
        171: 10320,
        172: 10320,
        173: 10320,
        174: 10320,
        175: 10320,
        176: 10320,
        177: 10281,
        178: 10320,
        179: 10320,
        18: 10039,
        180: 10320,
        181: 10320,
        182: 10281,
        183: 10320,
        184: 10320,
        185: 10320,
        186: 10320,
        187: 10320,
        188: 10320,
        189: 10320,
        19: 10024,
        190: 10320,
        191: 10320,
        192: 10320,
        193: 10320,
        194: 10320,
        195: 10320,
        196: 10320,
        197: 10320,
        198: 10320,
        199: 10320,
        2: 10117,
        20: 10039,
        200: 10320,
        201: 10320,
        202: 10320,
        203: 10320,
        204: 10320,
        205: 10320,
        206: 10320,
        207: 10320,
        208: 10320,
        209: 10320,
        21: 10024,
        210: 10320,
        211: 10320,
        212: 10320,
        213: 10320,
        214: 10320,
        215: 10320,
        216: 10320,
        217: 10320,
        218: 10320,
        219: 10320,
        22: 10537,
        220: 10320,
        221: 10320,
        222: 10320,
        223: 10320,
        224: 10320,
        225: 10320,
        226: 10320,
        227: 10320,
        228: 10320,
        229: 10320,
        23: 10039,
        230: 10320,
        231: 10320,
        232: 10320,
        233: 10320,
        234: 10320,
        235: 10320,
        236: 10320,
        237: 10320,
        238: 10320,
        239: 10320,
        24: 10024,
        240: 10320,
        241: 10320,
        242: 10320,
        243: 10320,
        244: 10320,
        245: 10320,
        246: 10320,
        247: 10320,
        248: 10320,
        249: 10320,
        25: 10537,
        250: 10320,
        251: 10320,
        252: 10320,
        253: 10320,
        254: 10320,
        255: 10320,
        256: 10320,
        257: 10320,
        258: 10320,
        259: 10320,
        26: 10039,
        260: 10320,
        261: 10320,
        262: 10320,
        263: 10320,
        264: 10320,
        265: 10320,
        266: 10320,
        267: 10320,
        268: 10320,
        269: 10320,
        27: 10024,
        270: 10320,
        271: 10392,
        272: 10490,
        273: 10068,
        274: 10068,
        275: 10490,
        276: 10253,
        277: 10490,
        278: 10490,
        279: 10068,
        28: 10024,
        280: 10068,
        281: 10490,
        282: 10320,
        283: 10253,
        284: 10392,
        285: 10392,
        286: 10392,
        287: 10068,
        288: 10253,
        289: 10320,
        29: 10537,
        290: 10490,
        291: 10320,
        292: 10320,
        293: 10320,
        294: 10320,
        295: 10320,
        296: 10253,
        297: 10541,
        298: 10490,
        299: 10253,
        3: 10157,
        30: 10024,
        300: 10392,
        301: 10320,
        302: 10320,
        303: 10253,
        304: 10392,
        305: 10253,
        306: 10320,
        307: 10709,
        308: 10709,
        309: 10541,
        31: 10537,
        310: 10709,
        311: 10709,
        312: 10709,
        313: 10709,
        314: 10709,
        315: 10709,
        316: 10709,
        317: 10709,
        318: 10709,
        319: 10709,
        32: 10024,
        320: 10709,
        321: 10709,
        322: 10709,
        323: 10737,
        324: 10234,
        325: 10709,
        326: 10035,
        327: 10320,
        328: 10320,
        329: 10709,
        33: 10024,
        330: 10320,
        331: 10709,
        332: 10320,
        333: 10320,
        334: 10320,
        335: 10709,
        336: 10320,
        337: 10320,
        338: 10320,
        339: 10320,
        34: 10537,
        340: 10320,
        341: 10709,
        342: 10320,
        343: 10035,
        344: 10035,
        345: 10320,
        346: 10320,
        347: 10709,
        348: 10320,
        349: 10035,
        35: 10585,
        350: 10034,
        351: 10035,
        352: 10035,
        353: 10320,
        354: 10035,
        355: 10709,
        356: 10035,
        357: 10709,
        358: 10320,
        359: 10320,
        36: 10024,
        360: 10035,
        361: 10221,
        362: 10068,
        363: 10221,
        364: 10313,
        365: 10068,
        366: 10313,
        367: 10313,
        368: 10221,
        369: 10068,
        37: 10024,
        370: 10068,
        371: 10313,
        372: 10221,
        373: 10068,
        374: 10221,
        375: 10313,
        376: 10068,
        377: 10313,
        378: 10221,
        379: 10313,
        38: 10024,
        380: 10313,
        381: 10068,
        382: 10221,
        383: 10068,
        384: 10313,
        385: 10313,
        386: 10221,
        387: 10313,
        388: 10313,
        389: 10221,
        39: 10537,
        390: 10068,
        391: 10313,
        392: 10302,
        393: 10302,
        394: 10302,
        395: 10302,
        396: 10302,
        397: 10302,
        398: 10302,
        399: 10302,
        4: 10643,
        40: 10537,
        400: 10302,
        401: 10302,
        402: 10302,
        403: 10302,
        404: 10302,
        405: 10302,
        406: 10302,
        407: 10302,
        408: 10320,
        409: 10320,
        41: 10039,
        410: 10320,
        411: 10068,
        412: 10068,
        413: 10034,
        414: 10313,
        415: 10259,
        416: 10259,
        417: 10068,
        418: 10259,
        419: 10313,
        42: 10537,
        420: 10143,
        421: 10313,
        422: 10068,
        423: 10259,
        424: 10333,
        425: 10143,
        426: 10333,
        427: 10259,
        428: 10709,
        429: 10392,
        43: 10413,
        430: 10259,
        431: 10333,
        432: 10320,
        433: 10034,
        434: 10320,
        435: 10063,
        436: 10392,
        437: 10333,
        438: 10313,
        439: 10259,
        44: 10039,
        440: 10034,
        441: 10333,
        442: 10333,
        443: 10034,
        444: 10259,
        445: 10143,
        446: 10259,
        447: 10259,
        448: 10333,
        449: 10333,
        45: 10537,
        450: 10333,
        451: 10259,
        452: 10259,
        453: 10333,
        454: 10259,
        455: 10302,
        456: 10333,
        457: 10313,
        458: 10259,
        459: 10034,
        46: 10537,
        460: 10064,
        461: 10064,
        462: 10064,
        463: 10064,
        464: 10064,
        465: 10064,
        466: 10064,
        467: 10064,
        468: 10336,
        469: 10336,
        47: 10039,
        470: 10336,
        471: 10336,
        472: 10336,
        473: 10336,
        474: 10336,
        475: 10336,
        476: 10336,
        477: 10336,
        478: 10336,
        479: 10336,
        48: 10024,
        480: 10336,
        481: 10336,
        482: 10336,
        483: 10336,
        484: 10336,
        485: 10336,
        486: 10659,
        487: 10659,
        488: 10659,
        489: 10659,
        49: 10024,
        490: 10139,
        491: 10139,
        492: 10659,
        493: 10659,
        494: 10073,
        495: 10659,
        496: 10659,
        497: 10659,
        498: 10659,
        499: 10139,
        5: 10117,
        50: 10537,
        500: 10139,
        501: 10659,
        502: 10659,
        503: 10671,
        504: 10029,
        505: 10601,
        506: 10073,
        507: 10029,
        508: 10093,
        509: 10601,
        51: 10024,
        510: 10097,
        511: 10471,
        512: 10601,
        513: 10097,
        514: 10471,
        515: 10601,
        516: 10029,
        517: 10029,
        518: 10659,
        519: 10601,
        52: 10537,
        520: 10723,
        521: 10334,
        522: 10144,
        523: 10260,
        524: 10591,
        525: 10674,
        526: 10591,
        527: 10303,
        528: 10393,
        529: 10278,
        53: 10024,
        530: 10065,
        531: 10334,
        532: 10239,
        533: 10144,
        534: 10332,
        535: 10287,
        536: 10092,
        537: 10552,
        538: 10115,
        539: 10597,
        54: 10024,
        540: 10597,
        541: 10597,
        542: 10597,
        543: 10597,
        544: 10597,
        545: 10597,
        546: 10597,
        547: 10597,
        548: 10597,
        549: 10597,
        55: 10024,
        550: 10597,
        551: 10127,
        552: 10059,
        553: 10333,
        554: 10667,
        555: 10740,
        556: 10709,
        557: 10247,
        558: 10050,
        559: 10740,
        56: 10039,
        560: 10740,
        561: 10199,
        562: 10740,
        563: 10734,
        564: 10458,
        565: 10027,
        566: 10709,
        567: 10270,
        568: 10458,
        569: 10740,
        57: 10024,
        570: 10740,
        571: 10171,
        572: 10739,
        573: 10059,
        574: 10308,
        575: 10247,
        576: 10333,
        577: 10709,
        578: 10653,
        579: 10127,
        58: 10024,
        580: 10740,
        581: 10709,
        582: 10453,
        583: 10270,
        584: 10247,
        585: 10247,
        586: 10740,
        587: 10684,
        588: 10740,
        589: 10059,
        59: 10024,
        590: 10027,
        591: 10061,
        592: 10667,
        593: 10270,
        594: 10740,
        595: 10308,
        596: 10735,
        597: 10059,
        598: 10740,
        599: 10453,
        6: 10117,
        60: 10537,
        600: 10709,
        601: 10709,
        602: 10468,
        603: 10709,
        604: 10247,
        605: 10171,
        606: 10667,
        607: 10333,
        608: 10709,
        609: 10667,
        61: 10024,
        610: 10586,
        611: 10586,
        612: 10586,
        613: 10586,
        614: 10586,
        615: 10586,
        616: 10586,
        617: 10586,
        618: 10586,
        619: 10586,
        62: 10024,
        620: 10586,
        621: 10586,
        622: 10586,
        623: 10586,
        624: 10586,
        625: 10586,
        626: 10586,
        627: 10586,
        628: 10586,
        629: 10586,
        63: 10039,
        630: 10586,
        631: 10586,
        632: 10586,
        633: 10586,
        634: 10586,
        635: 10586,
        636: 10586,
        637: 10586,
        638: 10586,
        639: 10586,
        64: 10039,
        640: 10586,
        641: 10270,
        642: 10739,
        643: 10247,
        644: 10740,
        645: 10740,
        646: 10247,
        647: 10667,
        648: 10445,
        649: 10740,
        65: 10039,
        650: 10523,
        651: 10709,
        652: 10333,
        653: 10059,
        654: 10523,
        655: 10709,
        656: 10584,
        657: 10247,
        658: 10270,
        659: 10468,
        66: 10024,
        660: 10740,
        661: 10171,
        662: 10740,
        663: 10270,
        664: 10308,
        665: 10050,
        666: 10453,
        667: 10734,
        668: 10247,
        669: 10059,
        67: 10039,
        670: 10740,
        671: 10571,
        672: 10667,
        673: 10308,
        674: 10405,
        675: 10405,
        676: 10405,
        677: 10405,
        678: 10405,
        679: 10405,
        68: 10024,
        680: 10405,
        681: 10405,
        682: 10405,
        683: 10405,
        684: 10405,
        685: 10405,
        686: 10405,
        687: 10406,
        688: 10405,
        689: 10405,
        69: 10024,
        690: 10405,
        691: 10405,
        692: 10405,
        693: 10405,
        694: 10405,
        695: 10405,
        696: 10405,
        697: 10405,
        698: 10405,
        699: 10405,
        7: 10283,
        70: 10039,
        700: 10405,
        701: 10405,
        702: 10405,
        703: 10405,
        704: 10405,
        705: 10405,
        706: 10405,
        707: 10405,
        708: 10405,
        709: 10405,
        71: 10024,
        710: 10587,
        711: 10587,
        712: 10587,
        713: 10587,
        714: 10587,
        715: 10587,
        716: 10587,
        717: 10587,
        718: 10587,
        719: 10587,
        72: 10039,
        720: 10587,
        721: 10587,
        722: 10587,
        723: 10587,
        724: 10587,
        725: 10587,
        726: 10740,
        727: 10740,
        728: 10667,
        729: 10057,
        73: 10537,
        730: 10057,
        731: 10057,
        732: 10057,
        733: 10057,
        734: 10057,
        735: 10057,
        736: 10057,
        737: 10057,
        738: 10057,
        739: 10057,
        74: 10039,
        740: 10057,
        741: 10057,
        742: 10057,
        743: 10057,
        744: 10057,
        745: 10057,
        746: 10057,
        747: 10057,
        748: 10057,
        749: 10057,
        75: 10024,
        750: 10057,
        751: 10057,
        752: 10057,
        753: 10057,
        754: 10057,
        755: 10610,
        756: 10610,
        757: 10610,
        758: 10610,
        759: 10517,
        76: 10537,
        760: 10740,
        761: 10059,
        762: 10270,
        763: 10684,
        764: 10199,
        765: 10734,
        766: 10667,
        767: 10247,
        768: 10247,
        769: 10247,
        77: 10039,
        770: 10247,
        771: 10171,
        772: 10247,
        773: 10709,
        774: 10571,
        775: 10247,
        776: 10247,
        777: 10171,
        778: 10308,
        779: 10709,
        78: 10024,
        780: 10740,
        781: 10740,
        782: 10374,
        783: 10247,
        784: 10270,
        785: 10709,
        786: 10333,
        787: 10270,
        788: 10270,
        789: 10740,
        79: 10039,
        790: 10308,
        791: 10171,
        792: 10554,
        793: 10374,
        794: 10709,
        795: 10171,
        796: 10473,
        797: 10308,
        798: 10247,
        799: 10199,
        8: 10117,
        80: 10024,
        800: 10308,
        801: 10453,
        802: 10517,
        803: 10517,
        804: 10517,
        805: 10517,
        806: 10517,
        807: 10517,
        808: 10517,
        809: 10517,
        81: 10024,
        810: 10517,
        811: 10517,
        812: 10517,
        813: 10517,
        814: 10517,
        815: 10517,
        816: 10517,
        817: 10517,
        818: 10517,
        819: 10517,
        82: 10024,
        820: 10517,
        821: 10517,
        822: 10517,
        823: 10517,
        824: 10517,
        825: 10517,
        826: 10709,
        827: 10038,
        828: 10038,
        829: 10038,
        83: 10024,
        830: 10038,
        831: 10038,
        832: 10038,
        833: 10038,
        834: 10584,
        835: 10038,
        836: 10038,
        837: 10038,
        838: 10038,
        839: 10038,
        84: 10039,
        840: 10038,
        841: 10038,
        842: 10038,
        843: 10038,
        844: 10038,
        845: 10584,
        846: 10038,
        847: 10038,
        848: 10038,
        849: 10038,
        85: 10024,
        850: 10038,
        851: 10038,
        852: 10041,
        853: 10041,
        854: 10041,
        855: 10041,
        856: 10041,
        857: 10041,
        858: 10041,
        859: 10041,
        86: 10024,
        860: 10675,
        861: 10041,
        862: 10675,
        863: 10041,
        864: 10675,
        865: 10041,
        866: 10397,
        867: 10319,
        868: 10041,
        869: 10041,
        87: 10024,
        870: 10675,
        871: 10041,
        872: 10397,
        873: 10041,
        874: 10041,
        875: 10074,
        876: 10247,
        877: 10071,
        878: 10255,
        879: 10255,
        88: 10024,
        880: 10070,
        881: 10255,
        882: 10071,
        883: 10071,
        884: 10255,
        885: 10448,
        886: 10071,
        887: 10071,
        888: 10071,
        889: 10255,
        89: 10039,
        890: 10255,
        891: 10071,
        892: 10071,
        893: 10071,
        894: 10071,
        895: 10740,
        896: 10667,
        897: 10517,
        898: 10571,
        899: 10247,
        9: 10039,
        90: 10039,
        900: 10518,
        901: 10515,
        902: 10518,
        903: 10518,
        904: 10639,
        905: 10468,
        906: 10109,
        907: 10747,
        908: 10747,
        909: 10747,
        91: 10214,
        910: 10747,
        911: 10747,
        912: 10747,
        913: 10747,
        914: 10747,
        915: 10747,
        916: 10747,
        917: 10747,
        918: 10747,
        919: 10747,
        92: 10039,
        920: 10747,
        921: 10747,
        922: 10747,
        923: 10747,
        924: 10747,
        925: 10747,
        926: 10747,
        927: 10747,
        928: 10747,
        929: 10747,
        93: 10039,
        930: 10747,
        931: 10747,
        932: 10747,
        933: 10747,
        934: 10747,
        935: 10747,
        936: 10747,
        937: 10747,
        938: 10747,
        939: 10747,
        94: 10537,
        940: 10747,
        941: 10747,
        942: 10747,
        943: 10747,
        944: 10747,
        945: 10747,
        946: 10747,
        947: 10747,
        948: 10747,
        949: 10747,
        95: 10039,
        950: 10747,
        951: 10747,
        952: 10747,
        953: 10747,
        954: 10747,
        955: 10747,
        956: 10747,
        957: 10747,
        958: 10747,
        959: 10747,
        96: 10039,
        960: 10747,
        961: 10747,
        962: 10747,
        963: 10747,
        964: 10747,
        965: 10747,
        966: 10747,
        967: 10747,
        968: 10747,
        969: 10747,
        97: 10039,
        970: 10747,
        971: 10747,
        972: 10747,
        973: 10747,
        974: 10747,
        975: 10747,
        976: 10747,
        977: 10747,
        978: 10747,
        979: 10747,
        98: 10039,
        980: 10747,
        981: 10747,
        982: 10747,
        983: 10747,
        984: 10747,
        985: 10747,
        986: 10747,
        987: 10517,
        988: 10676,
        989: 10103,
        99: 10039,
        990: 10676,
        991: 10103,
        992: 10676,
        993: 10154,
        994: 10676,
        995: 10676,
        996: 10676,
        997: 10103,
        998: 10149,
        999: 10149
    } as Record<string, number>,
    PL: {
        0: 'no',
        1: 'no',
        10: 'no',
        100: 'no',
        1000: 'no',
        1001: 'no',
        1002: 'no',
        1003: 'no',
        1004: 'no',
        1005: 'no',
        1006: 'no',
        1007: 'no',
        1008: 'no',
        1009: 'no',
        101: 'no',
        1010: 'no',
        1011: 'no',
        1012: 'no',
        1013: 'no',
        1014: 'no',
        1015: 'no',
        1016: 'no',
        1017: 'no',
        1018: 'no',
        1019: 'no',
        102: 'no',
        1020: 'no',
        1021: 'no',
        1022: 'no',
        1023: 'no',
        1024: 'no',
        1025: 'no',
        1026: 'no',
        1027: 'no',
        1028: 'no',
        1029: 'no',
        103: 'no',
        1030: 'no',
        1031: 'no',
        1032: 'no',
        1033: 'yes',
        1034: 'yes',
        1035: 'yes',
        1036: 'yes',
        1037: 'yes',
        1038: 'yes',
        1039: 'no',
        104: 'no',
        1040: 'no',
        1041: 'no',
        1042: 'no',
        1043: 'no',
        1044: 'no',
        1045: 'no',
        1046: 'no',
        1047: 'no',
        1048: 'no',
        1049: 'no',
        105: 'no',
        1050: 'no',
        1051: 'no',
        1052: 'no',
        1053: 'no',
        1054: 'no',
        1055: 'no',
        1056: 'no',
        1057: 'no',
        1058: 'no',
        1059: 'no',
        106: 'no',
        1060: 'no',
        1061: 'no',
        1062: 'no',
        1063: 'no',
        1064: 'no',
        1065: 'no',
        1066: 'no',
        1067: 'no',
        1068: 'no',
        1069: 'no',
        107: 'no',
        1070: 'no',
        1071: 'no',
        1072: 'no',
        1073: 'no',
        1074: 'no',
        1075: 'no',
        1076: 'no',
        1077: 'no',
        1078: 'no',
        1079: 'no',
        108: 'no',
        1080: 'no',
        1081: 'no',
        1082: 'no',
        1083: 'yes',
        1084: 'yes',
        1085: 'yes',
        1086: 'no',
        1087: 'no',
        1088: 'no',
        1089: 'no',
        109: 'no',
        1090: 'no',
        1091: 'no',
        1092: 'no',
        1093: 'no',
        1094: 'no',
        1095: 'no',
        1096: 'no',
        1097: 'no',
        1098: 'no',
        1099: 'no',
        11: 'no',
        110: 'no',
        1100: 'no',
        1101: 'no',
        1102: 'no',
        1103: 'no',
        1104: 'no',
        1105: 'no',
        1106: 'no',
        1107: 'no',
        1108: 'no',
        1109: 'no',
        111: 'no',
        1110: 'no',
        1111: 'no',
        1112: 'no',
        1113: 'no',
        1114: 'no',
        1115: 'no',
        1116: 'no',
        1117: 'no',
        1118: 'no',
        1119: 'no',
        112: 'no',
        1120: 'no',
        1121: 'no',
        1122: 'no',
        1123: 'no',
        1124: 'no',
        1125: 'no',
        1126: 'no',
        1127: 'no',
        1128: 'no',
        1129: 'no',
        113: 'no',
        1130: 'no',
        1131: 'no',
        1132: 'no',
        1133: 'no',
        1134: 'no',
        1135: 'no',
        1136: 'no',
        1137: 'no',
        1138: 'no',
        1139: 'no',
        114: 'no',
        1140: 'no',
        1141: 'no',
        1142: 'no',
        1143: 'no',
        1144: 'no',
        1145: 'no',
        1146: 'no',
        1147: 'no',
        1148: 'no',
        1149: 'no',
        115: 'no',
        1150: 'no',
        1151: 'yes',
        1152: 'no',
        1153: 'no',
        1154: 'no',
        1155: 'no',
        1156: 'no',
        1157: 'no',
        1158: 'no',
        1159: 'no',
        116: 'no',
        1160: 'yes',
        1161: 'no',
        1162: 'yes',
        1163: 'yes',
        1164: 'no',
        1165: 'no',
        1166: 'no',
        1167: 'no',
        1168: 'no',
        1169: 'no',
        117: 'no',
        1170: 'no',
        1171: 'no',
        1172: 'no',
        1173: 'no',
        1174: 'no',
        1175: 'no',
        1176: 'yes',
        1177: 'yes',
        1178: 'no',
        1179: 'no',
        118: 'no',
        1180: 'no',
        1181: 'yes',
        1182: 'yes',
        1183: 'yes',
        1184: 'yes',
        1185: 'yes',
        1186: 'yes',
        1187: 'yes',
        1188: 'no',
        1189: 'yes',
        119: 'no',
        1190: 'yes',
        1191: 'yes',
        1192: 'no',
        1193: 'no',
        1194: 'no',
        1195: 'yes',
        1196: 'no',
        1197: 'no',
        1198: 'yes',
        1199: 'no',
        12: 'no',
        120: 'no',
        1200: 'no',
        1201: 'yes',
        1202: 'no',
        1203: 'no',
        1204: 'yes',
        1205: 'yes',
        1206: 'no',
        1207: 'no',
        1208: 'yes',
        1209: 'yes',
        121: 'no',
        1210: 'yes',
        1211: 'yes',
        1212: 'yes',
        1213: 'yes',
        1214: 'yes',
        1215: 'yes',
        1216: 'yes',
        1217: 'yes',
        1218: 'no',
        1219: 'yes',
        122: 'no',
        1220: 'no',
        1221: 'no',
        1222: 'yes',
        1223: 'no',
        1224: 'no',
        1225: 'no',
        1226: 'no',
        1227: 'no',
        1228: 'no',
        1229: 'no',
        123: 'no',
        1230: 'no',
        1231: 'no',
        1232: 'no',
        1233: 'no',
        1234: 'no',
        1235: 'yes',
        1236: 'no',
        1237: 'no',
        1238: 'no',
        1239: 'no',
        124: 'no',
        1240: 'no',
        1241: 'yes',
        1242: 'no',
        1243: 'no',
        1244: 'no',
        1245: 'no',
        1246: 'no',
        1247: 'yes',
        1248: 'no',
        1249: 'no',
        125: 'no',
        1250: 'no',
        1251: 'no',
        1252: 'yes',
        1253: 'no',
        1254: 'no',
        1255: 'yes',
        1256: 'yes',
        1257: 'yes',
        1258: 'yes',
        1259: 'yes',
        126: 'no',
        1260: 'no',
        1261: 'yes',
        1262: 'yes',
        1263: 'yes',
        1264: 'yes',
        1265: 'no',
        1266: 'yes',
        1267: 'no',
        1268: 'no',
        1269: 'no',
        127: 'no',
        1270: 'no',
        1271: 'no',
        1272: 'no',
        1273: 'no',
        1274: 'no',
        1275: 'no',
        1276: 'no',
        1277: 'no',
        1278: 'yes',
        1279: 'yes',
        128: 'no',
        1280: 'yes',
        1281: 'no',
        1282: 'no',
        1283: 'no',
        1284: 'no',
        1285: 'no',
        1286: 'no',
        1287: 'no',
        1288: 'no',
        1289: 'yes',
        129: 'no',
        1290: 'no',
        1291: 'no',
        13: 'no',
        130: 'no',
        131: 'no',
        132: 'no',
        133: 'no',
        134: 'no',
        135: 'no',
        136: 'no',
        137: 'no',
        138: 'no',
        139: 'no',
        14: 'no',
        140: 'no',
        141: 'no',
        142: 'no',
        143: 'no',
        144: 'no',
        145: 'no',
        146: 'no',
        147: 'no',
        148: 'no',
        149: 'no',
        15: 'no',
        150: 'no',
        151: 'no',
        152: 'no',
        153: 'no',
        154: 'no',
        155: 'no',
        156: 'yes',
        157: 'no',
        158: 'no',
        159: 'no',
        16: 'no',
        160: 'no',
        161: 'no',
        162: 'no',
        163: 'no',
        164: 'no',
        165: 'no',
        166: 'no',
        167: 'no',
        168: 'no',
        169: 'no',
        17: 'no',
        170: 'no',
        171: 'no',
        172: 'no',
        173: 'no',
        174: 'no',
        175: 'no',
        176: 'no',
        177: 'no',
        178: 'no',
        179: 'no',
        18: 'no',
        180: 'no',
        181: 'no',
        182: 'no',
        183: 'no',
        184: 'no',
        185: 'no',
        186: 'no',
        187: 'no',
        188: 'no',
        189: 'no',
        19: 'no',
        190: 'no',
        191: 'no',
        192: 'no',
        193: 'no',
        194: 'no',
        195: 'no',
        196: 'no',
        197: 'no',
        198: 'no',
        199: 'no',
        2: 'no',
        20: 'no',
        200: 'no',
        201: 'no',
        202: 'no',
        203: 'no',
        204: 'no',
        205: 'no',
        206: 'no',
        207: 'no',
        208: 'no',
        209: 'no',
        21: 'no',
        210: 'no',
        211: 'no',
        212: 'no',
        213: 'no',
        214: 'no',
        215: 'no',
        216: 'no',
        217: 'no',
        218: 'no',
        219: 'no',
        22: 'no',
        220: 'no',
        221: 'no',
        222: 'no',
        223: 'no',
        224: 'no',
        225: 'no',
        226: 'no',
        227: 'no',
        228: 'no',
        229: 'no',
        23: 'no',
        230: 'no',
        231: 'no',
        232: 'no',
        233: 'no',
        234: 'no',
        235: 'no',
        236: 'no',
        237: 'no',
        238: 'no',
        239: 'no',
        24: 'no',
        240: 'no',
        241: 'no',
        242: 'no',
        243: 'no',
        244: 'no',
        245: 'no',
        246: 'no',
        247: 'no',
        248: 'no',
        249: 'no',
        25: 'no',
        250: 'no',
        251: 'no',
        252: 'no',
        253: 'no',
        254: 'no',
        255: 'no',
        256: 'no',
        257: 'no',
        258: 'no',
        259: 'no',
        26: 'no',
        260: 'no',
        261: 'no',
        262: 'no',
        263: 'no',
        264: 'no',
        265: 'no',
        266: 'no',
        267: 'no',
        268: 'no',
        269: 'no',
        27: 'no',
        270: 'no',
        271: 'no',
        272: 'no',
        273: 'no',
        274: 'no',
        275: 'no',
        276: 'no',
        277: 'no',
        278: 'no',
        279: 'no',
        28: 'no',
        280: 'no',
        281: 'no',
        282: 'no',
        283: 'no',
        284: 'no',
        285: 'no',
        286: 'no',
        287: 'no',
        288: 'no',
        289: 'no',
        29: 'no',
        290: 'no',
        291: 'no',
        292: 'no',
        293: 'no',
        294: 'no',
        295: 'no',
        296: 'no',
        297: 'no',
        298: 'no',
        299: 'no',
        3: 'no',
        30: 'no',
        300: 'no',
        301: 'no',
        302: 'no',
        303: 'no',
        304: 'no',
        305: 'no',
        306: 'no',
        307: 'no',
        308: 'no',
        309: 'no',
        31: 'no',
        310: 'no',
        311: 'no',
        312: 'no',
        313: 'no',
        314: 'no',
        315: 'no',
        316: 'no',
        317: 'no',
        318: 'no',
        319: 'no',
        32: 'no',
        320: 'no',
        321: 'no',
        322: 'no',
        323: 'no',
        324: 'no',
        325: 'no',
        326: 'no',
        327: 'no',
        328: 'no',
        329: 'no',
        33: 'no',
        330: 'no',
        331: 'no',
        332: 'no',
        333: 'no',
        334: 'no',
        335: 'no',
        336: 'no',
        337: 'no',
        338: 'no',
        339: 'no',
        34: 'no',
        340: 'no',
        341: 'no',
        342: 'no',
        343: 'no',
        344: 'no',
        345: 'no',
        346: 'no',
        347: 'no',
        348: 'no',
        349: 'no',
        35: 'no',
        350: 'no',
        351: 'no',
        352: 'no',
        353: 'no',
        354: 'no',
        355: 'no',
        356: 'no',
        357: 'no',
        358: 'no',
        359: 'no',
        36: 'no',
        360: 'no',
        361: 'no',
        362: 'no',
        363: 'no',
        364: 'no',
        365: 'no',
        366: 'no',
        367: 'no',
        368: 'no',
        369: 'no',
        37: 'no',
        370: 'no',
        371: 'no',
        372: 'no',
        373: 'no',
        374: 'no',
        375: 'no',
        376: 'no',
        377: 'no',
        378: 'no',
        379: 'no',
        38: 'no',
        380: 'no',
        381: 'no',
        382: 'no',
        383: 'no',
        384: 'no',
        385: 'no',
        386: 'no',
        387: 'no',
        388: 'no',
        389: 'no',
        39: 'no',
        390: 'no',
        391: 'no',
        392: 'no',
        393: 'no',
        394: 'no',
        395: 'no',
        396: 'no',
        397: 'no',
        398: 'no',
        399: 'no',
        4: 'no',
        40: 'no',
        400: 'no',
        401: 'no',
        402: 'no',
        403: 'no',
        404: 'no',
        405: 'no',
        406: 'no',
        407: 'no',
        408: 'no',
        409: 'no',
        41: 'no',
        410: 'no',
        411: 'no',
        412: 'no',
        413: 'no',
        414: 'no',
        415: 'no',
        416: 'no',
        417: 'no',
        418: 'no',
        419: 'no',
        42: 'no',
        420: 'no',
        421: 'no',
        422: 'no',
        423: 'no',
        424: 'no',
        425: 'no',
        426: 'no',
        427: 'no',
        428: 'no',
        429: 'no',
        43: 'no',
        430: 'no',
        431: 'no',
        432: 'no',
        433: 'no',
        434: 'no',
        435: 'no',
        436: 'no',
        437: 'no',
        438: 'no',
        439: 'no',
        44: 'no',
        440: 'no',
        441: 'no',
        442: 'no',
        443: 'no',
        444: 'no',
        445: 'no',
        446: 'no',
        447: 'no',
        448: 'no',
        449: 'no',
        45: 'no',
        450: 'no',
        451: 'no',
        452: 'no',
        453: 'no',
        454: 'no',
        455: 'no',
        456: 'no',
        457: 'no',
        458: 'no',
        459: 'no',
        46: 'no',
        460: 'no',
        461: 'no',
        462: 'no',
        463: 'no',
        464: 'no',
        465: 'no',
        466: 'no',
        467: 'no',
        468: 'no',
        469: 'no',
        47: 'no',
        470: 'no',
        471: 'no',
        472: 'no',
        473: 'no',
        474: 'no',
        475: 'no',
        476: 'no',
        477: 'no',
        478: 'no',
        479: 'no',
        48: 'no',
        480: 'no',
        481: 'no',
        482: 'no',
        483: 'no',
        484: 'no',
        485: 'no',
        486: 'no',
        487: 'no',
        488: 'no',
        489: 'no',
        49: 'no',
        490: 'no',
        491: 'no',
        492: 'no',
        493: 'no',
        494: 'no',
        495: 'no',
        496: 'no',
        497: 'no',
        498: 'no',
        499: 'no',
        5: 'no',
        50: 'no',
        500: 'no',
        501: 'no',
        502: 'no',
        503: 'no',
        504: 'no',
        505: 'no',
        506: 'no',
        507: 'no',
        508: 'no',
        509: 'no',
        51: 'no',
        510: 'no',
        511: 'no',
        512: 'no',
        513: 'no',
        514: 'no',
        515: 'no',
        516: 'no',
        517: 'no',
        518: 'no',
        519: 'no',
        52: 'no',
        520: 'no',
        521: 'no',
        522: 'no',
        523: 'no',
        524: 'no',
        525: 'no',
        526: 'no',
        527: 'no',
        528: 'no',
        529: 'no',
        53: 'no',
        530: 'no',
        531: 'no',
        532: 'no',
        533: 'no',
        534: 'no',
        535: 'no',
        536: 'no',
        537: 'no',
        538: 'no',
        539: 'yes',
        54: 'no',
        540: 'yes',
        541: 'yes',
        542: 'yes',
        543: 'yes',
        544: 'yes',
        545: 'yes',
        546: 'yes',
        547: 'yes',
        548: 'yes',
        549: 'yes',
        55: 'no',
        550: 'yes',
        551: 'yes',
        552: 'no',
        553: 'no',
        554: 'no',
        555: 'no',
        556: 'no',
        557: 'yes',
        558: 'no',
        559: 'no',
        56: 'no',
        560: 'no',
        561: 'no',
        562: 'no',
        563: 'no',
        564: 'yes',
        565: 'no',
        566: 'no',
        567: 'no',
        568: 'yes',
        569: 'no',
        57: 'no',
        570: 'no',
        571: 'no',
        572: 'no',
        573: 'no',
        574: 'no',
        575: 'yes',
        576: 'no',
        577: 'no',
        578: 'no',
        579: 'yes',
        58: 'no',
        580: 'no',
        581: 'no',
        582: 'no',
        583: 'no',
        584: 'no',
        585: 'no',
        586: 'no',
        587: 'no',
        588: 'no',
        589: 'no',
        59: 'no',
        590: 'no',
        591: 'no',
        592: 'yes',
        593: 'no',
        594: 'no',
        595: 'no',
        596: 'no',
        597: 'no',
        598: 'no',
        599: 'no',
        6: 'no',
        60: 'no',
        600: 'no',
        601: 'no',
        602: 'no',
        603: 'no',
        604: 'yes',
        605: 'no',
        606: 'no',
        607: 'no',
        608: 'no',
        609: 'no',
        61: 'no',
        610: 'no',
        611: 'no',
        612: 'no',
        613: 'no',
        614: 'no',
        615: 'no',
        616: 'no',
        617: 'no',
        618: 'no',
        619: 'no',
        62: 'no',
        620: 'no',
        621: 'no',
        622: 'no',
        623: 'no',
        624: 'no',
        625: 'no',
        626: 'no',
        627: 'no',
        628: 'no',
        629: 'no',
        63: 'no',
        630: 'no',
        631: 'no',
        632: 'no',
        633: 'no',
        634: 'no',
        635: 'no',
        636: 'no',
        637: 'no',
        638: 'no',
        639: 'no',
        64: 'no',
        640: 'no',
        641: 'no',
        642: 'no',
        643: 'no',
        644: 'no',
        645: 'no',
        646: 'no',
        647: 'no',
        648: 'yes',
        649: 'no',
        65: 'no',
        650: 'no',
        651: 'no',
        652: 'no',
        653: 'no',
        654: 'no',
        655: 'no',
        656: 'no',
        657: 'no',
        658: 'no',
        659: 'no',
        66: 'no',
        660: 'no',
        661: 'no',
        662: 'no',
        663: 'no',
        664: 'no',
        665: 'no',
        666: 'no',
        667: 'no',
        668: 'no',
        669: 'no',
        67: 'no',
        670: 'no',
        671: 'yes',
        672: 'no',
        673: 'no',
        674: 'no',
        675: 'no',
        676: 'no',
        677: 'no',
        678: 'no',
        679: 'no',
        68: 'no',
        680: 'no',
        681: 'no',
        682: 'no',
        683: 'no',
        684: 'no',
        685: 'no',
        686: 'no',
        687: 'no',
        688: 'no',
        689: 'no',
        69: 'no',
        690: 'no',
        691: 'no',
        692: 'no',
        693: 'no',
        694: 'no',
        695: 'no',
        696: 'no',
        697: 'no',
        698: 'no',
        699: 'no',
        7: 'no',
        70: 'no',
        700: 'no',
        701: 'no',
        702: 'no',
        703: 'no',
        704: 'no',
        705: 'no',
        706: 'no',
        707: 'no',
        708: 'no',
        709: 'no',
        71: 'no',
        710: 'no',
        711: 'no',
        712: 'no',
        713: 'no',
        714: 'no',
        715: 'no',
        716: 'no',
        717: 'no',
        718: 'no',
        719: 'no',
        72: 'no',
        720: 'no',
        721: 'no',
        722: 'no',
        723: 'no',
        724: 'no',
        725: 'no',
        726: 'no',
        727: 'no',
        728: 'no',
        729: 'yes',
        73: 'no',
        730: 'no',
        731: 'yes',
        732: 'yes',
        733: 'no',
        734: 'no',
        735: 'no',
        736: 'no',
        737: 'no',
        738: 'no',
        739: 'no',
        74: 'no',
        740: 'no',
        741: 'no',
        742: 'no',
        743: 'no',
        744: 'no',
        745: 'no',
        746: 'no',
        747: 'no',
        748: 'no',
        749: 'no',
        75: 'no',
        750: 'no',
        751: 'no',
        752: 'no',
        753: 'no',
        754: 'no',
        755: 'no',
        756: 'no',
        757: 'no',
        758: 'no',
        759: 'yes',
        76: 'no',
        760: 'no',
        761: 'no',
        762: 'no',
        763: 'no',
        764: 'no',
        765: 'no',
        766: 'no',
        767: 'no',
        768: 'yes',
        769: 'no',
        77: 'no',
        770: 'yes',
        771: 'no',
        772: 'yes',
        773: 'no',
        774: 'yes',
        775: 'yes',
        776: 'yes',
        777: 'no',
        778: 'no',
        779: 'no',
        78: 'no',
        780: 'no',
        781: 'no',
        782: 'yes',
        783: 'yes',
        784: 'no',
        785: 'no',
        786: 'no',
        787: 'no',
        788: 'no',
        789: 'no',
        79: 'no',
        790: 'no',
        791: 'no',
        792: 'no',
        793: 'no',
        794: 'no',
        795: 'no',
        796: 'no',
        797: 'no',
        798: 'yes',
        799: 'no',
        8: 'no',
        80: 'no',
        800: 'no',
        801: 'no',
        802: 'yes',
        803: 'yes',
        804: 'yes',
        805: 'yes',
        806: 'yes',
        807: 'yes',
        808: 'yes',
        809: 'yes',
        81: 'no',
        810: 'yes',
        811: 'yes',
        812: 'yes',
        813: 'yes',
        814: 'yes',
        815: 'yes',
        816: 'yes',
        817: 'yes',
        818: 'yes',
        819: 'yes',
        82: 'no',
        820: 'yes',
        821: 'yes',
        822: 'yes',
        823: 'yes',
        824: 'yes',
        825: 'yes',
        826: 'no',
        827: 'no',
        828: 'no',
        829: 'no',
        83: 'no',
        830: 'no',
        831: 'no',
        832: 'no',
        833: 'no',
        834: 'no',
        835: 'no',
        836: 'no',
        837: 'no',
        838: 'no',
        839: 'no',
        84: 'no',
        840: 'no',
        841: 'no',
        842: 'no',
        843: 'no',
        844: 'no',
        845: 'no',
        846: 'no',
        847: 'no',
        848: 'no',
        849: 'no',
        85: 'no',
        850: 'no',
        851: 'no',
        852: 'no',
        853: 'no',
        854: 'no',
        855: 'no',
        856: 'no',
        857: 'no',
        858: 'no',
        859: 'no',
        86: 'no',
        860: 'no',
        861: 'no',
        862: 'no',
        863: 'no',
        864: 'no',
        865: 'no',
        866: 'no',
        867: 'no',
        868: 'no',
        869: 'no',
        87: 'no',
        870: 'no',
        871: 'no',
        872: 'no',
        873: 'no',
        874: 'no',
        875: 'no',
        876: 'yes',
        877: 'yes',
        878: 'no',
        879: 'no',
        88: 'no',
        880: 'no',
        881: 'yes',
        882: 'no',
        883: 'no',
        884: 'no',
        885: 'yes',
        886: 'no',
        887: 'yes',
        888: 'no',
        889: 'no',
        89: 'no',
        890: 'no',
        891: 'yes',
        892: 'no',
        893: 'yes',
        894: 'yes',
        895: 'no',
        896: 'no',
        897: 'yes',
        898: 'yes',
        899: 'no',
        9: 'no',
        90: 'no',
        900: 'no',
        901: 'no',
        902: 'no',
        903: 'no',
        904: 'no',
        905: 'no',
        906: 'no',
        907: 'no',
        908: 'no',
        909: 'no',
        91: 'no',
        910: 'no',
        911: 'no',
        912: 'no',
        913: 'no',
        914: 'no',
        915: 'no',
        916: 'no',
        917: 'no',
        918: 'no',
        919: 'no',
        92: 'no',
        920: 'no',
        921: 'no',
        922: 'no',
        923: 'no',
        924: 'no',
        925: 'no',
        926: 'no',
        927: 'no',
        928: 'no',
        929: 'no',
        93: 'no',
        930: 'no',
        931: 'no',
        932: 'no',
        933: 'no',
        934: 'no',
        935: 'no',
        936: 'no',
        937: 'no',
        938: 'no',
        939: 'no',
        94: 'no',
        940: 'no',
        941: 'no',
        942: 'no',
        943: 'no',
        944: 'no',
        945: 'no',
        946: 'no',
        947: 'no',
        948: 'no',
        949: 'no',
        95: 'no',
        950: 'no',
        951: 'no',
        952: 'no',
        953: 'no',
        954: 'no',
        955: 'no',
        956: 'no',
        957: 'no',
        958: 'no',
        959: 'no',
        96: 'no',
        960: 'no',
        961: 'no',
        962: 'no',
        963: 'no',
        964: 'no',
        965: 'no',
        966: 'no',
        967: 'no',
        968: 'no',
        969: 'no',
        97: 'no',
        970: 'no',
        971: 'no',
        972: 'no',
        973: 'no',
        974: 'no',
        975: 'no',
        976: 'no',
        977: 'no',
        978: 'no',
        979: 'no',
        98: 'no',
        980: 'no',
        981: 'no',
        982: 'no',
        983: 'no',
        984: 'no',
        985: 'no',
        986: 'no',
        987: 'yes',
        988: 'no',
        989: 'no',
        99: 'no',
        990: 'no',
        991: 'no',
        992: 'no',
        993: 'no',
        994: 'no',
        995: 'no',
        996: 'no',
        997: 'no',
        998: 'no',
        999: 'no'
    }
};
export const BPP_DEDUCTIBLES = { 1000: '$1,000', 2500: '$2,500', 500: '$500' };
export const LOCATION_TYPES = {
    BuildingLeased: 'Building Leased',
    BuildingLeasedToOthers: 'Building Leased To Others',
    BuildingOwned: 'Building Owned',
    CommercialCondoLeased: 'Commercial Condo Leased',
    Home: 'Home',
    Kiosk: 'Kiosk',
    Other: 'Other',
    commercialCondoOwned: 'Commercial Condo Owned'
};
export const CONSTRUCTION_TYPES = {
    F: 'Frame',
    JM: 'Joisted Masonry',
    LNC: 'Light Noncombustible',
    MFR: 'Modified Fire Resistive',
    MNC: 'Masonry Noncombustible',
    R: 'Fire Resistive'
};

export const LOSS_REASONS = {
    Fire: 'Fire',
    'GL-AIPI': 'General Liability - AIPI',
    'GL-PremOps': 'General Liability - Prem/Ops',
    'GL-Products': 'General Liability - Products',
    'Internal Water': 'Internal Water',
    'Other Property': 'Other Property',
    'Other-Liability': 'Other Liability',
    'Vandalism/Theft': 'Vandalism/Theft',
    'Weather/Winter': 'Weather/Winter'
};

export const GL_LIMITS = {
    1000000: '$1,000,000',
    2000000: '$2,000,000',
    // 300000: '$300,000',
    4000000: '$4,000,000'
    // 500000: '$500,000'
};

export const OCCURANCE_LIMITS = {
    1000000: '$1,000,000',
    25000: '$25,000',
    250000: '$250,000',
    50000: '$50,000',
    500000: '$500,000'
};

export const DEDUCTIBLE_AMOUNT = {
    0: '$0',
    1500: '$1,500',
    20000: '$20,000',
    250: '$250',
    2500: '$2,500',
    5000: '$5,000',
    50000: '$50,000',
    750: '$750',
    7500: '$7,500'
};

export const PROPERTY_DAMAGE_LIABILITY_DEDUCTIBLES = {
    1000: '$1000',
    250: '$250',
    2500: '$2500',
    500: '$500'
};

export const US_STATES: Record<string, string> = {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AZ: 'Arizona',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'Washington DC',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    IA: 'Iowa',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    MI: 'Michigan',
    MN: 'Minnesota',
    MO: 'Missouri',
    MS: 'Mississippi',
    MT: 'Montana',
    NC: 'North Carolina',
    ND: 'North Dakota',
    NE: 'Nebraska',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NV: 'Nevada',
    NY: 'New York',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VA: 'Virginia',
    VT: 'Vermont',
    WA: 'Washington',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WY: 'Wyoming'
};
