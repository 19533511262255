import develop from './develop';
import development from './development';
import production from './production';
import staging from './staging';
import qa from './qa';

const env = process.env.REACT_APP_ENV ?? 'develop';
const appConfig: Record<string, typeof develop> = {
    develop,
    development,
    production,
    qa,
    staging
};

export const config = appConfig[env];
