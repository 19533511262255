import { all, call, put, select, takeLatest } from '@redux-saga/core/effects';
import moment from 'moment';

import { defaults } from '../../constants';
import type * as RT from '../../types/reduxTypes';
import { axiosRequest, currencyNumToStr, formatPhoneNumber, strToInt } from '../../utils';
import {
    createQuestionnaireFailure,
    createQuestionnaireSuccess,
    getQuestionnaireFailure,
    getQuestionnaireSuccess,
    setCoterieQuestionnaireData,
    setRatesState,
    updateQuestionnaireFailure,
    updateQuestionnaireSuccess
} from '../actions';
import * as actTypes from '../actionTypes/coterieQuestionnaire';
import { getQuestionnaireId } from '../selectors/coterieQuestionnaire';

const phoneRegex = /\((\d*)\)\s(\S*)/gu;

function cleanCreatePayload(
    payload: RT.ICreateQuestionnairePayload
): RT.ICleanedCreateQuestionnairePayload {
    const { policyInfo, ...payloadRest } = payload;
    return {
        ...payloadRest,
        applicantInfo: {
            ...payload.applicantInfo,
            phoneNumber: payload.applicantInfo.phoneNumber.replace(phoneRegex, '$1-$2')
        },
        policyInfoGeneral: {
            ...policyInfo,
            policyStartDate: policyInfo.policyStartDate.toDate().toISOString()
        }
    };
}

function* createQuestionnaire(action: RT.ICreateQuestionnaireRequest) {
    const { response, error } = yield call(
        axiosRequest,
        'POST',
        '/questionnaire',
        cleanCreatePayload(action.payload)
    );

    if (response) {
        yield put(
            createQuestionnaireSuccess({
                id: response.data.id
            })
        );
    } else {
        yield put(
            createQuestionnaireFailure({
                error: error?.response?.data || error?.message || 'error'
            })
        );
    }
}

function cleanUpdatePayload(
    payload: Partial<RT.ICoterieQuestionnaireData>
): Partial<RT.ICleanedUpdateQuestionnairePayload> {
    const {
        lossInfo,
        boCoverageInfo,
        plCoverageInfo,
        applicantInfo,
        policyInfo,
        glCoverageInfo,
        policyTypes,
        businessInfo,
        answers,
        carriers
    } = payload;
    const returnLoad: Partial<RT.ICleanedUpdateQuestionnairePayload> = {};
    let glInfoPDLD = strToInt(glCoverageInfo?.propertyDamageLiabilityDeductible ?? '');
    if (Number.isNaN(glInfoPDLD)) {
        glInfoPDLD = defaults.PDLD;
    }
    if (applicantInfo) {
        returnLoad.applicantInfo = {
            ...applicantInfo,
            phoneNumber: applicantInfo?.phoneNumber?.replace(phoneRegex, '$1-$2')
        };
    }
    if (policyInfo) {
        returnLoad.policyInfoGeneral = {
            ...policyInfo,
            akHash: policyInfo.akHash,
            industryId: policyInfo.industryId,
            policyStartDate: policyInfo.policyStartDate.toDate().toISOString()
        };
    }
    if (answers) {
        returnLoad.answers = [...answers];
    }
    if (boCoverageInfo) {
        returnLoad.boCoverageInfo = {
            ...boCoverageInfo,
            buildingLimit: strToInt(boCoverageInfo.buildingLimit),
            propertyDamageLiabilityDeductible: glInfoPDLD,
            propertyDeductible: strToInt(boCoverageInfo.propertyDeductible),
            propertyLimit: strToInt(boCoverageInfo.propertyLimit)
        };
    }

    if (glCoverageInfo) {
        returnLoad.glCoverageInfo = {
            generalLiabilityLimit: strToInt(glCoverageInfo.generalLiabilityLimit),
            propertyDamageLiabilityDeductible: glInfoPDLD
        };
    }

    if (plCoverageInfo) {
        returnLoad.plCoverageInfo = {
            ...plCoverageInfo,
            deductible: strToInt(plCoverageInfo.deductible || '0'),
            grossAnnualRevenue: strToInt(plCoverageInfo.grossAnnualRevenue || '0'),
            occurrenceLimit: strToInt(plCoverageInfo.occurrenceLimit || '0'),
            propertyDamageLiabilityDeductible: defaults.PDLD,
            yearsOfExperience: strToInt(plCoverageInfo.yearsOfExperience || '0')
        };
    }

    if (businessInfo) {
        returnLoad.businessInfo = {
            ...businessInfo,
            annualPayroll: strToInt(businessInfo.annualPayroll),
            basementCount: strToInt(businessInfo.basementCount),
            buildingArea: strToInt(businessInfo.buildingArea),
            businessStartDate:
                businessInfo.businessStartDate?.toDate().toISOString() ?? new Date().toISOString(),
            distanceToFirestation: strToInt(businessInfo.distanceToFirestation),
            distanceToHydrant: strToInt(businessInfo.distanceToHydrant),
            grossAnnualSales: strToInt(businessInfo.grossAnnualSales),
            numberOfEmployees: strToInt(businessInfo.numberOfEmployees),
            stories: strToInt(businessInfo.stories)
        };
    }

    if (lossInfo) {
        returnLoad.lossInfo = {
            ...lossInfo,
            lossAmount: lossInfo.losses.length,
            losses:
                (!lossInfo.hadLossesForPreviousYears && []) ||
                lossInfo.losses.map((loss) => ({
                    ...loss,
                    amount: strToInt(loss.amount)
                }))
        };
    }

    if (policyTypes) {
        returnLoad.policyTypes = policyTypes;
    }

    if (carriers) {
        returnLoad.carriers = [...carriers];
    }
    return returnLoad;
}

function* updateQuestionnaire(action: RT.IUpdateQuestionnaireRequest) {
    const id: string = yield select(getQuestionnaireId);
    if (id) {
        const { response, error } = yield call(
            axiosRequest,
            'PATCH',
            `/questionnaire/${id}`,
            cleanUpdatePayload(action.payload)
        );
        if (response) {
            yield put(updateQuestionnaireSuccess(action.clearRates ?? true));
        } else {
            yield put(
                updateQuestionnaireFailure({
                    error: error?.response?.data || error?.message || 'error'
                })
            );
        }
    } else {
        yield put(
            updateQuestionnaireFailure({
                error: 'Questionnaire Id not found\n Please create a new Request'
            })
        );
    }
}

function cleanGetQuestionnairePayload(
    payload: Partial<RT.IGetQuestionnaireResponsePayload>
): Partial<RT.ICoterieQuestionnaireData> {
    let newLosses = payload.lossInfo?.losses?.map((loss) => ({
        amount: currencyNumToStr(loss?.amount),
        description: loss?.description ?? '',
        lossDate: loss?.lossDate ? moment(new Date(loss?.lossDate)) : '',
        reason: loss?.reason ?? ''
    }));
    if (!newLosses || newLosses?.length <= 0) {
        newLosses = [{ amount: '', description: '', lossDate: '', reason: '' }];
    }
    return {
        answers: payload.answers ?? [],
        applicantInfo: {
            akHash: payload.applicantInfo?.akHash ?? '',
            businessName: payload.applicantInfo?.businessName ?? '',
            city: payload.applicantInfo?.city ?? payload.applicantInfo?.mailingCity ?? '',
            email: payload.applicantInfo?.email ?? '',
            firstName: payload.applicantInfo?.firstName ?? '',
            isMailingAddressSame: payload.applicantInfo?.isMailingAddressSame ?? true,
            lastName: payload.applicantInfo?.lastName ?? '',
            mailingCity: payload.applicantInfo?.mailingCity ?? '',
            mailingState: payload.applicantInfo?.mailingState ?? '',
            mailingStreet: payload.applicantInfo?.mailingStreet ?? '',
            mailingUnit: payload.applicantInfo?.mailingUnit ?? '',
            mailingZipCode: payload.applicantInfo?.mailingZipCode ?? '',
            phoneNumber: formatPhoneNumber(payload.applicantInfo?.phoneNumber ?? ''),
            state: payload.applicantInfo?.state ?? '',
            street: payload.applicantInfo?.street ?? '',
            unit: payload.applicantInfo?.unit ?? '',
            zipCode: payload.applicantInfo?.zipCode ?? ''
        },
        boCoverageInfo: {
            buildingLimit: currencyNumToStr(payload.boCoverageInfo?.buildingLimit),
            propertyDeductible: payload.boCoverageInfo?.propertyDeductible?.toString() ?? '',
            propertyLimit: currencyNumToStr(payload.boCoverageInfo?.propertyLimit)
        },
        businessInfo: {
            annualPayroll: currencyNumToStr(payload?.businessInfo?.annualPayroll),
            basementCount: payload.businessInfo?.basementCount ?? '',
            buildingArea: payload.businessInfo?.buildingArea ?? '',
            businessStartDate: payload.businessInfo?.businessStartDate
                ? payload?.businessInfo?.businessStartDate
                : null,
            constructionType: payload.businessInfo?.constructionType ?? '',
            distanceToFirestation: payload.businessInfo?.distanceToFirestation ?? '',
            distanceToHydrant: payload.businessInfo?.distanceToHydrant ?? '',
            grossAnnualSales: payload?.businessInfo?.grossAnnualSales ?? '',
            hasSprinklerSystem: payload.businessInfo?.hasSprinklerSystem ?? false,
            locationType: payload.businessInfo?.locationType ?? '',
            numberOfEmployees: payload?.businessInfo?.numberOfEmployees?.toLocaleString() ?? '',
            roofingImprovementYear: payload.businessInfo?.roofingImprovementYear ?? '',
            sprinklerSystem: payload.businessInfo?.sprinklerSystem ?? false,
            stories: payload.businessInfo?.stories ?? '',
            wiringImprovementYear: payload.businessInfo?.wiringImprovementYear ?? '',
            yearBuilt: payload.businessInfo?.yearBuilt ?? ''
        },
        carriers: payload.carriers ?? [],
        classCode: payload.classCode ?? '',
        clientId: payload.clientId ?? '',
        coterieAgencyId: payload.coterieAgencyId,
        coterieProducerId: payload.coterieProducerId,
        glCoverageInfo: {
            generalLiabilityLimit: payload.glCoverageInfo?.generalLiabilityLimit?.toString() ?? '',
            propertyDamageLiabilityDeductible:
                payload.glCoverageInfo?.propertyDamageLiabilityDeductible?.toString() ?? ''
        },
        lossInfo: {
            hadLossesForPreviousYears: payload.lossInfo?.hadLossesForPreviousYears ?? false,
            losses: newLosses
        },
        plCoverageInfo: {
            ...payload.plCoverageInfo,
            areCertificationsRequired: payload.plCoverageInfo?.areCertificationsRequired ?? false,
            deductible: payload.plCoverageInfo?.deductible?.toString() ?? '',
            doesApplicantMaintainCertifications:
                payload.plCoverageInfo?.doesApplicantMaintainCertifications ?? false,
            grossAnnualRevenue: currencyNumToStr(payload.plCoverageInfo?.grossAnnualRevenue),
            occurrenceLimit: payload.plCoverageInfo?.occurrenceLimit?.toString() ?? '',
            yearsOfExperience: payload.plCoverageInfo?.yearsOfExperience?.toString() ?? ''
        },
        policyInfo: {
            akHash: payload.policyInfoGeneral?.akHash ?? '',

            industryId: payload.policyInfoGeneral?.industryId ?? 0,
            policyStartDate: moment(
                new Date(payload?.policyInfoGeneral?.policyStartDate ?? new Date())
            )
        },
        policyTypes: payload.policyTypes ?? ['BOP'],
        producerCode: payload.producerCode ?? '',
        producerEmail: payload.producerEmail ?? '',
        producerId: payload.producerId ?? '',
        requestId: payload.requestId ?? '',
        status: payload.status ?? 'in-progress'
    };
}

function* getQuestionnaire(action: RT.IGetQuestionnaireRequest) {
    const { id } = action.payload;
    const { response, error } = yield call(axiosRequest, 'GET', `/questionnaire/${id}`);
    if (response) {
        yield put(setCoterieQuestionnaireData({ id }));
        yield put(getQuestionnaireSuccess(cleanGetQuestionnairePayload(response.data)));
        yield put(setRatesState({ data: { quotesList: response.data?.quotes } }));
    } else {
        yield put(
            getQuestionnaireFailure({ error: error?.response?.data || error?.message || 'error' })
        );
    }
}

export function* coterieQuestionnaireSaga() {
    yield all([
        takeLatest(actTypes.CREATE_QUESTIONNAIRE_REQUEST, createQuestionnaire),
        takeLatest(actTypes.UPDATE_QUESTIONNAIRE_REQUEST, updateQuestionnaire),
        takeLatest(actTypes.GET_QUESTIONNAIRE_REQUEST, getQuestionnaire)
    ]);
}
