/**
 * used to separate digit groups from a 10 digit numerical string.
 * @example
 * "9109072482" =>
 *      match : 9109072482
 *          group 1 : 910
 *          group 2 : 907
 *          group 3 : 2482
 */
export const PHONE_REGEX = /(\d{0,3})(\d{0,3})(\d{0,4})/u;

/**
 * filters string containing any character other than digit.
 */
export const DIGIT_FILTER_REGEX = /[^\d]+/gu;

export const ALPHANUM_TEST_REGEX = /^[^a-zA-Z]*$/u;

export const NAME_TEST_REGEX = /^(?=.*[a-zA-Z].*)([-'\u2019a-zA-Z0-9]+)$/u;

export const ALPHANUM_SPECIAL_ALLOWED_CHAR_REGEX = /\s|&|'/gu;
