import type { Gutter } from 'antd/lib/grid/row';

export const DEFAULT_GUTTER: [Gutter, Gutter] = [
    16,
    {
        lg: 32,
        md: 24,
        sm: 16,
        xs: 8
    }
];
