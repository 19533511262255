import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import type { FormListFieldData } from 'antd';
import { Button, Card, Col, Form, Input, Radio, Row, notification } from 'antd';
import type { RuleObject } from 'rc-field-form/lib/interface';
import type { ChangeEvent, FC, FocusEvent } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { FormListOperation } from 'antd/es/form/FormList';
import moment from 'moment/moment';
import { FormDate, FormSelect, useFormElementsStyles } from '../../../components';
import { LOSS_REASONS, formStateMap as fsm, literals, validateMessages } from '../../../constants';
import {
    setCoterieQuestionnaireState,
    setLossInfo,
    updateQuestionnaireRequest
} from '../../../redux/actions';
import type { IFormPropTypes } from '../../../types';
import type * as RT from '../../../types/reduxTypes';
import { ALPHANUM_TEST_REGEX, DIGIT_FILTER_REGEX, compareLossInfo, strToInt } from '../../../utils';
import { useQuestionnaireStyles } from '../questionnaireStyles';

const { Item } = Form;
const LossForm: FC<IFormPropTypes> = ({ nextTab, prevTab, setAreFormsUpdated }) => {
    const formElementsClasses = useFormElementsStyles();
    const questionnaireClasses = useQuestionnaireStyles();
    const [surelyUpdate, setSurelyUpdate] = useState(true);
    const [forceSubmit, setForceSubmit] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { status, hadLossesForPreviousYears, losses, quotesList, policyTypes } = useSelector(
        ({
            coterieQuestionnaire: {
                status,
                data: {
                    lossInfo: { hadLossesForPreviousYears, losses },
                    policyTypes
                }
            },
            rates: {
                data: { quotesList }
            }
        }: RT.TRootState) => ({
            hadLossesForPreviousYears,
            losses,
            policyTypes,
            quotesList,
            status
        })
    );
    const BOP = policyTypes.includes('BOP');
    const PL = policyTypes.includes('PL');
    const GL = policyTypes.includes('GL');

    const descriptionRules = [
        { required: true },
        {
            validator: (
                rule: RuleObject,
                value: string,
                cb: (error?: string | undefined) => void
            ) => {
                const word = value.replace(/\s/u, '');
                if (ALPHANUM_TEST_REGEX.test(word)) cb(validateMessages.invalid);
                else cb();
            }
        }
    ];

    const amountRules = [
        { message: validateMessages.required, required: true },
        {
            validator: (
                rule: RuleObject,
                value: string,
                cb: (error?: string | undefined) => void
            ) => {
                const num = strToInt(value);
                if (num < 1) cb(validateMessages.greaterThan0);
                else cb();
            }
        }
    ];

    const handleAmountChange = (e: ChangeEvent<HTMLInputElement>, key: number) => {
        let val = e.target.value.replace(DIGIT_FILTER_REGEX, '').slice(0, 9);
        const fieldValues = form.getFieldsValue();
        const newLosses = fieldValues.losses;
        val = parseInt(val, 10).toLocaleString();
        if (val === 'NaN') val = '';
        if (val) val = `$${val}`;
        if (key < newLosses?.length) newLosses[key].amount = val;
        form.setFieldsValue({
            losses: newLosses
        });
    };

    const handleDescriptionBlur = (e: FocusEvent<HTMLTextAreaElement>, key: number) => {
        const fieldValues = form.getFieldsValue();
        const newLosses = fieldValues.losses;
        const wordList = e.target.value.split(/\s/u);
        let val = '';
        for (const word of wordList) {
            if (word) val += `${word} `;
        }
        val = val.charAt(0).toUpperCase() + val.slice(1);
        newLosses[key].description = val;
        form.setFieldsValue({
            losses: newLosses
        });
    };

    const submit = (value: { losses: Array<RT.ILosses> }) => {
        const newLossInfo = {
            hadLossesForPreviousYears,
            losses: value.losses ?? []
        };
        if (
            (hadLossesForPreviousYears && !compareLossInfo(newLossInfo.losses, losses)) ||
            surelyUpdate ||
            forceSubmit ||
            quotesList.length <= 0
        ) {
            setAreFormsUpdated(true);
            dispatch(setLossInfo(newLossInfo));
            dispatch(updateQuestionnaireRequest({ lossInfo: newLossInfo }));
        } else {
            nextTab();
        }
    };

    useEffect(() => {
        if (status === 'updated') {
            if (forceSubmit) {
                setForceSubmit(false);
            }
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
            nextTab();
        } else if (status === 'failed') {
            setForceSubmit(true);
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
            notification.error({ description: literals.SUBMISSION_ERROR, message: 'Failed' });
        }
    }, [status]);

    useEffect(() => {
        setSurelyUpdate(!surelyUpdate);
        if (hadLossesForPreviousYears && losses.length <= 0) {
            form.setFieldsValue({ losses: [{ amount: '', description: '' }] });
        }
    }, [hadLossesForPreviousYears]);

    const element = (fields: Array<FormListFieldData>, { add, remove }: FormListOperation) => (
        <>
            <Row className={questionnaireClasses.mb15} gutter={[8, 16]}>
                <Col className={questionnaireClasses.lossHeading} md={{ span: 22 }} span={18}>
                    <h3 className={questionnaireClasses.head3}>{literals.LOSSES}</h3>
                </Col>
            </Row>

            <Row className={questionnaireClasses.lossItem} gutter={[8, 16]}>
                <Col flex='start' span={24}>
                    {fields.map((field, index, fields) => (
                        <Fragment key={field.key}>
                            <Row gutter={[8, 16]}>
                                <Col
                                    className={questionnaireClasses.lossHeading}
                                    md={{ span: 22 }}
                                    span={24}>
                                    {`${literals.LOSS} ${index + 1}`}
                                </Col>

                                <Col
                                    className={questionnaireClasses.lossDeleteIcon}
                                    md={{ span: 2 }}
                                    span={2}>
                                    <Row
                                        align='middle'
                                        aria-label='delete'
                                        hidden={fields.length <= 1}
                                        justify='center'
                                        onClick={() => remove(field.name)}
                                        role='button'
                                        style={{
                                            color: '#e07f7f',
                                            cursor: 'pointer',
                                            fontSize: '20px'
                                        }}>
                                        <DeleteOutlined />
                                    </Row>
                                </Col>
                            </Row>
                            {BOP ? (
                                <>
                                    <Row className={questionnaireClasses.mb15} gutter={[32, 8]}>
                                        <Col lg={{ span: 8 }} md={{ span: 10 }} span={24}>
                                            <FormSelect
                                                label={literals.REASON}
                                                name={[`${field.name}`, fsm.reason]}
                                                selectOption={LOSS_REASONS}
                                            />
                                        </Col>
                                        <Col lg={{ span: 8 }} md={{ span: 10 }} span={24}>
                                            <Item
                                                {...field}
                                                className={formElementsClasses.materialFormItem}
                                                // fieldKey={[field.fieldKey, fsm.amount]}
                                                label={
                                                    <p>
                                                        {literals.AMOUNT}

                                                        <strong>*</strong>
                                                    </p>
                                                }
                                                name={[field.name, fsm.amount]}
                                                rules={amountRules}
                                                validateFirst
                                                validateTrigger='onBlur'>
                                                <Input
                                                    onChange={(e) => {
                                                        handleAmountChange(e, field.key);
                                                    }}
                                                />
                                            </Item>
                                        </Col>
                                        <Col lg={{ span: 8 }} md={{ span: 10 }} span={24}>
                                            <FormDate
                                                disabledDate={(date) => date && date >= moment()}
                                                label={literals.LOSS_DATE}
                                                name={[`${field.name}`, fsm.lossDate]}
                                                showToday={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={questionnaireClasses.mb15} gutter={[32, 8]}>
                                        <Col md={{ span: 24 }} span={24}>
                                            <Item
                                                {...field}
                                                className={formElementsClasses.materialFormItem}
                                                label={
                                                    <p>
                                                        {literals.DESCRIPTION}

                                                        <strong>*</strong>
                                                    </p>
                                                }
                                                name={[field.name, fsm.description]}
                                                rules={descriptionRules}
                                                validateFirst
                                                validateTrigger='onBlur'>
                                                <Input.TextArea
                                                    onBlur={(e) => {
                                                        handleDescriptionBlur(e, field.key);
                                                    }}
                                                />
                                            </Item>
                                        </Col>
                                    </Row>
                                </>
                            ) : null}
                            {PL || GL ? (
                                <Row className={questionnaireClasses.mb15} gutter={[32, 8]}>
                                    <Col md={{ span: 16 }} span={24}>
                                        <Item
                                            {...field}
                                            className={formElementsClasses.materialFormItem}
                                            label={
                                                <p>
                                                    {literals.DESCRIPTION}

                                                    <strong>*</strong>
                                                </p>
                                            }
                                            name={[field.name, fsm.description]}
                                            rules={descriptionRules}
                                            validateFirst
                                            validateTrigger='onBlur'>
                                            <Input.TextArea
                                                onBlur={(e) => {
                                                    handleDescriptionBlur(e, field.key);
                                                }}
                                                rows={1}
                                            />
                                        </Item>
                                    </Col>
                                    <Col lg={{ span: 8 }} md={{ span: 10 }} span={24}>
                                        <Item
                                            {...field}
                                            className={formElementsClasses.materialFormItem}
                                            // fieldKey={[field.fieldKey, fsm.amount]}
                                            label={
                                                <p>
                                                    {literals.AMOUNT}

                                                    <strong>*</strong>
                                                </p>
                                            }
                                            name={[field.name, fsm.amount]}
                                            rules={amountRules}
                                            validateFirst
                                            validateTrigger='onBlur'>
                                            <Input
                                                onChange={(e) => {
                                                    handleAmountChange(e, field.key);
                                                }}
                                            />
                                        </Item>
                                    </Col>
                                </Row>
                            ) : null}
                        </Fragment>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col
                    className={questionnaireClasses.addButton}
                    md={{ span: 2 }}
                    offset={22}
                    span={2}>
                    <Button
                        onClick={add}
                        shape='round'
                        size='large'
                        style={{ marginTop: '20px' }}
                        type='default'>
                        {literals.ADD_LOSS}
                        <PlusCircleOutlined />
                    </Button>
                </Col>
            </Row>
        </>
    );
    return (
        <Card className={questionnaireClasses.form}>
            <Row justify='center'>
                <Col flex='start' span={18}>
                    <Row justify='center'>
                        <h2 className={questionnaireClasses.head2}>{literals.ENTER_LOSS_INFO}</h2>
                    </Row>

                    <Form
                        form={form}
                        initialValues={{ hadLossesForPreviousYears, losses }}
                        layout='vertical'
                        onFinish={submit}
                        requiredMark={false}
                        scrollToFirstError
                        validateMessages={validateMessages}>
                        <Row className={questionnaireClasses.mb24} gutter={[32, 8]}>
                            <Col lg={{ span: 8 }} span={24}>
                                <p className={questionnaireClasses.businessLossLabel}>
                                    {literals.BUSINESS_HAD_LOSSES}
                                </p>
                            </Col>

                            <Col lg={{ span: 16 }} span={24}>
                                <Item
                                    className={formElementsClasses.formItem}
                                    rules={[
                                        { message: validateMessages.required, required: true }
                                    ]}>
                                    <Radio.Group
                                        defaultValue={hadLossesForPreviousYears}
                                        onChange={(e) => {
                                            dispatch(
                                                setLossInfo({
                                                    hadLossesForPreviousYears: e.target.value,
                                                    losses
                                                })
                                            );
                                        }}
                                        style={{ marginLeft: '20px' }}>
                                        <Radio value>{literals.YES}</Radio>

                                        <Radio value={false}>{literals.NO}</Radio>
                                    </Radio.Group>
                                </Item>
                            </Col>
                        </Row>

                        {hadLossesForPreviousYears ? (
                            <Form.List name={fsm.losses}>{element}</Form.List>
                        ) : null}

                        <Row justify='center'>
                            <Item>
                                <Button
                                    className={questionnaireClasses.backBtn}
                                    disabled={status === 'pending'}
                                    onClick={prevTab}
                                    shape='round'
                                    type='default'>
                                    Back
                                </Button>

                                <Button
                                    className={questionnaireClasses.continueBtn}
                                    htmlType='submit'
                                    loading={status === 'pending'}
                                    shape='round'
                                    type='primary'>
                                    Continue
                                </Button>
                            </Item>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Card>
    );
};
export default LossForm;
