import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { axiosRequest } from '../../utils';
import * as actTypes from '../actionTypes/questions';
import type * as RT from '../../types/reduxTypes/questions';
import { getQuestionsFailure, setQuestions } from '../actions';

function* getQuestions(action: RT.IGetQuestionsRequest) {
    const { classCode }: { classCode: string } = action.payload;

    const { response, error } = yield call(
        axiosRequest,
        'GET',
        `/cna/questions/${classCode.substring(0, 5)}`
    );
    if (response?.data.isSuccess) {
        yield put(setQuestions(response.data));
    } else {
        yield put(
            getQuestionsFailure({ error: error?.response?.data || error?.message || 'error' })
        );
    }
}

export function* questionsSaga() {
    yield all([takeLatest(actTypes.GET_QUESTIONS_REQUEST, getQuestions)]);
}
