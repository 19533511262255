import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Card, Modal, Row } from 'antd';
import clsx from 'clsx';
import type { FC, MouseEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'theming';

import { Icon } from '..';
import { GLOBAL_THEME, ICONS, literals } from '../../constants';
import { setCoterieQuestionnaireData } from '../../redux/actions/coterieQuestionnaire';
import type { IAddProductsModalProps, ICustomTheme } from '../../types';
import type * as RT from '../../types/reduxTypes';

const useProductModalStyles = createUseStyles((theme: ICustomTheme) => ({
    card: {
        '& .ant-card-body': {
            padding: '10px'
        },

        borderColor: theme.iconColor,
        borderRadius: '6px',
        cursor: 'pointer',
        height: '110px',
        width: '120px'
    },

    checkboxStyle: {
        fontSize: '20px',
        left: 'auto',
        position: 'absolute',
        right: '5px',
        top: '5px'
    },
    checkedStyle: { color: theme.iconColor },

    continueBtn: {
        '&:hover, &:focus, &:active': {
            background: theme.unVisitedTabIconColor,
            borderColor: 'transparent',
            color: GLOBAL_THEME.visitedTabIconColor
        },

        background: theme.unVisitedTabIconColor,
        borderColor: 'transparent',
        borderRadius: '50px',
        color: GLOBAL_THEME.visitedTabIconColor,
        height: '45px',
        margin: '30px 0 20px',
        width: '151px'
    },

    mt10: { marginTop: '10px' },

    productText: {
        color: theme.iconColor,
        fontSize: '18px',
        lineHeight: '30px',
        marginBottom: 0
    },

    selectProductHeading: {
        fontSize: '20px',
        margin: '1em 0'
    },

    uncheckedStyle: { color: GLOBAL_THEME.disabledCheck }
}));

const ProductCheckBox: FC<{
    icon: 'BOP' | 'GL' | 'PL';
    checked: boolean;
    onClick: MouseEventHandler<HTMLDivElement>;
    disabled: boolean;
}> = ({ icon, checked, onClick, disabled }) => {
    const classes = useProductModalStyles();
    const theme: ICustomTheme = useTheme();
    const icons = {
        BOP: ICONS.BOP_ICON,
        GL: ICONS.GL_ICON,
        PL: ICONS.PL_ICON
    };

    return (
        <Card
            className={classes.card}
            onClick={(e) => {
                if (!disabled) {
                    onClick(e);
                }
            }}>
            <CheckCircleFilled
                className={clsx(
                    classes.checkboxStyle,
                    checked ? classes.checkedStyle : classes.uncheckedStyle
                )}
            />

            <Row className={classes.mt10} justify='center'>
                <Icon color={theme.iconColor} icon={icons[icon]} size={50} />
            </Row>

            <Row align='bottom' justify='center'>
                <p className={classes.productText}>{icon}</p>
            </Row>
        </Card>
    );
};

export const AddProductModal: FC<IAddProductsModalProps> = ({
    modalVisible,
    setModalVisible,
    secondTab
}) => {
    const policyTypes = useSelector(
        ({
            coterieQuestionnaire: {
                data: { policyTypes }
            }
        }: RT.TRootState) => policyTypes
    );
    const BOP = policyTypes.includes('BOP');
    const PL = policyTypes.includes('PL');
    const GL = policyTypes.includes('GL');
    // eslint-disable-next-line react/hook-use-state
    const [BOPCheck, setBOPCheck] = useState(BOP);
    // eslint-disable-next-line react/hook-use-state
    const [PLCheck, setPLCheck] = useState(PL);
    // eslint-disable-next-line react/hook-use-state
    const [GLCheck, setGLCheck] = useState(GL);
    const [continueDisabled, setContinueDisabled] = useState(false);
    const classes = useProductModalStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        if (BOPCheck === BOP && PLCheck === PL && GLCheck === GL) setContinueDisabled(true);
        else setContinueDisabled(false);
    }, [BOPCheck, PLCheck, GLCheck]);

    const goToCoverageForm = () => {
        const newProducts: Array<RT.PolicyTypes> = [];
        if (!BOP && BOPCheck) {
            newProducts.push('BOP');
        }
        if (!PL && PLCheck) {
            newProducts.push('PL');
        }
        if (!GL && GLCheck) {
            newProducts.push('GL');
        }
        const newPolicyTypes = policyTypes.concat(newProducts);

        dispatch(setCoterieQuestionnaireData({ policyTypes: newPolicyTypes }));
        secondTab(newProducts);
    };
    return (
        <Modal footer={null} onCancel={() => setModalVisible(false)} open={modalVisible}>
            <Row justify='center'>
                <h3 className={classes.selectProductHeading}>{literals.SELECT_PRODUCTS}</h3>
            </Row>

            <Row align='middle' justify='space-around'>
                <ProductCheckBox
                    checked={BOPCheck}
                    disabled={BOP}
                    icon='BOP'
                    onClick={() => {
                        setBOPCheck(!BOPCheck);
                    }}
                />

                <ProductCheckBox
                    checked={GLCheck}
                    disabled={GL}
                    icon='GL'
                    onClick={() => {
                        setGLCheck(!GLCheck);
                    }}
                />

                <ProductCheckBox
                    checked={PLCheck}
                    disabled={PL}
                    icon='PL'
                    onClick={() => {
                        setPLCheck(!PLCheck);
                    }}
                />
            </Row>

            <Row justify='center'>
                <Button
                    className={classes.continueBtn}
                    disabled={continueDisabled}
                    onClick={goToCoverageForm}
                    shape='round'
                    type='default'>
                    Continue
                </Button>
            </Row>
        </Modal>
    );
};
