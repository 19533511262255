import type { DeepPartial } from 'redux';
import type * as RT from '../../types/reduxTypes/coterieQuestionnaire';
import * as actType from '../actionTypes/coterieQuestionnaire';

export const setApplicantInfo = (payload: Partial<RT.IApplicantInfo>): RT.ISetApplicantInfo => ({
    payload,
    type: actType.SET_APPLICANT_INFO
});

export const setBopCoverageInfo = (
    payload: Partial<RT.IBOPCoverageInfo>
): RT.ISetBOPCoverageInfo => ({
    payload,
    type: actType.SET_BOP_COVERAGE_INFO
});

export const setBusinessInfo = (payload: Partial<RT.IBusinessInfo>): RT.ISetBusinessInfo => ({
    payload,
    type: actType.SET_BUSINESS_INFO
});

export const setCoterieQuestionnaireData = (
    payload: Partial<RT.ICoterieQuestionnaireData>
): RT.ISetCoterieQuestionnaireData => ({
    payload,
    type: actType.SET_COTERIE_QUESTIONNAIRE_DATA
});

export const setCoterieQuestionnaireState = (
    payload: Partial<RT.ICoterieQuestionnaireState>
): RT.ISetCoterieQuestionnaireState => ({
    payload,
    type: actType.SET_COTERIE_QUESTIONNAIRE_STATE
});

export const setGLCoverageInfo = (payload: Partial<RT.IGLCoverageInfo>): RT.ISetGLCoverageInfo => ({
    payload,
    type: actType.SET_GL_COVERAGE_INFO
});

export const setPLCoverageInfo = (payload: Partial<RT.IPLCoverageInfo>): RT.ISetPLCoverageInfo => ({
    payload,
    type: actType.SET_PL_COVERAGE_INFO
});

export const setPolicyInfo = (payload: RT.IPolicyInfo): RT.ISetPolicyInfo => ({
    payload,
    type: actType.SET_POLICY_INFO
});

export const setLossInfo = (payload: RT.ILossInfo): RT.ISetLossInfo => ({
    payload,
    type: actType.SET_LOSS_INFO
});

export const createQuestionnaireRequest = (
    payload: RT.ICreateQuestionnairePayload
): RT.ICreateQuestionnaireRequest => ({
    payload,
    type: actType.CREATE_QUESTIONNAIRE_REQUEST
});

export const createQuestionnaireSuccess = (payload: {
    id: string;
}): RT.ICreateQuestionnaireSuccess => ({
    payload,
    type: actType.CREATE_QUESTIONNAIRE_SUCCESS
});

export const createQuestionnaireFailure = (
    payload: Partial<RT.ICoterieQuestionnaireState>
): RT.ICreateQuestionnaireFailure => ({
    payload,
    type: actType.CREATE_QUESTIONNAIRE_FAILURE
});

export const updateQuestionnaireRequest = (
    payload: Partial<RT.ICoterieQuestionnaireData>,
    clearRates = true
): RT.IUpdateQuestionnaireRequest => ({
    clearRates,
    payload,
    type: actType.UPDATE_QUESTIONNAIRE_REQUEST
});

export const updateQuestionnaireSuccess = (
    clearRates: boolean
): RT.IUpdateQuestionnaireSuccess => ({
    clearRates,
    type: actType.UPDATE_QUESTIONNAIRE_SUCCESS
});

export const updateQuestionnaireFailure = (
    payload: DeepPartial<RT.ICoterieQuestionnaireState>
): RT.IUpdateQuestionnaireFailure => ({
    payload,
    type: actType.UPDATE_QUESTIONNAIRE_FAILURE
});

export const getQuestionnaireRequest = (payload: { id: string }): RT.IGetQuestionnaireRequest => ({
    payload,
    type: actType.GET_QUESTIONNAIRE_REQUEST
});

export const getQuestionnaireSuccess = (
    payload: Partial<RT.ICoterieQuestionnaireData>
): RT.IGetQuestionnaireSuccess => ({
    payload,
    type: actType.GET_QUESTIONNAIRE_SUCCESS
});

export const getQuestionnaireFailure = (
    payload: Partial<RT.ICoterieQuestionnaireState>
): RT.IGetQuestionnaireFailure => ({
    payload,
    type: actType.GET_QUESTIONNAIRE_FAILURE
});
