import { Form } from 'antd';
import generatePicker from 'antd/es/date-picker/generatePicker';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import type { FC } from 'react';
import { validateMessages } from '../../constants';
import type { IFormDateProps } from '../../types';
import { useFormElementsStyles } from './formElementsStyles';

/**
 * @example
 * function disabledDate(current:moment.Moment) {
 *      // Can not select days before today including today
 *      return current && current < moment().endOf('day');
 * }
 */

const { Item } = Form;

const DatePicker = generatePicker<Moment>(momentGenerateConfig);

export const FormDate: FC<IFormDateProps> = ({ label, disabledDate, name, showToday = true }) => {
    const classes = useFormElementsStyles();
    const dateFormat = ['MM/DD/YYYY', 'MMDDYY', 'MMDDYYYY', 'MM/DD/YY'];

    return (
        <div id={name[1]}>
            <Item
                className={classes.materialFormItem}
                label={
                    <p>
                        {label}

                        <strong>*</strong>
                    </p>
                }
                name={name}
                rules={[{ message: validateMessages.required, required: true }]}>
                <DatePicker
                    allowClear={false}
                    disabledDate={disabledDate}
                    format={dateFormat}
                    getPopupContainer={() => document.getElementById(name[1])!}
                    placeholder={dateFormat[0]}
                    showToday={showToday}
                />
            </Item>
        </div>
    );
};
