import { Button, Card, Col, Form, Row, notification } from 'antd';
import moment from 'moment';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormDate, FormInputNumber, FormSelect } from '../../../components';
import {
    BPP_DEDUCTIBLES,
    DEDUCTIBLE_AMOUNT,
    GL_LIMITS,
    OCCURANCE_LIMITS,
    PROPERTY_DAMAGE_LIABILITY_DEDUCTIBLES,
    formStateMap as fsm,
    literals,
    validateMessages
} from '../../../constants';
import {
    setCoterieQuestionnaireData,
    setCoterieQuestionnaireState,
    updateQuestionnaireRequest
} from '../../../redux/actions';
import type { IFormPropTypes } from '../../../types';
import type * as RT from '../../../types/reduxTypes';
import { useQuestionnaireStyles } from '../questionnaireStyles';
import { compareCoverageObjects } from '../../../utils';

const { Item } = Form;

const GLForm: FC<{ PL: boolean }> = ({ PL }) => {
    const classes = useQuestionnaireStyles();

    return (
        <>
            <h3 className={classes.head3}>{literals.GL_COVERAGE}</h3>

            <Row gutter={[32, 8]}>
                {PL ? (
                    <Col className={classes.generalLiabilityLimit} md={{ span: 8 }} span={24}>
                        <FormSelect
                            label={literals.OCCURRENCE_LIMIT}
                            name={[fsm.plCoverageInfo, fsm.occurrenceLimit]}
                            selectOption={OCCURANCE_LIMITS}
                        />
                    </Col>
                ) : (
                    <Col className={classes.generalLiabilityLimit} md={{ span: 8 }} span={24}>
                        <FormSelect
                            label={literals.GL_LIMIT}
                            name={[fsm.glCoverageInfo, fsm.generalLiabilityLimit]}
                            selectOption={GL_LIMITS}
                        />
                    </Col>
                )}

                {PL ? (
                    <Col className={classes.generalLiabilityLimit} md={{ span: 8 }} span={24}>
                        <FormSelect
                            label={literals.DEDUCTIBLE}
                            name={[fsm.plCoverageInfo, fsm.deductible]}
                            selectOption={DEDUCTIBLE_AMOUNT}
                        />
                    </Col>
                ) : (
                    <Col className={classes.generalLiabilityLimit} md={{ span: 8 }} span={24}>
                        <FormSelect
                            label={literals.PROPERTY_DAMAGE_LIABILITY_DEDUCTIBLE}
                            name={[fsm.glCoverageInfo, fsm.propertyDamageLiabilityDeductible]}
                            selectOption={PROPERTY_DAMAGE_LIABILITY_DEDUCTIBLES}
                        />
                    </Col>
                )}
            </Row>
        </>
    );
};

const RequestedPolicyForm: FC<
    IFormPropTypes & { editingProducts: Array<RT.PolicyTypes> | null }
> = ({ editingProducts, prevTab, nextTab, setAreFormsUpdated }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [forceSubmit, setForceSubmit] = useState(false);
    const classes = useQuestionnaireStyles();

    const { policyInfo, status, policyTypes, id, glCoverageInfo, plCoverageInfo, boCoverageInfo } =
        useSelector(({ coterieQuestionnaire: { data, error, status } }: RT.TRootState) => ({
            ...data,
            error,
            status
        }));
    const productList = editingProducts ?? policyTypes;
    const BOP = productList.includes('BOP');
    const GL = productList.includes('GL');
    const PL = productList.includes('PL');

    useEffect(() => {
        if (status === 'created' || status === 'updated') {
            if (forceSubmit) {
                setForceSubmit(false);
            }
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
            nextTab();
        } else if (status === 'failed') {
            notification.error({
                description: literals.SUBMISSION_ERROR,
                message: 'Failed'
            });
            setForceSubmit(true);
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
        }
    }, [status]);

    const submit = (value: {
        boCoverageInfo?: RT.IBOPCoverageInfo;
        glCoverageInfo?: RT.IGLCoverageInfo;
        plCoverageInfo?: RT.IPLCoverageInfo;
        policyInfo: RT.IPolicyInfo;
    }) => {
        if (id) {
            const payload: Partial<RT.ICoterieQuestionnaireData> = {};
            if (!compareCoverageObjects(value.boCoverageInfo, boCoverageInfo)) {
                payload.boCoverageInfo = value.boCoverageInfo;
            }

            if ((GL || BOP) && !compareCoverageObjects(value.glCoverageInfo, glCoverageInfo)) {
                payload.glCoverageInfo = value.glCoverageInfo;
            }
            if (!compareCoverageObjects(value.policyInfo, policyInfo)) {
                payload.policyInfo = { ...policyInfo, ...value.policyInfo };
            }

            if (PL && !compareCoverageObjects(value.plCoverageInfo, plCoverageInfo)) {
                payload.plCoverageInfo = { ...plCoverageInfo, ...value.plCoverageInfo };
            }

            if (editingProducts) {
                payload.policyTypes = policyTypes;
            }

            if (Object.keys(payload).length !== 0) {
                dispatch(setCoterieQuestionnaireData(payload));
                dispatch(updateQuestionnaireRequest(payload));
                setAreFormsUpdated(true);
            } else {
                nextTab();
            }
        } else {
            notification.error({ description: literals.SUBMISSION_ERROR, message: 'Failed' });
        }
    };

    return (
        <Card className={classes.form}>
            <Row justify='center'>
                <Col span={18}>
                    <Row justify='center'>
                        <h2 className={classes.head2}>{literals.ENTER_POLICY_INFO}</h2>
                    </Row>
                    <Form
                        form={form}
                        initialValues={{
                            boCoverageInfo,
                            glCoverageInfo,
                            plCoverageInfo,
                            policyInfo
                        }}
                        layout='vertical'
                        onFinish={submit}
                        requiredMark={false}
                        scrollToFirstError
                        validateMessages={validateMessages}>
                        <Row gutter={[32, 8]}>
                            <Col md={{ span: 8 }} span={24}>
                                <FormDate
                                    disabledDate={(date) =>
                                        date &&
                                        (date < moment() || date >= moment().add(90, 'days'))
                                    }
                                    label={literals.POLICY_START_DATE}
                                    name={[fsm.policyInfo, fsm.policyStartDate]}
                                    showToday={false}
                                />
                            </Col>
                        </Row>
                        <h3 className={classes.head3}>{literals.COVERAGE_INFORMATION}</h3>
                        <Row className={`${classes.mb24}`} gutter={[32, 8]}>
                            <Col lg={{ span: 8 }} md={{ span: 10 }} span={24}>
                                <FormInputNumber
                                    format='currency'
                                    formInstance={form}
                                    label={literals.BPP_LIMIT}
                                    max={500000}
                                    min={0}
                                    name={[fsm.boCoverageInfo, fsm.propertyLimit]}
                                />
                            </Col>

                            <Col lg={{ span: 8 }} md={{ span: 10 }} span={24}>
                                <FormSelect
                                    label={literals.BPP_DEDUCTIBLE}
                                    name={[fsm.boCoverageInfo, fsm.propertyDeductible]}
                                    selectOption={BPP_DEDUCTIBLES}
                                />
                            </Col>

                            <Col lg={{ span: 8 }} md={{ span: 4 }} span={24}>
                                <FormInputNumber
                                    format='currency'
                                    formInstance={form}
                                    label={literals.BUILDING_LIMIT}
                                    max={1000000}
                                    min={0}
                                    name={[fsm.boCoverageInfo, fsm.buildingLimit]}
                                />
                            </Col>
                        </Row>

                        <GLForm PL={PL} />

                        <Row justify='center'>
                            <Item>
                                <Button
                                    className={classes.backBtn}
                                    onClick={prevTab}
                                    shape='round'
                                    type='default'>
                                    Back
                                </Button>

                                <Button
                                    className={classes.continueBtn}
                                    htmlType='submit'
                                    loading={status === 'pending'}
                                    shape='round'
                                    type='default'>
                                    Continue
                                </Button>
                            </Item>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Card>
    );
};

export default RequestedPolicyForm;
