import { Modal, Row } from 'antd';
import type { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { US_STATES, literals } from '../../constants';
import type { ICustomTheme, ILicensedStatesModalProps } from '../../types';
import type * as RT from '../../types/reduxTypes';

const useLicencedStatesModalStyles = createUseStyles((theme: ICustomTheme) => ({
    closeBtn: {
        color: theme.productFontColor,
        cursor: 'pointer',
        letterSpacing: '0.05em'
    }
}));
export const LicensedStatesModal: FC<ILicensedStatesModalProps> = ({
    modalVisible,
    setModalVisible,
    formInstance
}) => {
    const classes = useLicencedStatesModalStyles();
    const getMailingState = () => {
        const value: { applicantInfo: RT.IApplicantInfo } = formInstance.getFieldsValue();
        return value?.applicantInfo?.state ?? '';
    };
    return (
        <Modal
            closable={false}
            footer={null}
            onCancel={() => setModalVisible(false)}
            open={modalVisible}>
            <h2>{literals.INVALID_PRODUCER_LICENCE}</h2>

            <Row align='middle' justify='center'>
                <p>
                    {`Quoting is not allowed in the state of ${
                        US_STATES[getMailingState()]
                    } as we were unable to verify the validity of your individual/business license for this state.`}
                </p>
            </Row>

            <Row justify='center'>
                <span
                    className={classes.closeBtn}
                    onClick={() => setModalVisible(false)}
                    role='button'>
                    CLOSE
                </span>
            </Row>
        </Modal>
    );
};
