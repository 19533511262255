import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeCardElementOptions } from '@stripe/stripe-js';
import { Button } from 'antd';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { literals } from '../../constants';

interface StripeCardFormProps {
    setIsSubmitted: Dispatch<SetStateAction<boolean>>;
    setError: Dispatch<SetStateAction<string>>;
    sendBindRequest: (token: string) => void;
}

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: {
        base: {
            '::placeholder': {
                color: '#3a4b5ea1'
            },
            color: '#32325d',
            fontFamily: 'Manrope, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased'
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};
export const StripeCardForm: FC<StripeCardFormProps> = ({
    setIsSubmitted,
    setError,
    sendBindRequest
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isDisabled, setIsDisabled] = useState(true);
    const [rerender, setRerender] = useState(false);
    const [cardError, setCardError] = useState('');

    const card = elements?.getElement(CardElement);
    card?.on('change', (event) => {
        if (event.error) {
            setCardError(event.error.message);
        } else if (cardError) {
            setCardError('');
        }
        if (event.complete) {
            setIsDisabled(false);
        } else if (!isDisabled) {
            setIsDisabled(true);
        }
    });

    useEffect(() => {
        if (!card) {
            setTimeout(() => setRerender(!rerender), 1000);
        }
    }, [rerender]);

    const handleSubmit = async () => {
        if (!stripe || !elements || !card) {
            return;
        }
        const result = await stripe.createToken(card);
        if (result.error) {
            setError(result.error.message ?? '');
        } else {
            sendBindRequest(result.token.id);
            setIsSubmitted(true);
        }
    };

    return (
        <form>
            <label className='provide-details'>{literals.STRIPE_PROVIDE_DETAILS}</label>
            <p className='details-disclosure'>{literals.STRIPE_DETAILS_DISCLAIMER}</p>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <p className='powered-by'>
                Powered by <strong>stripe</strong>
            </p>
            <p className='error-label'>{cardError}</p>
            <Button
                className='submit-payment-btn'
                disabled={!stripe || isDisabled}
                onClick={handleSubmit}>
                {literals.SUBMIT_PAYMENT}
            </Button>
        </form>
    );
};
