export const ICONS = {
    BIND: 'bind',
    BOP_ICON: 'bop-icon',
    BRIDGE: 'bridge',
    BUSINESS_INFO: 'business-info',
    EDIT: 'edit',
    GL_ICON: 'gl-icon',
    LOSS_ICON: 'loss-icon',
    PAYMENT_PROCESS: 'payment-process',
    PL_ICON: 'pl-icon',
    POLICY_INFO: 'policy-info',
    POLICY_ISSUED: 'policy-issued',
    QUESTION: 'question',
    RATES: 'rates',
    UMBRELLA_MONEY: 'umbrella-money',
    USER: 'user'
};
