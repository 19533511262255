import type * as RT from '../../types/reduxTypes';
import * as actType from '../actionTypes/rates';

export const getCoterieRates = (): RT.IGetCoterieRatesRequest => ({
    type: actType.GET_COTERIE_RATES_REQUEST
});

export const getCNARates = (): RT.IGetCNARatesRequest => ({
    type: actType.GET_CNA_RATES_REQUEST
});

export const setQuote = (payload: RT.IQuote): RT.ISetQuote => ({
    payload,
    type: actType.SET_QUOTE
});

export const setRatesState = (payload: Partial<RT.IRatesState>): RT.ISetRatesState => ({
    payload,
    type: actType.SET_RATES_STATE
});

export const cancelPolling = (payload: Partial<RT.IRatesState>): RT.ISetRatesState => ({
    payload,
    type: actType.CANCEL_CNA_RATES_POLLING
});

export const bindPolicy = (payload: string): RT.IBindPolicy => ({
    payload,
    type: actType.COTERIE_BIND_POLICY
});

export const bindPolicySuccess = (payload: string): RT.IBindPolicySuccess => ({
    payload,
    type: actType.COTERIE_BIND_POLICY_SUCCESS
});

export const bindPolicyFailed = (): RT.IBindPolicyFailed => ({
    type: actType.COTERIE_BIND_POLICY_FAILED
});
