// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Form, Input, Radio, Select } from 'antd';
import { useTheme } from 'react-jss';
import type { RadioChangeEvent } from 'antd/es/radio/interface';
import { ActionStatuses, AnswerType, InputType, WorkflowControlType } from '../constants';
import { isYesOrNoQuestion } from '../utils';
import { InputNumberControlled } from './Shared/Elements';
import type { ICustomTheme } from '../types';
import { useFormElementsStyles } from './FormElements';
import './QuestionItem.scss';
import { useEffect } from 'react';

const { Option } = Select;

export const QuestionItem = ({ question, handleChange }) => {
    const { options, action, questionText } = question;
    const validationRules = [{ message: 'The field is required', options: options[0]?.required }];
    const inputNumberMin = 0;
    const inputNumberMax = 1000000000;
    const inputControlStep = 1;
    const questionTextWithStatus = questionText;
    let ValidationMessageComponent = null;
    const formItemClassName = question.originatingId
        ? 'regular-question follow-up-question'
        : 'regular-question';
    const theme = useTheme<ICustomTheme>();
    const formStyles = useFormElementsStyles({ theme });

    if (action.message)
        ValidationMessageComponent = <span className='error-message'>{action.message}</span>;
    const onChange = (value: RadioChangeEvent) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        handleChange?.(value, question);
    };

    let ItemComponent = null;
    switch (question.answerType) {
        case AnswerType.INPUT:
            ItemComponent =
                // eslint-disable-next-line no-nested-ternary
                options[0].type === InputType.INTEGER ? (
                    <InputNumberControlled
                        max={inputNumberMax}
                        min={inputNumberMin}
                        onChange={onChange}
                        step={inputControlStep}
                    />
                ) : options[0].type === InputType.DECIMAL ? (
                    <InputNumberControlled
                        max={inputNumberMax}
                        min={inputNumberMin}
                        onChange={onChange}
                        step={inputControlStep}
                    />
                ) : (
                    <Input onChange={onChange} />
                );
            break;
        case AnswerType.SELECT:
            if (isYesOrNoQuestion(options)) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                const radioOptions = options.map((o) => ({
                    label: o.label,
                    value: o.optionId
                }));
                ItemComponent = (
                    <Radio.Group
                        name={question.questionId}
                        onChange={onChange}
                        options={radioOptions}
                    />
                );
            } else {
                ItemComponent = (
                    <Select onChange={onChange}>
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                        {options.map((o) => (
                            <Option key={o.optionId} value={o.optionId}>
                                {o.label}
                            </Option>
                        ))}
                    </Select>
                );
            }
            break;
        case AnswerType.MULTI_SELECT:
            ItemComponent = (
                <Select allowClear mode='multiple' name={question.questionId} onChange={onChange}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                    {options.map((o) => (
                        <Option key={o.optionId} value={o.optionId}>
                            {o.label}
                        </Option>
                    ))}
                </Select>
            );
            break;
        // @TODO check this case
        case AnswerType.MULTI_INPUT:
            ItemComponent = <Input onChange={onChange} />;
            break;
        default:
            ItemComponent = <Input onChange={onChange} />;
            break;
    }

    return (
        <>
            <Form.Item
                key={question.questionId}
                className={`${formItemClassName} ${formStyles.formItem}`}
                label={questionTextWithStatus}
                labelCol={{ span: 24 }}
                name={question.questionId}
                rules={validationRules}
                wrapperCol={{ span: 24 }}>
                {ItemComponent}
            </Form.Item>
            {ValidationMessageComponent}
        </>
    );
};
