import type { ICoterieQuestionnaireData, TRootState } from '../../types/reduxTypes';

export const getQuestionnaireId = (state: TRootState): string => {
    const {
        coterieQuestionnaire: {
            data: { id }
        }
    } = state;
    return id ?? '';
};

export const getIdAndCarriers = (state: TRootState): Partial<ICoterieQuestionnaireData> => {
    const {
        coterieQuestionnaire: {
            data: { id, carriers }
        }
    } = state;
    return { carriers, id: id ?? '' };
};
