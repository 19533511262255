// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { Button, InputNumber } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import './Elements.scss';

export const InputNumberControlled = (props) => {
    const { value, step, onChange } = props;
    const decrement = () => {
        // eslint-disable-next-line no-nested-ternary
        const inputVal = value
            ? step === 0.1
                ? Number((((value - step) * 100) / 100).toFixed(1))
                : value - step
            : // eslint-disable-next-line no-undefined
              undefined;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        onChange(inputVal);
    };

    const increment = () => {
        // eslint-disable-next-line no-nested-ternary
        const inputVal = value
            ? step === 0.1
                ? // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  Number((((value + step) * 100) / 100).toFixed(1))
                : // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  value + step
            : step;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        onChange(inputVal);
    };

    return (
        <>
            <Button
                className='btn-circle-light'
                icon={<MinusOutlined />}
                onClick={decrement}
                shape='circle'
                type='link'
            />
            <InputNumber {...props} className='input-number-controlled' />
            <Button
                className='btn-circle-light'
                icon={<PlusOutlined />}
                onClick={increment}
                shape='circle'
                type='link'
            />
        </>
    );
};
