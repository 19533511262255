import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useRatesStyles from './ratesStyles';

import { literals } from '../../constants';
import type * as RT from '../../types/reduxTypes';
import { DIGIT_FILTER_REGEX, currencyFormat, strToInt } from '../../utils';

export const RenderBox = ({ label, value }: { label: string; value: string }) => {
    const classes = useRatesStyles();
    return (
        <Col md={{ span: 8 }} span={24}>
            <div className={classes.viewMoreBoxes}>
                <Row className={classes.viewMoreBoxLabel} justify='center'>
                    {label}
                </Row>

                <Row className={classes.viewMoreBoxValue} justify='center'>
                    ${value}
                </Row>
            </div>
        </Col>
    );
};

export const ViewMoreContent: FC<{ type: RT.TPolicyType }> = ({ type }) => {
    const classes = useRatesStyles();
    const [showContent, setShowContent] = useState(false);
    const {
        buildingLimit,
        propertyLimit,
        generalLiabilityLimit,
        occurrenceLimit,
        propertyDeductible,
        deductible
    } = useSelector(
        ({
            coterieQuestionnaire: {
                data: {
                    boCoverageInfo: { buildingLimit, propertyLimit, propertyDeductible },
                    glCoverageInfo: { generalLiabilityLimit },
                    plCoverageInfo: { occurrenceLimit, deductible }
                }
            }
        }: RT.TRootState) => ({
            buildingLimit,
            deductible,
            generalLiabilityLimit,
            occurrenceLimit,
            propertyDeductible,
            propertyLimit
        })
    );

    const kValue = (v: string): string => {
        const x = v.replace(DIGIT_FILTER_REGEX, '');
        if (x.length > 3) {
            const num = parseInt(x.slice(0, x.length - 3), 10).toLocaleString();
            return `${num}K`;
        }
        return x;
    };

    const boxes = {
        BOP: (
            <>
                <RenderBox label={literals.BOX_PERSONAL_PROPERTY} value={kValue(propertyLimit)} />

                <RenderBox label={literals.BOX_BUILDING} value={kValue(buildingLimit)} />

                <RenderBox label={literals.BOX_GL} value={kValue(generalLiabilityLimit)} />
            </>
        ),
        GL: (
            <>
                <RenderBox label={literals.BOX_GL} value={kValue(generalLiabilityLimit)} />

                <RenderBox
                    label={literals.BOX_GL_AGGREGATE}
                    value={kValue((strToInt(generalLiabilityLimit) * 2).toString())}
                />

                <RenderBox
                    label={literals.BOX_AGGREGATE_PCO}
                    value={kValue((strToInt(generalLiabilityLimit) * 2).toString())}
                />
            </>
        ),
        PL: <RenderBox label={literals.BOX_OCCURRENCE} value={kValue(occurrenceLimit)} />
    };
    return (
        <>
            <Row justify='end'>
                <span
                    className={classes.viewMoreBtn}
                    onClick={() => {
                        setShowContent(!showContent);
                    }}
                    role='button'>
                    {showContent ? <MinusOutlined /> : <PlusOutlined />}
                    View {showContent ? 'Less' : 'More'}
                </span>
            </Row>

            {showContent ? (
                <>
                    <Divider />

                    <Row gutter={[16, 8]}>{boxes[type]}</Row>

                    <Row>
                        <Col md={{ span: 19 }} span={24}>
                            {(type === 'BOP' && (
                                <div className={classes.deductibles}>
                                    <h4>Deductibles:</h4>

                                    <p>
                                        {`${literals.DEDUCTIBLE_BUILDING}: ${currencyFormat.format(
                                            parseInt(propertyDeductible, 10)
                                        )}`}
                                    </p>

                                    <p>
                                        {`${
                                            literals.DEDUCTIBLE_BUSINESS_PERSONAL_PROPERTY
                                        }: ${currencyFormat.format(
                                            parseInt(propertyDeductible, 10)
                                        )}`}
                                    </p>
                                </div>
                            )) ||
                                (type === 'PL' && (
                                    <div className={classes.deductibles}>
                                        <h4>Deductibles:</h4>

                                        <p>
                                            {`${
                                                literals.DEDUCTIBLE_AMOUNT
                                            }: ${currencyFormat.format(parseInt(deductible, 10))}`}
                                        </p>
                                    </div>
                                ))}
                        </Col>
                    </Row>
                </>
            ) : null}
        </>
    );
};

export const Info = ({ infoType, value }: { infoType: string; value: string }) => {
    const classes = useRatesStyles();
    return (
        <>
            <Row className={classes.infoType}>{infoType}</Row>

            <Row className={classes.infoValue}>{value}</Row>
        </>
    );
};
