import { Avatar, Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import type { FC } from 'react';
import { useTheme } from 'react-jss';

import { GLOBAL_THEME } from '../../constants';
import type { ICustomTheme } from '../../types';
import { Icon } from '../Assets/Icon';
import { useTabsStyles } from './tabsStyles';

const Tab: FC<ITabProps> = ({ tabs, index, activeTabIndex }) => {
    const theme: ICustomTheme = useTheme();
    const classes = useTabsStyles();
    return (
        <Col className={classes.tab} span={3}>
            <Avatar
                className={clsx(
                    classes.tabAvatar,
                    (index < activeTabIndex && classes.visited) ||
                        (index === activeTabIndex && classes.current) ||
                        classes.notVisited
                )}
                icon={
                    <Icon
                        color={
                            (index === activeTabIndex && GLOBAL_THEME.visitedTabIconColor) ||
                            theme.unVisitedTabIconColor
                        }
                        icon={tabs[index].icon}
                        size={24}
                    />
                }
                size='large'
            />

            <p
                className={clsx(
                    index <= activeTabIndex
                        ? classes.visitedTextColor
                        : classes.notVisitedTextColor,
                    classes.tabLabel
                )}>
                {tabs[index].name}
            </p>

            <Divider
                className={clsx(
                    classes.tabDivider,
                    (index === 0 && classes.dividerRight) ||
                        (index === tabs.length - 1 && classes.dividerLeft)
                )}
            />
        </Col>
    );
};

export const TabView: FC<ITabViewProps> = ({ tabs, activeTabIndex }) => {
    const classes = useTabsStyles();
    return (
        <Row className={classes.mtb2} justify='center'>
            {tabs.map((tab, i) => (
                <Tab key={tab.id} activeTabIndex={activeTabIndex} index={i} tabs={tabs} />
            ))}
        </Row>
    );
};
