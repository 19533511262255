import { createUseStyles } from 'react-jss';

import { GLOBAL_THEME } from '../../constants/theme';
import type { ICustomTheme } from '../../types/theme';

export const useFormElementsStyles = createUseStyles((theme: ICustomTheme) => ({
    formItem: {
        '& .ant-form-item-label > label': {
            '& p': {
                '& strong': {
                    color: GLOBAL_THEME.requiredStarColor
                },

                marginBottom: 0
            },

            color: theme.formItemLabelColor,
            fontSize: '13px',
            fontWeight: 600,
            letterSpacing: '0.04em',
            lineHeight: '18px'
        },
        '& .ant-input': {
            '&:focus': {
                border: `1px solid ${theme.formInputHoverBorderColor}`,
                boxShadow: 'none'
            },

            '&:hover': {
                border: `1px solid ${theme.formInputHoverBorderColor}`
            },

            border: `1px solid ${GLOBAL_THEME.formInputBorderColor}`,
            borderRadius: '6px',
            color: GLOBAL_THEME.inputFontColor
        },
        '& .ant-picker': {
            '& .ant-picker-clear': {
                color: theme.formInputHoverBorderColor
            },

            '& .ant-picker-input': {
                '& input': {
                    color: GLOBAL_THEME.inputFontColor
                }
            },

            '& .ant-picker-suffix ': {
                color: theme.formInputHoverBorderColor,
                cursor: 'pointer',
                pointerEvents: 'inherit'
            },

            '&:focus': {
                border: `1px solid ${theme.formInputHoverBorderColor}`,
                boxShadow: 'none'
            },

            '&:hover': {
                border: `1px solid ${theme.formInputHoverBorderColor}`
            },

            border: `1px solid ${GLOBAL_THEME.formInputBorderColor}`,
            borderRadius: '6px',
            color: GLOBAL_THEME.inputFontColor,
            display: 'block'
        },

        '& .ant-radio-checked .ant-radio-inner': {
            '&::after': {
                backgroundColor: theme.formInputHoverBorderColor,
                transform: 'scale(0.6)'
            },

            '&:hover': {
                border: `1px solid ${theme.formInputHoverBorderColor}`
            },
            backgroundColor: 'white',
            border: `1px solid ${theme.formInputHoverBorderColor}`
        },

        '& .ant-radio-inner': {
            '&:hover': {
                border: `1px solid ${theme.formInputHoverBorderColor}`
            },
            padding: '10px'
        },

        '& .ant-radio-wrapper': {
            marginRight: '30px'
        },

        '& .ant-select-arrow': {
            color: theme.iconColor
        },

        '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector':
            {
                borderRadius: '6px',
                boxShadow: 'none'
            },

        '& .ant-select-selector': {
            '& .ant-select-selection-item': {
                color: GLOBAL_THEME.inputFontColor,
                lineHeight: '38px'
            },

            '&:hover': {
                border: `1px solid ${theme.formInputHoverBorderColor} !important`
            },

            border: `1px solid ${GLOBAL_THEME.formInputBorderColor} !important`,
            borderRadius: '6px !important'
        },

        '& .business-loss-radio-btn': {
            '& .ant-form-item': {
                top: '0 !important'
            }
        },

        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            margin: 0,
            webkitAppearance: 'none'
        },

        'input[type=number]': {
            mozAppearance: 'textfield'
        }
    },
    labelSuffix: {
        color: '#3a4b5ec7',
        fontSize: '10px',
        marginLeft: '3px'
    },
    materialFormItem: {
        '& .ant-form-item-control-input-content': {
            '&:before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                bottom: '0',
                content: '"\\00a0"',
                left: '0',
                pointerEvents: 'none',
                position: 'absolute',
                right: '0',
                transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
            },
            '&:hover:before': {
                borderBottom: '2px solid rgba(0, 0, 0, 0.87)'
            }
        },
        '& .ant-form-item-label': {
            '& > label ': {
                '& p': {
                    '& strong': {
                        color: GLOBAL_THEME.requiredStarColor
                    },

                    marginBottom: 0
                },

                color: theme.formItemLabelColor,
                fontSize: '13px',
                fontWeight: 600,
                letterSpacing: '0.04em',
                lineHeight: '18px'
            },
            padding: '0'
        },

        '& .ant-form-vertical .ant-form-item-label': {
            padding: '0'
        },

        '& .ant-input': {
            '&.ant-input-status-error:not(.ant-input-borderless):focus': {
                boxShadow: 'none'
            },
            '&:focus-visible': {
                outline: 'none'
            },
            '&:hover,&:focus': {
                border: 'none',
                borderBottom: `1px solid ${GLOBAL_THEME.formInputHoverBorderColor}`,
                boxShadow: 'none',
                transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
            },
            border: 'none',
            borderBottom: `1px solid ${GLOBAL_THEME.formInputBorderColor}`,
            borderRadius: '0',
            color: GLOBAL_THEME.inputFontColor
        },

        '& .ant-input-affix-wrapper': {
            '& .ant-input:hover, & .ant-input:focus': {
                border: 'none !important'
            },
            '&-status-error:not(.ant-input-affix-wrapper-borderless)': {
                '&.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused': {
                    border: 'none',
                    boxShadow: 'none'
                }
            },
            '&:focus, &-focused': {
                border: 'none',
                boxShadow: 'none'
            },
            background: 'none',
            border: 'none',
            fontSize: '16px',
            lineHeight: '1.4',
            padding: '9px 0'
        },

        '& .ant-input-suffix': {
            color: 'rgba(0, 0, 0, 0.25)'
        },

        '& .ant-picker': {
            '& .ant-picker-clear': {
                color: theme.formInputHoverBorderColor
            },

            '& .ant-picker-input': {
                '& input': {
                    color: GLOBAL_THEME.inputFontColor
                }
            },

            '& .ant-picker-suffix ': {
                color: theme.formInputHoverBorderColor,
                cursor: 'pointer',
                pointerEvents: 'inherit'
            },

            '&-focused': {
                boxShadow: 'none'
            },
            // '&:focus': {
            //     border: `1px solid ${theme.formInputHoverBorderColor}`,
            //     boxShadow: 'none'
            // },
            '&:hover, &:focus': {
                border: 'none',
                borderBottom: `1px solid ${GLOBAL_THEME.formInputBorderColor}`,
                boxShadow: 'none'
            },
            border: 'none',
            borderBottom: `1px solid ${GLOBAL_THEME.formInputBorderColor}`,
            borderRadius: '0',
            color: GLOBAL_THEME.inputFontColor,
            display: 'block'
        },

        '& .ant-radio-checked .ant-radio-inner': {
            '&::after': {
                backgroundColor: theme.formInputHoverBorderColor
            },

            '&:hover': {
                border: `1px solid ${theme.formInputHoverBorderColor}`
            },

            border: `1px solid ${theme.formInputHoverBorderColor}`
        },

        '& .ant-radio-inner': {
            '&:after': {
                transform: 'scale(0.8)'
            },
            '&:hover': {
                border: `1px solid ${theme.formInputHoverBorderColor}`
            },
            padding: '10px'
        },

        '& .ant-radio-wrapper': {
            marginRight: '30px'
        },

        '& .ant-select-arrow': {
            color: theme.iconColor
        },

        // '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector':{
        //     borderRadius: '6px',
        //     boxShadow: 'none'
        // },
        '& .ant-select-selector': {
            '& .ant-select-selection-item': {
                color: GLOBAL_THEME.inputFontColor,
                lineHeight: '38px'
            },

            // '&:hover': {
            //     border: `1px solid ${theme.formInputHoverBorderColor} !important`
            // },

            border: 'none',
            borderBottom: `1px solid ${GLOBAL_THEME.formInputBorderColor}`,
            borderRadius: '0'
        },

        '& .ant-select:not(.ant-select-customize-input)': {
            '& .ant-select-selector': {
                '&:hover, &:focus': {
                    border: 'none',
                    borderBottom: `1px solid ${GLOBAL_THEME.formInputBorderColor}`,
                    borderRadius: '0',
                    boxShadow: 'none'
                },
                border: 'none',
                borderBottom: `1px solid ${GLOBAL_THEME.formInputBorderColor}`
            },
            '&.ant-select-focused:not(.ant-select-disabled):not(.ant-pagination-size-changer)': {
                '& .ant-select-selector': {
                    boxShadow: 'none'
                }
            }
        },

        '& .business-loss-radio-btn': {
            '& .ant-form-item': {
                top: '0 !important'
            }
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            margin: 0,
            webkitAppearance: 'none'
        },
        'input[type=number]': {
            mozAppearance: 'textfield'
        }
    },
    stripeFormItem: {
        '& .StripeElement': {
            display: 'inline-block',
            paddingLeft: '10px',
            position: 'relative',
            verticalAlign: '-0.125em',
            width: '80%'
        },
        border: `1px solid ${GLOBAL_THEME.formInputBorderColor}`,
        borderRadius: '6px',
        fontSize: '16px',
        lineHeight: '1.4',

        padding: '9px 20px'
    }
}));
