import { CarrierTypes } from '../../constants';
import type { TCreateQuestionnaireActions } from '../../types/reduxTypes';
import type * as RT from '../../types/reduxTypes/rates';
import * as actType from '../actionTypes/rates';
import * as questionnaireActType from '../actionTypes/coterieQuestionnaire';

const initState: RT.IRatesState = {
    cnaStatus: 'idle',
    coterieBindStatus: 'idle',
    data: {
        quotesList: []
    },
    error: null,
    status: 'idle'
};

export const ratesReducer = (
    state = initState,
    action: RT.TRatesActions | TCreateQuestionnaireActions
): RT.IRatesState => {
    switch (action.type) {
        case actType.GET_COTERIE_RATES_REQUEST:
            return {
                ...state
            };
        case actType.SET_QUOTE: {
            const quotesList = [...state.data.quotesList];
            const quoteIdx = quotesList.findIndex(
                (quote) =>
                    action.payload.source === quote.source ||
                    (!quote.source && action.payload.source === CarrierTypes.COTERIE)
            );
            if (quoteIdx >= 0) {
                quotesList[quoteIdx] = action.payload;
            } else {
                quotesList.push(action.payload);
            }
            return {
                ...state,
                cnaStatus: action.payload.source === CarrierTypes.CNA ? 'loaded' : state.cnaStatus,
                data: {
                    ...state.data,
                    quotesList
                },
                status: action.payload.source === CarrierTypes.COTERIE ? 'loaded' : state.status
            };
        }
        case actType.SET_RATES_STATE:
        case actType.CANCEL_CNA_RATES_POLLING:
            return {
                ...state,
                ...action.payload
            };
        case actType.COTERIE_BIND_POLICY:
            return { ...state, coterieBindStatus: 'pending' };
        case actType.COTERIE_BIND_POLICY_SUCCESS: {
            const quotesList = [...state.data.quotesList];
            const coterieQuoteIdx = quotesList.findIndex(
                (value) => !value.source || value.source === CarrierTypes.COTERIE
            );
            if (coterieQuoteIdx >= 0) {
                quotesList[coterieQuoteIdx].isPolicyBound = true;
                quotesList[coterieQuoteIdx].policyNumber = action.payload;
            }
            return {
                ...state,
                coterieBindStatus: 'loaded',
                data: { ...state.data, quotesList }
            };
        }
        case actType.COTERIE_BIND_POLICY_FAILED:
            return { ...state, coterieBindStatus: 'failed' };

        case questionnaireActType.UPDATE_QUESTIONNAIRE_SUCCESS:
            if (action.clearRates) {
                return { ...state, data: { quotesList: [] } };
            }
            return { ...state };
        default:
            return { ...state };
    }
};
