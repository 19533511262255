import { Button, Checkbox, Divider, Form, Modal, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useForm } from 'antd/lib/form/Form';
import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CARRIERS,
    CarrierTypes,
    LOB_OPTIONS,
    mockUrlData,
    validateMessages
} from '../../constants';
import type { IFCOpts, INewQuoteModalProps } from '../../types';
import type { PolicyTypes } from '../../types/reduxTypes';
import { encryptData } from '../../utils';

const isLOBAllowed = (
    allowedCarriers: Array<CarrierTypes>,
    selectedCarriers: Array<CarrierTypes>
) => {
    if (!selectedCarriers.length) return false;
    for (const carrier of selectedCarriers) {
        if (!allowedCarriers.includes(carrier)) {
            return false;
        }
    }
    return true;
};

export const NewQuoteModal: FC<INewQuoteModalProps> = ({ modalVisible, setModalVisible }) => {
    const [selectedCarrier, setSelectedCarrier] = useState({
        [CarrierTypes.CNA]: false,
        [CarrierTypes.COTERIE]: false
    });
    const [coterieOptions, setCoterieOptions] = useState<
        Partial<IFCOpts> & { product: Array<PolicyTypes> }
    >({
        ...mockUrlData,
        product: []
    });
    const navigate = useNavigate();
    const [form] = useForm();

    const handleCarrierCheck = (event: CheckboxChangeEvent, carrierId: CarrierTypes) => {
        setSelectedCarrier({ ...selectedCarrier, [carrierId]: event.target.checked });
    };

    const handleOptionCheck = (event: CheckboxChangeEvent, optionId: PolicyTypes) => {
        if (event.target.checked)
            setCoterieOptions({
                ...coterieOptions,
                product: [optionId]
            });
        else {
            setCoterieOptions({ ...coterieOptions, product: [] });
        }
    };
    const carriersSelected: Array<CarrierTypes> = [];
    Object.entries(selectedCarrier).forEach(([key, value]) => {
        if (value) {
            carriersSelected.push(key as CarrierTypes);
        }
    });
    const handleGetQuote = () => {
        navigate(
            `/applicant/${encryptData(
                JSON.stringify({ ...coterieOptions, carriers: carriersSelected })
            )}`
        );
    };

    return (
        <Modal footer={null} onCancel={() => setModalVisible(false)} open={modalVisible}>
            <Form
                form={form}
                layout='horizontal'
                onFinish={handleGetQuote}
                validateMessages={validateMessages}>
                <Row justify='center'>
                    {CARRIERS.map((carrier) => (
                        <Checkbox
                            key={carrier.id}
                            checked={selectedCarrier[carrier.id]}
                            onChange={(e) => {
                                handleCarrierCheck(e, carrier.id);
                            }}>
                            {carrier.name}
                        </Checkbox>
                    ))}
                </Row>

                <Divider>Options</Divider>

                {LOB_OPTIONS.map(
                    (option) =>
                        isLOBAllowed(option.carriers, carriersSelected) && (
                            <Row key={option.id} justify='start'>
                                <Checkbox
                                    key={option.id}
                                    checked={coterieOptions.product[0] === option.id}
                                    onChange={(e) => {
                                        handleOptionCheck(e, option.id);
                                    }}>
                                    {option.name}
                                </Checkbox>
                            </Row>
                        )
                )}

                <Row justify='center'>
                    <Button
                        disabled={coterieOptions.product.length === 0}
                        htmlType='submit'
                        size='small'
                        type='primary'>
                        Fetch Quotes
                    </Button>
                </Row>
            </Form>
        </Modal>
    );
};
