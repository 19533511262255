import type { Method } from 'axios';
import axios from 'axios';
import { config } from '../configs';

export const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
    timeout: config.requestTimeout
});

export const axiosRequest = async (method: Method, url: string, data?: any) =>
    axiosInstance
        .request({
            data,
            method,
            url
        })
        .then((response) => ({ response }))
        .catch((error) => ({ error }));
