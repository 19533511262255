import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './reducers';
import { rootSaga } from './sagas';

export const configStore = (initState?: any) => {
    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore({
        devTools: process.env.REACT_APP_ENV !== 'production',
        middleware: [sagaMiddleware],
        preloadedState: initState,
        reducer: rootReducer
    });

    sagaMiddleware.run(rootSaga);
    return store;
};
