const development = {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3000/dev',
    coterieApiUrl:
        process.env.REACT_APP_COTERIE_API_URL ?? 'https://api-sandbox.coterieinsurance.com',
    cryptoKey: process.env.REACT_APP_CRYPTO_KEY ?? 'RmP97UjxH5AgZMxz',
    enableCoterieBind: process.env.REACT_APP_ENABLE_COTERIE_BIND === 'true',
    fcDashboard: 'https://portal.firstconnect-dev.myhippo.io/app/producer/dashboard',
    googleAutoCompleteKey:
        process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_KEY ?? 'AIzaSyBCzqCo-fFDTIfUCo-O3YtCGa3GXQWKKgE',
    requestTimeout: 35000,
    stripePublicKey:
        process.env.REACT_APP_STRIPE_PUBLIC_KEY ??
        'pk_test_51IF1byI0JrvxM1u0FBkUF8g2oOOa5SfHjsnKXyCtj1Q9PaNKiUn4oLKIl7qGYzQCfzEmoON64raEl5FkhS0ys2OH00Nk39quBh'
};
export default development;
