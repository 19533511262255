import moment from 'moment';
import type * as RT from '../../types/reduxTypes/coterieQuestionnaire';
import * as actTypes from '../actionTypes/coterieQuestionnaire';

export const questionnaireInitState: RT.ICoterieQuestionnaireState = {
    data: {
        answers: [],
        applicantInfo: {
            akHash: '',
            businessName: '',
            city: '',
            email: '',
            firstName: '',
            isMailingAddressSame: true,
            lastName: '',
            mailingCity: '',
            mailingState: '',
            mailingStreet: '',
            mailingUnit: '',
            mailingZipCode: '',
            phoneNumber: '',
            state: '',
            street: '',
            unit: '',
            zipCode: ''
        },
        boCoverageInfo: {
            buildingLimit: '',
            propertyDeductible: '',
            propertyLimit: ''
        },
        businessInfo: {
            annualPayroll: '',
            basementCount: '',
            buildingArea: '',
            businessStartDate: null,
            constructionType: '',
            distanceToFirestation: '',
            distanceToHydrant: '',
            grossAnnualSales: '',
            hasSprinklerSystem: false,
            locationType: '',
            numberOfEmployees: '',
            roofingImprovementYear: '',
            sprinklerSystem: false,
            stories: '',
            wiringImprovementYear: '',
            yearBuilt: ''
        },
        carriers: [],
        classCode: '',
        clientId: '',
        cnaAgentId: '',
        cnaProducerId: '',
        coterieAgencyId: '',
        coterieProducerId: '',
        glCoverageInfo: {
            generalLiabilityLimit: '',
            propertyDamageLiabilityDeductible: ''
        },
        id: '',
        lossInfo: {
            hadLossesForPreviousYears: false,
            losses: [{ amount: '', description: '', lossDate: '', reason: '' }]
        },
        plCoverageInfo: {
            areCertificationsRequired: false,
            deductible: '',
            doesApplicantMaintainCertifications: false,
            grossAnnualRevenue: '',
            occurrenceLimit: '',
            yearsOfExperience: ''
        },
        policyInfo: {
            akHash: '',
            industryId: 0,
            policyStartDate: moment().add(1, 'day')
        },
        policyTypes: [],
        producerCode: '',
        producerEmail: '',
        producerId: '',
        status: 'in-progress'
    },
    error: null,
    status: 'idle'
};

export const coterieQuestionnaireReducer = (
    state = questionnaireInitState,
    action: RT.TCreateQuestionnaireActions
): RT.ICoterieQuestionnaireState => {
    switch (action.type) {
        case actTypes.SET_APPLICANT_INFO:
            return {
                ...state,
                data: {
                    ...state.data,
                    applicantInfo: {
                        ...state.data.applicantInfo,
                        ...action.payload
                    }
                }
            };
        case actTypes.SET_BOP_COVERAGE_INFO:
            return {
                ...state,
                data: {
                    ...state.data,
                    boCoverageInfo: {
                        ...state.data.boCoverageInfo,
                        ...action.payload
                    }
                }
            };
        case actTypes.SET_COTERIE_QUESTIONNAIRE_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            };
        case actTypes.SET_COTERIE_QUESTIONNAIRE_STATE:
            return {
                ...state,
                ...action.payload
            };
        case actTypes.SET_GL_COVERAGE_INFO:
            return {
                ...state,
                data: {
                    ...state.data,
                    glCoverageInfo: {
                        ...state.data.glCoverageInfo,
                        ...action.payload
                    }
                }
            };
        case actTypes.SET_LOSS_INFO:
            return {
                ...state,
                data: {
                    ...state.data,
                    lossInfo: {
                        ...state.data.lossInfo,
                        ...action.payload
                    }
                }
            };
        case actTypes.SET_PL_COVERAGE_INFO:
            return {
                ...state,
                data: {
                    ...state.data,
                    plCoverageInfo: {
                        ...state.data.plCoverageInfo,
                        ...action.payload
                    }
                }
            };
        case actTypes.CREATE_QUESTIONNAIRE_REQUEST:
        case actTypes.GET_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                status: 'pending'
            };
        case actTypes.CREATE_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    id: action.payload.id
                },
                status: 'created'
            };
        case actTypes.CREATE_QUESTIONNAIRE_FAILURE:
        case actTypes.GET_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                error: action.payload.error,
                status: 'failed'
            };
        case actTypes.UPDATE_QUESTIONNAIRE_REQUEST:
            return { ...state, status: 'pending' };
        case actTypes.UPDATE_QUESTIONNAIRE_SUCCESS:
            return { ...state, status: 'updated' };
        case actTypes.UPDATE_QUESTIONNAIRE_FAILURE:
            return { ...state, error: action.payload.error, status: 'failed' };
        case actTypes.GET_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                },
                status: 'loaded'
            };
        default:
            return { ...state };
    }
};
