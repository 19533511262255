import { Form, Input } from 'antd';
import type { Rule } from 'rc-field-form/lib/interface';
import type { ChangeEvent, FC, FocusEvent } from 'react';
import { useTheme } from 'react-jss';
import validator from 'validator';

import { validateMessages } from '../../constants';
import type { ICustomTheme, IFormInputTextProps } from '../../types';
import { ALPHANUM_TEST_REGEX, DIGIT_FILTER_REGEX, NAME_TEST_REGEX } from '../../utils';
import { useFormElementsStyles } from './formElementsStyles';

const { Item } = Form;

export const FormInputText: FC<IFormInputTextProps> = ({
    label,
    name,
    required = true,
    formInstance,
    type = 'text',
    variant = 'default',
    capitalize = 'first',
    maxChar,
    minChar
}) => {
    const theme = useTheme<ICustomTheme>();
    const classes = useFormElementsStyles({ theme });
    const rules: Array<Rule> = [{ message: validateMessages.required, required }];

    if (variant === 'alphanum') {
        rules.push({
            validator: (_, value: string, cb) => {
                const word = value.replace(/\s/u, '');
                if (ALPHANUM_TEST_REGEX.test(word)) cb(validateMessages.invalid);
                else cb();
            }
        });
    } else if (variant === 'name') {
        rules.push({
            validator: (_, value: string, cb) => {
                const word = value.replace(/\s/u, '');
                if (NAME_TEST_REGEX.test(word)) cb();
                else cb(validateMessages.invalid);
            }
        });
    }

    if (typeof minChar === 'number') {
        rules.push({
            validator: (_, value: string, cb) => {
                if (value.length < minChar) cb(validateMessages.short);
                else cb();
            }
        });
    }

    if (type === 'email') {
        rules.push({
            validator: (_, value: string, cb) => {
                if (validator.isEmail(value)) {
                    cb();
                } else {
                    cb(validateMessages.types.email);
                }
            }
        });
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal = '';
        if (variant === 'num') {
            newVal = e.target.value.replace(DIGIT_FILTER_REGEX, '').slice(0, maxChar);
        } else {
            newVal = e.target.value.trimStart().slice(0, maxChar);
        }
        formInstance.setFieldsValue({
            [name[0]]: {
                [name[1]]: newVal
            }
        });
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        let newVal = '';
        if (type !== 'email') {
            if (capitalize === 'first') {
                const targetVal = e.target.value.trimEnd();
                newVal = targetVal.charAt(0).toUpperCase() + targetVal.slice(1);
            } else {
                const words = e.target.value.trimEnd().split(/\s/u);
                const capitalizedWords: Array<string> = [];
                words.forEach((word) => {
                    if (word !== '')
                        capitalizedWords.push(word[0].toUpperCase() + word.slice(1, word.length));
                });
                newVal = capitalizedWords.join(' ');
            }
        } else {
            newVal = e.target.value.trim();
            // below two statements are written intentionally.
            // https://stackoverflow.com/questions/38901062/cannot-trim-whitespace-on-email-input
            e.target.value = '';
            e.target.value = newVal;
        }
        formInstance.setFieldsValue({
            [name[0]]: {
                [name[1]]: newVal
            }
        });
    };

    return (
        <Item
            className={classes.materialFormItem}
            label={
                (required && (
                    <p>
                        {label}

                        <strong>*</strong>
                    </p>
                )) ||
                label
            }
            name={name}
            rules={rules}
            validateFirst
            validateTrigger='onBlur'>
            <Input onBlur={handleBlur} onChange={handleChange} type={type ?? 'text'} />
        </Item>
    );
};
