import { CloseCircleOutlined } from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'antd';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PolicyIssuedGif from '../../assets/images/policy-issued.gif';
import ProcessingPaymentGif from '../../assets/images/processing-payment.gif';
import { config } from '../../configs';
import useRatesStyles from '../../containers/Rates/ratesStyles';
import { bindPolicy } from '../../redux/actions';
import { axiosInstance } from '../../utils';
import { StripeCardForm } from './StripeCardForm';

interface StripeChargeModalProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    quoteId: string;
    coterieAgencyId: string;
    coterieProducerId: string;
    coterieBindStatus?: 'failed' | 'idle' | 'loaded' | 'pending';
    isPolicyBound?: boolean;
}

interface BindResponse {
    data: {
        isSuccess?: boolean;
        errors?: Array<string>;
        policies?: Array<{
            policyNumber: string;
        }>;
    };
}

export const StripeChargeModal: FC<StripeChargeModalProps> = ({
    showModal,
    setShowModal,
    quoteId,
    coterieAgencyId,
    coterieBindStatus,
    coterieProducerId,
    isPolicyBound
}) => {
    const stripePromise = loadStripe(config.stripePublicKey);
    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const classes = useRatesStyles();

    const sendBindRequest = async (token: string) => {
        setIsProcessing(true);
        const { data }: BindResponse = await axiosInstance.post(
            `${config.coterieApiUrl}/v1.3/commercial/quotes/${quoteId}/bind?agencyExternalId=${coterieAgencyId}`,
            {
                paymentInterval: 'None',
                tokenizedPaymentID: token
            },
            {
                headers: {
                    Authorization: `token producer_${coterieProducerId}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        if (data.isSuccess) {
            dispatch(bindPolicy(data.policies?.[0]?.policyNumber ?? ''));
        } else {
            setError(`Error Binding Policy: ${data?.errors?.join(',') ?? ''}`);
        }
        setIsProcessing(false);
    };

    const isLoading = isProcessing || coterieBindStatus === 'pending';

    const onCancel = () => {
        setError('');
        setIsProcessing(false);
        setIsSubmitted(false);
        setShowModal(false);
    };

    return (
        <Elements stripe={stripePromise}>
            <Modal
                className={classes.paymentModal}
                closable={!isLoading}
                closeIcon={<CloseCircleOutlined />}
                footer={null}
                maskClosable={false}
                onCancel={onCancel}
                open={showModal}>
                {!isSubmitted && (
                    <StripeCardForm
                        sendBindRequest={sendBindRequest}
                        setError={setError}
                        setIsSubmitted={setIsSubmitted}
                    />
                )}
                {isLoading ? (
                    <img
                        alt='Processing Policy'
                        className={classes.processing}
                        src={ProcessingPaymentGif}
                    />
                ) : (
                    <div className={classes.policyIssued}>
                        {isPolicyBound ? (
                            <img alt='Policy Issued' src={PolicyIssuedGif} />
                        ) : (
                            <h2 style={{ color: '#ff4d4f', textAlign: 'center' }}>{error}</h2>
                        )}
                    </div>
                )}
            </Modal>
        </Elements>
    );
};

StripeChargeModal.defaultProps = { coterieBindStatus: 'idle', isPolicyBound: false };
