import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Row, Spin, Table, Typography } from 'antd';

import { NewQuoteModal } from '../../components';

const { Title } = Typography;

const Home: FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const TableHeader = () => (
        <Row>
            <Col span={12}>
                <Title level={4}>Quotes</Title>
            </Col>

            <Col span={12}>
                <Button
                    onClick={() => setModalVisible(true)}
                    style={{ float: 'right' }}
                    type='primary'>
                    + Create Quote
                </Button>

                <NewQuoteModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
            </Col>
        </Row>
    );

    return (
        <Spin spinning={isLoading}>
            <Row>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{
                            defaultPageSize: 5,
                            locale: { items_per_page: 'rows' },
                            pageSizeOptions: ['5', '10', '20'],
                            showSizeChanger: true
                        }}
                        scroll={{ x: '100%' }}
                        title={TableHeader}
                    />
                </Col>
            </Row>
        </Spin>
    );
};
export default Home;
