import { createUseStyles } from 'react-jss';

import { GLOBAL_THEME } from '../../constants/theme';
import type { ICustomTheme } from '../../types/theme';

export const useQuestionnaireStyles = createUseStyles((theme: ICustomTheme) => ({
    addButton: {
        '& .ant-btn-circle': {
            backgroundColor: theme.formInputHoverBorderColor,
            border: `1px solid ${theme.formInputHoverBorderColor}`,
            color: 'white'
        },

        '& .ant-btn-primary': {
            backgroundColor: theme.cardBorderHoverColor,
            border: `1px solid ${theme.cardBorderHoverColor}`
        },

        '& .ant-btn-round': {
            color: theme.formInputHoverBorderColor
        },

        display: 'flex',
        justifyContent: 'flex-end'
    },

    backBtn: {
        '&:hover': {
            background: GLOBAL_THEME.visitedTabIconColor,
            border: `1px solid ${theme.formInputHoverBorderColor}`,
            color: theme.cardBorderHoverColor
        },

        background: GLOBAL_THEME.visitedTabIconColor,
        color: `${theme.productFontColor} !important`,
        fontSize: '14px',
        fontWeight: 500,
        height: '45px',
        margin: '30px 20px',
        paddingInline: '20px',
        width: '110px'
    },

    buildingSpinkerRadio: {
        '& .ant-form-item-label': {
            '& label': {
                marginRight: '20px'
            }
        },

        '& .ant-radio-wrapper': {
            marginRight: '30px'
        },

        '@media(minWidth:768px) and (maxWidth:1245px)': {
            top: '0'
        },

        top: '20px'
    },

    businessLossLabel: {
        color: theme.formItemLabelColor,
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '18px'
        // textTransform: 'uppercase'
    },

    businessOwnerRow: {
        '& .ant-form-item-label': {
            minHeight: '50px'
        }
    },

    carrierCheckbox: {
        '& .ant-checkbox': {
            position: 'absolute',
            right: '20px',
            top: '20px'
        },
        '& > span:not(.ant-checkbox)': {
            height: 'inherit'
        },
        '& img': {
            height: 'auto',
            width: '80%'
        },
        border: `1px solid ${GLOBAL_THEME.cardBorderColorSecondary}`,
        borderRadius: '8px',
        height: '180px',
        padding: '20px',
        position: 'relative',
        width: '250px'
    },

    carrierSelector: {
        display: 'block',
        margin: '320px auto auto',
        maxWidth: '40%'
    },

    center: {
        left: '50%',
        margin: '0',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },

    continueBtn: {
        '&.disabled': {
            background: GLOBAL_THEME.disableTextColor,
            borderColor: 'transparent',
            color: `${GLOBAL_THEME.visitedTabIconColor} !important`
        },
        '&:hover, &:focus, &:active': {
            background: theme.unVisitedTabIconColor,
            borderColor: 'transparent',
            color: `${GLOBAL_THEME.visitedTabIconColor} !important`
        },
        background: theme.unVisitedTabIconColor,
        borderColor: 'transparent',
        borderRadius: '50px',
        color: GLOBAL_THEME.visitedTabIconColor,
        height: '45px',
        margin: '30px 0',
        width: '110px'
    },

    form: {
        '& .ant-input, & .ant-picker, & .ant-select-selection-search-input': {
            fontSize: '16px',
            lineHeight: '1.4',
            padding: '9px 0'
        },
        '& .ant-select .ant-select-selector': {
            height: '42px'
        },
        border: 'none',
        marginInline: '10%'
    },

    generalLiabilityLimit: {
        '& .ant-form-item-control': {
            marginTop: '17px'
        },
        '@media(min-width:768px) and (max-width: 1702px)': {
            '& label': {
                minHeight: '44px'
            }
        }
    },
    head2: {
        '@media(max-width:768px)': {
            fontSize: '18px',
            textAlign: 'center'
        },
        color: GLOBAL_THEME.formHeadingColor,
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '33px',
        marginBottom: '2em',
        marginTop: '1em'
    },

    head3: {
        color: theme.formSubHeadingColor,
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '25px',
        marginTop: '30px'
    },

    licence: {
        color: 'orange',
        fontSize: '15px',
        marginInline: '3px'
    },

    lossDeleteIcon: {
        '& .anticon-delete': {
            color: theme.iconColor
        },

        display: 'flex',
        justifyContent: 'flex-end'
    },

    lossHeading: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 600
    },

    lossItem: {
        border: `1px solid ${GLOBAL_THEME.borderColor}`,
        borderRadius: '6px',
        padding: '30px'
    },

    lossLabelHeading: {
        color: theme.formSubHeadingColor,
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '25px',
        marginBottom: 0
    },

    lossRadio: {
        '& .ant-form-item': {
            flexDirection: 'row'
        },

        '& .ant-form-item-label': {
            display: 'flex',
            paddingBottom: 0
        },

        alignItems: 'flex-end',
        display: 'flex'
    },

    mailingAddressSwitchWrapper: {
        '& .ant-switch': {
            marginBottom: '1em'
        },
        '& h3': {
            marginBottom: '1em',
            marginTop: '0 !important'
        },
        alignItems: 'center',
        display: 'flex',

        gap: '25px',

        marginTop: '30px'
    },

    mb0: {
        marginBottom: 0
    },

    mb15: {
        marginBottom: '15px'
    },

    mb24: {
        marginBottom: '24px'
    },

    mb8: {
        marginBottom: '8px'
    },

    provider: {
        color: '#737373',
        fontSize: '14px',
        fontWeight: 'normal',
        marginInline: '3px'
    },

    queLabel: {
        color: '#3a4b5e',
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '19px'
    },
    themeBackground: {
        backgroundColor: GLOBAL_THEME.mainBackgroundColor
    },
    yearsOFexpRow: {
        '& .ant-form-item-label': {
            '@media(min-width:768px) and (max-width:1400px)': {
                minHeight: '50px'
            }
        }
    }
}));
