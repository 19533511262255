export const literals = {
    ADDRESS: 'Address',
    ADD_LOSS: 'Add Loss',
    AMOUNT: 'Amount',
    ANNUALLY: 'Annually',
    ANNUAL_PAYROLL: 'Annual Payroll',
    APPLICANT: 'Applicant',
    APPLICANT_MAINTAIN_CERTIFICATIONS: 'DOES THE APPLICANT MAINTAIN THEIR CERTIFICATIONS?',
    BASEMENT_COUNT: 'Basement Count',
    BIND_ONLINE: 'Bind Online',
    BOP_COVERAGE: "Business Owner's Policy Coverage",
    BOX_AGGREGATE_PCO: 'Aggregate PCO',
    BOX_BUILDING: 'Building',
    BOX_GL: 'GL',
    BOX_GL_AGGREGATE: 'Aggregate',
    BOX_OCCURRENCE: 'Occurrence',
    BOX_PERSONAL_PROPERTY: 'Personal Property',
    BOX_PL_AGGREGATE: 'Aggregate',
    BPP_DEDUCTIBLE: 'Personal Property Deductible',
    BPP_LIMIT: 'Personal Property Limit',
    BRIDGE_TO_CARRIER: 'Bridge To Carrier',
    BUILDING_AREA: 'Building Area',
    BUILDING_HAVE_SPRINKLER: 'Sprinkler System',
    BUILDING_LIMIT: 'Building Limit',
    BUSINESS_DETAIL_INFORMATION: 'Business Detail Information',
    BUSINESS_HAD_LOSSES: 'Has the business had any losses in the previous 5 years?',
    BUSINESS_NAME: 'Business Name',
    BUSINESS_NAME_LABEL: 'Business Name',
    BUSINESS_START_DATE: 'Business Start Date',
    CANNOT_EMPTY: "This field can't be empty",
    CERTIFICATIONS_REQUIRED: 'ARE CERTIFICATIONS REQUIRED FOR THE PROFESSIONAL?',
    CITY: 'City',
    CLASS_CODE: 'Class Code',
    CLASS_CODE_ERROR: 'Class code has not been set',
    CLIENT_ID: 'clientId',
    CNA_GENERIC_ERROR:
        'CNA is unable to complete the quoting process due to some technical errors. Please contact CNA',
    CONSTRUCTION_TYPE: 'Construction Type',
    CONTACT_NAME: 'Contact Name',
    COVERAGE_INFORMATION: 'Coverages',
    DASHBOARD: 'Dashboard',
    DEDUCTIBLE: 'Deductible',
    DEDUCTIBLE_AMOUNT: 'Amount',
    DEDUCTIBLE_BUILDING: 'Building',
    DEDUCTIBLE_BUSINESS_PERSONAL_PROPERTY: 'Business personal property',
    DESCRIPTION: 'Description',
    DISCLOSURE:
        'Final price may change based on additional information provided. ' +
        'Any information provided outside this submission will not be reflected in the portal. ' +
        'This quote expires after 30 days. The effective date of your coverage cannot be before receipt of payment.',
    DISTANCE_TO_FIRESTATION: 'Distance to Fire Department',
    DISTANCE_TO_HYDRANT: 'Distance to Hydrant',
    EMAIL: 'Email',
    EMAIL_ADDRESS: 'Email Address',
    ENTER_APPLICANT_INFO: 'Applicant Information',
    ENTER_BUSINESS_INFO: 'Business Information',
    ENTER_COVERAGE_INFO: 'Enter the coverage information',
    ENTER_LOSS_INFO: 'Loss Information',
    ENTER_POLICY_INFO: 'Requested Policy',
    ERROR: 'We are having trouble verifying your property details. But we can still provide you with great quote options.',
    FIRST_NAME: 'First Name',
    GL_COVERAGE: 'Deductibles',
    GL_LIMIT: 'General Liability Limit',
    GROSS_ANNUAL_REVENUE: 'Gross Annual Revenue',
    GROSS_ANNUAL_SALES: 'Gross Annual Sales',
    INDUSTRY_CLASSIFICATION: 'Industry Classification',
    INVALID_PRODUCER_LICENCE: 'Invalid Producer License',
    LAST_NAME: 'Last Name',
    LOCATION_TYPE: 'Location Type',
    LOSS: 'Loss',
    LOSSES: 'Losses',
    LOSS_DATE: 'Loss Date',
    MAILING_ADDRESS_LABEL: 'Mailing Address',
    MAILING_CITY: 'Mailing City',
    MAILING_STATE: 'Mailing State',
    MAILING_STREET_ADDRESS: 'Mailing Street Address',
    MAILING_UNIT: 'Mailing Unit #',
    MAILING_ZIP_CODE: 'Mailing Zip Code',
    NO: 'No',
    NO_QUOTES_AVAILABLE: 'No quotes are available for this product',
    NUMBER_OF_EMPLOYEES: 'Number Of Employees',
    OCCURRENCE_LIMIT: 'Occurrence Limit',
    PAYMENT_GATEWAY: 'Payment Gateway',
    PHONE_NO: 'Phone Number',
    PHONE_NO_LABEL: 'Phone Number',
    PL_COVERAGE: 'Professional Liability Coverage',
    POLICY_BOUND: 'Policy Bound',
    POLICY_INFO: 'Policy Information',
    POLICY_START_DATE: 'Policy Start Date',
    PRICE: 'Price',
    PRIMARY_STATE: 'Primary State',
    PRODUCER_CODE: 'Producer Code',
    PRODUCER_EMAIL: 'Producer Email',
    PRODUCER_ID: 'Producer Id',
    PROPERTY_DAMAGE_LIABILITY_DEDUCTIBLE: 'Property Damage Liability Deductible',
    PROPERTY_DETAILS: 'Property Details',
    PROVIDED_BY: 'Insurance quotes provided by',
    QUESTION_ERRORS: 'Something went wrong when trying to get CNA questions.',
    REASON: 'Reason',
    ROOFING_IMPROVEMENT_YEAR: 'Roofing Improvement Year',
    SAME_AS_BUSINESS_ADDRESS: 'Same as Business Address',
    SELECT_PRODUCTS: 'Select one or more products to quote',
    STATE: 'State',
    STORIES: 'Stories',
    STREET_ADDRESS: 'Street Address',
    STRIPE_DETAILS_DISCLAIMER:
        "Fill in applicant's Credit or Debit Card information to finalize the quote and bind.",
    STRIPE_PROVIDE_DETAILS: 'Provide payment details',
    SUBMISSION_ERROR: 'Something went wrong. we are unable to proceed',
    SUBMIT_PAYMENT: 'SUBMIT PAYMENT',
    UNIT: 'Unit #',
    WIRING_IMPROVEMENT_YEAR: 'Wiring Improvement Year',
    YEARS_OF_PATC: 'YEARS OF PRIOR ACTS TO COVER',
    YEARS_OF_PE: 'YEARS OF PROFESSIONAL EXPERIENCE',
    YEAR_BUILT: 'Year Built',
    YES: 'Yes',
    ZIP_CODE: 'Zip Code'
};

export const addressTypes = {
    CITY: 'administrative_area_level_2',
    COUNTRY: 'us',
    LOCALITY: 'locality',
    ROUTE: 'route',
    STATE: 'administrative_area_level_1',
    STREET: 'street_number',
    TYPE: 'address',
    ZIP: 'postal_code'
};

export const validateMessages = {
    greaterThan0: 'Must be greater than 0',
    invalid: 'Invalid',
    required: 'Required',
    short: 'Too short',
    types: {
        date: 'Invalid date',
        email: 'Invalid email',
        phone: 'Invalid phone number'
    }
};

export const formStateMap = {
    amount: 'amount',
    annualPayroll: 'annualPayroll',
    applicantInfo: 'applicantInfo',
    areCertificationsRequired: 'areCertificationsRequired',
    basementCount: 'basementCount',
    boCoverageInfo: 'boCoverageInfo',
    buildingArea: 'buildingArea',
    buildingLimit: 'buildingLimit',
    businessInfo: 'businessInfo',
    businessName: 'businessName',
    businessStartDate: 'businessStartDate',
    city: 'city',
    classCode: 'classCode',
    constructionType: 'constructionType',
    deductible: 'deductible',
    description: 'description',
    distanceToFirestation: 'distanceToFirestation',
    distanceToHydrant: 'distanceToHydrant',
    doesApplicantMaintainCertifications: 'doesApplicantMaintainCertifications',
    email: 'email',
    firstName: 'firstName',
    generalLiabilityLimit: 'generalLiabilityLimit',
    glCoverageInfo: 'glCoverageInfo',
    grossAnnualRevenue: 'grossAnnualRevenue',
    grossAnnualSales: 'grossAnnualSales',
    hadLossesForPreviousYears: 'hadLossesForPreviousYears',
    hasSprinklerSystem: 'hasSprinklerSystem',
    industryId: 'industryId',
    lastName: 'lastName',
    locationType: 'locationType',
    lossDate: 'lossDate',
    lossInfo: 'lossInfo',
    losses: 'losses',
    mailingCity: 'mailingCity',
    mailingState: 'mailingState',
    mailingStreet: 'mailingStreet',
    mailingUnit: 'mailingUnit',
    mailingZipCode: 'mailingZipCode',
    numberOfEmployees: 'numberOfEmployees',
    occurrenceLimit: 'occurrenceLimit',
    phoneNumber: 'phoneNumber',
    plCoverageInfo: 'plCoverageInfo',
    policyInfo: 'policyInfo',
    policyStartDate: 'policyStartDate',
    primaryState: 'primaryState',
    propertyDamageLiabilityDeductible: 'propertyDamageLiabilityDeductible',
    propertyDeductible: 'propertyDeductible',
    propertyLimit: 'propertyLimit',
    reason: 'reason',
    roofingImprovementYear: 'roofingImprovementYear',
    state: 'state',
    stories: 'stories',
    street: 'street',
    unit: 'unit',
    wiringImprovementYear: 'wiringImprovementYear',
    yearBuilt: 'yearBuilt',
    yearsOfExperience: 'yearsOfExperience',
    yearsOfPriorActsToCover: 'yearsOfPriorActsToCover',
    zipCode: 'zipCode'
};

export const defaults = {
    CLIENT_ID: '1',
    PDLD: 250,
    PRODUCER_CODE: '',
    PRODUCER_EMAIL: '',
    PRODUCER_ID: '',
    PRODUCER_LICENCED_STATES: []
};

export const AnswerType = {
    INPUT: 'INPUT',
    MULTI_INPUT: 'MULTI_INPUT',
    MULTI_SELECT: 'MULTI_SELECT',
    SELECT: 'SELECT'
};

export const InputType = {
    DECIMAL: 'DECIMAL',
    INTEGER: 'INTEGER',
    TEXT: 'TEXT'
};

export const WorkflowControlType = {
    CONTINUE: 'CONTINUE',
    STOP: 'STOP',
    VALIDATE: 'VALIDATE'
};

export const ActionStatuses = {
    ACCEPT: 'ACCEPT',
    ACTIVE: 'ACTIVE',
    DECLINE: 'DECLINE',
    INVALID: 'INVALID',
    REFERRAL: 'REFERRAL',
    UNKNOWN: 'UNKNOWN'
};
