import { all, call, delay, put, race, select, take, takeLatest } from '@redux-saga/core/effects';
import type { AxiosResponse } from 'axios';
import { CarrierTypes } from '../../constants';
import type { IBindPolicy, ICoterieQuestionnaireData, IQuote } from '../../types/reduxTypes';
import { axiosRequest } from '../../utils';
import * as actTypes from '../actionTypes/rates';
import { bindPolicyFailed, bindPolicySuccess, setQuote, setRatesState } from '../actions';
import { getIdAndCarriers, getQuestionnaireId } from '../selectors/coterieQuestionnaire';

function* getCoterieRates() {
    const { id }: Partial<ICoterieQuestionnaireData> = yield select(getIdAndCarriers);

    if (id) {
        yield put(setRatesState({ status: 'pending' }));

        const { response, error }: { response: AxiosResponse; error: any } = yield call(
            axiosRequest,
            'GET',
            `coterie/quote-result/${id}`
        );
        if (response) {
            yield put(setQuote(response.data));
        } else {
            yield put(
                setQuote({
                    error: [error?.response?.data ?? error?.message],
                    isSuccess: false,
                    policyType: 'BOP',
                    source: CarrierTypes.COTERIE
                })
            );
        }
        yield put(setRatesState({ status: 'loaded' }));
    }
}

const POLLING_DELAY = 3000;
export function* cnaRatesPollingWorker() {
    const id: string = yield select(getQuestionnaireId);
    while (true) {
        try {
            const { response } = (yield call(axiosRequest, 'GET', `cna/quote-result/${id}`)) as {
                response: AxiosResponse<{ quote?: IQuote }>;
            };

            if (response.data.quote) {
                yield put(setQuote(response.data.quote));
                yield put({ type: actTypes.CANCEL_CNA_RATES_POLLING });
                yield put(setRatesState({ cnaStatus: 'loaded' }));
            }
            yield delay(POLLING_DELAY);
        } catch (error) {
            yield put({ type: actTypes.CANCEL_CNA_RATES_POLLING });
            yield put(
                setQuote({
                    error: [
                        (error as Error).message || 'Something went wrong while loading your quote'
                    ],
                    isSuccess: false,
                    policyType: 'BOP',
                    source: CarrierTypes.CNA
                })
            );
        }
    }
}

function* cnaRatesWatchWorker() {
    const { timeout } = yield race({
        cancel: take(actTypes.CANCEL_CNA_RATES_POLLING),
        task: call(cnaRatesPollingWorker),
        timeout: delay(90 * 1000) // 90 sec
    });
    if (timeout) {
        yield put(
            setQuote({
                error: ['Unable to load CNA Quote within allowed time.'],
                isSuccess: false,
                policyType: 'BOP',
                source: CarrierTypes.CNA
            })
        );
        yield put(setRatesState({ cnaStatus: 'loaded' }));
        yield put({ type: actTypes.CANCEL_CNA_RATES_POLLING });
    }
}

function* pollCNARates() {
    const { carriers, id }: Partial<ICoterieQuestionnaireData> = yield select(getIdAndCarriers);

    if (id && carriers?.includes(CarrierTypes.CNA)) {
        try {
            yield put(setRatesState({ cnaStatus: 'pending' }));
            yield call(axiosRequest, 'GET', `/cna/quotes/${id}`);
            yield call(cnaRatesWatchWorker);
        } catch (error) {
            yield put(
                setQuote({
                    error: [
                        (error as Error).message || 'Something went wrong while loading your quote'
                    ],
                    isSuccess: false,
                    policyType: 'BOP',
                    source: CarrierTypes.CNA
                })
            );
        }
    } else {
        yield put(
            setQuote({
                error: ['Something went wrong while loading your quote'],
                isSuccess: false,
                policyType: 'BOP',
                source: CarrierTypes.CNA
            })
        );
    }
}

function* bindPolicy({ payload }: IBindPolicy) {
    const id: string = yield select(getQuestionnaireId);
    const { response } = yield call(axiosRequest, 'POST', '/coterie/bound', {
        policyNumber: payload,
        questionnaireId: id
    });
    if (response) {
        yield put(bindPolicySuccess(payload));
    } else {
        yield put(bindPolicyFailed());
    }
}

export function* ratesSaga() {
    yield all([
        takeLatest(actTypes.GET_COTERIE_RATES_REQUEST, getCoterieRates),
        takeLatest(actTypes.GET_CNA_RATES_REQUEST, pollCNARates),
        takeLatest(actTypes.COTERIE_BIND_POLICY, bindPolicy)
    ]);
}
