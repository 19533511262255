import { addressTypes } from '../constants';

export const getAddress = (
    place: any
): { streetAddress: string; cityAddress: string; zipAddress: string; stateAddress: string } => {
    const address = place?.address_components || {};
    let streetAddress = '';
    let cityAddress = '';
    let zipAddress = '';
    let stateAddress = '';
    if (!address.length) {
        return { cityAddress, stateAddress, streetAddress, zipAddress };
    }
    for (const obj of address) {
        switch (obj.types[0]) {
            case addressTypes.STREET:
                streetAddress = obj.long_name;
                break;
            case addressTypes.ROUTE:
                streetAddress = `${streetAddress} ${obj.long_name}`;
                break;
            case addressTypes.LOCALITY:
                cityAddress = obj.long_name;
                break;
            case addressTypes.STATE:
                stateAddress = obj.short_name;
                break;
            case addressTypes.ZIP:
                zipAddress = obj.long_name;
                break;
            default:
                break;
        }
    }
    return { cityAddress, stateAddress, streetAddress, zipAddress };
};
