export const GET_COTERIE_RATES_REQUEST = 'GET_COTERIE_RATES_REQUEST';
export const GET_CNA_RATES_REQUEST = 'GET_CNA_RATES_REQUEST';

export const SET_QUOTE = 'SET_QUOTE';
export const SET_RATES_STATE = 'SET_RATES_STATE';

export const CANCEL_CNA_RATES_POLLING = 'CANCEL_CNA_RATES_POLLING';

export const COTERIE_BIND_POLICY = 'COTERIE_BIND_POLICY';
export const COTERIE_BIND_POLICY_SUCCESS = 'COTERIE_BIND_POLICY_SUCCESS';
export const COTERIE_BIND_POLICY_FAILED = 'COTERIE_BIND_POLICY_FAILED';
