import type * as RT from '../../types/reduxTypes/questions';
import * as actType from '../actionTypes/questions';

const initState: RT.IQuestionsState = {
    data: {
        questions: []
    },
    error: null,
    status: 'idle'
};

export const questionsReducer = (
    state = initState,
    actions: RT.TQuestionsActions
): RT.IQuestionsState => {
    switch (actions.type) {
        case actType.GET_QUESTIONS_REQUEST:
            return {
                ...state
            };
        case actType.GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    questions: actions.payload.questions
                },
                status: 'loaded'
            };
        case actType.GET_QUESTIONS_FAILURE:
            return {
                ...state,
                error: actions.payload.error,
                status: 'failed'
            };
        case actType.SET_QUESTIONS_STATE:
            return {
                ...state,
                ...actions.payload
            };

        default:
            return { ...state };
    }
};
