import { createUseStyles } from 'react-jss';
import { GLOBAL_THEME } from '../../constants/theme';
import type { ICustomTheme } from '../../types/theme';

export const useTabsStyles = createUseStyles((theme: ICustomTheme) => ({
    current: {
        backgroundColor: theme.unVisitedTabIconColor
    },

    dividerLeft: {
        marginRight: '100%'
    },

    dividerRight: {
        marginLeft: '100%'
    },

    mt2: {
        marginTop: '2%'
    },

    mtb2: {
        margin: '2% auto',
        width: '75%'
    },

    notVisited: {
        backgroundColor: GLOBAL_THEME.visitedTabIconColor
    },

    notVisitedTextColor: {
        color: GLOBAL_THEME.disableTextColor
    },

    tab: {
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between'
    },

    tabAvatar: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        height: '45px',
        justifyContent: 'center',
        width: '45px',
        zIndex: 1
    },

    tabDivider: {
        borderTop: `1px solid ${GLOBAL_THEME.tabDividerColor}`,
        position: 'absolute',
        top: '0',
        zIndex: 0
    },

    tabLabel: {
        '@media(max-width:575px)': {
            fontSize: '12px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '74%'
        },
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '18px',
        marginTop: '1em',
        textAlign: 'center'
    },

    visited: {
        backgroundColor: GLOBAL_THEME.visitedTabIconColor,
        border: `1px solid ${theme.visitedTabBorderColor}`
    },

    visitedTextColor: {
        color: GLOBAL_THEME.textColor
    }
}));
