import { Form, Input } from 'antd';
import type { Rule } from 'rc-field-form/lib/interface';
import type { ChangeEvent, FC, FocusEvent } from 'react';
import { useTheme } from 'react-jss';
import { validateMessages } from '../../constants';
import type { ICustomTheme, IFormInputTextProps } from '../../types';
import { DIGIT_FILTER_REGEX } from '../../utils';
import { useFormElementsStyles } from './formElementsStyles';

const { Item } = Form;

export const FormInputSuffix: FC<IFormInputTextProps & { suffix: string }> = ({
    label,
    suffix,
    name,
    required = true,
    formInstance,
    type,
    capitalize = 'first'
}) => {
    const theme = useTheme<ICustomTheme>();
    const classes = useFormElementsStyles({ theme });
    const rules: Array<Rule> = [{ message: validateMessages.required, required }];
    let helper: HTMLElement | null = null;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal = '';
        newVal = e.target.value.replace(DIGIT_FILTER_REGEX, '');
        formInstance.setFieldsValue({
            [name[0]]: {
                [name[1]]: newVal
            }
        });
        if (!helper) {
            helper = document.getElementById('helper');
        }
        if (helper) {
            helper.innerText = e.target.value;
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        let newVal = '';
        if (type !== 'email') {
            if (capitalize === 'first') {
                const targetVal = e.target.value.trimEnd();
                newVal = targetVal.charAt(0).toUpperCase() + targetVal.slice(1);
            } else {
                const words = e.target.value.trimEnd().split(/\s/u);
                const capitalizedWords: Array<string> = [];
                words.forEach((word) => {
                    if (word !== '')
                        capitalizedWords.push(word[0].toUpperCase() + word.slice(1, word.length));
                });
                newVal = capitalizedWords.join(' ');
            }
        } else {
            newVal = e.target.value.trim();
            // below two statements are written intentionally.
            // https://stackoverflow.com/questions/38901062/cannot-trim-whitespace-on-email-input
            e.target.value = '';
            e.target.value = newVal;
        }
        formInstance.setFieldsValue({
            [name[0]]: {
                [name[1]]: newVal
            }
        });
    };

    return (
        <>
            <Item
                className={classes.materialFormItem}
                label={
                    (required && (
                        <p>
                            <span>{label}</span>
                            <span className={classes.labelSuffix}>{suffix}</span>
                            <strong>*</strong>
                        </p>
                    )) ||
                    label
                }
                name={name}
                rules={rules}
                validateFirst
                validateTrigger='onBlur'>
                <Input onBlur={handleBlur} onChange={handleChange} />
            </Item>
            <span id='helper' style={{ left: -5000, position: 'absolute' }} />
        </>
    );
};
