import { Col, Row, Space } from 'antd';
import React from 'react';
import { config } from '../../configs';
import { inIframe } from '../../utils';

const NotFound = () => {
    const inFrame = inIframe();
    return (
        <Row align='middle' justify='center'>
            <Col>
                Page not found.
                <Row>
                    <Space size={[20, 20]}>
                        <a href={inFrame ? config.fcDashboard : '/'}>
                            {inFrame ? 'FC Dashboard' : 'Home'}
                        </a>
                    </Space>
                </Row>
            </Col>
        </Row>
    );
};

export default NotFound;
