import 'antd/dist/reset.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Buffer } from 'buffer';

import App from './containers/App/App';
import './index.scss';

// If we don't have a value for Buffer (node core module) create it/polyfill it
if (!window.Buffer) window.Buffer = Buffer;
const root = ReactDOM.createRoot(document.getElementById('root')!);
// eslint-disable-next-line jest/require-hook
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
