import { createUseStyles } from 'react-jss';

import { GLOBAL_THEME } from '../../constants';
import type { ICustomTheme } from '../../types';

const useRatesStyles = createUseStyles((theme: ICustomTheme) => ({
    addCarrierCheckbox: {
        '&.selected': {
            color: theme.unVisitedTabIconColor
        },
        color: GLOBAL_THEME.disabledCheck,
        fontSize: '20px',
        position: 'absolute',
        right: '10px',
        top: '5px'
    },

    addCarrierContinueBtn: {
        '&.ant-btn-default:disabled': {
            backgroundColor: GLOBAL_THEME.disabledCheck,
            color: '#fff',
            cursor: 'default'
        },
        '&.ant-btn-default:hover': {
            color: '#fff !important'
        },
        backgroundColor: theme.unVisitedTabIconColor,
        borderRadius: '30px',
        color: '#fff',
        height: '48px',
        marginTop: '60px',
        width: '150px'
    },

    addCarrierModal: {
        '& .ant-modal-content': {
            '& .ant-modal-body': {
                height: 'min-content !important'
            },

            '& .ant-modal-header': {
                '& .ant-modal-title': {
                    fontFamily: 'Nunito Sans',
                    fontSize: '20px',
                    fontWeight: 200
                },
                marginBottom: '36px',
                textAlign: 'center'
            },
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        maxWidth: '600px'
    },

    addCarrierOption: {
        '& .ant-card-body': {
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            padding: '20px 24px',
            width: '100%'
        },
        '&.enabled': { cursor: 'pointer' },
        border: '1px solid #f0f2f5',
        borderRadius: '5px',
        boxShadow: '0px 4px 26px rgba(0, 0, 0, 0.06)',
        height: '118px',
        margin: '10px',
        width: '200px'
    },
    addIcon: {
        display: 'flex',
        fontSize: '18px'
    },

    addProductBtn: {
        '&:hover': {
            border: `1px solid ${theme.cardBorderHoverColor}`,
            color: theme.cardBorderHoverColor
        },

        alignItems: 'center',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        color: `${theme.productFontColor} !important`,
        display: 'flex',
        fontSize: '14px',
        fontWeight: 500,
        height: '50px',
        paddingInline: '20px'
    },

    applicantLabel: {
        color: GLOBAL_THEME.applicantLabel,
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '25px'
    },

    borderRight: {
        borderRight: `1px solid ${GLOBAL_THEME.quoteRightBorder}`
    },

    bridgeText: {
        '@media (min-width: 992px) and (max-width: 1400px)': {
            fontSize: '13px'
        },

        color: GLOBAL_THEME.bridgeTextColor,
        fontSize: '15px',
        fontWeight: 'bold',
        lineHeight: '23px',
        textAlign: 'center',
        textTransform: 'uppercase'
    },

    carrierLogowrap: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },

    centerAlignIcon: {
        padding: '2em 0'
    },

    cnaLogo: {
        '@media (minWidth: 992px) and (maxWidth: 1400px)': {
            width: '140px'
        },

        height: 'auto',
        marginBlock: '40px',
        paddingInline: '10px',
        width: '160px'
    },

    coterieLogo: {
        '@media (minWidth: 992px) and (maxWidth: 1400px)': {
            width: '140px'
        },

        height: 'auto',
        marginBlock: '40px',
        paddingInline: '10px',
        width: '160px'
    },

    dashboardBtn: {
        color: theme.productFontColor,
        cursor: 'pointer',
        fontWeight: 500,
        letterSpacing: '0.03em'
    },

    deductibles: {
        '& h4': {
            fontSize: '13px',
            fontWeight: 600,
            marginBottom: 0,
            marginTop: '15px'
        },

        '& p': {
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '16px',
            marginBottom: 0
        }
    },

    disclosure: {
        color: '#616E85',
        fontSize: '12px',
        maxWidth: '740px'
    },

    dividerH: {
        borderTop: `1px solid ${GLOBAL_THEME.dividerColor}`,
        margin: '8px 0 16px 0'
    },

    dividerV: {
        borderLeft: `2px solid ${GLOBAL_THEME.dividerColor}`,
        height: '5.9em'
    },

    downloadBtn: {
        alignItems: 'center',
        color: GLOBAL_THEME.formSubHeadingColor,
        cursor: 'pointer',
        display: 'flex',
        fontSize: '14px',
        fontWeight: '700',
        letterSpacing: '0.28px',
        lineHeight: '24px',
        margin: '12px 6px 0 0'
    },

    errors: {
        '& p': {
            color: GLOBAL_THEME.textDarkGrayApplicantInfo,
            fontSize: '13px',
            marginBottom: 0
        },

        justifyContent: 'left',
        padding: '1em'
    },

    head2: {
        color: GLOBAL_THEME.applicantLabel
    },

    infoCard: {
        border: `2px solid ${GLOBAL_THEME.cardBorderColor}`,
        borderRadius: '6px',
        marginBlock: '16px'
    },

    infoType: {
        color: GLOBAL_THEME.formHeadingColor,
        fontSize: '13px',
        fontWeight: 'bold',
        lineHeight: '18px',
        marginBottom: '5px'
    },

    infoValue: {
        color: GLOBAL_THEME.textDarkGrayApplicantInfo,
        fontSize: '13px',
        fontWeight: 'normal'
    },

    mb4: {
        marginBottom: '15px'
    },

    oversizedInput: {
        padding: '13px 20px'
    },

    paymentModal: {
        '& .ant-modal-close-x': {
            '& svg': {
                fontSize: '22px'
            },
            fontSize: '16px',
            height: '26px',
            width: '26px'
        },
        '& .ant-modal-content': {
            '& .ant-modal-body': {
                '& > form ': {
                    '& .StripeElement': {
                        backgroundColor: 'white',
                        border: '0.5px solid rgba(107, 122, 147, 0.20)',
                        borderRadius: '8px',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                        color: '#32325d',
                        display: 'flex',
                        flexDirection: 'column',
                        flexShrink: '0',
                        height: '59px',
                        justifyContent: 'center',
                        padding: '10px 12px',
                        transition: 'box-shadow 150ms ease',
                        width: '582px'
                    },
                    '& .StripeElement--focus': {
                        boxShadow: '4px 4px 8px  0 #cfd7df'
                    },
                    '& .StripeElement--invalid': {
                        borderColor: '#fa755a'
                    },
                    '& .StripeElement--webkit-autofill': {
                        backgroundColor: '#fefde5 !important'
                    },
                    '& .details-disclosure': {
                        color: '#3a4b5e',
                        fontFamily: 'Manrope, sans-serif',
                        fontSize: '16px',
                        lineHeight: '21.86px',
                        marginBottom: '30px',
                        marginTop: 0
                    },
                    '& .error-label': {
                        color: '#fa755a',
                        marginTop: 0
                    },
                    '& .powered-by': {
                        color: '#3a4b5e82',
                        fontSize: '14px',
                        marginTop: '8px'
                    },
                    '& .submit-payment-btn': {
                        '&:disabled': {
                            '&:hover': {
                                cursor: 'default'
                            },
                            background: '#0000004d'
                        },
                        '&:not(:disabled):hover': {
                            color: 'white'
                        },
                        background: '#FF8125',
                        border: 'none',
                        borderRadius: '50px',
                        color: 'white',
                        fontSize: '15px',
                        height: '48px',
                        padding: '14px, 30px, 14px, 30px',
                        width: '188px'
                    },
                    '& label.provide-details': {
                        color: '#3A4B5E',
                        fontFamily: 'Nunito Sans',
                        fontSize: '24px',
                        fontWeight: '400',
                        lineHeight: '32.74px',
                        marginBlock: '5px',
                        marginBottom: '15px'
                    },
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                },
                display: 'flex',
                height: '400px',
                justifyContent: 'center',
                minHeight: '188px'
            }
        },
        minHeight: '400px',
        minWidth: '770px'
    },

    paymentSubmitButton: {
        '&:not(:disabled):hover': {
            color: 'white'
        },
        background: 'linear-gradient(180deg, #2196F3 0%, #47ADFE 100%)',
        color: 'white',
        fontSize: '22px',
        height: '50px'
    },

    policyIssued: {
        '& img': {
            maxHeight: '400px',
            maxWidth: '500px'
        },
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        span: {
            color: GLOBAL_THEME.inputFontColor
        }
    },

    priceText: {
        color: GLOBAL_THEME.priceTextColor,
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '33px'
    },

    priceValueText: {
        '@media (minWidth: 992px) and (maxWidth: 1400px)': {
            fontSize: '34px'
        },

        color: GLOBAL_THEME.priceValueColor,
        fontSize: '42px',
        fontWeight: 'bold',
        lineHeight: '57px',
        marginBlock: '-16px'
    },

    processing: {
        margin: 'auto',
        maxWidth: '200px'
    },

    productIconImg: {
        display: 'flex',
        justifyContent: 'center'
    },

    productTypeText: {
        color: theme.productFontColor,
        fontSize: '1.5em',
        fontWeight: 500
    },

    rateCard: {
        '& .ant-card-body': {
            padding: '65px 65px 15px'
        },

        '&:hover': {
            borderColor: theme.cardBorderHoverColor,
            boxShadow: '0 4px 10px 1px #d4d4d5'
        },
        border: `1px solid ${GLOBAL_THEME.cardBorderColor}`,
        borderRadius: '6px',
        marginBlock: '16px',
        transition:
            'border 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },
    rateView: {
        background: theme.rateCardInnerBackground,
        borderRadius: '10px',
        height: 'auto',
        minHeight: '130px'
    },
    ratesContent: {
        marginInline: '12% !important'
    },
    viewMoreBoxLabel: {
        color: GLOBAL_THEME.bridgeTextColor,
        fontSize: '13px',
        fontWeight: 600,
        letterSpacing: '0.00938em',
        lineHeight: '1.5'
    },
    viewMoreBoxValue: {
        color: GLOBAL_THEME.bridgeTextColor,
        fontSize: '24px',
        fontWeight: 700
    },
    viewMoreBoxes: {
        background: GLOBAL_THEME.viewMoreBoxesBackgroundColor,
        borderRadius: '10px',
        padding: '2em 1em'
    },
    viewMoreBtn: {
        '& .anticon-minus': {
            color: GLOBAL_THEME.priceValueColor,
            fontSize: '14px',
            marginRight: '5px'
        },

        '& .anticon-plus': {
            color: GLOBAL_THEME.priceValueColor,
            fontSize: '14px',
            marginRight: '5px'
        },

        alignItems: 'center',
        color: GLOBAL_THEME.priceValueColor,
        cursor: 'pointer',
        display: 'flex',
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '0.00938em',
        lineHeight: '16px',
        margin: '12px 6px 0 0',
        textTransform: 'uppercase'
    }
}));

export default useRatesStyles;
