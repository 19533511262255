export const CREATE_QUESTIONNAIRE_FAILURE = 'CREATE_QUESTIONNAIRE_FAILURE';
export const CREATE_QUESTIONNAIRE_REQUEST = 'CREATE_QUESTIONNAIRE_REQUEST';
export const CREATE_QUESTIONNAIRE_SUCCESS = 'CREATE_QUESTIONNAIRE_SUCCESS';

export const GET_QUESTIONNAIRE_FAILURE = 'GET_QUESTIONNAIRE_FAILURE';
export const GET_QUESTIONNAIRE_REQUEST = 'GET_QUESTIONNAIRE_REQUEST';
export const GET_QUESTIONNAIRE_SUCCESS = 'GET_QUESTIONNAIRE_SUCCESS';

export const SET_APPLICANT_INFO = 'SET_APPLICANT_INFO';
export const SET_BOP_COVERAGE_INFO = 'SET_BOP_COVERAGE_INFO';
export const SET_BUSINESS_INFO = 'SET_BUSINESS_INFO';
export const SET_COTERIE_QUESTIONNAIRE_DATA = 'SET_COTERIE_QUESTIONNAIRE_DATA';
export const SET_COTERIE_QUESTIONNAIRE_STATE = 'SET_COTERIE_QUESTIONNAIRE_STATE';
export const SET_GL_COVERAGE_INFO = 'SET_GL_COVERAGE_INFO';
export const SET_LOSS_INFO = 'SET_LOSS_INFO';
export const SET_POLICY_INFO = 'SET_POLICY_INFO';
export const SET_PL_COVERAGE_INFO = 'SET_PL_COVERAGE_INFO';

export const UPDATE_QUESTIONNAIRE_FAILURE = 'UPDATE_QUESTIONNAIRE_FAILURE';
export const UPDATE_QUESTIONNAIRE_REQUEST = 'UPDATE_QUESTIONNAIRE_REQUEST';
export const UPDATE_QUESTIONNAIRE_SUCCESS = 'UPDATE_QUESTIONNAIRE_SUCCESS';
