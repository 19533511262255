import { Card, Col, Row } from 'antd';
import type { FC } from 'react';
import { createUseStyles } from 'react-jss';

import { CoterieImg } from '../../components';
import { literals } from '../../constants';

const useErrorStyles = createUseStyles({
    errorColumn: {
        border: '1px solid #ddd',
        marginTop: '4em'
    },

    errorMessage: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    }
});

export const ErrorComponent: FC = () => {
    const classes = useErrorStyles();
    return (
        <Col className={classes.errorColumn}>
            <Card>
                <Row>
                    <Col span={5}>
                        <CoterieImg />
                    </Col>

                    <Col className={classes.errorMessage} span={19}>
                        <p>{literals.ERROR}</p>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};

export const ErrorPage: FC = () => (
    <Row align='top' justify='space-around'>
        <ErrorComponent />
    </Row>
);
