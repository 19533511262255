/* eslint-disable no-undefined */
import { Form, Input } from 'antd';
import type { Rule } from 'rc-field-form/lib/interface';
import type { ChangeEvent, FC } from 'react';
import { useTheme } from 'react-jss';

import { validateMessages } from '../../constants';
import type { ICustomTheme, IFormInputNumberProps } from '../../types';
import { currencyFormat, strToInt } from '../../utils';
import { useFormElementsStyles } from './formElementsStyles';

const { Item } = Form;

export const FormInputNumber: FC<IFormInputNumberProps> = ({
    label,
    name,
    required = true,
    formInstance,
    max,
    min,
    format = 'default'
}) => {
    const theme = useTheme<ICustomTheme>();
    const classes = useFormElementsStyles({ theme });
    const rules: Array<Rule> = [{ message: validateMessages.required, required }];
    let message = '';
    if (typeof min === 'number') {
        message = `min: ${min.toLocaleString()} `;
        rules.push({
            validator: (_, value: string, cb) => {
                const num = format === 'default' ? parseInt(value, 10) : strToInt(value);
                if (Number.isNaN(num)) {
                    cb(validateMessages.required);
                } else if (min === 1 && num < min) {
                    cb(validateMessages.greaterThan0);
                } else if (num < min) {
                    cb(message);
                } else {
                    cb();
                }
            }
        });
    }
    if (typeof max === 'number') {
        if (min || min === 0) message += 'or ';
        message += `max: ${max.toLocaleString()}`;
        rules.push({
            validator: (_, value: string, cb) => {
                const num = format === 'default' ? parseInt(value, 10) : strToInt(value);
                if (num > max) cb(message);
                else cb();
            }
        });
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newVal = '';
        const val = strToInt(e.target.value);
        if (format !== 'default') {
            if (!Number.isNaN(val)) {
                if (format === 'locale') {
                    newVal = val.toLocaleString();
                } else {
                    newVal = currencyFormat.format(val);
                }
            }
        } else {
            newVal = val.toString();
        }
        formInstance.setFieldsValue({
            [name[0]]: {
                [name[1]]: newVal
            }
        });
    };

    return (
        <Item
            className={classes.materialFormItem}
            label={
                (required && (
                    <p>
                        {label}

                        <strong>*</strong>
                    </p>
                )) ||
                label
            }
            name={name}
            rules={rules}
            validateFirst
            validateTrigger='onBlur'>
            <Input
                max={format === 'default' ? max : undefined}
                min={format === 'default' ? min : undefined}
                onChange={handleChange}
                type={format === 'default' ? 'number' : 'text'}
            />
        </Item>
    );
};
