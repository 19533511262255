import {
    ArrowLeftOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Divider, Image, Modal, Row, Spin } from 'antd';

import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import clsx from 'clsx';
import { CNALogo, CoterieNavyLogo, Icon } from '../../components';
import { CoterieRate } from '../../components/Rates/CoterieRate';
import { config } from '../../configs';
import { CarrierTypes, DEFAULT_GUTTER, GLOBAL_THEME, ICONS, literals } from '../../constants';
import {
    getCNARates,
    getCoterieRates,
    setRatesState,
    updateQuestionnaireRequest
} from '../../redux/actions';
import { getAvailableCarriers } from '../../redux/actions/compliance';
import type { ICustomTheme, IRatesProps } from '../../types';
import type * as RT from '../../types/reduxTypes';
import { formatPhoneNumber, inIframe } from '../../utils';
import useRatesStyles from './ratesStyles';
import { Info, ViewMoreContent } from './ViewMore';

const selectRatesState = ({
    coterieQuestionnaire: {
        data: {
            applicantInfo,
            coterieProducerId,
            coterieAgencyId,
            carriers,
            requestId,
            policyTypes
        }
    },
    rates: {
        data: { quotesList },
        coterieBindStatus,
        status,
        cnaStatus
    },
    compliance: {
        data: { availableCarriers }
    }
}: RT.TRootState) => ({
    ...applicantInfo,
    availableCarriers,
    carriers,
    cnaStatus,
    coterieAgencyId,
    coterieBindStatus,
    coterieProducerId,
    policyTypes,
    quotesList,
    requestId,
    status
});

const Rates: FC<IRatesProps> = ({ setActiveTabIndex, firstTab }) => {
    const dispatch = useDispatch();
    const [openAdditionalModal, setOpenAdditionalModal] = useState(false);
    const classes = useRatesStyles();
    const theme: ICustomTheme = useTheme();
    const mountRef = useRef(false);
    const navigate = useNavigate();
    const {
        cnaStatus,
        quotesList,
        city,
        state,
        zipCode,
        street,
        businessName,
        firstName,
        lastName,
        email,
        phoneNumber,
        status,
        coterieProducerId,
        coterieAgencyId,
        coterieBindStatus,
        carriers,
        availableCarriers,
        requestId,
        policyTypes
    } = useSelector(selectRatesState);
    const [selectedCarrier, setSelectedCarrier] = useState(carriers ?? [CarrierTypes.COTERIE]);
    const BOP = policyTypes.includes('BOP');

    const getRates = () => {
        if (carriers?.includes(CarrierTypes.COTERIE)) {
            const isCoterieQuotePresent = quotesList.find(
                (quote) => quote.source === CarrierTypes.COTERIE || !quote.source
            );
            if (!isCoterieQuotePresent) {
                dispatch(getCoterieRates());
            }
        }
        if (carriers?.includes(CarrierTypes.CNA)) {
            const isCnaQuotePresent = quotesList.find((quote) => quote.source === CarrierTypes.CNA);
            if (!isCnaQuotePresent) {
                dispatch(getCNARates());
            }
        }
    };

    useEffect(() => {
        if (!mountRef.current) {
            getRates();
            if (carriers && carriers.length < 2 && requestId && !availableCarriers.length && BOP) {
                dispatch(getAvailableCarriers(requestId));
            }
        }
        return () => {
            mountRef.current = false;
        };
    }, []);

    useEffect(() => {
        const ratesUpdates: Partial<RT.IRatesState> = {};
        if (status === 'loaded') {
            ratesUpdates.status = 'idle';
        }
        if (cnaStatus === 'loaded') {
            ratesUpdates.cnaStatus = 'idle';
        }
        dispatch(setRatesState(ratesUpdates));
    }, [status, cnaStatus]);

    const handleGoToDashBoard = () => {
        if (inIframe()) {
            window.top!.location.href = config.fcDashboard;
        } else {
            navigate('/');
        }
    };

    const addOnCarriers: Array<CarrierTypes> = [];
    for (const carrier of selectedCarrier) {
        if (!carriers?.includes(carrier)) {
            addOnCarriers.push(carrier);
        }
    }

    const onClickOkAdditionalModal = () => {
        if (!addOnCarriers.length) {
            return;
        }
        carriers?.push(...addOnCarriers);
        dispatch(updateQuestionnaireRequest({ carriers }, false));
        if (addOnCarriers.includes(CarrierTypes.CNA)) {
            setActiveTabIndex(2);
        } else {
            // when adding coterie carrier
            getRates();
        }
        setOpenAdditionalModal(false);
    };

    const onCarrierSelection = (carrier: CarrierTypes) => () => {
        if (carriers?.includes(carrier)) {
            return;
        }
        const updateCarriers = [...selectedCarrier];
        const index = updateCarriers.indexOf(carrier);
        if (typeof index === 'number' && index > -1) {
            updateCarriers.splice(index, 1);
        } else {
            updateCarriers.push(carrier);
        }
        setSelectedCarrier(updateCarriers);
    };

    const coterieQuote = quotesList.find(
        (quote) => !quote.source || quote.source === CarrierTypes.COTERIE
    );

    const isAddCarrierAvailable =
        BOP &&
        cnaStatus !== 'pending' &&
        status !== 'pending' &&
        carriers &&
        carriers.length < 2 &&
        availableCarriers.length > 0 &&
        availableCarriers.length > carriers.length &&
        !coterieQuote?.isPolicyBound;

    return (
        <div className={classes.ratesContent}>
            {quotesList.length > 0 && (
                <Row>
                    <span
                        className={classes.dashboardBtn}
                        onClick={handleGoToDashBoard}
                        role='button'>
                        <ArrowLeftOutlined style={{ marginRight: '8px' }} />

                        {literals.DASHBOARD}
                    </span>
                </Row>
            )}

            <Row gutter={DEFAULT_GUTTER}>
                <Col md={{ span: 18 }} span={24}>
                    {quotesList.map((quoteItem: RT.IQuote) =>
                        quoteItem.source === CarrierTypes.CNA ? (
                            <Card
                                key={quoteItem.source || quoteItem.policyType}
                                className={classes.rateCard}>
                                <Row align='top' gutter={8}>
                                    <Col lg={{ span: 24 }} span={24}>
                                        <Row
                                            align='middle'
                                            className={classes.rateView}
                                            gutter={DEFAULT_GUTTER}>
                                            <Col
                                                className={classes.borderRight}
                                                md={{ span: 8 }}
                                                span={24}>
                                                <Row justify='center'>
                                                    <img
                                                        alt='cna'
                                                        className={classes.coterieLogo}
                                                        src={CNALogo}
                                                    />
                                                </Row>
                                            </Col>

                                            {quoteItem.isSuccess && quoteItem.quote?.premium ? (
                                                <>
                                                    <Col md={{ span: 8 }} span={24}>
                                                        <Row
                                                            className={classes.priceText}
                                                            justify='center'>
                                                            {literals.PRICE}
                                                        </Row>

                                                        <Row
                                                            className={classes.priceValueText}
                                                            justify='center'>
                                                            $
                                                            {quoteItem.quote?.premium?.toLocaleString()}
                                                        </Row>

                                                        <Row
                                                            className={classes.priceText}
                                                            justify='center'>
                                                            {literals.ANNUALLY}
                                                        </Row>
                                                    </Col>

                                                    <Col
                                                        className={classes.carrierLogowrap}
                                                        md={{ span: 8 }}
                                                        span={24}>
                                                        <a
                                                            href={quoteItem.quote?.agentUrl ?? '#!'}
                                                            rel='noreferrer'
                                                            target='_blank'>
                                                            <Col md={{ span: 24 }} span={24}>
                                                                <Row justify='center'>
                                                                    <Icon
                                                                        color={
                                                                            GLOBAL_THEME.bridgeTextColor
                                                                        }
                                                                        icon={ICONS.BRIDGE}
                                                                        size={48}
                                                                    />
                                                                </Row>

                                                                <Row
                                                                    className={classes.bridgeText}
                                                                    justify='center'>
                                                                    {literals.BRIDGE_TO_CARRIER}
                                                                </Row>
                                                            </Col>
                                                        </a>
                                                    </Col>
                                                </>
                                            ) : (
                                                <Col md={{ span: 16 }} span={24}>
                                                    <Row className={classes.errors}>
                                                        {quoteItem.error &&
                                                        Array.isArray(quoteItem.error) &&
                                                        quoteItem.error?.length
                                                            ? quoteItem.error?.map((err) => (
                                                                  <p key={err.slice(0, 10)}>
                                                                      {err}
                                                                  </p>
                                                              ))
                                                            : literals.CNA_GENERIC_ERROR}
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>

                                        {quoteItem.isSuccess && quoteItem.quote?.premium ? (
                                            <ViewMoreContent type={quoteItem.policyType} />
                                        ) : null}
                                    </Col>

                                    <Col md={{ span: 1 }} span={0} />
                                </Row>
                            </Card>
                        ) : (
                            <CoterieRate
                                key={quoteItem.source ?? quoteItem.policyType}
                                coterieAgencyId={coterieAgencyId}
                                coterieBindStatus={coterieBindStatus}
                                coterieProducerId={coterieProducerId}
                                quoteItem={quoteItem}
                            />
                        )
                    )}
                    {quotesList.length > 0 && cnaStatus !== 'pending' && status !== 'pending' && (
                        <p className={classes.disclosure}>{literals.DISCLOSURE}</p>
                    )}
                    {(cnaStatus === 'pending' || status === 'pending') && (
                        <Row
                            className='cna-span'
                            style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spin />
                        </Row>
                    )}
                    {isAddCarrierAvailable ? (
                        <Button
                            className={classes.addProductBtn}
                            htmlType='submit'
                            onClick={() => setOpenAdditionalModal(true)}
                            shape='round'
                            type='default'>
                            Additional Carrier
                            <PlusCircleOutlined />
                        </Button>
                    ) : null}
                </Col>

                <Col md={{ span: 6 }} span={24}>
                    <Card className={classes.infoCard}>
                        <Row className={classes.mb4}>
                            <h2 className={classes.applicantLabel}>{literals.APPLICANT} </h2>

                            <span
                                hidden={coterieQuote?.isPolicyBound}
                                onClick={firstTab}
                                role='button'
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: 'auto',
                                    marginRight: 0
                                }}>
                                <Icon
                                    color={theme.formInputHoverBorderColor}
                                    icon={ICONS.EDIT}
                                    size={18}
                                />
                            </span>
                        </Row>

                        <Info infoType={literals.CONTACT_NAME} value={`${firstName} ${lastName}`} />

                        <Divider className={classes.dividerH} />

                        <Info infoType={literals.BUSINESS_NAME} value={businessName ?? ''} />

                        <Divider className={classes.dividerH} />

                        <Info
                            infoType={literals.ADDRESS}
                            value={`${street} ${city}, ${state}, ${zipCode}`}
                        />

                        <Divider className={classes.dividerH} />

                        <Info infoType={literals.EMAIL_ADDRESS} value={email ?? ''} />

                        <Divider className={classes.dividerH} />

                        <Info
                            infoType={literals.PHONE_NO}
                            value={formatPhoneNumber(phoneNumber ?? '')}
                        />
                    </Card>
                </Col>
            </Row>
            <Modal
                bodyStyle={{ height: '250px' }}
                className={classes.addCarrierModal}
                footer={
                    <Button
                        className={classes.addCarrierContinueBtn}
                        disabled={!addOnCarriers.length}
                        onClick={onClickOkAdditionalModal}>
                        Continue
                    </Button>
                }
                onCancel={() => setOpenAdditionalModal(false)}
                onOk={onClickOkAdditionalModal}
                open={openAdditionalModal}
                title='Select an additional carrier to quote'
                width={750}>
                <Row align='middle' justify='space-around'>
                    <Card
                        className={clsx(
                            classes.addCarrierOption,
                            !carriers?.includes(CarrierTypes.COTERIE) && 'enabled'
                        )}
                        onClick={onCarrierSelection(CarrierTypes.COTERIE)}>
                        <span
                            className={clsx(
                                classes.addCarrierCheckbox,
                                addOnCarriers.includes(CarrierTypes.COTERIE) && 'selected'
                            )}>
                            {selectedCarrier?.includes(CarrierTypes.COTERIE) ? (
                                <CheckCircleFilled />
                            ) : (
                                <CheckCircleOutlined />
                            )}
                        </span>
                        <Image preview={false} src={CoterieNavyLogo} width={220} />
                    </Card>
                    <Card
                        className={clsx(
                            classes.addCarrierOption,
                            !carriers?.includes(CarrierTypes.CNA) && 'enabled'
                        )}
                        onClick={onCarrierSelection(CarrierTypes.CNA)}>
                        <span
                            className={clsx(
                                classes.addCarrierCheckbox,
                                addOnCarriers.includes(CarrierTypes.CNA) && 'selected'
                            )}>
                            {selectedCarrier?.includes(CarrierTypes.CNA) ? (
                                <CheckCircleFilled size={20} />
                            ) : (
                                <CheckCircleOutlined size={20} />
                            )}
                        </span>
                        <Image preview={false} src={CNALogo} width={220} />
                    </Card>
                </Row>
            </Modal>
        </div>
    );
};

export default Rates;
