import { Button, Card, Col, Row } from 'antd';
import type { FC } from 'react';
import { useState } from 'react';
import PolicyBoundSvg from '../../assets/images/policy-bound.svg';
import { config } from '../../configs';
import { DEFAULT_GUTTER, GLOBAL_THEME, ICONS, literals } from '../../constants';
import useRatesStyles from '../../containers/Rates/ratesStyles';
import type * as RT from '../../types/reduxTypes';
import { Icon } from '../Assets/Icon';
import { CoterieNavyLogo } from '../Assets/Images';
import { StripeChargeModal } from './StripeCharge';
import { ViewMoreContent } from './ViewMoreContent';

export const CoterieRate: FC<{
    quoteItem: RT.IQuote;
    coterieProducerId: string;
    coterieAgencyId: string;
    coterieBindStatus?: 'failed' | 'idle' | 'loaded' | 'pending';
}> = ({ quoteItem, coterieProducerId, coterieAgencyId, coterieBindStatus }) => {
    const [showModal, setShowModal] = useState(false);
    const classes = useRatesStyles();

    return (
        <Card className={classes.rateCard}>
            <Row align='top' gutter={8}>
                <Col lg={{ span: 24 }} span={24}>
                    <Row align='middle' className={classes.rateView} gutter={DEFAULT_GUTTER}>
                        <Col className={classes.borderRight} md={{ span: 8 }} span={24}>
                            <Row justify='center'>
                                <img
                                    alt='coterie'
                                    className={classes.coterieLogo}
                                    src={CoterieNavyLogo}
                                />
                            </Row>
                        </Col>

                        {quoteItem.quote?.premium ? (
                            <>
                                <Col md={{ span: 8 }} span={24}>
                                    <Row className={classes.priceText} justify='center'>
                                        {literals.PRICE}
                                    </Row>

                                    <Row className={classes.priceValueText} justify='center'>
                                        ${quoteItem.quote?.premium?.toLocaleString()}
                                    </Row>

                                    <Row className={classes.priceText} justify='center'>
                                        {literals.ANNUALLY}
                                    </Row>
                                </Col>

                                <Col className={classes.carrierLogowrap} md={{ span: 8 }} span={24}>
                                    {config.enableCoterieBind ? (
                                        <Button
                                            disabled={quoteItem.isPolicyBound}
                                            onClick={() => setShowModal(true)}
                                            style={{
                                                cursor: quoteItem.isPolicyBound
                                                    ? 'default'
                                                    : 'pointer',
                                                height: 'auto'
                                            }}
                                            type='link'>
                                            <Col md={{ span: 24 }} span={24}>
                                                <Row justify='center'>
                                                    {quoteItem.isPolicyBound ? (
                                                        <img
                                                            alt='policy-bound'
                                                            src={PolicyBoundSvg}
                                                        />
                                                    ) : (
                                                        <Icon
                                                            color={GLOBAL_THEME.bridgeTextColor}
                                                            icon={ICONS.BIND}
                                                            size={36}
                                                        />
                                                    )}
                                                </Row>

                                                <Row
                                                    className={classes.bridgeText}
                                                    justify='center'>
                                                    {quoteItem.isPolicyBound
                                                        ? literals.POLICY_BOUND
                                                        : literals.BIND_ONLINE}
                                                </Row>
                                            </Col>
                                        </Button>
                                    ) : (
                                        <a
                                            href={quoteItem.quote?.agentUrl}
                                            rel='noreferrer'
                                            target='_blank'>
                                            <Col md={{ span: 24 }} span={24}>
                                                <Row justify='center'>
                                                    <Icon
                                                        color={GLOBAL_THEME.bridgeTextColor}
                                                        icon={ICONS.BRIDGE}
                                                        size={36}
                                                    />
                                                </Row>

                                                <Row
                                                    className={classes.bridgeText}
                                                    justify='center'>
                                                    {literals.BRIDGE_TO_CARRIER}
                                                </Row>
                                            </Col>
                                        </a>
                                    )}
                                </Col>
                                {config.enableCoterieBind ? (
                                    <StripeChargeModal
                                        coterieAgencyId={coterieAgencyId}
                                        coterieBindStatus={coterieBindStatus}
                                        coterieProducerId={coterieProducerId}
                                        isPolicyBound={quoteItem.isPolicyBound}
                                        quoteId={quoteItem.quote?.quoteId}
                                        setShowModal={setShowModal}
                                        showModal={showModal}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <Col md={{ span: 16 }} span={24}>
                                <Row className={classes.errors}>
                                    {quoteItem.error &&
                                    Array.isArray(quoteItem.error) &&
                                    quoteItem.error?.length
                                        ? quoteItem.error?.map((err) => (
                                              <p key={err.slice(0, 10)}>{err}</p>
                                          ))
                                        : literals.NO_QUOTES_AVAILABLE}
                                </Row>
                            </Col>
                        )}
                    </Row>

                    {quoteItem.quote?.premium ? (
                        <ViewMoreContent
                            agentUrl={quoteItem.quote.agentUrl}
                            quoteProposalUrl={quoteItem.quote.quoteProposalUrl}
                            type={quoteItem.policyType}
                        />
                    ) : null}
                </Col>

                <Col md={{ span: 1 }} span={0} />
            </Row>
        </Card>
    );
};
CoterieRate.defaultProps = { coterieBindStatus: 'idle' };
