import type * as RT from '../../types/reduxTypes/compliance';
import * as actTypes from '../actionTypes/compliance';

export const complianceInitState: RT.IComplianceState = {
    availableCarriersStatus: 'idle',
    data: { availableCarriers: [], markets: [] },
    error: null,
    status: 'idle'
};

export const complianceReducer = (
    state = complianceInitState,
    action: RT.TComplianceActions
): RT.IComplianceState => {
    switch (action.type) {
        case actTypes.GET_COMPLIANCE_MARKET_FAILURE:
            return { ...state, error: action.payload, status: 'failed' };
        case actTypes.GET_COMPLIANCE_MARKET_SUCCESS:
            return { ...state, data: { ...state.data, markets: action.payload }, status: 'loaded' };
        case actTypes.GET_COMPLIANCE_MARKET_REQUEST:
            return { ...state, status: 'pending' };
        case actTypes.GET_AVAILABLE_CARRIERS_FAILURE:
            return { ...state, error: action.payload, status: 'failed' };
        case actTypes.GET_AVAILABLE_CARRIERS_SUCCESS:
            return {
                ...state,
                data: { ...state.data, availableCarriers: action.payload },
                status: 'loaded'
            };
        case actTypes.GET_AVAILABLE_CARRIERS_REQUEST:
            return { ...state, status: 'pending' };

        default:
            return state;
    }
};
