const develop = {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? '',
    coterieApiUrl: process.env.REACT_APP_COTERIE_API_URL ?? '',
    cryptoKey: process.env.REACT_APP_CRYPTO_KEY ?? '',
    enableCoterieBind: process.env.REACT_APP_ENABLE_COTERIE_BIND === 'true',
    fcDashboard: process.env.REACT_APP_FC_DASHBOARD_URL ?? '',
    googleAutoCompleteKey: process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_KEY,
    requestTimeout: 35000,
    stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? ''
};
export default develop;
