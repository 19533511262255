import type { IAnswer } from '../types/reduxTypes';

export const mapQuestionsToAnswers = (
    questions: Record<string, boolean | string>
): Array<IAnswer> => {
    const questionKeys: Array<string> = Object.keys(questions) as unknown as Array<string>;

    return questionKeys.map((questionKey) => {
        const isBoolean = typeof questions[questionKey] == 'boolean';
        let answer = null;
        if (isBoolean) {
            answer = questions[questionKey] === true ? 'Yes' : 'No';
        } else {
            answer = questions[questionKey];
        }
        return {
            answer,
            questionId: questionKey
        };
    });
};
