import type { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ErrorPage } from '../ErrorPage/ErrorPage';
import Home from '../Home/Home';
import NotFound from '../NotFound/NotFound';
import Questionnaire from '../Questionnaire/Questionnaire';
import { configStore } from '../../redux/store';

const App: FC = () => {
    const store = configStore();
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Home />} path='/' />

                <Route element={<ErrorPage />} path='/error' />

                <Route
                    element={
                        <Provider store={store}>
                            <Questionnaire viewing={false} />
                        </Provider>
                    }
                    path='/applicant/:query'
                />

                <Route
                    element={
                        <Provider store={store}>
                            <Questionnaire viewing />
                        </Provider>
                    }
                    path='/questionnaire/:query'
                />

                <Route element={<NotFound />} path='*' />
            </Routes>
        </BrowserRouter>
    );
};
export default App;
