import type { ICustomTheme } from '../types';

export const CUSTOM_THEMES = {
    firstConnect: {
        cardBorderHoverColor: '#D9D9D9',
        formInputHoverBorderColor: '#2196f3',
        formItemLabelColor: '#3A4B5E',
        formSubHeadingColor: '#2196f3',
        iconColor: '#2196F3',
        mainBackgroundColor: '#ffffff',
        productFontColor: '#4696EC',
        rateCardInnerBackground: '#f3fbff',
        unVisitedTabIconColor: '#2196f3',
        visitedTabBorderColor: '#2196f3'
    } as ICustomTheme,
    hippo: {
        cardBorderHoverColor: '#00cf83',
        formInputHoverBorderColor: '#00cf83',
        formItemLabelColor: '#3A4B5E',
        formSubHeadingColor: '#00cf83',
        iconColor: '#00cf83',
        productFontColor: '#00cf83',
        rateCardInnerBackground: '#F3FBFF',
        unVisitedTabIconColor: '#00cf83',
        visitedTabBorderColor: '#000'
    } as ICustomTheme,
    pennyMac: {
        cardBorderHoverColor: '#003087',
        formInputHoverBorderColor: '#003087',
        formItemLabelColor: '#3A4B5E',
        formSubHeadingColor: '#003087',
        iconColor: '#003087',
        productFontColor: '#003087',
        rateCardInnerBackground: '#eaf5ff',
        unVisitedTabIconColor: '#003087',
        visitedTabBorderColor: '#003087'
    } as ICustomTheme
};

export const GLOBAL_THEME = {
    applicantLabel: '#31355C',
    borderColor: '#CDDBEB',
    bridgeTextColor: '#3A4B5E',
    cardBorderColor: '#d9d9d9',
    cardBorderColorSecondary: '#d9dfef',
    disableTextColor: '#B9B9B9',
    disabledCheck: '#ccc',
    dividerColor: '#DDE1EC',
    formHeadingColor: '#3a4b5e',
    formInputBorderColor: '#949494',
    formInputHoverBorderColor: 'rgba(0, 0, 0, 0.87)',
    formSubHeadingColor: '#2196f3',
    grayIconColor: '#808080',
    greyBackground: '#ececec',
    inputFontColor: '#3a4b5e',
    mainBackgroundColor: '#ffffff',
    priceTextColor: '#6B7A93',
    priceValueColor: '#565980',
    quoteRightBorder: '#E3E8EB',
    requiredStarColor: '#ff4d4f',
    tabDividerColor: '#C6CFDA',
    textColor: '#303030',
    textDarkGrayApplicantInfo: '#3a4b5e',
    viewMoreBoxesBackgroundColor: '#f9fbfc',
    visitedTabIconColor: '#ffffff'
};
