import { Button, Card, Col, Form, Radio, Row, notification } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment/moment';
import { useTheme } from 'react-jss';
import {
    FormDate,
    FormInputNumber,
    FormInputSuffix,
    FormSelect,
    FormYear,
    useFormElementsStyles
} from '../../../components';
import {
    CONSTRUCTION_TYPES,
    LOCATION_TYPES,
    formStateMap as fsm,
    literals,
    validateMessages
} from '../../../constants';
import {
    setCoterieQuestionnaireData,
    setCoterieQuestionnaireState,
    updateQuestionnaireRequest
} from '../../../redux/actions';
import type { ICustomTheme, IFormPropTypes } from '../../../types';
import type * as RT from '../../../types/reduxTypes';
import { compareBusinessInfo, compareCoverageObjects } from '../../../utils';
import { useQuestionnaireStyles } from '../questionnaireStyles';

const { Item } = Form;

const BusinessInfoForm: FC<IFormPropTypes> = ({ prevTab, nextTab, setAreFormsUpdated }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [forceSubmit, setForceSubmit] = useState(false);
    const classes = useQuestionnaireStyles();
    const theme = useTheme<ICustomTheme>();
    const formStyles = useFormElementsStyles({ theme });

    const { businessInfo, plCoverageInfo, status, id, policyTypes } = useSelector(
        ({ coterieQuestionnaire: { data, status } }: RT.TRootState) => {
            if (typeof data.businessInfo.yearBuilt === 'string' && data.businessInfo.yearBuilt) {
                // eslint-disable-next-line no-param-reassign
                data.businessInfo.yearBuilt = moment(data.businessInfo.yearBuilt);
            }
            if (
                typeof data.businessInfo.roofingImprovementYear === 'string' &&
                data.businessInfo.roofingImprovementYear
            ) {
                // eslint-disable-next-line no-param-reassign
                data.businessInfo.roofingImprovementYear = moment(
                    data.businessInfo.roofingImprovementYear
                );
            }
            if (
                typeof data.businessInfo.wiringImprovementYear === 'string' &&
                data.businessInfo.wiringImprovementYear
            ) {
                // eslint-disable-next-line no-param-reassign
                data.businessInfo.wiringImprovementYear = moment(
                    data.businessInfo.wiringImprovementYear
                );
            }
            if (
                typeof data.businessInfo.businessStartDate === 'string' &&
                data.businessInfo.businessStartDate
            ) {
                // eslint-disable-next-line no-param-reassign
                data.businessInfo.businessStartDate = moment(data.businessInfo.businessStartDate);
            }
            return {
                ...data,
                status
            };
        }
    );

    const PL = policyTypes.includes('PL');

    useEffect(() => {
        if (status === 'created' || status === 'updated') {
            if (forceSubmit) {
                setForceSubmit(false);
            }
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
            nextTab();
        } else if (status === 'failed') {
            notification.error({
                description: literals.SUBMISSION_ERROR,
                message: 'Failed'
            });
            setForceSubmit(true);
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
        }
    }, [status]);

    const submit = (value: {
        businessInfo: RT.IBusinessInfo;
        plCoverageInfo: RT.IPLCoverageInfo;
    }) => {
        if (id) {
            const payload: Partial<RT.ICoterieQuestionnaireData> = {};
            if (!compareBusinessInfo(value.businessInfo, businessInfo)) {
                payload.businessInfo = value.businessInfo;
            }

            if (PL && !compareCoverageObjects(value.plCoverageInfo, plCoverageInfo)) {
                payload.plCoverageInfo = value.plCoverageInfo;
            }

            if (Object.keys(payload).length !== 0) {
                dispatch(setCoterieQuestionnaireData(payload));
                dispatch(updateQuestionnaireRequest(payload));
                setAreFormsUpdated(true);
            } else {
                nextTab();
            }
        } else {
            notification.error({ description: literals.SUBMISSION_ERROR, message: 'Failed' });
        }
    };
    return (
        <Card className={classes.form}>
            <Row justify='center'>
                <Col span={18}>
                    <Row justify='center'>
                        <h2 className={classes.head2}>{literals.ENTER_BUSINESS_INFO}</h2>
                    </Row>
                    <Form
                        form={form}
                        initialValues={{ businessInfo, plCoverageInfo }}
                        layout='vertical'
                        onFinish={submit}
                        requiredMark={false}
                        scrollToFirstError
                        validateMessages={validateMessages}>
                        <Row gutter={[32, 8]}>
                            <Col md={{ span: 8 }} span={24}>
                                <FormDate
                                    disabledDate={(date) => date && date >= moment()}
                                    label={literals.BUSINESS_START_DATE}
                                    name={[fsm.businessInfo, fsm.businessStartDate]}
                                    showToday={false}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormSelect
                                    label={literals.LOCATION_TYPE}
                                    name={[fsm.businessInfo, fsm.locationType]}
                                    selectOption={LOCATION_TYPES}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputNumber
                                    format='locale'
                                    formInstance={form}
                                    label={literals.NUMBER_OF_EMPLOYEES}
                                    min={1}
                                    name={[fsm.businessInfo, fsm.numberOfEmployees]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[32, 8]}>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputNumber
                                    format='currency'
                                    formInstance={form}
                                    label={literals.ANNUAL_PAYROLL}
                                    min={1}
                                    name={[fsm.businessInfo, fsm.annualPayroll]}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputNumber
                                    format='currency'
                                    formInstance={form}
                                    label={literals.GROSS_ANNUAL_SALES}
                                    min={1}
                                    name={[fsm.businessInfo, fsm.grossAnnualSales]}
                                />
                            </Col>
                            {PL ? (
                                <Col md={{ span: 8 }} span={24}>
                                    <FormInputNumber
                                        format='currency'
                                        formInstance={form}
                                        label={literals.GROSS_ANNUAL_REVENUE}
                                        min={1}
                                        name={[fsm.plCoverageInfo, fsm.grossAnnualRevenue]}
                                    />
                                </Col>
                            ) : null}
                        </Row>
                        {PL ? (
                            <Row className={classes.yearsOFexpRow} gutter={[32, 8]}>
                                <Col md={{ span: 8 }} span={24}>
                                    <FormInputNumber
                                        format='default'
                                        formInstance={form}
                                        label={literals.YEARS_OF_PE}
                                        min={0}
                                        name={[fsm.plCoverageInfo, fsm.yearsOfExperience]}
                                    />
                                </Col>
                                <Col className={classes.lossRadio} lg={{ span: 8 }} span={24}>
                                    <Item
                                        className={formStyles.formItem}
                                        label={
                                            <p className={classes.queLabel}>
                                                {literals.CERTIFICATIONS_REQUIRED}

                                                <strong>*</strong>
                                            </p>
                                        }
                                        name={[fsm.plCoverageInfo, fsm.areCertificationsRequired]}
                                        rules={[
                                            { message: validateMessages.required, required: true }
                                        ]}>
                                        <Radio.Group>
                                            <Radio value>{literals.YES}</Radio>
                                            <Radio value={false}>{literals.NO}</Radio>
                                        </Radio.Group>
                                    </Item>
                                </Col>
                                <Col className={classes.lossRadio} lg={{ span: 8 }} span={24}>
                                    <Item
                                        className={formStyles.formItem}
                                        label={
                                            <p className={classes.queLabel}>
                                                {literals.APPLICANT_MAINTAIN_CERTIFICATIONS}

                                                <strong>*</strong>
                                            </p>
                                        }
                                        name={[
                                            fsm.plCoverageInfo,
                                            fsm.doesApplicantMaintainCertifications
                                        ]}
                                        rules={[
                                            { message: validateMessages.required, required: true }
                                        ]}>
                                        <Radio.Group>
                                            <Radio value>{literals.YES}</Radio>
                                            <Radio value={false}>{literals.NO}</Radio>
                                        </Radio.Group>
                                    </Item>
                                </Col>
                            </Row>
                        ) : null}
                        <Row justify='start'>
                            <h3 className={classes.head3}>{literals.PROPERTY_DETAILS}</h3>
                        </Row>
                        <Row gutter={[32, 8]}>
                            <Col md={{ span: 8 }} span={24}>
                                <FormSelect
                                    label={literals.CONSTRUCTION_TYPE}
                                    name={[fsm.businessInfo, fsm.constructionType]}
                                    selectOption={CONSTRUCTION_TYPES}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormYear
                                    disabledDate={(date) => date && date >= moment()}
                                    label={literals.YEAR_BUILT}
                                    name={[fsm.businessInfo, fsm.yearBuilt]}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputSuffix
                                    formInstance={form}
                                    label={literals.BUILDING_AREA}
                                    name={[fsm.businessInfo, fsm.buildingArea]}
                                    suffix='(sq.ft.)'
                                />
                            </Col>
                        </Row>

                        <Row gutter={[32, 8]}>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputNumber
                                    format='locale'
                                    formInstance={form}
                                    label={literals.STORIES}
                                    min={1}
                                    name={[fsm.businessInfo, fsm.stories]}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormYear
                                    disabledDate={(date) => date && date >= moment()}
                                    label={literals.ROOFING_IMPROVEMENT_YEAR}
                                    name={[fsm.businessInfo, fsm.roofingImprovementYear]}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputSuffix
                                    formInstance={form}
                                    label={literals.DISTANCE_TO_FIRESTATION}
                                    name={[fsm.businessInfo, fsm.distanceToFirestation]}
                                    suffix='(miles)'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[32, 8]}>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputNumber
                                    format='locale'
                                    formInstance={form}
                                    label={literals.BASEMENT_COUNT}
                                    min={1}
                                    name={[fsm.businessInfo, fsm.basementCount]}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormYear
                                    disabledDate={(date) => date && date >= moment()}
                                    label={literals.WIRING_IMPROVEMENT_YEAR}
                                    name={[fsm.businessInfo, fsm.wiringImprovementYear]}
                                />
                            </Col>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputSuffix
                                    formInstance={form}
                                    label={literals.DISTANCE_TO_HYDRANT}
                                    name={[fsm.businessInfo, fsm.distanceToHydrant]}
                                    suffix='(miles)'
                                />
                            </Col>
                        </Row>

                        <Row gutter={[32, 8]}>
                            <Col lg={{ span: 8 }} span={24}>
                                <Item
                                    className={formStyles.formItem}
                                    label={
                                        <p>
                                            {literals.BUILDING_HAVE_SPRINKLER}

                                            <strong>*</strong>
                                        </p>
                                    }
                                    name={[fsm.businessInfo, fsm.hasSprinklerSystem]}
                                    rules={[
                                        { message: validateMessages.required, required: true }
                                    ]}>
                                    <Radio.Group>
                                        <Radio value>{literals.YES}</Radio>
                                        <Radio value={false}>{literals.NO}</Radio>
                                    </Radio.Group>
                                </Item>
                            </Col>
                        </Row>

                        <Row justify='center'>
                            <Item>
                                <Button
                                    className={classes.backBtn}
                                    onClick={prevTab}
                                    shape='round'
                                    type='default'>
                                    Back
                                </Button>

                                <Button
                                    className={classes.continueBtn}
                                    htmlType='submit'
                                    loading={status === 'pending'}
                                    shape='round'
                                    type='default'>
                                    Continue
                                </Button>
                            </Item>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Card>
    );
};

export default BusinessInfoForm;
