import { Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment/moment';
import { TabView } from '../../components';
import { CUSTOM_THEMES, CarrierTypes, QUESTIONNAIRE_TABS, defaults } from '../../constants';
import {
    getQuestionnaireRequest,
    setCoterieQuestionnaireData,
    setCoterieQuestionnaireState,
    setRatesState
} from '../../redux/actions';
import type { IFCOpts } from '../../types';
import type * as RT from '../../types/reduxTypes';
import { decryptData } from '../../utils';
import Rates from '../Rates/Rates';
import { ApplicantForm, LossForm, QuestionsForm, RequestedPolicyForm } from './coterieForms';
import BusinessInfoForm from './coterieForms/BusinessInfoForm';
import { useQuestionnaireStyles } from './questionnaireStyles';

const Questionnaire: FC<{ viewing: boolean }> = ({ viewing }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [editingProducts, setEditingProducts] = useState<Array<RT.PolicyTypes> | null>(null);
    const classes = useQuestionnaireStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [areFormsUpdated, setAreFormsUpdated] = useState(false);
    const { query } = useParams<{ query: string }>();
    const scrollDivRef = useRef<HTMLDivElement>(null);

    const {
        questionnaireError,
        applicantInfo,
        ratesError,
        status,
        quoteStatus,
        carriers = []
    } = useSelector(
        ({
            coterieQuestionnaire: {
                error: questionnaireError,
                data: { status: quoteStatus, carriers, applicantInfo },
                status
            },
            rates: { error: ratesError }
        }: RT.TRootState) => ({
            applicantInfo,
            carriers,
            questionnaireError,
            quoteStatus,
            ratesError,
            status
        })
    );

    const lastTab = () => {
        setActiveTabIndex(carriers.includes(CarrierTypes.CNA) ? 5 : 4);
        if (editingProducts) setEditingProducts(null);
    };

    useEffect(() => {
        const errorCodes = ['ERR500', 'ERR400_2'];
        if (errorCodes.includes(questionnaireError?.code)) {
            navigate('/error');
            dispatch(setCoterieQuestionnaireState({ error: null, status: 'idle' }));
        } else if (errorCodes.includes(ratesError?.code || '')) {
            navigate('/error');
            dispatch(setRatesState({ error: null, status: 'idle' }));
        }
    }, [questionnaireError, ratesError]);

    useEffect(() => {
        if (!viewing) {
            const opts: IFCOpts & Record<string, string> =
                query && JSON.parse(decryptData(query) ?? '{}');

            const carriers = opts.carriers || [CarrierTypes.COTERIE];
            try {
                dispatch(
                    setCoterieQuestionnaireData({
                        applicantInfo: {
                            ...applicantInfo,
                            businessName: opts.businessName || '',
                            state: opts.primaryState || ''
                        },
                        carriers,
                        classCode: '57131', // opts.industry.cnaClassCode,
                        clientId: opts.clientId ?? defaults.CLIENT_ID,
                        cnaAgentId: opts.cnaAgentId || 'TEST110',
                        cnaProducerId: opts.cnaProducerId || '010018297',
                        coterieAgencyId: opts.coterieAgencyId || '',
                        coterieProducerId: opts.coterieProducerId || '',
                        policyInfo: {
                            akHash: opts.industry.coterieAkHash ?? '',
                            industryId: Number(opts.industry.coterieId),
                            policyStartDate: moment().add(1, 'day')
                        },
                        policyTypes: opts.product,
                        producerCode: opts.producerInfo.producerCode,
                        producerEmail: opts.producerInfo.email,
                        producerId: opts.producerInfo.producerId.toString(),
                        requestId: opts.requestId
                    })
                );
            } catch (error) {
                navigate('/not-found');
            }
            setIsLoading(false);
        } else if (query) {
            dispatch(getQuestionnaireRequest({ id: query }));
        }
    }, []);

    const getTabs = () => {
        if (carriers && !carriers.includes(CarrierTypes.CNA)) {
            return QUESTIONNAIRE_TABS.filter((item) => item.id !== 'questions');
        }
        return QUESTIONNAIRE_TABS;
    };

    useEffect(() => {
        if (status === 'loaded') {
            if (quoteStatus !== 'in-progress') {
                lastTab();
            }
            setIsLoading(false);
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
        }
        if (status === 'failed') {
            setIsLoading(false);
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
        }
    }, [status]);

    useEffect(() => scrollDivRef.current?.scrollTo(0, 0), [activeTabIndex]);

    const nextTab = () => {
        if (activeTabIndex < QUESTIONNAIRE_TABS.length - 1) setActiveTabIndex(activeTabIndex + 1);
        if (editingProducts) setEditingProducts(null);
    };

    const prevTab = () => {
        if (activeTabIndex > 0) {
            setActiveTabIndex(activeTabIndex - 1);
        }
    };

    const firstTab = () => {
        setActiveTabIndex(0);
    };

    const secondTab = (selectedProducts: Array<RT.PolicyTypes> | null) => {
        setEditingProducts(selectedProducts);
        setActiveTabIndex(1);
    };

    const props = {
        areFormsUpdated,
        carriers,
        firstTab,
        nextTab,
        prevTab,
        secondTab,
        setActiveTabIndex,
        setAreFormsUpdated
    };

    const tabs = [
        <ApplicantForm key={0} {...props} />,
        <BusinessInfoForm key={1} {...props} />,
        carriers.includes(CarrierTypes.CNA) && <QuestionsForm key={2} {...props} />,
        <LossForm key={3} {...props} />,
        <RequestedPolicyForm key={4} {...props} editingProducts={editingProducts} />,
        <Rates key={5} {...props} />
    ].filter((item) => item);

    return (
        <ThemeProvider theme={CUSTOM_THEMES.firstConnect}>
            <div ref={scrollDivRef} className={classes.themeBackground} id='scrolldiv'>
                <TabView activeTabIndex={activeTabIndex} tabs={getTabs()} />
                {isLoading ? (
                    <Row justify='center'>
                        <Spin />
                    </Row>
                ) : (
                    <Content>{tabs[activeTabIndex]}</Content>
                )}
            </div>
        </ThemeProvider>
    );
};

export default Questionnaire;
