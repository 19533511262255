import { all, fork } from '@redux-saga/core/effects';

import { complianceSaga } from './compliance';
import { coterieQuestionnaireSaga } from './coterieQuestionnaire';
import { ratesSaga } from './rates';
import { questionsSaga } from './questions';

export function* rootSaga() {
    yield all([
        fork(coterieQuestionnaireSaga),
        fork(ratesSaga),
        fork(complianceSaga),
        fork(questionsSaga)
    ]);
}
