import type * as RT from '../../types/reduxTypes';
import * as actType from '../actionTypes/questions';

export const getQuestionsFailure = (
    payload: Partial<RT.IQuestionsState>
): RT.IGetQuestionsFailure => ({
    payload,
    type: actType.GET_QUESTIONS_FAILURE
});

export const setQuestions = (payload: Partial<RT.IQuestionsList>): RT.ISetQuestionsList => ({
    payload,
    type: actType.GET_QUESTIONS_SUCCESS
});

export const getQuestionsRequest = (payload: { classCode: string }): RT.IGetQuestionsRequest => ({
    payload,
    type: actType.GET_QUESTIONS_REQUEST
});

export const setQuestionsState = (payload: Partial<RT.IQuestionsState>): RT.ISetQuestionsState => ({
    payload,
    type: actType.SET_QUESTIONS_STATE
});
