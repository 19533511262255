import { Form, Input } from 'antd';
import type { ChangeEvent, FC, useEffect } from 'react';
import { validateMessages } from '../../constants';
import type { IPhoneInputProps } from '../../types';
import { DIGIT_FILTER_REGEX, PHONE_REGEX } from '../../utils/regexp';
import { useFormElementsStyles } from './formElementsStyles';
import { useFetcher } from 'react-router-dom';

const { Item } = Form;

export const PhoneInput: FC<IPhoneInputProps> = ({ label, name, formInstance }) => {
    const classes = useFormElementsStyles();

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const cleaned = e.target.value.replace(DIGIT_FILTER_REGEX, '');
        const match = PHONE_REGEX.exec(cleaned);
        let num = '';
        if (match) {
            if (match[1]) {
                num += `(${match[1]}`;
            }
            if (match[2]) {
                num += `) ${match[2]}`;
            }
            if (match[3]) {
                num += `-${match[3]}`;
            }
        }
        // eslint-disable-next-line
        formInstance.setFieldsValue({
            [name[0]]: {
                [name[1]]: num
            }
        });
    };

    return (
        <Item
            className={classes.materialFormItem}
            label={
                <p>
                    {label}

                    <strong>*</strong>
                </p>
            }
            name={name}
            rules={[
                {
                    message: validateMessages.required,
                    required: true
                },
                {
                    validator: (_, value: string, cb) => {
                        const num = value.replace(DIGIT_FILTER_REGEX, '').slice(0, 10);
                        let warn = true;
                        if (num.length > 9) {
                            for (const char of num) {
                                if (char !== num[0]) {
                                    warn = false;
                                    break;
                                }
                            }
                        }
                        if (warn) cb(validateMessages.types.phone);
                        else cb();
                    }
                }
            ]}
            validateFirst>
            <Input name={name.toString()} onChange={onChangeHandler} />
        </Item>
    );
};
