import { Form, Select } from 'antd';
import type { FC } from 'react';
import { validateMessages } from '../../constants';
import type { IFormSelectProps } from '../../types';
import { useFormElementsStyles } from './formElementsStyles';

const { Item } = Form;
const { Option } = Select;

export const FormSelect: FC<IFormSelectProps> = ({
    label,
    name,
    selectOption,
    showSearch = false
}) => {
    const classes = useFormElementsStyles();
    return (
        <div id={name[1]} style={{ position: 'relative' }}>
            <Item
                className={classes.materialFormItem}
                label={
                    <p>
                        {label}

                        <strong>*</strong>
                    </p>
                }
                name={name}
                rules={[{ message: validateMessages.required, required: true }]}>
                <Select
                    defaultActiveFirstOption
                    getPopupContainer={() => document.getElementById(name[1])!}
                    optionFilterProp='children'
                    showSearch={showSearch}>
                    <Option key='select' value=''>
                        Select
                    </Option>

                    {Object.entries(selectOption).map(([key, val]) => (
                        <Option key={key} value={key}>
                            {val as string}
                        </Option>
                    ))}
                </Select>
            </Item>
        </div>
    );
};
