/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createCipher, createDecipher } from 'crypto-browserify';
import { config } from '../configs';

export const encryptData = (details: string): string => {
    if (!details) return details;
    const key = createCipher('aes-192-cbc', config.cryptoKey);
    let hex: string = key.update(details, 'utf8', 'hex');
    hex += key.final('hex');
    return hex;
};

export const decryptData = (data: string): string | null => {
    try {
        const key = createDecipher('aes-192-cbc', config.cryptoKey);
        let pass: string = key.update(data, 'hex', 'utf8');
        pass += key.final('utf8');
        return pass;
    } catch (error: unknown) {
        return null;
    }
};
