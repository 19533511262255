import type { ICarrier } from '../types';
import type { PolicyTypes } from '../types/reduxTypes';
import { ICONS } from './icons';

export const COTERIE_CARRIER_ID = 'COTERIE';
export const CNA_CARRIER_ID = 'CNA';

export enum CarrierTypes {
    CNA = 'CNA',
    COTERIE = 'COTERIE'
}

export const CARRIERS: Array<ICarrier> = [
    {
        id: CarrierTypes.COTERIE,
        name: 'Coterie'
    },
    {
        id: CarrierTypes.CNA,
        name: 'CNA'
    }
];
export const LOB_OPTIONS: Array<{ id: PolicyTypes; name: string; carriers: Array<CarrierTypes> }> =
    [
        {
            carriers: [CarrierTypes.CNA, CarrierTypes.COTERIE],
            id: 'BOP',
            name: 'Business Owner Policy'
        },
        {
            carriers: [CarrierTypes.COTERIE],
            id: 'GL',
            name: 'General Liability'
        },
        {
            carriers: [CarrierTypes.COTERIE],
            id: 'PL',
            name: 'Professional Liability'
        }
    ];

export const QUESTIONNAIRE_TABS = [
    {
        icon: ICONS.USER,
        id: 'applicant',
        name: 'Applicant Info'
    },
    {
        icon: ICONS.BUSINESS_INFO,
        id: 'business',
        name: 'Business Info'
    },
    {
        icon: ICONS.QUESTION,
        id: 'questions',
        name: 'Additional Questions'
    },
    {
        icon: ICONS.LOSS_ICON,
        id: 'loss',
        name: 'Loss Info'
    },
    {
        icon: ICONS.POLICY_INFO,
        id: 'policy',
        name: 'Requested Policy'
    },
    {
        icon: ICONS.RATES,
        id: 'rates',
        name: 'Rates'
    }
];

export const mockUrlData = {
    businessName: 'Subway',
    coterieAgencyId: 'ff01ce8e-b17b-4ce9-8873-e7ee6ff179a8',
    coterieProducerId: '6cd7958c-6252-4db8-9b15-9fa6879db80e',
    industry: {
        cnaClassCode: '07821',
        coterieAkHash: '062ac79188e64a0da2ed8dcc713b563b',
        coterieId: '10025',
        description: 'Games, computer software, publishing'
    },
    primaryState: 'CA',
    producerInfo: {
        email: 'fake-email8630@yopmail.com',
        firstName: 'Cristian',
        lastName: 'Ramos',
        producerCode: 'FC09288',
        producerId: 4635
    },
    product: [],
    provider: 'firstconnect',
    requestId: '943cb6d5-5c68-4411-8108-600422255c50'
};
