import { Form } from 'antd';
import type { FC } from 'react';
import generatePicker from 'antd/es/date-picker/generatePicker';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import type { Moment } from 'moment';

import { validateMessages } from '../../constants';
import { useFormElementsStyles } from './formElementsStyles';
import type { IFormYearProps } from '../../types';

const { Item } = Form;

const DatePicker = generatePicker<Moment>(momentGenerateConfig);

export const FormYear: FC<IFormYearProps> = ({ label, name, disabledDate, ...attrs }) => {
    const classes = useFormElementsStyles();
    // const dateFormat = ['MM/DD/YYYY', 'MMDDYY', 'MMDDYYYY', 'MM/DD/YY'];

    return (
        <div id={name[1]}>
            <Item
                className={classes.materialFormItem}
                label={
                    <p>
                        {label}

                        <strong>*</strong>
                    </p>
                }
                name={name}
                rules={[{ message: validateMessages.required, required: true }]}>
                <DatePicker
                    disabledDate={disabledDate}
                    getPopupContainer={() => document.getElementById(name[1])!}
                    picker='year'
                    {...attrs}
                />
            </Item>
        </div>
    );
};
// FormYear.defaultProps = { showToday: true };
