import { combineReducers } from 'redux';

import { complianceReducer } from './compliance';
import { coterieQuestionnaireReducer } from './coterieQuestionnaire';
import { ratesReducer } from './rates';
import { questionsReducer } from './questions';

export const rootReducer = combineReducers({
    compliance: complianceReducer,
    coterieQuestionnaire: coterieQuestionnaireReducer,
    questions: questionsReducer,
    rates: ratesReducer
});
