import { DIGIT_FILTER_REGEX, PHONE_REGEX } from './regexp';

export * from './crypto';
export * from './axiosInstance';
export * from './regexp';
export * from './comparators';
export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e: unknown) {
        return true;
    }
};

export const currencyFormat = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency'
});

/**
 * @param value string
 * @param len number
 * @returns a number that can be NaN.
 */
export const strToInt = (value: string, len = 9) =>
    Number.isInteger(value)
        ? (value as unknown as number)
        : parseInt(value.replace(DIGIT_FILTER_REGEX, '').slice(0, len), 10);

export const currencyNumToStr = (value: number | undefined) => {
    let x = currencyFormat.format(value ?? NaN);
    if (x === '$NaN') x = '';
    return x;
};

export const isYesOrNoQuestion = (options: Array<any>) =>
    options.length === 2 &&
    ((options[0].optionId === 'yes' && options[1].optionId === 'no') ||
        (options[0].optionId === 'no' && options[1].optionId === 'yes'));

export const formatPhoneNumber = (phone: string) => {
    const cleaned = phone.replace(DIGIT_FILTER_REGEX, '');
    const match = PHONE_REGEX.exec(cleaned);
    let formatted = '';
    if (match) {
        if (match[1]) {
            formatted += `(${match[1]}`;
        }
        if (match[2]) {
            formatted += `) ${match[2]}`;
        }
        if (match[3]) {
            formatted += `-${match[3]}`;
        }
    }
    return formatted;
};
