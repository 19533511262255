import { Button, Card, Col, Form, Row, Switch, notification } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { AddressForm, FormInputText, LicensedStatesModal, PhoneInput } from '../../../components';
import { config } from '../../../configs';
import type { CarrierTypes } from '../../../constants';
import { formStateMap as fsm, literals, validateMessages } from '../../../constants';
import {
    createQuestionnaireRequest,
    setApplicantInfo,
    setCoterieQuestionnaireState,
    updateQuestionnaireRequest
} from '../../../redux/actions';
import { getComplianceMarketRequest } from '../../../redux/actions/compliance';
import type { IFormPropTypes } from '../../../types';
import type * as RT from '../../../types/reduxTypes';
import { compareApplicantInfo, inIframe } from '../../../utils';
import { useQuestionnaireStyles } from '../questionnaireStyles';

const { Item } = Form;

const ApplicantForm: FC<IFormPropTypes & { carriers: Array<CarrierTypes> }> = ({
    nextTab,
    setAreFormsUpdated,
    carriers
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [forceSubmit, setForceSubmit] = useState(false);
    const [showLicensedStateModal, setShowLicensedStateModal] = useState(false);
    const classes = useQuestionnaireStyles();
    const navigate = useNavigate();

    const {
        applicantInfo,
        clientId,
        complianceStatus,
        coterieAgencyId,
        coterieProducerId,
        id,
        markets,
        policyTypes,
        producerCode,
        producerEmail,
        producerId,
        status,
        policyInfo,
        classCode,
        requestId
    } = useSelector(
        ({
            coterieQuestionnaire: { data, status },
            compliance: {
                data: { markets },
                status: complianceStatus
            }
        }: RT.TRootState) => ({
            ...data,
            complianceStatus,
            markets,
            status
        })
    );
    const [showMailingAddress, setShowMailingAddress] = useState(
        !applicantInfo.isMailingAddressSame
    );

    useEffect(() => {
        if (complianceStatus === 'idle' || !markets.length) {
            dispatch(getComplianceMarketRequest({ producerCode }));
        }
    }, []);

    useEffect(() => {
        if (status === 'created' || status === 'updated') {
            if (forceSubmit) {
                setForceSubmit(false);
            }
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
            nextTab();
        } else if (status === 'failed') {
            notification.error({
                description: literals.SUBMISSION_ERROR,
                message: 'Failed'
            });
            setForceSubmit(true);
            dispatch(setCoterieQuestionnaireState({ status: 'idle' }));
        }
    }, [status]);

    const toggleMailingAddress = (checked: boolean) => {
        setShowMailingAddress(!checked);
        const fieldValues = form.getFieldsValue();
        if (checked) {
            form.setFieldsValue({
                applicantInfo: {
                    mailingCity: fieldValues.applicantInfo.city,
                    mailingState: fieldValues.applicantInfo.state,
                    mailingStreet: fieldValues.applicantInfo.street,
                    mailingUnit: fieldValues.applicantInfo.unit,
                    mailingZipCode: fieldValues.applicantInfo.zipCode
                }
            });
        } else {
            form.setFieldsValue({
                applicantInfo: {
                    mailingCity: '',
                    mailingState: '',
                    mailingStreet: '',
                    mailingUnit: '',
                    mailingZipCode: ''
                }
            });
        }
    };
    const submit = (value: { applicantInfo: RT.IApplicantInfo }) => {
        if (markets?.length && markets?.includes(value.applicantInfo.state)) {
            const updatedApplicantInfo = {
                ...value.applicantInfo,
                isMailingAddressSame: !showMailingAddress
            };
            if (
                !compareApplicantInfo(
                    value.applicantInfo,
                    applicantInfo as unknown as RT.IApplicantInfo
                ) ||
                forceSubmit
            ) {
                dispatch(setApplicantInfo(updatedApplicantInfo));
                if (id) {
                    dispatch(
                        updateQuestionnaireRequest({
                            applicantInfo: updatedApplicantInfo
                        })
                    );
                } else {
                    dispatch(
                        createQuestionnaireRequest({
                            applicantInfo: updatedApplicantInfo,
                            carriers,
                            classCode,
                            clientId,
                            coterieAgencyId,
                            coterieProducerId,
                            policyInfo,
                            policyTypes,
                            producerCode,
                            producerEmail,
                            producerId,
                            requestId
                        })
                    );
                }
                setAreFormsUpdated(true);
            } else if (id) {
                nextTab();
            } else {
                dispatch(
                    createQuestionnaireRequest({
                        applicantInfo: updatedApplicantInfo,
                        carriers,
                        classCode,
                        clientId,
                        coterieAgencyId,
                        coterieProducerId,
                        policyInfo,
                        policyTypes,
                        producerCode,
                        producerEmail,
                        producerId,
                        requestId
                    })
                );
            }
        } else {
            setShowLicensedStateModal(true);
        }
    };

    const validateAddress = () => {
        if (!showMailingAddress) {
            const fieldValues = form.getFieldsValue();
            form.setFieldsValue({
                applicantInfo: {
                    mailingCity: fieldValues.applicantInfo.city,
                    mailingState: fieldValues.applicantInfo.state,
                    mailingStreet: fieldValues.applicantInfo.street,
                    mailingUnit: fieldValues.applicantInfo.unit,
                    mailingZipCode: fieldValues.applicantInfo.zipCode
                }
            });
        }
    };

    const goToDashboard = () => {
        if (inIframe()) {
            if (window.top) window.top.location.href = config.fcDashboard;
        } else {
            navigate('/');
        }
    };

    return (
        <Card className={classes.form}>
            <Row justify='center'>
                <Col span={18}>
                    <Row justify='center'>
                        <h2 className={classes.head2}>{literals.ENTER_APPLICANT_INFO}</h2>
                    </Row>
                    <Form
                        form={form}
                        initialValues={{ applicantInfo }}
                        layout='vertical'
                        onFinish={submit}
                        requiredMark={false}
                        scrollToFirstError
                        validateMessages={validateMessages}>
                        <Row gutter={[32, 8]}>
                            <Col md={{ span: 8 }} span={24}>
                                <FormInputText
                                    capitalize='all'
                                    formInstance={form}
                                    label={literals.FIRST_NAME}
                                    maxChar={30}
                                    name={[fsm.applicantInfo, fsm.firstName]}
                                    variant='name'
                                />
                            </Col>

                            <Col md={{ span: 8 }} span={24}>
                                <FormInputText
                                    capitalize='all'
                                    formInstance={form}
                                    label={literals.LAST_NAME}
                                    maxChar={30}
                                    name={[fsm.applicantInfo, fsm.lastName]}
                                    variant='name'
                                />
                            </Col>

                            <Col md={{ span: 8 }} span={24}>
                                <FormInputText
                                    formInstance={form}
                                    label={literals.BUSINESS_NAME_LABEL}
                                    maxChar={100}
                                    name={[fsm.applicantInfo, fsm.businessName]}
                                    variant='alphanum'
                                />
                            </Col>
                        </Row>
                        <AddressForm
                            formInstance={form}
                            labels={{
                                labelCity: literals.CITY,
                                labelState: literals.STATE,
                                labelStreet: literals.STREET_ADDRESS,
                                labelUnit: literals.UNIT,
                                labelZip: literals.ZIP_CODE
                            }}
                            names={{
                                nameCity: [fsm.applicantInfo, fsm.city],
                                nameState: [fsm.applicantInfo, fsm.state],
                                nameStreet: [fsm.applicantInfo, fsm.street],
                                nameUnit: [fsm.applicantInfo, fsm.unit],
                                nameZip: [fsm.applicantInfo, fsm.zipCode]
                            }}
                        />

                        <Row gutter={[32, 8]}>
                            <Col md={{ span: 12 }} span={24}>
                                <PhoneInput
                                    formInstance={form}
                                    label={literals.PHONE_NO_LABEL}
                                    name={[fsm.applicantInfo, fsm.phoneNumber]}
                                />
                            </Col>

                            <Col md={{ span: 12 }} span={24}>
                                <FormInputText
                                    formInstance={form}
                                    label={literals.EMAIL_ADDRESS}
                                    name={[fsm.applicantInfo, fsm.email]}
                                    type='email'
                                />
                            </Col>
                        </Row>
                        <Row className={classes.mailingAddressSwitchWrapper} justify='start'>
                            <h3 className={classes.head3}>{literals.MAILING_ADDRESS_LABEL}</h3>
                            <p>{literals.SAME_AS_BUSINESS_ADDRESS}</p>
                            <Switch checked={!showMailingAddress} onChange={toggleMailingAddress} />
                        </Row>
                        <Col hidden={!showMailingAddress}>
                            <AddressForm
                                formInstance={form}
                                labels={{
                                    labelCity: literals.MAILING_CITY,
                                    labelState: literals.MAILING_STATE,
                                    labelStreet: literals.MAILING_STREET_ADDRESS,
                                    labelUnit: literals.MAILING_UNIT,
                                    labelZip: literals.MAILING_ZIP_CODE
                                }}
                                names={{
                                    nameCity: [fsm.applicantInfo, fsm.mailingCity],
                                    nameState: [fsm.applicantInfo, fsm.mailingState],
                                    nameStreet: [fsm.applicantInfo, fsm.mailingStreet],
                                    nameUnit: [fsm.applicantInfo, fsm.mailingUnit],
                                    nameZip: [fsm.applicantInfo, fsm.mailingZipCode]
                                }}
                            />
                        </Col>

                        <Row justify='center' style={{ gap: '20px' }}>
                            <Item>
                                <Button
                                    className={classes.backBtn}
                                    onClick={goToDashboard}
                                    shape='round'
                                    type='default'>
                                    Cancel
                                </Button>
                            </Item>
                            <Item>
                                <Button
                                    className={classes.continueBtn}
                                    htmlType='submit'
                                    loading={status === 'pending'}
                                    onClick={validateAddress}
                                    shape='round'
                                    type='default'>
                                    Continue
                                </Button>
                            </Item>
                        </Row>

                        <LicensedStatesModal
                            formInstance={form}
                            modalVisible={showLicensedStateModal}
                            setModalVisible={setShowLicensedStateModal}
                        />
                    </Form>
                </Col>
            </Row>
        </Card>
    );
};

export default ApplicantForm;
