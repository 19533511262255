import { Col, Form, Row } from 'antd';
import type { FC } from 'react';
import { useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

import { FormInputText, FormSelect } from '..';
import { config } from '../../configs';
import { US_STATES, addressTypes } from '../../constants';
import type { IAddressFormProps } from '../../types';
import { getAddress } from '../../utils/googlePlaceUtils';
import { useFormElementsStyles } from '../FormElements';

const { Item } = Form;

export const AddressForm: FC<IAddressFormProps> = ({ labels, names, formInstance }) => {
    const { labelStreet, labelCity, labelState, labelZip, labelUnit } = labels;
    const classes = useFormElementsStyles();

    const { ref } = usePlacesWidget<HTMLInputElement>({
        apiKey: config.googleAutoCompleteKey,
        onPlaceSelected: (place) => {
            const { cityAddress, stateAddress, streetAddress, zipAddress } = getAddress(place);
            formInstance.setFieldsValue({
                [names.nameCity[0]]: {
                    [names.nameCity[1]]: cityAddress,
                    [names.nameState[1]]: stateAddress,
                    [names.nameStreet[1]]: streetAddress,
                    [names.nameZip[1]]: zipAddress
                }
            });
        },
        options: {
            componentRestrictions: { country: addressTypes.COUNTRY },
            types: [addressTypes.TYPE]
        }
    });

    useEffect(() => {
        const inputElement = document.getElementById(
            `${names.nameStreet[0]}_${names.nameStreet[1]}`
        ) as HTMLInputElement;
        // find scrolldiv at containers/Questionnaire.tsx
        const scrollDiv = document.getElementById('scrolldiv') as HTMLDivElement;
        if (scrollDiv) {
            scrollDiv.onscroll = () => {
                const rect = inputElement.getBoundingClientRect();
                const pacContainers = document.getElementsByClassName(
                    'pac-container'
                ) as HTMLCollectionOf<HTMLDivElement>;
                if (pacContainers) {
                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < pacContainers.length; i++) {
                        pacContainers[i].style.top = `${rect.y + 32}px`;
                    }
                }
            };
        }
    }, []);

    return (
        <>
            <Row gutter={[32, 8]}>
                <Col md={{ span: 16 }} span={24}>
                    <Item
                        className={classes.materialFormItem}
                        label={
                            <p>
                                {labelStreet}

                                <strong>*</strong>
                            </p>
                        }
                        name={names.nameStreet}
                        rules={[{ required: true }]}>
                        <input
                            ref={ref}
                            className='ant-input'
                            onBlur={(e) => {
                                formInstance.setFieldsValue({
                                    [names.nameStreet[0]]: {
                                        [names.nameStreet[1]]: e.target.value.trimEnd()
                                    }
                                });
                            }}
                            onChange={(e) => {
                                formInstance.setFieldsValue({
                                    [names.nameStreet[0]]: {
                                        [names.nameStreet[1]]: e.target.value.trimStart()
                                    }
                                });
                            }}
                            placeholder=''
                        />
                    </Item>
                </Col>

                <Col md={{ span: 8 }} span={24}>
                    <FormInputText
                        formInstance={formInstance}
                        label={labelUnit}
                        maxChar={30}
                        name={names.nameUnit}
                        required={false}
                        variant='default'
                    />
                </Col>
            </Row>

            <Row gutter={[32, 8]}>
                <Col md={{ span: 8 }} span={24}>
                    <FormInputText
                        formInstance={formInstance}
                        label={labelCity}
                        maxChar={100}
                        name={names.nameCity}
                        variant='alphanum'
                    />
                </Col>

                <Col md={{ span: 8 }} span={24}>
                    <FormSelect
                        label={labelState}
                        name={names.nameState}
                        selectOption={US_STATES}
                    />
                </Col>

                <Col md={{ span: 8 }} span={24}>
                    <FormInputText
                        formInstance={formInstance}
                        label={labelZip}
                        maxChar={5}
                        minChar={4}
                        name={names.nameZip}
                        variant='num'
                    />
                </Col>
            </Row>
        </>
    );
};
