import type * as RT from '../../types/reduxTypes';
import * as actType from '../actionTypes/compliance';

export const getComplianceMarketRequest = (payload: {
    producerCode: string;
}): RT.IGetComplianceMarketRequest => ({
    payload,
    type: actType.GET_COMPLIANCE_MARKET_REQUEST
});

export const getComplianceMarketSuccess = (
    payload: RT.IComplianceState['data']['markets']
): RT.IGetComplianceMarketSuccess => ({
    payload,
    type: actType.GET_COMPLIANCE_MARKET_SUCCESS
});

export const getComplianceMarketFailure = (
    payload: RT.IComplianceState['error']
): RT.IGetComplianceMarketFailure => ({
    payload,
    type: actType.GET_COMPLIANCE_MARKET_FAILURE
});

export const getAvailableCarriers = (payload: string): RT.IGetAvailableCarriersRequest => ({
    payload,
    type: actType.GET_AVAILABLE_CARRIERS_REQUEST
});

export const getAvailableCarriersSuccess = (
    payload: RT.IComplianceState['data']['availableCarriers']
): RT.IGetAvailableCarriersSuccess => ({
    payload,
    type: actType.GET_AVAILABLE_CARRIERS_SUCCESS
});

export const getAvailableCarriersFailure = (
    payload: RT.IComplianceState['error']
): RT.IGetAvailableCarriersFailure => ({
    payload,
    type: actType.GET_AVAILABLE_CARRIERS_FAILURE
});
