import type * as RT from '../types/reduxTypes';

export const compareApplicantInfo = (a: RT.IApplicantInfo, b: RT.IApplicantInfo): boolean => {
    const keys = Object.keys(a) as unknown as Array<keyof RT.IApplicantInfo>;
    for (const key of keys) {
        if (a[key] !== b[key]) return false;
    }
    return true;
};
export const comparePolicyInfo = (a: RT.IPolicyInfo, b: RT.IPolicyInfo): boolean => {
    const keys = Object.keys(a) as unknown as Array<keyof RT.IPolicyInfo>;
    for (const key of keys) {
        if (key === 'policyStartDate') {
            if (!a[key]?.isSame(b[key])) return false;
        } else if (a[key] !== b[key]) return false;
    }
    return true;
};

export const compareCoverageObjects = (a: any, b: any): boolean => {
    for (const key of Object.keys(a)) {
        if (a[key] !== b[key]) return false;
    }
    return true;
};
export const compareBusinessInfo = (a: RT.IBusinessInfo, b: RT.IBusinessInfo): boolean => {
    const keys = Object.keys(a) as unknown as Array<keyof RT.IBusinessInfo>;
    for (const key of keys) {
        if (key === 'businessStartDate') {
            if (!a[key]?.isSame(b[key])) return false;
        } else if (a[key] !== b[key]) return false;
    }
    return true;
};

export const compareLossInfo = (a: Array<RT.ILosses>, b: Array<RT.ILosses>): boolean => {
    if (a instanceof Array && b instanceof Array) {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (a[i].amount !== b[i].amount || a[i].description !== b[i].description) return false;
        }
    }
    return true;
};

export const compareAnswers = (a: any, b: any): boolean => {
    for (const key of Object.keys(a)) {
        if (a[key] !== b[key]) return false;
    }
    return true;
};
